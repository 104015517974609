import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { dataLayerPush } from 'utils/analytics';
import {
  BOOKING_STEPS,
  CABIN_TYPE,
  IBookingFunnelState,
} from 'interfaces/IBooking';
import {
  ICabinCategory,
  ICabinsData,
  ICabinsInitialStateCabinsData,
} from 'interfaces/ICabin';
import { ISelectedPacket } from 'interfaces/IPacket';
import { IPassenger } from 'interfaces/IPassenger';
import { IQuote } from 'interfaces/IQuote';
import {
  commitQuote,
  deletePackage,
  getQuote,
  quoteCabin,
  quoteDeckSpace,
  quotePackages,
  quotePassengers,
} from 'store/services/quote';
import { getCabins } from 'store/services/voyages';
import { resetBookingStepHandler } from 'utils/resetBookingStep';
import { ICabin as IPassengerInfo } from 'containers/Book/BookingProgress/BookingFunnel/PassengerDetails/PassengerDetails.types';

const initialState: IBookingFunnelState = {
  preSelectedCabinType: null,
  selectedVoyageID: null,
  selectedTourID: null,
  quoteID: null,
  selectedPackageID: null,
  bookedCabins: [],
  selectedCabinIndex: 0,
  bookingStep: CABIN_TYPE,
  passengersData: [],
  currentCabinsData: null,
  currentQuoteData: null,
  commitQuoteData: null,
  passengersInfo: [],
};

const bookingFunnelSlice = createSlice({
  name: 'bookingFunnel',
  initialState,
  reducers: {
    setCabinType: (
      state,
      action: PayloadAction<{
        cabinTitle: string;
        cabinType: string;
        cabinIndex: number;
        price: number;
      }>
    ) => {
      const newCabin = action.payload;
      state.bookedCabins = [
        ...state.bookedCabins.filter(
          (cabin) => cabin.cabinIndex !== newCabin.cabinIndex
        ),
        newCabin,
      ];
    },
    setCabinGrade: (
      state,
      action: PayloadAction<{
        cabinGrade: string;
        cabinIndex: number;
        price: number;
        cabinGradeTitle: string;
        fareStructure: string;
      }>
    ) => {
      const { cabinIndex } = action.payload;
      const newCabin = {
        ...state.bookedCabins.find((cabin) => cabin.cabinIndex === cabinIndex),
        ...action.payload,
      };
      state.bookedCabins = [
        ...state.bookedCabins.filter(
          (cabin) => cabin.cabinIndex !== newCabin.cabinIndex
        ),
        newCabin,
      ];
    },
    setBookingStep: (state, action: PayloadAction<BOOKING_STEPS>) => {
      state.bookingStep = action.payload;
    },
    resetBookingFunnel: () => initialState,
    setPreSelectedCabinType: (state, action: PayloadAction<string>) => {
      state.preSelectedCabinType = action.payload;
    },
    setQuoteID: (state, action: PayloadAction<string>) => {
      state.quoteID = action.payload;
    },
    setSelectedVoyageID: (state, action: PayloadAction<string>) => {
      state.selectedVoyageID = action.payload;
    },
    setSelectedTourID: (state, action: PayloadAction<string>) => {
      state.selectedTourID = action.payload;
    },
    setSelectedCabinIndex: (state, action: PayloadAction<number>) => {
      state.selectedCabinIndex = action.payload;
    },
    setCabinNumber: (
      state,
      action: PayloadAction<{
        selectedCabinIndex: number;
        cabinNumber: string;
      }>
    ) => {
      const { selectedCabinIndex, cabinNumber } = action.payload;
      state.bookedCabins = state.bookedCabins.map((cabin, index) => {
        if (index === selectedCabinIndex) {
          return {
            ...cabin,
            cabinNumber,
          };
        }
        return cabin;
      });
    },
    addPackage: (state, action: PayloadAction<ISelectedPacket>) => {
      const packagePayload = action.payload;
      const { packageCode, passengers, selectedRoute } = packagePayload;
      const hasPackageCode = (_packageCode: string) =>
        _packageCode === packageCode;
      const hasSelectedPackageAlready = state.selectedPackages?.some(
        (_package) => hasPackageCode(_package.packageCode)
      );
      if (state.selectedPackages) {
        if (hasSelectedPackageAlready) {
          if (passengers.length > 0) {
            state.selectedPackages = state.selectedPackages.map((_package) =>
              hasPackageCode(_package.packageCode)
                ? {
                    ..._package,
                    passengers,
                    selectedRoute: selectedRoute || null,
                  }
                : _package
            );
          } else {
            state.selectedPackages = state.selectedPackages.filter(
              (_package) => !hasPackageCode(_package.packageCode)
            );
          }
        } else {
          state.selectedPackages.push(packagePayload);
        }
      } else {
        state.selectedPackages = [packagePayload];
      }
    },
    removePackage: (state, action: PayloadAction<string>) => {
      state.selectedPackages = state.selectedPackages?.filter(
        (_package) => _package.packageCode !== action.payload
      );
    },
    resetBookingStep: (
      state,
      action: PayloadAction<{
        selectedBookingStep: BOOKING_STEPS;
        cabinIndex: number;
        cabinCategories: ICabinCategory[];
      }>
    ) => {
      const { selectedBookingStep, cabinIndex, cabinCategories } =
        action.payload;
      state.bookedCabins = resetBookingStepHandler(
        selectedBookingStep,
        cabinIndex,
        state.bookedCabins,
        cabinCategories
      );
    },
    resetCurrentQuoteData: (state) => {
      state.currentQuoteData = initialState.currentQuoteData;
    },
    cancelBooking: () => initialState,
    setPassengersData: (
      state,
      action: PayloadAction<{
        passengersData:
          | IPassenger[]
          | ICabinsInitialStateCabinsData
          | ICabinsData
          | IQuote;
        converted: boolean;
      }>
    ) => {
      let allPassengers: IPassenger[] = [];

      if (action.payload.converted) {
        allPassengers = action.payload.passengersData as IPassenger[];
      } else if (
        (
          action?.payload?.passengersData as
            | ICabinsInitialStateCabinsData
            | ICabinsData
        )?.partyMixDetails?.cabinsRequested?.length
      ) {
        const rawPassengers = (
          action.payload.passengersData as
            | ICabinsInitialStateCabinsData
            | ICabinsData
        ).partyMixDetails.cabinsRequested.map((cabin) => cabin.passengers);

        allPassengers = rawPassengers.flat();
      } else {
        allPassengers = (action.payload.passengersData as IQuote).passengers;
      }
      state.passengersData = allPassengers;
    },
    setPassengersInfo: (state, action: PayloadAction<IPassengerInfo[]>) => {
      state.passengersInfo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(getCabins.matchFulfilled, (state, action) => {
        bookingFunnelSlice.caseReducers.setPassengersData(state, {
          type: 'setPassengersData',
          payload: {
            converted: false,
            passengersData: action.payload.cabinsData,
          },
        });
        state.currentCabinsData = action.payload.cabinsData;
      })
      .addMatcher(quoteDeckSpace.matchFulfilled, (state, action) => {
        bookingFunnelSlice.caseReducers.setPassengersData(state, {
          type: 'setPassengersData',
          payload: {
            converted: false,
            passengersData: action.payload,
          },
        });
        state.currentQuoteData = action.payload;
      })
      .addMatcher(getQuote.matchFulfilled, (state, action) => {
        state.currentQuoteData = action.payload;
      })
      .addMatcher(quoteCabin.matchFulfilled, (state, action) => {
        state.currentQuoteData = action.payload;
      })
      .addMatcher(quotePassengers.matchFulfilled, (state, action) => {
        state.currentQuoteData = action.payload;
      })
      .addMatcher(quotePackages.matchFulfilled, (state, action) => {
        state.currentQuoteData = action.payload;
      })
      .addMatcher(deletePackage.matchFulfilled, (state, action) => {
        state.currentQuoteData = action.payload;
      })
      .addMatcher(commitQuote.matchFulfilled, (state, action) => {
        state.commitQuoteData = action.payload;
        dataLayerPush('purchase', action.payload);
      });
  },
});

export const {
  setQuoteID,
  setSelectedVoyageID,
  setSelectedTourID,
  setCabinType,
  setCabinGrade,
  setSelectedCabinIndex,
  setPreSelectedCabinType,
  setBookingStep,
  cancelBooking,
  resetBookingStep,
  resetCurrentQuoteData,
  resetBookingFunnel,
  setCabinNumber,
  setPassengersData,
  addPackage,
  removePackage,
  setPassengersInfo,
} = bookingFunnelSlice.actions;
export default bookingFunnelSlice.reducer;
