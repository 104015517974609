import { handleActions } from 'redux-actions';
import { IOnboardingState } from 'interfaces/IOnboarding';
import { onboardingStep } from 'utils/constants';

import {
  resetOnboardData,
  saveOnboardData,
  fetchLeadValuesStart,
  fetchLeadValuesSuccess,
  fetchLeadValuesFailed,
  fetchAgentValuesStart,
  fetchAgentValuesSuccess,
  fetchAgentValuesFailed,
  fetchAgencyValuesStart,
  fetchAgencyValuesSuccess,
  fetchAgencyValuesFailed,
} from '../actions/onboarding';

const initialState: IOnboardingState = {
  agentData: null,
  agencyData: null,
  selectedAgencyId: null,
  agencyId: null,
  email: null,
  password: null,
  region: null,
  agentAddress: null,
  userWantToRegisterAgency: false,
  userSearchedAgencies: false,
  confirmedAgencyData: false,
  activeStep: onboardingStep.AgentRegistration,
  leadValuesLoading: null,
  agencyValidValues: null,
  agencyValuesLoading: false,
  leadValidValues: null,
  agentValidValues: null,
  agentValuesLoading: false,
  error: false,
  values: null,
  fields: null,
};

const reducer = handleActions<IOnboardingState>(
  {
    [resetOnboardData.toString()]: () => ({
      ...initialState,
    }),
    [saveOnboardData.toString()]: (state, action) => ({
      ...state,
      ...action.payload,
    }),
    [fetchLeadValuesStart.toString()]: (state) => ({
      ...state,
      leadValuesLoading: true,
    }),
    [fetchLeadValuesSuccess.toString()]: (state, action) => ({
      ...state,
      leadValidValues: action.payload.fields,
      leadValuesLoading: false,
    }),
    [fetchLeadValuesFailed.toString()]: (state, action) => ({
      ...state,
      leadValuesLoading: false,
      error: action.payload?.error || null,
    }),
    [fetchAgentValuesStart.toString()]: (state) => ({
      ...state,
      agentValuesLoading: true,
    }),
    [fetchAgentValuesSuccess.toString()]: (state, action) => ({
      ...state,
      agentValidValues: action.payload?.values || state.values,
      agentValuesLoading: false,
      error: null,
    }),
    [fetchAgentValuesFailed.toString()]: (state, action) => ({
      ...state,
      agentValuesLoading: false,
      error: action.payload?.error || null,
    }),
    [fetchAgencyValuesStart.toString()]: (state) => ({
      ...state,
      agencyValuesLoading: true,
    }),
    [fetchAgencyValuesSuccess.toString()]: (state, action) => ({
      ...state,
      agencyValidValues: action.payload?.values || state.values,
      agencyValuesLoading: false,
    }),
    [fetchAgencyValuesFailed.toString()]: (state, action) => ({
      ...state,
      agencyValuesLoading: false,
      error: action.payload?.error || null,
    }),
  },
  initialState
);

export default reducer;
