import { SVGProps } from 'react';

const ArrowRightShortIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 12C1.5 11.7239 1.72386 11.5 2 11.5H22C22.2761 11.5 22.5 11.7239 22.5 12C22.5 12.2761 22.2761 12.5 22 12.5H2C1.72386 12.5 1.5 12.2761 1.5 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M14.6464 4.64645C14.8417 4.45118 15.1583 4.45118 15.3536 4.64645L22.3536 11.6464C22.5488 11.8417 22.5488 12.1583 22.3536 12.3536L15.3536 19.3536C15.1583 19.5488 14.8417 19.5488 14.6464 19.3536C14.4512 19.1583 14.4512 18.8417 14.6464 18.6464L21.2929 12L14.6464 5.35355C14.4512 5.15829 14.4512 4.84171 14.6464 4.64645Z"
    />
  </svg>
);

export default ArrowRightShortIcon;
