import { SVGProps } from 'react';

const PaperIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={21}
    viewBox="0 0 17 21"
    {...props}
  >
    <path
      fill="#000"
      d="M15.93.067c.15.09.25.25.25.43v18.29c0 .18-.09.34-.25.43l-1.52.89c-.16.09-.35.09-.51 0l-1.26-.74-1.27.74c-.15.09-.34.09-.5 0l-1.27-.74-1.26.74c-.16.09-.35.09-.51 0l-1.26-.74-1.27.74c-.15.09-.35.09-.5 0l-1.27-.74-1.26.74c-.15.09-.35.09-.5 0l-1.52-.89a.484.484 0 0 1-.25-.43V.498c0-.18.1-.34.25-.43.16-.09.35-.09.5 0l1.27.74 1.26-.74c.15-.09.35-.09.51 0l1.26.74 1.26-.74c.16-.09.35-.09.51 0l1.27.74 1.26-.74c.16-.09.35-.09.51 0l1.26.74 1.27-.74c.15-.09.35-.09.5 0l1.27.74 1.27-.74c.15-.09.34-.09.5 0Zm-3.29 1.01-1.26.74c-.16.1-.36.1-.51 0l-1.27-.74-1.26.74c-.16.1-.35.1-.51 0l-1.26-.74-1.27.74c-.15.1-.35.1-.5 0l-1.27-.74-1.26.74c-.15.1-.35.1-.51 0L1 1.367v17.13l1.02.6 1.26-.74c.15-.09.35-.09.5 0l1.27.74 1.27-.74c.15-.09.34-.09.5 0l1.27.74 1.26-.74c.16-.09.35-.09.51 0l1.26.74 1.27-.74c.15-.09.35-.09.5 0l1.27.74 1.02-.6V1.367l-.77.45c-.16.1-.35.1-.51 0l-1.26-.74Zm.36 14.53c.28 0 .5.23.5.5 0 .28-.22.5-.5.5h-2.68c-.27 0-.5-.22-.5-.5 0-.27.23-.5.5-.5H13Zm0-2.67c.28 0 .5.22.5.5 0 .27-.22.5-.5.5H3.18c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5H13Zm0-2.68c.28 0 .5.22.5.5s-.22.5-.5.5H3.18c-.28 0-.5-.22-.5-.5s.22-.5.5-.5H13Zm0-2.68c.28 0 .5.23.5.5 0 .28-.22.5-.5.5H3.18c-.28 0-.5-.22-.5-.5 0-.27.22-.5.5-.5H13Zm-2.68-3.57c.28 0 .5.23.5.5 0 .28-.22.5-.5.5H5.86c-.28 0-.5-.22-.5-.5 0-.27.22-.5.5-.5h4.46Z"
      fillRule="evenodd"
    />
  </svg>
);

export default PaperIcon;
