import { LinkText } from 'components';
import { Header2, BodyText } from 'components/UI/Brand/text';
import { createFailedMessage, createSuccessMessage } from 'store/actions';
import translate from 'translate';
import { useValidateUserMutation } from 'store/services/user';
import { useEffect } from 'react';
import { useAppDispatch } from 'hooks/storeHooks';
import OnboardHeader from '../../Onboard/OnboardHeader/OnboardHeader';
import styles from './styles.module.scss';

interface ILoginWaitEmailVerification {
  email: string;
}

const LoginWaitEmailVerification = ({ email }: ILoginWaitEmailVerification) => {
  const dispatch = useAppDispatch();
  const [validateUser, { isError, isSuccess }] = useValidateUserMutation();

  useEffect(() => {
    if (isSuccess) {
      dispatch(
        createSuccessMessage(
          translate('Login_AccountMigration_WeHaveSentEmailMessage')
        )
      );
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isError) {
      dispatch(
        createFailedMessage(
          translate('Login_AccountMigration_SomethingWentWrongMessage')
        )
      );
    }
  }, [isError]);

  return (
    <div data-testid="loginWaitEmailVerification-container">
      <OnboardHeader title={translate('General_SignUp')} />
      <div className={styles.root}>
        <Header2 marginBottom="three">
          {translate('Login_AccountMigration_FirstPage_Title')}
        </Header2>
        <BodyText className={styles.desc}>
          {translate(
            'Login_EmailHasBeenSentDesc',
            <span className={styles.bold}>{` ${email} `}</span>
          )}
        </BodyText>
        <BodyText className={styles.desc}>
          {translate(
            'Login_AccountMigration_FirstPage_IfYouDontReceiveDesc',
            <button
              className={styles.reSendEmailButton}
              onClick={() => {
                validateUser({ email });
              }}
            >
              <LinkText className={styles.label}>
                {translate('General_ClickHere')}
              </LinkText>
            </button>
          )}
        </BodyText>
      </div>
    </div>
  );
};

export default LoginWaitEmailVerification;
