import { ICabin } from 'interfaces/ICabin';

export const getTotalPasssengersInCabin = (
  cabins: ICabin[],
  isInfantsIncluded = true
): { totalPassengers: number; totalInfants: number } => {
  const totalPassengersWithInfants = cabins?.reduce(
    (total, cabin) =>
      total +
      cabin?.adults +
      cabin?.children +
      (cabin?.infants ?? 0) +
      (cabin?.benefitFares ?? 0) +
      (cabin?.companions ?? 0) +
      (cabin?.militaries ?? 0) +
      (cabin?.students ?? 0),
    0
  );

  const totalInfants = cabins?.reduce(
    (total, cabin) => total + cabin.infants,
    0
  );

  return isInfantsIncluded
    ? { totalPassengers: totalPassengersWithInfants, totalInfants }
    : {
        totalPassengers: totalPassengersWithInfants - totalInfants,
        totalInfants,
      };
};
