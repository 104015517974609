import Icon from 'components/UI/Icons/Icon';
import { TIcon } from 'components/UI/Icons/Icon.type';
import { ButtonHTMLAttributes } from 'react';
import { typography } from 'style/typography';
import { colors } from 'style/colors';
import styled from 'styled-components';

type TButtonSize = 'normal' | 'small';

interface IFlatIcon
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'disabled'> {
  buttonColor?: string;
  size?: TButtonSize;
  isDisabled?: boolean;
  icon?: TIcon;
  iconFill?: string;
  iconPosition?: 'left' | 'right';
  iconWidth?: number;
  iconHeight?: number;
  classes?: string;
  dataTestid?: string;
}

const Button = styled.button<IFlatIcon>`
  color: ${colors.shared.black};
  font-size: ${({ size }) =>
    size === 'normal' ? '16px' : typography.normal.fontSize.small};
  line-height: ${({ size }) =>
    size === 'normal'
      ? typography.normal.lineHeight.normal
      : typography.normal.lineHeight.small};
  font-family: ${typography.normal.fontFamily};
  font-weight: ${({ size }) => (size === 'normal' ? 500 : 300)};
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px 0;
  &:hover:not([disabled]) {
    text-decoration: underline;
  }
`;

const ButtonChildren = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const FlatButton = ({
  icon,
  iconPosition = 'left',
  size = 'normal',
  iconFill,
  children,
  classes,
  type,
  isDisabled,
  iconWidth,
  iconHeight,
  dataTestid,
  ...rest
}: IFlatIcon) => {
  const buttonChildren = (
    <ButtonChildren>
      {icon && iconPosition === 'left' && (
        <Icon
          data-testid={dataTestid}
          iconType={icon}
          fill={iconFill}
          width={iconWidth}
          height={iconHeight}
        />
      )}
      {children}
      {icon && iconPosition === 'right' && (
        <Icon
          data-testid={dataTestid}
          iconType={icon}
          fill={iconFill}
          width={iconWidth}
          height={iconHeight}
        />
      )}
    </ButtonChildren>
  );

  return (
    <Button
      data-testid={dataTestid}
      className={classes}
      type={type}
      disabled={isDisabled}
      size={size}
      {...rest}
    >
      {buttonChildren}
    </Button>
  );
};
