import { IProduct, IProductPayload } from 'interfaces/IAnalytics';
import { handleActions } from 'redux-actions';
import {
  setCabinGradeProduct,
  setProductData,
  setCabinNumberProduct,
} from 'store/actions';

const inititalState: { products: IProduct[] } = {
  products: [],
};

const reducer = handleActions<{ products: IProduct[] }, IProductPayload>(
  {
    [setProductData.toString()]: (state, action) => ({
      ...state,
      products: action.payload.products,
    }),
    [setCabinGradeProduct.toString()]: (state, action) => {
      const data = state.products.map((product) => {
        if (product.category.includes('Cabin')) {
          return {
            ...product,
            id: action.payload.cabinGrade,
            price: action.payload.cabinPrice,
          };
        }
        return product;
      });

      return {
        ...state,
        products: data,
      };
    },
    [setCabinNumberProduct.toString()]: (state, action) => {
      const data = state.products.map((product) => {
        if (product.category.includes('Cabin')) {
          return { ...product, variant: action.payload.cabinNumber };
        }
        return product;
      });

      return {
        ...state,
        products: data,
      };
    },
  },
  inititalState
);

export default reducer;
