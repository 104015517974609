import {
  configureStore,
  combineReducers,
  AnyAction,
  PreloadedState,
} from '@reduxjs/toolkit';
import { api } from 'store/services/api';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import bookingFunnelSlice from 'store/features/bookingProcess/bookingFunnelSlice';
import extrasSlice from 'store/features/bookingProcess/extrasSlice';
import bookingsSlice from 'store/features/bookings/bookingsSlice';
import oldAnalyticsReducer from './store/reducers/oldAnalytics';
import analyticsReducer from './store/reducers/analytics';
import regReducer from './store/reducers/registration';
import searchReducer from './store/features/search/searchSlice';
import agencySearchingReducer from './store/reducers/agencySearching';
import destinationsSlice from './store/features/destinations/destinationsSlice';
import campaignReducer from './store/reducers/campaign';
import onboardingReducer from './store/reducers/onboarding';
import onboardingPopupReducer from './store/reducers/onboardingPopup';
import summaryReducer from './store/reducers/summary';
import modalsReducer from './store/reducers/modals';
import paymentReducer from './store/reducers/payment';
import trainingReducer from './store/reducers/training';
import profileReducer from './store/reducers/profile';
import productsReducer from './store/reducers/products';
import legalReducer from './store/reducers/legal';
import headerMessageReducer from './store/reducers/headerMessage';
import homeReducer from './store/reducers/home';
import newsReducer from './store/reducers/news';
import featureFlagsSlice from './store/features/featureFlagsSlice';
import promotionReducer from './store/reducers/promotion';
import bookingDetailsReducer from './store/reducers/bookingdetails';
import quizSlice from './store/features/training/quizSlice';
import headerSlice from './store/features/header/headerSlice';
import authSlice, { logout } from './store/features/auth/authSlice';
import portsReducer from './store/reducers/ports';
import classicVoyagesReducer from './store/reducers/classicVoyages';
import shipsReducer from './store/reducers/ships';
import mapReducer from './store/reducers/map';
import tourReducer from './store/reducers/tour';
import tourNamesReducer from './store/reducers/tourNames';
import agencyReducer from './store/reducers/agency';

const appReducer = combineReducers({
  [api.reducerPath]: api.reducer,
  agencySearching: agencySearchingReducer,
  analytics: analyticsReducer,
  oldAnalytics: oldAnalyticsReducer,
  auth: authSlice,
  bookingDetails: bookingDetailsReducer,
  bookings: bookingsSlice,
  bookingFunnel: bookingFunnelSlice,
  campaign: campaignReducer,
  classicVoyages: classicVoyagesReducer,
  quizState: quizSlice,
  headerState: headerSlice,
  destinations: destinationsSlice,
  extras: extrasSlice,
  feature: featureFlagsSlice,
  headerMessage: headerMessageReducer,
  home: homeReducer,
  legal: legalReducer,
  map: mapReducer,
  modals: modalsReducer,
  news: newsReducer,
  onboarding: onboardingReducer,
  onboardingPopup: onboardingPopupReducer,
  payment: paymentReducer,
  ports: portsReducer,
  profile: profileReducer,
  promotions: promotionReducer,
  products: productsReducer,
  registration: regReducer,
  search: searchReducer,
  ships: shipsReducer,
  summary: summaryReducer,
  training: trainingReducer,
  tour: tourReducer,
  tourNames: tourNamesReducer,
  agency: agencyReducer,
});

// on logout clear the persisted state
const rootReducer = (
  state: ReturnType<typeof appReducer>,
  action: AnyAction
) => {
  if (action.type === logout?.toString() || action.type === PURGE) {
    storage.removeItem('persist:root');
    return appReducer(undefined, action);
  }

  return appReducer(state, action);
};

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  whitelist: ['bookingFunnel', 'search', 'extras', 'destinations', 'feature'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: persistedReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        immutableCheck: {
          warnAfter: 75, // consoles a warning about size of redux state if checks take more than Xms (TODO: remove search results from bookingFunnel state)
        },
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
        },
      }).concat(api.middleware),
  });

const store = setupStore({});

export default store;

// When all reducers are typed, we can swap to this RootState and remove IRootState.
// RootState will automatically infer reducer types, so less maintenance for us.
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
