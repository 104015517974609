/* eslint-disable react/destructuring-assignment */

import { forwardRef, InputHTMLAttributes } from 'react';
import styled from 'styled-components';
import { colors } from 'style/colors';
import { typography } from 'style/typography';

export interface TextInputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  mask?: Array<string | RegExp>;
}

const StyledTextInput = styled.input<TextInputProps>`
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};
  color: ${colors.shared.textBlack};
  background-color: ${colors.shared.white};
  width: 100%;
  margin: 0;
  padding: 15px 20px;
  box-sizing: border-box;
  font-family: ${typography.normal.fontFamily};
  font-size: ${typography.normal.fontSize.normal};
  line-height: ${typography.normal.lineHeight.normal};
  border-radius: 8px;
  appearance: none;
  border: 1.4px solid
    ${(props) => (props.hasError ? colors.shared.red : colors.shared.warmGray6)};
`;

const TextInputComponent = forwardRef<HTMLInputElement, TextInputProps>(
  (props, ref) => (
    <StyledTextInput
      hasError={props.hasError}
      aria-invalid={props.hasError}
      ref={ref}
      {...props}
    />
  )
);

export default TextInputComponent;
