import { CloseModalIcon } from 'components';
import { PrimaryButton } from 'components/UI/Buttons/PrimaryButton/PrimaryButton';
import { colors } from 'style/colors';
import styled from 'styled-components';
import translate from 'translate';

const Title = styled.h3`
  font-family: 'Atlas Grotesk Medium';
  font-size: 24px;
  color: ${colors.shared.black};
`;

const Message = styled.span`
  font-family: 'Atlas Grotesk Regular';
  font-size: 14px;
  color: ${colors.shared.warmGray6};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TenMinutesModal = ({
  seconds,
  closeModal,
}: {
  seconds: number;
  closeModal: () => void;
}) => {
  const calcTimeLeft = (time: number) => {
    const timeLeft = 1800 - time;
    const mins = Math.floor((timeLeft % 3600) / 60);
    const secs = Math.floor(timeLeft % 60);
    return `${mins}:${secs < 10 ? '0' : ''}${secs}`;
  };

  return (
    <Container>
      <Header>
        <Title>
          {translate('TenMinutespModal_Title', calcTimeLeft(seconds))}
        </Title>
        <CloseModalIcon onClose={closeModal} />
      </Header>
      <Message>{translate('TenMinutespModal_Message')}</Message>
      <PrimaryButton onClick={closeModal}>
        {translate('TenMinutespModal_Btn')}
      </PrimaryButton>
    </Container>
  );
};

export default TenMinutesModal;
