import { createSlice } from '@reduxjs/toolkit';
import { IDestinationRTKState } from 'interfaces/IDestination';
import { getDestinations } from 'store/services/destinations';

const initialState: IDestinationRTKState = {
  homeDestinations: [],
};

const destinationsSlice = createSlice({
  name: 'destinations',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(getDestinations.matchFulfilled, (state, action) => {
      state.homeDestinations = action.payload;
    });
  },
});

export default destinationsSlice.reducer;
