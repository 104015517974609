import { useEffect, useState } from 'react';

export function useMediaQuery(query: string): boolean {
  const getMatches = (): boolean => {
    // Prevents SSR issues
    if (typeof window !== 'undefined') {
      return window.matchMedia(query).matches;
    }
    return false;
  };

  const [matches, setMatches] = useState<boolean>(getMatches());

  function handleChange() {
    setMatches(getMatches());
  }

  useEffect(() => {
    const matchMedia = window.matchMedia(query);

    // Triggered at the first client-side load and if query changes
    handleChange();

    // Listen matchMedia
    const handleChangeWrapper = () => {
      handleChange();
    };
    matchMedia.addEventListener('change', handleChangeWrapper);

    return () => {
      matchMedia.removeEventListener('change', handleChangeWrapper);
    };
  }, [query]);

  return matches;
}
