import { IItineraryByVoyage } from 'interfaces/IItineraryByVoyage';
import { api } from './api';

export const itineraryApi = api.injectEndpoints({
  endpoints: (build) => ({
    getItineraryByVoyage: build.query<IItineraryByVoyage[], string>({
      query: (voyage) => ({
        url: `Itinerary/${voyage}`,
      }),
    }),
  }),
});

export const { useGetItineraryByVoyageQuery } = itineraryApi;

export const {
  endpoints: { getItineraryByVoyage },
} = itineraryApi;
