import { NavLink } from 'react-router-dom';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { colors } from 'style/colors';

interface IMobileHeaderItem {
  children: ReactNode;
  to: string;
  closeMenu: () => void;
}

const Container = styled.div`
  margin-bottom: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  background-color: ${colors.shared.white};
`;

const StyledNavLink = styled(NavLink)`
  font-family: 'Atlas Grotesk Medium';
  font-size: 18;
  font-weight: 500;
  line-height: 1.11;
  color: ${colors.shared.black};
  text-decoration: none;
  background-color: ${colors.shared.white};
`;

const MobileHeaderItem = ({ children, to, closeMenu }: IMobileHeaderItem) => (
  <Container
    onClick={() => {
      closeMenu();
    }}
  >
    <StyledNavLink to={to}>{children}</StyledNavLink>
  </Container>
);

export default MobileHeaderItem;
