import { COASTAL_OPTIONS, PACKAGE_DATES_TYPE } from 'utils/constants';

export const getStartDate = (
  selectedVoyage: COASTAL_OPTIONS,
  packageDates?: string[],
  packageDateType?: PACKAGE_DATES_TYPE
) => {
  const today = new Date();

  if (
    selectedVoyage === COASTAL_OPTIONS.P2P ||
    selectedVoyage === COASTAL_OPTIONS.CLASSIC_VOYAGE
  ) {
    return new Date();
  }

  if (
    packageDateType === PACKAGE_DATES_TYPE.RANGE ||
    packageDateType === PACKAGE_DATES_TYPE.SPECIFIC
  ) {
    return packageDates?.[0]
      ? today > new Date(packageDates[0])
        ? today
        : new Date(packageDates[0])
      : today;
  }

  return today;
};
