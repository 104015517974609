import { IProduct } from 'interfaces/IAnalytics';
import { createActions } from 'redux-actions';

export const { setProductData, setCabinGradeProduct, setCabinNumberProduct } =
  createActions({
    SET_PRODUCT_DATA: (products: IProduct[]) => ({ products }),
    SET_CABIN_GRADE_PRODUCT: (cabinGrade: string, cabinPrice: number) => ({
      cabinGrade,
      cabinPrice,
    }),
    SET_CABIN_NUMBER_PRODUCT: (cabinNumber: string) => ({
      cabinNumber,
    }),
  });
