import { Header3 } from 'components/UI/Brand/text';
import translate from 'translate';
import { IDeckData } from 'interfaces/ICabin';
import { useAppSelector } from 'hooks/storeHooks';
import { ChangeEvent } from 'react';
import { UNSPECIFIED_CABIN_NUMBER } from 'utils/constants';
import { AlertBox } from 'components';
import RadioOptionGroup from 'components/UI/RadioOptionGroup/RadioOptionGroup';
import styles from './styles.module.scss';

export interface ICabinDeckItemProps {
  onChangeCabinNumber: (event: ChangeEvent<HTMLInputElement>) => void;
  availableCabinNumbers: string[];
  deckData: IDeckData;
}

const CabinDeckItem = ({
  onChangeCabinNumber,
  deckData,
  availableCabinNumbers,
}: ICabinDeckItemProps) => {
  const { bookedCabins, selectedCabinIndex } = useAppSelector(
    (state) => state.bookingFunnel
  );
  const selectedCabinNumber = bookedCabins[selectedCabinIndex]?.cabinNumber;
  const cabinNumberOptions = availableCabinNumbers.map((deckNumber) => ({
    value: deckNumber === UNSPECIFIED_CABIN_NUMBER ? '' : deckNumber,
    label:
      deckNumber === UNSPECIFIED_CABIN_NUMBER
        ? translate('CabinSelection_CabinDeck_ConfirmUnspecifiedCabinNumber')
        : deckNumber,
  }));

  return (
    <fieldset className={styles.container}>
      {deckData.deck !== UNSPECIFIED_CABIN_NUMBER && (
        <Header3
          aria-labelledby={translate('Booking_CabinNumber')}
          className={styles.title}
        >
          {translate('CabinSelection_Deck', deckData.deck)}
        </Header3>
      )}
      {cabinNumberOptions?.length ? (
        <>
          {deckData.deckImage && (
            <div className={styles.imageContainer}>
              <img
                alt=""
                role="presentation"
                className={styles.image}
                src={deckData.deckImage}
              />
            </div>
          )}
          <RadioOptionGroup
            legend={translate('Booking_CabinNumber')}
            name={'cabin-select-radio-group'}
            onChange={onChangeCabinNumber}
            options={cabinNumberOptions}
            defaultValue={selectedCabinNumber}
          />
        </>
      ) : (
        <AlertBox className={styles.infoBox} type="info">
          {translate('CabinSelection_CabinDeck_FullyBookedDeck')}
        </AlertBox>
      )}
    </fieldset>
  );
};

export default CabinDeckItem;
