import { useState, FormEvent } from 'react';
import { SecondaryButton } from 'components/UI/Buttons/SecondaryButton/SecondaryButton';
import { Header2 } from 'components/UI/Brand/text';
import clsx from 'clsx';
import { useDispatch } from 'react-redux';
import translate from 'translate';
import { setPromotionCode } from 'store/actions';
import { useAppSelector } from 'hooks/storeHooks';
import TextInput, { TextInputChangeEvent } from '../../TextInput/TextInput';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

interface IPromotionCodeModal {
  onClose: () => void;
}
const PromotionCodeModal = ({ onClose }: IPromotionCodeModal) => {
  const initPromoCode = useAppSelector((state) => state.search.promotionCode);
  const [promoCode, setPromoCode] = useState(initPromoCode || '');
  const dispatch = useDispatch();

  const handleInputChange = (event: TextInputChangeEvent) => {
    const { value } = event.target;
    setPromoCode(value);
  };

  const handleCancel = () => {
    onClose();
  };

  const handleSubmit = (event: FormEvent) => {
    event.preventDefault();
    dispatch(setPromotionCode(promoCode));
    onClose();
  };

  return (
    <div className={styles.container} id="promo_modal">
      <Header2>{translate('PromotionCodeModal_AddPromoCode')}</Header2>
      <form onSubmit={handleSubmit} className={styles.formContainer}>
        <div className={styles.inputContainer}>
          <TextInput
            id="promo_modal_input"
            name="promocode"
            label={translate('PromotionCodeModal_PromotionCode')}
            placeholder={translate('PromotionCodeModal_PromotionCode')}
            onChange={handleInputChange}
            value={promoCode}
          />
        </div>
        <div className={styles.btnContainer}>
          <SecondaryButton
            className={styles.button}
            size="small"
            onClick={handleCancel}
          >
            {translate('PromotionCodeModal_Cancel')}
          </SecondaryButton>
          <PrimaryButton
            className={clsx(styles.button, styles.submitButton)}
            type="submit"
            id="promo_modal_submit"
            size="small"
            disabled={initPromoCode ? false : !promoCode || promoCode === ''}
          >
            {initPromoCode && promoCode !== null && promoCode.length === 0
              ? translate('PromotionCodeModal_Remove')
              : translate('PromotionCodeModal_Submit')}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default PromotionCodeModal;
