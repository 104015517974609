import 'react-phone-number-input/style.css';
import PhoneInputWithCountrySelect, {
  isPossiblePhoneNumber,
} from 'react-phone-number-input';
import flags from 'react-phone-number-input/flags';
import { useCallback, useState } from 'react';
import { convertMarketToPhoneCode } from 'utils/convertMarketToPhoneCode';
import { getJwtPayload } from 'utils/analytics';
import debounce from 'utils/debounce';
import { getRegionByLocale, getRegionName } from 'utils/regions';
import { getLanguage } from 'utils/getLanguage';
import TextInputComponent from '../TextInput/TextInputComponent';
import { HelperText, Label } from '../Brand/text';

export interface IPhoneInput {
  label?: string;
  error: boolean;
  onChange?: (phoneNumberWithCountrycode: string) => void;
  helperText?: string;
  id: string;
  value?: string;
  isRequired?: boolean;
}

const PhoneInput = ({
  label,
  error,
  onChange,
  helperText,
  id,
  value,
  isRequired,
}: IPhoneInput) => {
  const [phoneNumber, setPhoneNumber] = useState<string>(value);
  const marketId = getJwtPayload()['http://user/market'];
  const tempId = id?.includes('adults') ? id : '';

  const handlePhoneChange = useCallback(
    debounce((inputValue: string) => {
      if (!onChange) return;
      onChange(inputValue);
    }, 500),
    []
  );

  return (
    <div data-testid="phone-input-container">
      {label && (
        <Label htmlFor={id} hasError={error} data-testid="phone-input-label">
          {label}
        </Label>
      )}
      <div id={id}>
        <PhoneInputWithCountrySelect
          onChange={(inputValue: string) => {
            setPhoneNumber(inputValue);
            handlePhoneChange(inputValue);
          }}
          id={`${tempId}phoneinput_phone`}
          value={phoneNumber}
          flags={flags}
          withCountryCallingCode={false}
          rules={{
            required: isRequired,
            validate: {
              isPossiblePhoneNumber,
            },
          }}
          defaultCountry={convertMarketToPhoneCode(
            marketId || getRegionName(getRegionByLocale(getLanguage()))
          )}
          style={{
            '--PhoneInputCountryFlag-height': '2rem',
            '--PhoneInputCountrySelect-marginRight': '1rem',
          }}
          inputComponent={TextInputComponent}
          data-testid="phone-input-text-input"
        />
      </div>
      {!!helperText && (
        <HelperText
          id="edit_user_phone_errorMessage"
          hasError={error}
          data-testid="phone-input-help-text"
        >
          {helperText}
        </HelperText>
      )}
    </div>
  );
};

export default PhoneInput;
