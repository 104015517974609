import { createActions, Action, ActionFunctionAny } from 'redux-actions';
import { IOpenHeaderPayload } from 'interfaces/IHeader';
import { FAILED, SUCCESS } from '../../utils/constants';

let autoClose: ReturnType<typeof setTimeout> | undefined;

export const { openHeader, closeHeader } = createActions({
  OPEN_HEADER: (
    type: string,
    title: string,
    message: string
  ): IOpenHeaderPayload => ({
    type,
    title,
    message,
  }),
  CLOSE_HEADER: undefined,
});

export const removeHeaderMessage =
  () => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(closeHeader());
    clearTimeout(autoClose);
  };

export const createHeaderMessage =
  (type: string, title: string, message: string) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(openHeader(type, title, message));
    if (type !== FAILED) {
      autoClose = setTimeout(() => dispatch(closeHeader()), 5000);
    }
  };

export const createSuccessMessage =
  (msg: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(createHeaderMessage(SUCCESS, 'Success', msg));
  };

export const createFailedMessage =
  (msg: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(createHeaderMessage(FAILED, 'Failed', msg));
  };
