import { SVGProps } from 'react';

const BinocularIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="currentColor"
    {...props}
  >
    <path d="M12.55 6.829H9.459a.456.456 0 0 1-.46-.46c0-.258.203-.46.46-.46h3.091c.257 0 .46.202.46.46 0 .257-.212.46-.46.46zm0 5.408H9.459a.456.456 0 0 1-.46-.46c0-.257.203-.46.46-.46h3.091c.257 0 .46.203.46.46 0 .258-.212.46-.46.46z" />
    <path d="M4.823 21.5c-2.465 0-4.314-1.242-4.314-2.355L.5 13.525c0-.037.009-.083.018-.12l2.318-8.701a.445.445 0 0 1 .442-.341h6.181c.258 0 .46.203.46.46v8.5a.455.455 0 0 1-.46.459h-.312v5.354c-.01 1.113-1.859 2.364-4.324 2.364zM1.42 13.58l.009 5.565c0 .359 1.205 1.435 3.394 1.435s3.404-1.085 3.404-1.444v-5.813c0-.258.202-.46.46-.46h.312v-7.58H3.627L1.42 13.58z" />
    <path d="M4.823 21.5c-2.465 0-4.314-1.242-4.314-2.355 0-1.122 1.886-2.428 4.314-2.428 2.438 0 4.324 1.297 4.324 2.419 0 1.113-1.849 2.364-4.324 2.364zm0-3.863c-2.189 0-3.394 1.131-3.394 1.508 0 .359 1.205 1.435 3.394 1.435 2.199 0 3.404-1.085 3.404-1.444 0-.368-1.205-1.499-3.404-1.499zM7.914 5.283H4.823a.456.456 0 0 1-.46-.46V1.466C4.363.702 5.679.5 6.378.5c.772 0 1.996.267 1.996.984v3.339c0 .249-.212.46-.46.46zm-2.631-.92h2.171V1.631c-.166-.082-.57-.211-1.076-.211-.497 0-.92.11-1.095.193v2.75zM17.177 21.5c-2.465 0-4.314-1.251-4.323-2.364v-5.363h-.304a.455.455 0 0 1-.46-.46v-8.49c0-.257.202-.46.46-.46h6.181c.212 0 .387.138.442.341l2.318 8.701a.553.553 0 0 1 .018.12l-.009 5.629c-.009 1.104-1.849 2.346-4.323 2.346zm-4.167-8.637h.313c.257 0 .46.202.46.46v5.813c0 .359 1.214 1.444 3.403 1.444s3.394-1.076 3.394-1.435l.009-5.565-2.207-8.297H13.01v7.58z" />
    <path d="M17.177 21.5c-2.475 0-4.323-1.251-4.323-2.364s1.885-2.419 4.323-2.419c2.428 0 4.314 1.306 4.314 2.428 0 1.113-1.84 2.355-4.314 2.355zm0-3.863c-2.199 0-3.404 1.122-3.404 1.499 0 .359 1.205 1.444 3.404 1.444 2.189 0 3.394-1.076 3.394-1.435 0-.377-1.205-1.508-3.394-1.508zm0-12.354h-3.091a.456.456 0 0 1-.46-.46V1.484C13.626.767 14.85.5 15.622.5c.699 0 2.015.202 2.015.966v3.357c0 .249-.203.46-.46.46zm-2.631-.92h2.171v-2.75c-.175-.083-.598-.193-1.086-.193-.505 0-.91.129-1.076.211v2.732h-.009z" />
  </svg>
);

export default BinocularIcon;
