import clsx from 'clsx';
import { TagType } from 'interfaces/ITag';
import { BrandProvider } from 'components';
import styles from './styles.module.scss';

interface ITagProps {
  title: string;
  type: TagType;
  isCoastal?: boolean;
}

const Tag = ({ title, type, isCoastal }: ITagProps) => {
  if (!title) return null;

  return (
    <div className={styles.root}>
      <div
        className={clsx(styles.container, {
          [styles.voyage]: type === 'voyage',
          [styles.invertedvoyage]: type === 'invertedvoyage',
          [styles.promotion]: type === 'promotion',
          [styles.feature]: type === 'feature',
          [styles.isCoastal]: isCoastal,
        })}
      >
        {isCoastal ? (
          <BrandProvider fixedBrand="coastal">
            <p
              className={clsx(styles.tag, {
                [styles.voyage]: type === 'voyage',
                [styles.invertedvoyage]: type === 'invertedvoyage',
                [styles.promotion]: type === 'promotion',
                [styles.feature]: type === 'feature',
                [styles.isCoastal]: isCoastal,
              })}
            >
              {title}
            </p>
          </BrandProvider>
        ) : (
          <p
            className={clsx(styles.tag, {
              [styles.voyage]: type === 'voyage',
              [styles.invertedvoyage]: type === 'invertedvoyage',
              [styles.promotion]: type === 'promotion',
              [styles.feature]: type === 'feature',
              [styles.uppercase]:
                (!isCoastal && type === 'voyage') ||
                (!isCoastal && type === 'invertedvoyage'),
            })}
          >
            {title}
          </p>
        )}
      </div>
    </div>
  );
};

export default Tag;
