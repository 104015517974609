import { useState } from 'react';
import clsx from 'clsx';
import { Backdrop } from '../Backdrop/Backdrop';
import styles from './styles.module.scss';

interface IImageViewProps {
  src: string;
  alt: string;
  containerClassName?: string;
  imageClassName?: string;
}

export const ImageView = ({
  src,
  alt,
  containerClassName,
  imageClassName,
}: IImageViewProps) => {
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);

  const handleImageClick = () => {
    setIsBackdropOpen(true);
  };

  const handleBackdropClick = () => {
    setIsBackdropOpen(false);
  };

  return (
    <>
      <figure
        className={clsx(styles.imageViewContainer, containerClassName)}
        onClick={handleImageClick}
      >
        <img
          className={clsx(styles.imageViewImageMin, imageClassName)}
          src={src}
          alt={alt}
        />
        <div className={styles.imageViewOverlay} />
      </figure>
      {isBackdropOpen ? (
        <Backdrop onClick={handleBackdropClick}>
          <figure>
            <img src={src} alt={alt} className={styles.imageViewImage} />
          </figure>
        </Backdrop>
      ) : null}
    </>
  );
};
