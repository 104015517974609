import { HtmlHTMLAttributes, SelectHTMLAttributes } from 'react';
import { colors } from 'style/colors';
import { typography } from 'style/typography';
import styled from 'styled-components';
import { IOption } from 'interfaces/IOption';
import Icon from '../Icons/Icon';

type IconType = 'arrow' | 'ship';

interface SelectProps extends SelectHTMLAttributes<HTMLSelectElement> {
  hasError: boolean;
  icon: IconType;
  hasBorderRadius?: boolean;
}

const SelectWrapper = styled.div`
  width: 100%;
  position: relative;
  display: inline-block;
`;

const Select = styled.select<SelectProps>`
  background: ${colors.shared.white};
  position: relative;
  border-radius: ${({ hasBorderRadius }) => (hasBorderRadius ? '8px' : 0)};
  appearance: none;
  overflow: hidden;
  border: 1.4px solid
    ${(props) => (props.hasError ? colors.shared.red : colors.shared.warmGray7)};
  font-weight: 400;
  font-family: ${typography.normal.fontFamily};
  font-size: ${typography.normal.fontSize.normal};
  line-height: ${typography.normal.lineHeight.normal};
  padding: 15px;
  padding-left: 20px;
  padding-right: 55px;
  width: 100%;
  opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  color: ${(props) =>
    props.disabled ? colors.shared.warmGray5 : colors.shared.textBlack};

  &::-ms-expand {
    display: none;
  }
`;

const StyledOption = styled.option`
  color: ${colors.shared.textBlack};

  &:disabled {
    color: ${colors.shared.warmGray4};
  }
`;

interface IDropdownProps extends HtmlHTMLAttributes<HTMLSelectElement> {
  value?: string;
  options: IOption[];
  disabled?: boolean;
  name?: string;
  title?: string;
  hasError?: boolean;
  icon?: IconType;
  hasBorderRadius?: boolean;
}

const Dropdown = ({
  id,
  value,
  options,
  disabled,
  onChange,
  name,
  title,
  defaultValue,
  placeholder,
  hasError = false,
  icon = 'arrow',
  hasBorderRadius = true,
  ...rest
}: IDropdownProps) => (
  <SelectWrapper>
    <Select
      id={id}
      name={name}
      title={title}
      aria-invalid={hasError}
      onChange={onChange}
      disabled={disabled}
      defaultValue={value ? undefined : defaultValue || placeholder}
      value={value}
      hasError={hasError}
      icon={icon}
      hasBorderRadius={hasBorderRadius}
      {...rest}
    >
      {placeholder && (
        <StyledOption value={placeholder} disabled>
          {placeholder}
        </StyledOption>
      )}
      {options.map((option) => (
        <StyledOption
          key={option.value}
          value={option.value}
          disabled={option.disabled}
          hidden={option.hidden}
        >
          {option.description || option.value}
        </StyledOption>
      ))}
    </Select>
    <Icon
      iconType={icon === 'ship' ? 'shipFront' : 'arrowDown'}
      style={{
        position: 'absolute',
        top: 20,
        right: 20,
        content: '',
        pointerEvents: 'none',
      }}
    />
  </SelectWrapper>
);

export default Dropdown;
