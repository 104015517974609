import axios from 'axios-instance';
import { ICampaignPage } from 'containers/Learn/CampaignPage/CampaignPage.types';
import { Action } from 'redux';
import { ActionFunctionAny, createActions } from 'redux-actions';

export const { fetchCampaignStart, fetchCampaignSuccess, fetchCampaignFailed } =
  createActions({
    FETCH_CAMPAIGN_START: undefined,
    FETCH_CAMPAIGN_SUCCESS: (campaign: ICampaignPage) => ({
      campaign,
    }),
    FETCH_CAMPAIGN_FAILED: (error) => ({ error }),
  });

export const fetchCampaign =
  (name: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchCampaignStart());
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}CampaignPage/${name}`)
      .then((res) => {
        dispatch(fetchCampaignSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchCampaignFailed(err));
      });
  };
