import { SVGAttributes } from 'react';
import { colors } from 'style/colors';

export interface IconProps extends SVGAttributes<SVGElement> {
  logoType: LogoType;
  width?: number;
  height?: number;
}

interface LogoColors {
  textColor?: string;
  circleFillColor?: string;
  innerCircleFillColor?: string;
}

export enum Logo {
  MAIN_WHITE_FONT = 'LogoWhite',
  MAIN_WHITE = 'LogoAllWhite',
  MAIN_BLACK_FONT = 'LogoBlack',
  MAIN_BLACK = 'LogoAllBlack',
  MAIN_HX_BLUE = 'LogoBlue',

  MARK_RED = 'hrgBrandMarkRed',
  MARK_WHITE = 'hrgBrandMarkWhite',
  MARK_BLACK = 'hrgBrandMarkBlack',
}

export type LogoType =
  | Logo.MAIN_WHITE_FONT
  | Logo.MAIN_WHITE
  | Logo.MAIN_BLACK_FONT
  | Logo.MAIN_BLACK
  | Logo.MAIN_HX_BLUE
  | Logo.MARK_RED
  | Logo.MARK_WHITE
  | Logo.MARK_BLACK;

export const getLogoColors = (iconType: LogoType): LogoColors => {
  switch (iconType) {
    case Logo.MAIN_WHITE:
      return {
        textColor: colors.shared.white,
        circleFillColor: colors.shared.white,
        innerCircleFillColor: colors.shared.black,
      };
    case Logo.MAIN_WHITE_FONT:
      return {
        textColor: colors.shared.white,
        circleFillColor: colors.shared.hurtigrutenRed1,
        innerCircleFillColor: colors.shared.white,
      };
    case Logo.MAIN_BLACK:
      return {
        textColor: colors.shared.black,
        circleFillColor: colors.shared.black,
        innerCircleFillColor: colors.shared.white,
      };
    case Logo.MAIN_BLACK_FONT:
      return {
        textColor: colors.shared.black,
        circleFillColor: colors.shared.hurtigrutenRed1,
        innerCircleFillColor: colors.shared.white,
      };
    case Logo.MAIN_HX_BLUE:
      return {
        textColor: colors.hrx.blueDark,
        circleFillColor: colors.hrx.blueDark,
        innerCircleFillColor: colors.hrx.blueDark,
      };
    case Logo.MARK_RED:
      return {
        circleFillColor: colors.shared.hurtigrutenRed1,
        innerCircleFillColor: colors.shared.white,
      };
    case Logo.MARK_WHITE:
      return {
        circleFillColor: colors.shared.white,
        innerCircleFillColor: colors.shared.black,
      };
    case Logo.MARK_BLACK:
      return {
        circleFillColor: colors.shared.black,
        innerCircleFillColor: colors.shared.black,
      };
    default:
      return null;
  }
};
