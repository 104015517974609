import { ButtonText } from 'components/UI/Brand/text';
import { track } from 'utils/analytics';
import styles from './styles.module.scss';

interface IHeaderItem {
  name: string;
  id: number;
}
interface ITabHeaderProps {
  headerItems: IHeaderItem[];
  activeTabIndex: number;
  setActiveTabIndex: (index: number) => void;
  desktopContainer?: boolean;
  destinationName?: string;
  className?: string;
}
const TabHeader = (props: ITabHeaderProps) => {
  const {
    headerItems,
    setActiveTabIndex,
    activeTabIndex,
    desktopContainer = false,
    destinationName,
    className,
  } = props;

  return (
    <div
      className={[
        desktopContainer
          ? styles.headerContainer
          : styles.tabSecondaryHeaderContainer,
        headerItems && headerItems.length > 2 ? styles.scrollX : null,
        className,
      ]
        .filter((_class) => _class)
        .join(' ')}
    >
      <div className={styles.headerContentContainer}>
        {headerItems.map((headerItem, index) => (
          <button
            type="button"
            role="tab"
            key={headerItem.name}
            tabIndex={index}
            className={
              index === activeTabIndex
                ? [styles.tabHeaderItemActive, styles.tabHeaderItem].join(' ')
                : styles.tabHeaderItem
            }
            onClick={() => {
              track(
                `Click ${headerItem.name} highlights`,
                destinationName ? { Destination: destinationName } : null
              );
              setActiveTabIndex(index);
            }}
          >
            <ButtonText className={styles.tabHeaderText}>
              {headerItem.name}
            </ButtonText>
          </button>
        ))}
      </div>
    </div>
  );
};

export default TabHeader;
