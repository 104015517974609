import { SVGProps } from 'react';

const SnowflakeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="currentColor"
    {...props}
  >
    <path
      d="m8.242.146 2.55 2.553 2.55-2.553c.2-.195.51-.195.71 0 .19.196.19.512 0 .708l-2.76 2.752v6.216l5.255-3.297.885-3.892a.502.502 0 0 1 .6-.378c.27.061.43.33.37.598l-.808 3.59 3.458.691a.5.5 0 0 1-.2.981l-3.76-.752-5.356 3.362 5.349 3.358 3.757-.876c.27-.063.53.104.6.373a.505.505 0 0 1-.38.6l-3.471.812.811 3.605c.06.27-.1.537-.37.598a.501.501 0 0 1-.6-.378l-.885-3.892-5.255-3.297v6.216l2.76 2.753a.51.51 0 0 1 0 .707.504.504 0 0 1-.71 0l-2.55-2.553-2.55 2.553a.504.504 0 0 1-.71 0 .51.51 0 0 1 0-.707l2.76-2.753v-6.212l-5.257 3.3-.883 3.885a.491.491 0 0 1-.59.378.502.502 0 0 1-.38-.598l.81-3.603-3.6-.813a.502.502 0 0 1-.38-.598.501.501 0 0 1 .6-.377l3.887.877 5.353-3.358-5.36-3.362-3.89.752a.497.497 0 0 1-.59-.396.497.497 0 0 1 .4-.585l3.577-.693-.807-3.588a.502.502 0 0 1 .38-.598c.27-.06.53.11.59.378l.883 3.885 5.257 3.3V3.606L7.532.854a.511.511 0 0 1 0-.708c.2-.195.52-.195.71 0Z"
      fillRule="evenodd"
    />
  </svg>
);

export default SnowflakeIcon;
