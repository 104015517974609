import { ButtonText } from 'components/UI/Brand/text';
import { ITabHeader } from 'interfaces/ITab';
import { track } from 'utils/analytics';
import styles from './styles.module.scss';

const TabTertiaryHeader = ({
  headerItems,
  setActiveTabIndex,
  activeTabIndex,
}: ITabHeader) => (
  <div className={styles.tabTernaryHeaderContainer}>
    {headerItems.map((item, index) => (
      <button
        role="tab"
        key={item.name}
        tabIndex={index}
        className={
          index === activeTabIndex
            ? `${styles.active} ${styles.tab}`
            : styles.tab
        }
        onClick={() => {
          track(`Click ${item.name} highlights`);
          setActiveTabIndex(index);
        }}
      >
        <ButtonText>{item.name}</ButtonText>
      </button>
    ))}
  </div>
);

export default TabTertiaryHeader;
