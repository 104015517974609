import { AxiosError } from 'axios';
import { ICabin as IPassengerInfo } from 'containers/Book/BookingProgress/BookingFunnel/PassengerDetails/PassengerDetails.types';
import { IPassenger } from './IPassenger';
import { ICabinsData } from './ICabin';
import { ISelectedPacket } from './IPacket';
import { IQuote } from './IQuote';
import { IQuoteCommitResult } from './ICommitQuote';

export interface IBookingFunnelState {
  bookedCabins: IBookedCabin[];
  bookingStep: BOOKING_STEPS;
  preSelectedCabinType: string;
  quoteID: string;
  selectedCabinIndex: number;
  selectedPackageID: string; // needs refactoring along with IPacket changes
  selectedTourID: string;
  selectedVoyageID: string;
  passengersData: IPassenger[];
  selectedPackages?: ISelectedPacket[];
  currentCabinsData: ICabinsData;
  currentQuoteData: IQuote;
  commitQuoteData: IQuoteCommitResult;
  passengersInfo: IPassengerInfo[];
}
export enum BOOKING_STEPS {
  CABIN_TYPE = 'cabin-type',
  CABIN_GRADE = 'cabin-grade',
  CABIN_DECK = 'cabin-deck',
  PACKAGES = 'packages',
  EXTRAS = 'extras',
  PASSENGER_INFO = 'passenger-info',
  CONFIRM = 'confirm',
}
export const {
  CABIN_DECK,
  CABIN_GRADE,
  CABIN_TYPE,
  CONFIRM,
  EXTRAS,
  PACKAGES,
  PASSENGER_INFO,
} = BOOKING_STEPS;

export enum BOOKING_STEP_DIRECTIONS {
  FORWARD = 'forward',
  BACKWARD = 'backward',
}
export const { FORWARD, BACKWARD } = BOOKING_STEP_DIRECTIONS;
export const CABIN_STEPS = [CABIN_TYPE, CABIN_GRADE, CABIN_DECK];
export const NOT_CABIN_STEPS = [PACKAGES, EXTRAS, PASSENGER_INFO, CONFIRM];
export const DECKSPACE_STEPS = [EXTRAS, PASSENGER_INFO, CONFIRM];

export interface IChangeBookingStepParams {
  direction?: BOOKING_STEP_DIRECTIONS;
  goTo?: { step: BOOKING_STEPS; cabinIndex?: number };
}

export interface IBookedCabin {
  cabinIndex?: number;
  cabinTitle?: string;
  cabinType?: string;
  cabinState?: string;
  cabinGrade?: string;
  cabinGradeTitle?: string;
  cabinNumber?: string;
  price?: number;
  alreadyQuoted?: boolean;
  fareStructure?: string;
}

export interface IPaymentResponse {
  paymentStatus: string;
}

export interface IBookingPaymentState {
  paymentType: string;
  paymentTypes: string;
  paymentOption: string;
  specificAmount: number;
  paymentUrl: IPaymentUrl;
  error: AxiosError;
  isPaymentUrlLoading: boolean;
  getPaymentLoading: boolean;
  getPaymentResponse: IPaymentResponse;
}

interface IPaymentUrl {
  bookingId: string;
  passengerGroupId: string;
  paymentUrl: string;
}
