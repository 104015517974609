import { SVGProps } from 'react';

const SingleBedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={21}
    viewBox="0 0 18 21"
    {...props}
  >
    <g fillRule="evenodd">
      <path d="M3.21 1.24C3.09 1.4 3 1.66 3 2v3.5c0 .28-.22.5-.5.5S2 5.78 2 5.5V2c0-.5.12-.99.42-1.37C2.73.23 3.2 0 3.77 0h10.15c.57 0 1.04.23 1.35.63.3.38.42.88.42 1.37v3.5c0 .28-.22.5-.5.5s-.5-.22-.5-.5V2c0-.34-.09-.6-.21-.76-.11-.14-.28-.24-.56-.24H3.77c-.28 0-.45.1-.56.24zM2.5 17c.28 0 .5.22.5.5v3c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-3c0-.28.22-.5.5-.5zm13 0c.28 0 .5.22.5.5v3c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-3c0-.28.22-.5.5-.5z" />
      <path d="M2.65 5.02c.27.07.43.35.35.62l-1.75 6.33h15l-1.6-6.33c-.07-.27.08-.55.35-.62.27-.08.54.08.62.34l1.86 7.03c.04.15.01.31-.08.43-.1.13-.27 0-.43 0H.47c-.16 0-.27.13-.37 0a.503.503 0 0 1-.08-.43l2.02-7.03a.49.49 0 0 1 .61-.34z" />
      <path d="M0 12.5c0-.28.22-.5.5-.5H17c.28 0 .5.22.5.5v5c0 .28-.22.5-.5.5H.5c-.28 0-.5-.22-.5-.5v-5zm1 .5v4h15.5v-4H1zm1.38-8.13C3.77 4.44 5.92 4 8.85 4c2.91 0 5.07.45 6.47.88.28.08-.24.32-.32.62-.08.3.31.56.03.48-1.31-.4-3.37-.84-6.18-.84-2.83 0-4.88.43-6.18.83-.28.09-.21-.17-.29-.47-.08-.3-.28-.54 0-.63z" />
    </g>
  </svg>
);

export default SingleBedIcon;
