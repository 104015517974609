import clsx from 'clsx';
import styles from './styles.module.scss';

const LineBreak = ({ hasMargin = false }) => (
  <hr
    className={clsx(styles.linebreak, {
      [styles.hasMargin]: hasMargin,
    })}
  />
);

export default LineBreak;
