import translate from 'translate';
import { DetailsDisclosure } from '../UI/DetailsDisclosure/DetailsDisclosure';
import { AgencyCommission } from './AgencyCommission';

export const AgencyCommissionDisclosure = ({
  commission,
}: {
  commission: number;
}) => (
  <DetailsDisclosure
    title={translate('BookingSummary_AgencyCommission')}
    bodyText={<AgencyCommission commission={commission} />}
    hasButton
    isRegularTitle
  />
);
