import { Action, ActionFunctionAny } from 'redux-actions';
import { removeAllExtras } from 'store/features/bookingProcess/extrasSlice';
import { quoteApi } from 'store/services/quote';
import { dataLayerPush } from 'utils/analytics';
import { cancelBooking, resetSummary } from '.';
import axios from '../../axios-instance';

export const cancelQuote =
  (quoteId: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    axios
      .delete(`${process.env.REACT_APP_API_BASE_URL}Quote/${quoteId}`)
      .then(() => {
        dispatch(cancelBooking());
        dispatch(removeAllExtras());
        dispatch(resetSummary());
        dataLayerPush('canceled', { payload: { quoteId } });
      })
      .catch(() => {
        // TODO: return handler when the backend will be fixed
        // dispatch(quoteCommitFailed());
      });
  };

export const resetCommitQuote =
  () => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(quoteApi.util.resetApiState());
  };
