import { Icon } from 'components';
import { InfoText } from 'components/UI/Brand/text';
import { colors } from 'style/colors';
import styled from 'styled-components';
import translate from 'translate';

const AlertMessageContainer = styled.div`
  width: 100%;
  border-radius: inherit;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
`;

const SearchOverCapacity = styled.span`
  margin-left: 2px;
`;

export const MaxPaxNotification = ({
  maxOccupancy,
}: {
  maxOccupancy: number;
}) => (
  <AlertMessageContainer>
    <InfoText>
      <Icon
        iconType={'circleWithExclamation'}
        color={colors.shared.black}
        width={14}
        height={14}
      />
      <SearchOverCapacity data-testid={'max-occupancy-message'}>
        {maxOccupancy === 0
          ? translate('BookingSummary_ContactCSC')
          : translate(
              'SearchResultItemRow_CabinSearchOverCapacity',
              maxOccupancy
            )}
      </SearchOverCapacity>
    </InfoText>
  </AlertMessageContainer>
);
