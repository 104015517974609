import Icon from 'components/UI/Icons/Icon';
import AccessibleHelperText from '../AccessibleHelperText';
import styles from './styles.module.scss';

const AccessibilityLabel = () => (
  <div className={styles.container} data-testid="accessible-label">
    <AccessibleHelperText type="accessibleCabin" />
    <Icon iconType="wheelchairSmall" width={12} height={12} />
  </div>
);

export default AccessibilityLabel;
