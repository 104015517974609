import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IQuizData, IQuizState } from 'interfaces/IQuiz';

const initialState: IQuizState = {
  quizData: [],
  passed: false,
};

const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    setQuiz: (state, action: PayloadAction<IQuizData>) => {
      let quizArray: IQuizData[] = [];
      if (state.quizData !== null) {
        quizArray = [...state.quizData];
      }

      const refreshableIndex = quizArray.findIndex(
        (quiz) => quiz.id === action.payload.id
      );

      if (refreshableIndex !== -1) {
        quizArray[refreshableIndex] = action.payload;
      } else {
        quizArray.push(action.payload);
      }

      state.quizData = [...quizArray];
    },
    setPassedQuiz: (state, action: PayloadAction<boolean>) => {
      state.passed = action.payload;
    },
    resetQuiz: () => initialState,
  },
});

export const { setQuiz, setPassedQuiz, resetQuiz } = quizSlice.actions;

export default quizSlice.reducer;
