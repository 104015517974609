import axios, { AxiosError } from 'axios';
import {
  createActions,
  createAction,
  ActionFunctionAny,
  ActionFunction1,
  Action,
} from 'redux-actions';
import { IAgency } from '../../interfaces/IProfile';

const AGENCY_SEARCHING_FAILED = 'AGENCY_SEARCHING_FAILED';
const AGENCY_SEARCHING_SUCCESS = 'AGENCY_SEARCHING_SUCCESS';

type ErrorAction = Action<{ error: AxiosError }>;
type SuccessAction = Action<{ agencies: IAgency[] }>;

export const { agencySearchingStart, agencySearchingClear } =
  createActions<void>({
    AGENCY_SEARCHING_START: undefined,
    AGENCY_SEARCHING_CLEAR: undefined,
  });

export const agencySearchingFailed = createAction<
  { error: AxiosError },
  AxiosError
>(AGENCY_SEARCHING_FAILED, (error) => ({ error }));

export const agencySearchingSuccess = createAction<
  { agencies: IAgency[] },
  IAgency[]
>(AGENCY_SEARCHING_SUCCESS, (agencies) => ({ agencies }));

export const searchAgencies =
  (agentId: string, region: string) =>
  (
    dispatch:
      | ActionFunctionAny<Action<void>>
      | ActionFunction1<AxiosError, ErrorAction>
      | ActionFunction1<IAgency[], SuccessAction>
  ) => {
    dispatch(agencySearchingStart());

    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}user/accounts/${agentId}/region/${region}`
      )
      .then((response) => {
        dispatch(agencySearchingSuccess(response.data.accountList));
      })
      .catch((err) => {
        // TODO: We need to make a uniform error message from server.
        dispatch(agencySearchingFailed(err));
      });
  };
