interface IColors {
  shared: {
    black: string;
    greyDark: string;
    clearBlue: string;
    textBlack: string;
    white: string;
    red: string;
    darkRed: string;
    lightRed: string;
    snowBlue: string;
    frozenBlue: string;
    snowYellow: string;
    penguinYellow: string;
    warmGray1: string;
    warmGray2: string;
    warmGray3: string;
    warmGray4: string;
    warmGray5: string;
    warmGray6: string;
    warmGray7: string;
    warmBrown: string;
    midnightYellow1: string;
    midnightYellow3: string;
    midnightYellow5: string;
    warmRed1: string;
    warmRed2: string;
    expeditionJungleGreen1: string;
    hurtigrutenRed1: string;
    hurtigrutenRed2: string;
    hurtigrutenRed3: string;
    hurtigrutenRed4: string;
    icyBlue: string;
    clearGreen: string;
  };
  b2b: {
    snowGreen: string;
    justGreen: string;
    mossGreen: string;
    frozenGreen: string;
    lightGreen: string;
    clearGreen: string;
    snowOrange: string;
    snowNo: string;
    lightBlue: string;
    foggyBlue1: string;
    foggyBlue2: string;
    clearBlue: string;
    clearBlue2: string;
    clearBlue3: string;
    grey1: string;
    grey2: string;
    grey3: string;
    grey4: string;
    grey5: string;
    redRegular: string;
    greyDark: string;
  };
  hrn: {
    navyBlue1: string;
    navyBlue2: string;
    navyBlue3: string;
    navyBlue4: string;
    navyBlue5: string;
    navyBlue6: string;
    clearBlue1: string;
    clearBlue2: string;
    redDark: string;
    menu: string;
  };
  hrx: {
    blue: string;
    blueDark: string;
    volcanoBlack1: string;
    volcanoBlack2: string;
    volcanoBlack3: string;
    volcanoBlack4: string;
    volcanoBlack7: string;
    volcanoBlack8: string;
    volcanoBlack9: string;
    greyRegular: string;
    redDark: string;
    redRegular: string;
  };
}

export const colors: IColors = {
  shared: {
    black: '#000000', // black.regular
    greyDark: '#3a3a3a', // grey.dark
    clearBlue: '#D1EDFE', // clearBlue.shade1
    textBlack: '#1D1D1D', // black.text
    white: '#FFFFFF', // white
    red: '#D40000', // hurtigrutenRed  *code is new one
    darkRed: '#7F0C07', // hurtigrutenRed.shade140
    lightRed: '#FDE3E1', // warmRed.shade1
    snowBlue: '#D1F3FE', // coastalNavyBlue.shade040
    frozenBlue: '#A0DBEF', // coastalNavyBlue.shade050
    snowYellow: '#FFF4D1', // penguinYellow.shade070
    penguinYellow: '#F8CF5A', // penguinYellow.shade100
    warmGray1: '#F8F8F8', //  warmGray.shade0
    warmGray2: '#E5E3E3', //  warmGray.shade1
    warmGray3: '#D1CECC', //  warmGray.shade2
    warmGray4: '#B0AEAC', //  warmGray.shade3
    warmGray5: '#656664', //  warmGray.shade4
    warmGray6: '#3B3B3A', //  warmGray.shade5
    warmGray7: '#3B3B3A', //  warmGray.shade6
    warmBrown: '#462312', // warmBrown.shade6
    midnightYellow1: '#FFF1D1', // midnightYellow.shade1
    midnightYellow3: '#FDD24E', // midnightYellow.shade3
    midnightYellow5: '#E76A1E', // midnightYellow.shade5
    warmRed1: '#FDAAA6', // warmRed.shade3
    warmRed2: '#E6716E', // warmRed.shade4
    expeditionJungleGreen1: '#6DC88D', // expeditionJungleGreen.shade100
    hurtigrutenRed1: '#E01C1F', // hurtigrutenRed.shade100
    hurtigrutenRed2: '#E94444', // hurtigrutenRed.shade090
    hurtigrutenRed3: '#E01C1F', // hurtigrutenRed.shade120
    hurtigrutenRed4: '#FE8787', // hurtigrutenRed.shade080
    icyBlue: '#518BAC', // icyBlue.shade4
    clearGreen: '#C9FED5', // clearGreen.shade1
  },
  b2b: {
    snowGreen: '#169D3B', // clearGreen.shade4
    justGreen: '#A9C15E', // mossGreen.shade2
    mossGreen: '#F2F7E8', // mossGreen.shade0,
    frozenGreen: '#A5C480', // new one b2b color
    lightGreen: '#E0FBC8', // new one b2b color
    clearGreen: '#20CE57', // clearGreen.shade3
    snowOrange: '#D87035', // new one b2b color
    snowNo: '#F2F0AF', // autumnYellow.shade1
    lightBlue: '#E8F3FD', // new one b2b color
    foggyBlue1: '#E8EDF1', // foggyBlue.shade1
    foggyBlue2: '#CFDAE3', // foggyBlue.shade2
    clearBlue: '#47AFFC', // clearBlue.shade3
    clearBlue2: '#8DD3FE', // clearBlue.shade2
    clearBlue3: '#3eaceb',
    grey1: '#E6E6E6', // vulcanoBlack.shade010
    grey2: '#CCCCCC', // <- as Volcano black for borders
    grey3: '#999999', // vulcanoBlack.shade040
    grey4: '#666666', // vulcanoBlack.shade050
    grey5: '#333333', // new one b2b color
    redRegular: '#E21710', // red regular for group brand
    greyDark: '#f3f0ee', // grey dark for group brand
  },
  hrn: {
    navyBlue1: '#092C55', // coastalNavyBlue.shade100
    navyBlue2: '#174C8B', // coastalNavyBlue.shade090
    navyBlue3: '#47A4D9', // coastalNavyBlue.shade070
    navyBlue4: '#67BDE2', // coastalNavyBlue.shade060
    navyBlue5: '#276FB2', // coastalNavyBlue.shade080
    navyBlue6: '#051D39', // coastalNavyBlue.shade110
    clearBlue1: '#0F4FA8', // clearBlue.shade5
    clearBlue2: '#2B87D7', // clearBlue.shade4
    redDark: '#af0a0f', // red dark for coastal brand
    menu: '#0b233f', // menu color for coastal brand
  },
  hrx: {
    blue: '#374d67',
    blueDark: '#202835',
    volcanoBlack1: '#494949', // vulcanoBlack.shade060
    volcanoBlack2: '#B3B3B3', // vulcanoBlack.shade030
    volcanoBlack3: '#F5F5F5', // vulcanoBlack.shade005
    volcanoBlack4: '#CCCCCC', // vulcanoBlack.shade020
    volcanoBlack7: '#383838', // vulcanoBlack.shade070
    volcanoBlack8: '#272727', // vulcanoBlack.shade080
    volcanoBlack9: '#1A1A1A', // vulcanoBlack.shade090
    greyRegular: '#dfdfdf', // grey.regular for exp brand
    redDark: '#B10400', // red dark for exp brand
    redRegular: '#cf3425', // red regular for exp brand
  },
};
