import { getLanguage } from './getLanguage';

const SP_ACTIVE_CHAT_KEY = 'SP_ACTIVE_CHAT_KEY';
const SP_ROOT_CONTAINER = 'sp-root-container';
const SP_CHAT_SCRIPT_ID = 'sp-chat-script';

const BRIGHT_PATTERN_ID_US = '1da1507fcfd648cdabeee01cc2a7b5ab';
const BRIGHT_PATTERN_ID_APAC = '3856d858b1294ba0ad76d94f98a05b35';
const BRIGHT_PATTERN_ID_UK = '2fe72aa8f27b4c579fea41bd0cb1cd94';
const BRIGHT_PATTERN_ID_EMEA = 'f371e8f6e6f349ed957c3f293839c00c';
const BRIGHT_PATTERN_ID_GERMAN = 'd6e5cafa1af94292b4f432ab40fbf363';
const BRIGHT_PATTERN_ID_FR = 'ae72f952e7ca4b03a8c2badb07a0ba2d';
const BRIGHT_PATTERN_ID_NORDICS = '56a7b1681dc149ad8f923e0f0ed55c21';

let spIsLoaded = false;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const onMessage = (e: MessageEvent<any>) => {
  if (e.data === 'sp-session-start') {
    localStorage.setItem(SP_ACTIVE_CHAT_KEY, 'true');
  } else if (
    e.data === 'sp-session-end' ||
    e.data === 'bp-stop-cobrowsing-and-close-chat'
  ) {
    localStorage.removeItem(SP_ACTIVE_CHAT_KEY);
  }
};

const getBrightPatternId = (locale: string | undefined) => {
  if (locale?.toLowerCase() === 'en-americas') {
    return BRIGHT_PATTERN_ID_US || '';
  }
  if (['de-at', 'de-ch', 'de-de'].includes(locale?.toLowerCase())) {
    return BRIGHT_PATTERN_ID_GERMAN || '';
  }
  if (locale?.toLowerCase() === 'fr-fr') {
    return BRIGHT_PATTERN_ID_FR || '';
  }
  if (locale?.toLowerCase() === 'en-gb') {
    return BRIGHT_PATTERN_ID_UK || '';
  }
  if (['nb-no', 'sv-se', 'da-dk'].includes(locale?.toLowerCase())) {
    return BRIGHT_PATTERN_ID_NORDICS || '';
  }
  if (locale?.toLowerCase() === 'en-apac') {
    return BRIGHT_PATTERN_ID_APAC || '';
  }
  if (locale?.toLowerCase() === 'en-eu') {
    return BRIGHT_PATTERN_ID_EMEA || '';
  }

  return BRIGHT_PATTERN_ID_EMEA;
};

export const initChat = () => {
  const brightPatternId = getBrightPatternId(getLanguage());

  const servicePatternChatConfig = `SERVICE_PATTERN_CHAT_CONFIG = ${JSON.stringify(
    {
      appId: brightPatternId,
      apiUrl: 'https://hurtigruten.brightpattern.com/clientweb/api/v1',
      tenantUrl: 'hurtigruten.brightpattern.com',
      chatPath:
        'https://hurtigruten.brightpattern.com/clientweb/chat-client-v4/',
    }
  )}`;

  const scriptChatConfig = document.createElement('script');
  scriptChatConfig.type = 'text/javascript';
  scriptChatConfig.id = 'brightpattern-chat';
  scriptChatConfig.append(servicePatternChatConfig);
  document.body.appendChild(scriptChatConfig);
};

const addScript = () => {
  const script = document.createElement('script');
  script.id = SP_CHAT_SCRIPT_ID;
  script.src =
    'https://hurtigruten.brightpattern.com/clientweb/chat-client-v4/js/init.js';

  document.body.appendChild(script);

  window.removeEventListener('message', onMessage);
  window.addEventListener('message', onMessage);
};

/**
 * Used to check if user has an active chat session
 */
export const hasActiveChat = () =>
  localStorage.getItem(SP_ACTIVE_CHAT_KEY) === 'true';

/**
 * Will load the Bright Pattern chat script if it is not already added.
 * If already loaded it will show the chat widget.
 */
export const indicateShowChat = () => {
  if (!spIsLoaded) {
    try {
      addScript();
    } catch (error) {
      console.error('Unable to load Bright Pattern script');
    } finally {
      spIsLoaded = true;
    }
  } else {
    try {
      const container = document.getElementById(SP_ROOT_CONTAINER);
      if (container) {
        container.style.display = 'block';
      }
    } catch (error) {
      console.error('Unable to show Bright Pattern widget');
    }
  }
};

/**
 * Will hide the Bright Pattern widget unless user has active chat.
 */
export const indicateHideChat = () => {
  if (!hasActiveChat()) {
    try {
      const container = document.getElementById(SP_ROOT_CONTAINER);
      if (container) {
        container.style.display = 'none';
      }
    } catch (error) {
      console.error('Unable to hide Bright Pattern widget');
    }
  }
};
