import CabinGuestSelector from 'components/Search/CabinGuestSelection/CabinGuestSelector/CabinGuestSelector';
import { Label } from 'components/UI/Brand/text';
import { ICabinBaseSelector } from 'interfaces/ICabin';
import translate from 'translate';
import styles from './styles.module.scss';

interface ICabinAndPassengersSelectorProps {
  isDeckSpaceBookingEnabled: boolean;
  onDeckSelected: (deckPassengers: ICabinBaseSelector) => void;
  isP2P: boolean;
  deckPassengers: ICabinBaseSelector;
  showAccessibleModal: () => void;
  showPromotionCodeModal: () => void;
}

export const CabinAndPassengersSelector = ({
  isDeckSpaceBookingEnabled,
  onDeckSelected,
  isP2P,
  deckPassengers,
  showAccessibleModal,
  showPromotionCodeModal,
}: ICabinAndPassengersSelectorProps) => (
  <div className={styles.selectorGroup}>
    <Label htmlFor="booking_search_bar_cabin">
      {translate('Coastal_Search_Iconic_Cabin_Label')}*
    </Label>
    <CabinGuestSelector
      isDeckSpaceBookingEnabled={isDeckSpaceBookingEnabled}
      onDeckSelected={onDeckSelected}
      deckPassengers={deckPassengers}
      isP2P={isP2P}
      showAccessibleModal={showAccessibleModal}
      showPromotionCodeModal={showPromotionCodeModal}
    />
  </div>
);
