import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import AccessibleHelperText from '../AccessibleHelperText';
import styles from './styles.module.scss';

interface IInformationIconProps {
  onClick: () => void;
  id: string;
  color?: string;
  height?: number;
  width?: number;
}

const InformationIcon = ({
  onClick,
  id,
  color,
  height,
  width,
}: IInformationIconProps) => (
  <button id={id} onClick={onClick} className={styles.informationIconCircle}>
    <AccessibleHelperText type="info" />
    <Icon
      iconType="circleInformation"
      width={width || 20}
      height={height || 20}
      color={color || colors.shared.black}
      className={styles.icon}
    />
  </button>
);

export default InformationIcon;
