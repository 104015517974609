import { SVGAttributes } from 'react';
import { TIcon } from './Icon.type';
import { getIcon } from './utils/getIcon';

export interface IconProps extends SVGAttributes<SVGElement> {
  iconType: TIcon;
  color?: string;
  width?: number;
  height?: number;
}

const Icon = ({ iconType, ...rest }: IconProps) => {
  const PredefinedIcon = getIcon(iconType);
  if (PredefinedIcon != null) {
    return <PredefinedIcon {...rest} />;
  }

  return <div />;
};

export default Icon;
