import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { InfoText, Header3 } from 'components/UI/Brand/text';
import styles from './styles.module.scss';

interface IDestinationCard {
  children?: ReactNode;
  description: ReactNode;
  image: string;
  linkPath?: string;
  title: string;
}

const DestinationCard = ({
  title,
  description,
  children,
  image,
  linkPath,
}: IDestinationCard) => (
  <article className={styles.article}>
    <Link to={linkPath} className={styles.link}>
      <img src={image} role="presentation" alt="" className={styles.image} />
      <div className={styles.textContainer}>
        <Header3 as="span">{title}</Header3>
        <InfoText as="span" className={styles.description}>
          {description}
        </InfoText>
      </div>
    </Link>
    {children}
  </article>
);

export default DestinationCard;
