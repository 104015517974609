import styled from 'styled-components';

import { Icon } from 'components';
import { typography } from 'style/typography';
import { HTMLAttributes, ReactNode } from 'react';
import { getAlertColor } from './utils/getAlertColor';
import { TAlertType } from './utils/alertType';
import AlertIcon from './AlertIcon';

export interface AlertProps extends HTMLAttributes<HTMLDivElement> {
  type: TAlertType;
  OverrideIcon?: ReactNode;
  onClose?: () => void;
}

const AlertCard = styled.div<AlertProps>`
  position: relative;
  display: flex;
  align-items: center;
  padding: 20px;
  background-color: ${(props) => getAlertColor(props.type)};
  border: 1.4px solid ${(props) => getAlertColor(props.type)};
  font-family: ${typography?.normal?.fontFamily};
  font-size: ${typography?.normal?.fontSize?.small};
`;

const AlertContent = styled.div`
  padding-left: 10px;
  flex: 1;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 40px;
  top: 23px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

/**
 * Use `AlertBanner` to display local messages to the user. For instance information,
 * warnings or errors
 */
const AlertBox = ({
  type,
  OverrideIcon,
  children,
  onClose,
  ...rest
}: AlertProps) => (
  <AlertCard type={type} {...rest}>
    {OverrideIcon || <AlertIcon alertType={type} />}
    <AlertContent>{children}</AlertContent>
    {onClose && (
      <CloseButton onClick={onClose} type="button">
        <Icon iconType="cross" />
      </CloseButton>
    )}
  </AlertCard>
);

export default AlertBox;
