import RadioOptionGroup from 'components/UI/RadioOptionGroup/RadioOptionGroup';
import { IDepartureAndArrivalPort } from 'interfaces/IPort';
import { useAppSelector } from 'hooks/storeHooks';
import translate from 'translate';
import Icon from 'components/UI/Icons/Icon';
import { ChangeEvent } from 'react';
import { colors } from 'style/colors';
import Dropdown from 'components/UI/Select/Dropdown';
import styles from './styles.module.scss';

interface IShortVoyageFieldsProps {
  ports: IDepartureAndArrivalPort;
  setPorts: (ports: IDepartureAndArrivalPort) => void;
  setIsViaKirkenes: (isViaKirkenes: boolean) => void;
  isViaKirkenes: boolean;
}

const ShortVoyageFields = ({
  ports,
  setPorts,
  setIsViaKirkenes,
  isViaKirkenes,
}: IShortVoyageFieldsProps) => {
  const { portsList, error, loading } = useAppSelector((state) => state.ports);

  const portsDropdownOptions = portsList.map((port) => ({
    value: port.code,
    description: port.name,
  }));

  const fromPortPlaceholder = loading
    ? translate('Coastal_Search_Short_Voyage_Loading')
    : translate('Coastal_Search_Short_Voyage_Origin');

  const toPortPlaceholder = loading
    ? translate('Coastal_Search_Short_Voyage_Loading')
    : translate('Coastal_Search_Short_Voyage_Destination');

  const swapPorts = () => {
    setPorts({
      fromPort: ports.toPort,
      toPort: ports.fromPort,
    });
  };

  const getPortByPortCode = (portCode: string) =>
    portsList.find((port) => port.code === portCode);

  return (
    <div className={styles.shortVoyageFields}>
      <div className={styles.dropdowns}>
        <Dropdown
          id={'origin-port'}
          name="origin port"
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            setPorts({
              ...ports,
              fromPort: getPortByPortCode(event.currentTarget.value),
            })
          }
          placeholder={fromPortPlaceholder}
          options={portsDropdownOptions}
          value={ports?.fromPort?.code || fromPortPlaceholder}
          disabled={loading || error}
        />
        <button onClick={swapPorts} className={styles.iconButton}>
          <Icon
            className={styles.iconArrowUpDown}
            iconType="arrowUpDown"
            color={colors.shared.black}
            width={40}
            height={40}
          />
        </button>
        <Dropdown
          id={'destination-port'}
          name="destination port"
          onChange={(event: ChangeEvent<HTMLSelectElement>) =>
            setPorts({
              ...ports,
              toPort: getPortByPortCode(event.currentTarget.value),
            })
          }
          placeholder={toPortPlaceholder}
          options={portsDropdownOptions}
          value={ports?.toPort?.code || toPortPlaceholder}
          disabled={loading || error}
        />
      </div>
      <RadioOptionGroup
        name={'package-select-radio-group'}
        options={[
          {
            value: 'oneway',
            label: translate('Coastal_Search_Short_Voyage_OneWay'),
          },
          {
            value: 'viakirkenes',
            label: translate('Coastal_Search_Short_Voyage_ThroughKirkenes'),
          },
        ]}
        defaultValue={isViaKirkenes ? 'viakirkenes' : 'oneway'}
        onChange={(event: ChangeEvent<HTMLInputElement>) =>
          setIsViaKirkenes(event.currentTarget.value === 'viakirkenes')
        }
        className={styles.radioButtons}
      />
    </div>
  );
};

export default ShortVoyageFields;
