import styled from 'styled-components';
import { MarginProps, spacing } from 'style/spacing';
import { getTypographyStyling } from '../fontThemes/getTypographyStyling';

export const Header0 = styled.h1<MarginProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'h0')}
  ${spacing}
`;

export const Header1 = styled.h1<MarginProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'h1')}
  ${spacing}
`;

export const Header2 = styled.h2<MarginProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'h2')}
  ${spacing}
`;

export const Header3 = styled.h3<MarginProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'h3')}
  ${spacing}
`;

export const Header4 = styled.h4<MarginProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'h4')}
  ${spacing}
`;

export const Subtitle = styled.p<MarginProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'subtitle')}
  ${spacing}
`;

export const TabularHeader = styled.h3<MarginProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'tabularHeader')}
  ${spacing}
`;
