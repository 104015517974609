import { Header3 } from 'components/UI/Brand/text';
import translate, { TDictionaryKey } from 'translate';
import { differenceInDays, fromUnixTime } from 'date-fns';
import styles from './styles.module.scss';

interface ISearchResultSummaryProps {
  isCoastal: boolean;
  isAccessbilityRequested: boolean;
  toursCount: number;
  accessibleCabinsCount: number;
  startDate: number;
  endDate: number;
}

const getResultTitleDictionaryKey = (
  isCoastal: boolean,
  isAccessbilityRequested: boolean,
  toursCount: number,
  accessibleCabinsCount: number
): TDictionaryKey => {
  if (isAccessbilityRequested) {
    if (accessibleCabinsCount > 1) {
      return isCoastal ? 'Accessible_CruisePlural' : 'Accessible_Voyages';
    }
    return isCoastal ? 'Accessible_Cruise' : 'Accessible_Voyage';
  }

  if (toursCount > 1) {
    return isCoastal
      ? 'SearchResult_CoastalTitlePlural'
      : 'SearchResult_TitlePlural';
  }
  return isCoastal
    ? 'SearchResult_CoastalTitleSingle'
    : 'SearchResult_TitleSingle';
};

export const SearchResultSummary = ({
  isCoastal,
  isAccessbilityRequested,
  toursCount,
  accessibleCabinsCount,
  startDate,
  endDate,
}: ISearchResultSummaryProps) => {
  const dayDifference = differenceInDays(
    fromUnixTime(endDate),
    fromUnixTime(startDate)
  );

  const getResultTitle = () => {
    const dictionaryKey = getResultTitleDictionaryKey(
      isCoastal,
      isAccessbilityRequested,
      toursCount,
      accessibleCabinsCount
    );

    return translate(
      dictionaryKey,
      isAccessbilityRequested ? accessibleCabinsCount : toursCount,
      isCoastal ? dayDifference : ''
    );
  };

  return <Header3 className={styles.resultSummary}>{getResultTitle()}</Header3>;
};
