import { createActions, ActionFunctionAny, Action } from 'redux-actions';
import axios from '../../axios-instance';

export const { fetchShipsStart, fetchShipsSuccess, fetchShipsFailed } =
  createActions({
    FETCH_SHIPS_START: undefined,
    FETCH_SHIPS_SUCCESS: (shipsList) => ({ shipsList }),
    FETCH_SHIPS_FAILED: undefined,
  });

export const fetchShips =
  (coastal: boolean) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchShipsStart());

    const queryCoastal = coastal ? '?coastal=true' : '';
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}ships${queryCoastal}`)
      .then((response) => {
        dispatch(fetchShipsSuccess(response.data));
      })
      .catch(() => {
        dispatch(fetchShipsFailed());
      });
  };
