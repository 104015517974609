import { IPackage } from 'interfaces/IPackage';

export const getStrikeThroughPrice = (tourPackages: IPackage[]) => {
  if (tourPackages) {
    const strikeThroughPrices = tourPackages
      .map((tourPackage) => tourPackage.priceDetail.strikeThroughPrice)
      .filter((price) => price);
    if (!strikeThroughPrices) return null;
    return Math.min(...strikeThroughPrices);
  }
  return null;
};
