import { colors } from 'style/colors';
import { IBrandColors } from '../interfaces/IBrandColors';

export const groupColors: IBrandColors = {
  black: {
    regular: colors.shared.black,
    header: colors.shared.black,
    text: colors.shared.textBlack,
  },
  white: colors.shared.white,
  default: {
    regular: colors.b2b.redRegular,
    dark: colors.hrx.redDark,
  },
  grey: { regular: colors.shared.warmGray1, dark: colors.b2b.greyDark },
  offer: colors.shared.midnightYellow3,
  menu: { regular: colors.shared.white, dark: colors.shared.warmGray1 },
  link: colors.shared.black,
};
