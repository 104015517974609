import { KeyboardEvent } from 'react';
import AnimateHeight from 'react-animate-height';
import { BodyText } from 'components/UI/Brand/text';
import Icon from 'components/UI/Icons/Icon';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import sizes from 'style/sizes';
import { SUCCESS } from 'utils/constants';
import { removeHeaderMessage } from 'store/actions';
import styled from 'styled-components';
import { colors } from 'style/colors';

interface IContainer {
  isSuccess: boolean;
}

const Container = styled.div<IContainer>`
  padding: 14px 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({ isSuccess }) =>
    isSuccess ? colors.b2b.lightGreen : colors.shared.lightRed};
  width: 100%;
  min-height: 24px;
  box-sizing: border-box;
`;

const SubContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: ${sizes.desktopContainerWidth}px;
`;

const Text = styled.span`
  font-weight: 500;
`;

const Content = styled.div`
  display: flex;
  margin-right: 10px;
  align-items: center;
`;

const IconWrapper = styled.div`
  margin-right: 12px;
`;

const Cancel = styled.div`
  justify-self: end;
  position: absolute;
  right: 0;
  outline: none;
  cursor: pointer;
`;

const HeaderMessage = () => {
  const dispatch = useAppDispatch();
  const { type, title, message, visible } = useAppSelector(
    (state) => state.headerMessage
  );

  const onCancelClicked = () => {
    dispatch(removeHeaderMessage());
  };

  const onCancelKeyPressed = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      dispatch(removeHeaderMessage());
    }
  };

  return (
    <AnimateHeight duration={500} height={visible ? 'auto' : 0}>
      <Container isSuccess={type === SUCCESS} id={`header-message-${SUCCESS}`}>
        <SubContainer>
          <Content>
            <IconWrapper>
              <Icon
                color={colors.shared.black}
                height={20}
                width={20}
                iconType={
                  type === SUCCESS ? 'circleWithCheckmark' : 'circleWithCross'
                }
              />
            </IconWrapper>
            <BodyText>
              <Text>{`${title}: `}</Text>
              {message}
            </BodyText>
          </Content>
          <Cancel
            onClick={onCancelClicked}
            role="button"
            tabIndex={0}
            onKeyUp={onCancelKeyPressed}
            id="header-massage-close"
          >
            <Icon
              color={colors.shared.black}
              height={24}
              width={24}
              iconType="cross"
            />
          </Cancel>
        </SubContainer>
      </Container>
    </AnimateHeight>
  );
};

export default HeaderMessage;
