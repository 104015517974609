import { handleActions } from 'redux-actions';
import * as actions from '../actions/oldAnalytics';

const initialState = {
  voyageType: null,
  voyageName: null,
  voyageId: null,
  packageCode: null,
  baseCategoryString: null,
  passengers: null,
  departureDate: null,
  departurePort: null,
  arrivalPort: null,
  shipName: null,
  voyageDuration: null,
  daysUntilDeparture: null,
  ecommerce: {
    checkout: {
      actionField: { step: null },
      currencyCode: null,
      products: [],
    },
  },
};

const reducer = handleActions(
  {
    [actions.setAnalyticsData]: (state, action) => ({
      ...state,
      ...action.payload.datalayerData,
    }),
    [actions.updateCabinNumber]: (state, action) => {
      const data = state.ecommerce.checkout.products.map((product) => {
        if (product.category.includes('Cabin')) {
          return { ...product, variant: action.payload.cabinNumber };
        }
        return product;
      });

      return {
        ...state,
        ecommerce: {
          ...state.ecommerce,
          checkout: {
            ...state.ecommerce.checkout,
            products: data,
            actionField: {
              step: 3,
            },
          },
        },
      };
    },
    [actions.addProduct]: (state, action) => {
      const product = { ...action.payload.product };
      product.brand = state.shipName;
      const category = `${state.baseCategoryString}/${product.category}`;
      product.category = category;
      const products = state.ecommerce.checkout.products.concat(product);
      return {
        ...state,
        ecommerce: {
          ...state.ecommerce,
          checkout: {
            ...state.ecommerce.checkout,
            products,
            actionField: {
              step: 4,
            },
          },
        },
      };
    },
    [actions.removeProduct]: (state, action) => {
      const products = state.ecommerce.checkout.products.filter(
        (product) => product.id !== action.payload.productId
      );

      return {
        ...state,
        ecommerce: {
          ...state.ecommerce,
          checkout: {
            ...state.ecommerce.checkout,
            products,
            actionField: {
              step: 4,
            },
          },
        },
      };
    },
    [actions.increaseStep]: (state) => {
      const { step: prevStep } = state.ecommerce.checkout.actionField;
      const step = prevStep + 1;
      return {
        ...state,
        ecommerce: {
          ...state.ecommerce,
          checkout: {
            ...state.ecommerce.checkout,
            actionField: {
              step,
            },
          },
        },
      };
    },
  },
  initialState
);

export default reducer;
