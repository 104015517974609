import { getLanguage } from './getLanguage';
import languageKeys from './languageKeys';

const isInt = (value: number) => value % 1 === 0;

const getCurrentLocaleString = () => {
  const currLang = getLanguage();
  switch (currLang) {
    case languageKeys.FR:
    case languageKeys.NO:
    case languageKeys.SE:
    case languageKeys.DK:
      return 'fr-FR';
    case languageKeys.EN_APAC:
      return 'en-AU';
    case languageKeys.de:
    case languageKeys.DE_CH:
    case languageKeys.AT:
      return 'de-DE';
    case languageKeys.EN_AMERICAS:
    default:
      return 'en-US';
  }
};

const getLocaleNumberFormat = (price: number | string) => {
  if (price !== null && price !== undefined && !Number.isNaN(price)) {
    const number = parseFloat(price as string);
    const isNumberInt = isInt(number);
    return number.toLocaleString(getCurrentLocaleString(), {
      minimumFractionDigits: isNumberInt ? 0 : 2,
      maximumFractionDigits: isNumberInt ? 0 : 2,
    });
  }
  return price;
};

export default getLocaleNumberFormat;
