import { useDispatch, useSelector } from 'react-redux';
import translate from 'translate';
import { IRootState } from 'store/types/root';
import {
  setShowAccessibleCabins,
  setShowHomePortDepartures,
} from 'store/actions';
import InformationIcon from 'components/UI/InformationIcon/InformationIcon';
import FilterSearchButton from 'components/Search/SearchResult/FilterSearchResultBar/FilterButton';
import { useModal } from 'hooks/useModal';
import AccessibleCabinInfoModal from 'components/Search/AccessibleCabinInfoModal';
import styles from './styles.module.scss';

interface IFilterSearchBar {
  allCount: number;
  accessibleCount: number;
  hasShowAccessibilityFilter: boolean;
}

const FilterSearchBar = ({
  allCount,
  accessibleCount,
  hasShowAccessibilityFilter = true,
}: IFilterSearchBar) => {
  const { isShowAccessibleCabins, showHomePortDepartures } = useSelector(
    (state: IRootState) => state.search
  );
  const { isModalVisible, showModal, hideModal, RenderModal } = useModal();

  const dispatch = useDispatch();

  const getAllResultsButtonState = () => {
    if (isShowAccessibleCabins) {
      return false;
    }
    if (showHomePortDepartures) {
      return false;
    }
    return true;
  };

  return (
    <>
      <div className={styles.container}>
        <FilterSearchButton
          active={getAllResultsButtonState()}
          onClick={() => {
            dispatch(setShowAccessibleCabins(false));
            dispatch(setShowHomePortDepartures(false));
          }}
          title={translate('SearchFilter_AllVoyages', allCount)}
        />
        {hasShowAccessibilityFilter && (
          <>
            <FilterSearchButton
              active={isShowAccessibleCabins}
              disabled={accessibleCount <= 0}
              id="cabin_accessible_button"
              iconType="wheelchairSmall"
              onClick={() => {
                dispatch(setShowAccessibleCabins(!isShowAccessibleCabins));
              }}
              title={translate('SearchFilter_Accessible', accessibleCount)}
            />
            <InformationIcon
              id="accessibility_info_on_accessibility_chooser"
              onClick={showModal}
            />
          </>
        )}
      </div>
      {isModalVisible && (
        <RenderModal>
          <AccessibleCabinInfoModal onModalClose={hideModal} />
        </RenderModal>
      )}
    </>
  );
};

export default FilterSearchBar;
