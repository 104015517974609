import {
  eachMonthOfInterval,
  endOfYear,
  format,
  getUnixTime,
  startOfMonth,
  startOfYear,
  subMonths,
} from 'date-fns';
import { getCalendarLocale } from 'utils/dates';
import { getLanguage } from 'utils/getLanguage';
import styles from './styles.module.scss';

interface ICalendarFlexibleMonths {
  year: number;
  selectedUnixPeriods: number[];
  setSelectedUnixPeriods: (unixPeriods: number[]) => void;
  monthsAvailableForDestinationType?: string[];
}

const CalendarFlexibleMonths = ({
  year,
  selectedUnixPeriods,
  setSelectedUnixPeriods,
  monthsAvailableForDestinationType,
}: ICalendarFlexibleMonths) => {
  const language = getLanguage();
  const locale = getCalendarLocale(language);
  const previousMonth = getUnixTime(subMonths(new Date(), 1));
  const months = eachMonthOfInterval({
    start: startOfYear(new Date(year, 0, 1)),
    end: endOfYear(new Date(year, 11, 31)),
  });

  const onChangeHandler = (period: number) => {
    setSelectedUnixPeriods(
      selectedUnixPeriods.some((somePeriod) => somePeriod === period)
        ? selectedUnixPeriods.filter((somePeriod) => somePeriod !== period)
        : [...selectedUnixPeriods, period]
    );
  };

  return (
    <div role="group" className={styles.calendarFlexibleMonthsContainer}>
      {months.map((monthDate, index) => {
        const periodUnix = getUnixTime(startOfMonth(monthDate));
        const periodID = format(monthDate, 'MM-yyyy');
        const isChecked = selectedUnixPeriods.includes(periodUnix);
        const isDisabled =
          getUnixTime(startOfMonth(new Date(year, index, 1))) < previousMonth ||
          (monthsAvailableForDestinationType?.length > 0 &&
            !monthsAvailableForDestinationType.includes(
              format(monthDate, 'MMM')
            ));

        return (
          <label key={periodID} htmlFor={periodID}>
            <input
              type="checkbox"
              className={styles.checkbox}
              disabled={isDisabled}
              checked={isChecked}
              id={periodID}
              onChange={() => onChangeHandler(periodUnix)}
            />
            <span
              className={`${styles.button} ${isChecked ? styles.checked : ''} ${isDisabled ? styles.disabled : ''}`}
              role="checkbox"
              aria-disabled={isDisabled}
              aria-checked={isChecked}
              tabIndex={0}
              onKeyUp={(event) =>
                event.key === 'Enter' && onChangeHandler(periodUnix)
              }
            >
              {format(monthDate, 'MMMM', { locale })}
            </span>
          </label>
        );
      })}
    </div>
  );
};

export default CalendarFlexibleMonths;
