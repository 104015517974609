import { SVGProps } from 'react';

const SummerIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="currentColor"
    {...props}
  >
    <g fillRule="evenodd">
      <path d="M11 4.752c-3.4 0-6.16 2.773-6.16 6.198 0 3.426 2.76 6.198 6.16 6.198 3.41 0 6.17-2.772 6.17-6.198 0-3.425-2.76-6.198-6.17-6.198zM3.84 10.95c0-3.973 3.2-7.198 7.16-7.198s7.17 3.225 7.17 7.198c0 3.973-3.21 7.198-7.17 7.198s-7.16-3.225-7.16-7.198z" />
      <path d="M.5 10.95c0-.276.22-.5.5-.5h3.34c.27 0 .5.224.5.5s-.23.5-.5.5H1c-.28 0-.5-.224-.5-.5zm16.67 0c0-.276.22-.5.5-.5H21c.28 0 .5.224.5.5s-.22.5-.5.5h-3.33c-.28 0-.5-.224-.5-.5zM11 .5c.28 0 .5.224.5.5v3.252c0 .276-.22.5-.5.5-.27 0-.5-.224-.5-.5V1c0-.276.23-.5.5-.5zm0 16.648c.28 0 .5.224.5.5V21c0 .276-.22.5-.5.5-.27 0-.5-.224-.5-.5v-3.352c0-.276.23-.5.5-.5zM2.96 2.867a.491.491 0 0 1 .7.002l2.7 2.704a.5.5 0 0 1-.01.707.491.491 0 0 1-.7-.002L2.96 3.574a.492.492 0 0 1 0-.707zM15.65 15.62c.2-.194.51-.193.71.002l2.69 2.709a.51.51 0 0 1 0 .707.503.503 0 0 1-.71-.002l-2.69-2.709a.492.492 0 0 1 0-.707zm3.4-12.753a.51.51 0 0 1 0 .707l-2.69 2.704a.503.503 0 0 1-.71.002.492.492 0 0 1 0-.707l2.69-2.704a.503.503 0 0 1 .71-.002zM6.35 15.62a.5.5 0 0 1 .01.707l-2.7 2.709a.491.491 0 0 1-.7.002.492.492 0 0 1 0-.707l2.69-2.709a.493.493 0 0 1 .7-.002z" />
    </g>
  </svg>
);

export default SummerIcon;
