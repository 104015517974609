import { SVGProps } from 'react';

const TvIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    viewBox="0 0 19 19"
    fill="#000"
    {...props}
  >
    <path
      d="M12.77.202c.17.22.13.53-.1.7L9.655 3.17h8.265c.27 0 .5.22.5.5v14.25c0 .27-.23.5-.5.5H.5c-.28 0-.5-.23-.5-.5V3.672c0-.28.22-.5.5-.5h8.262L5.74.901a.507.507 0 0 1-.1-.7c.17-.22.48-.27.7-.1l2.869 2.154L12.07.102c.23-.17.54-.12.7.1Zm4.65 3.97H1v13.25h16.42V4.172Zm-2.67 10.58.108.007c.388.052.682.38.682.783 0 .44-.35.79-.79.79-.44 0-.79-.35-.79-.79 0-.44.35-.79.79-.79Zm-2.38 0 .1.006c.393.048.7.377.7.784 0 .44-.36.79-.8.79-.43 0-.79-.35-.79-.79 0-.44.36-.79.79-.79Zm2.38-8.42c.28 0 .5.23.5.5v6.34c0 .27-.22.5-.5.5H3.67c-.28 0-.5-.23-.5-.5v-6.34c0-.27.22-.5.5-.5h11.08Zm-.5 1H4.17v5.34h10.08v-5.34Z"
      fillRule="evenodd"
    />
  </svg>
);

export default TvIcon;
