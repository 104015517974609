import { Header4 } from 'components/UI/Brand/text';
import { FlatButton } from 'components/UI/Buttons/FlatButton/FlatButton';
import IncrementableInput from 'components/UI/IncrementableInput/IncrementableInput';
import { ICabin } from 'interfaces/ICabin';
import translate from 'translate';
import {
  CHILD_P2P,
  CHILD_REGULAR,
  INFANT_P2P,
  INFANT_REGULAR,
} from 'utils/constants';
import { isOverCabinLimit } from './utils/cabinLimit';
import styles from './styles.module.scss';

export interface ICabinsSelector {
  cabins: ICabin[];
  onGuestIncrement: (filed: string, value: string, id: string) => void;
  removeCabin: (id: string) => void;
  addCabin: () => void;
  isP2P?: boolean;
  isEU?: boolean;
  isNo?: boolean;
}

export const CabinsSelector = ({
  cabins,
  onGuestIncrement,
  removeCabin,
  addCabin,
  isP2P,
  isEU,
  isNo,
}: ICabinsSelector) => {
  const lengthOfCabinList = cabins?.length;
  return (
    <div data-testid="cabins-selector">
      {cabins.map((cabin, index) => (
        <div
          className={styles.cabinItem}
          key={`c${cabin.id.toString()}`}
          data-testid="cabins-selector__cabin"
        >
          <div className={styles.headerLine}>
            <Header4 as={'p'} className={styles.cabinTitle}>
              {translate(
                'SearchBar_CabinGuestSelector_CabinWithIndex',
                index + 1
              )}
            </Header4>
            {lengthOfCabinList > 1 && (
              <div data-testid="cabins-selector__remove-button">
                <FlatButton
                  onClick={() => {
                    removeCabin(cabin.id);
                  }}
                  icon="trash"
                  iconWidth={16}
                  iconHeight={16}
                  size={'small'}
                >
                  {translate('SearchBar_CabinGuestSelector_RemoveCabin')}
                </FlatButton>
              </div>
            )}
          </div>

          <div className={styles.inputsGroup}>
            <IncrementableInput
              value={cabin.adults.toString()}
              name="adults"
              label={translate('General_Adults')}
              max={cabin.maxAdults}
              handleClick={(field, value) =>
                onGuestIncrement(field, value, cabin.id)
              }
              handleChange={() => {}}
            />
            <IncrementableInput
              value={cabin.children.toString()}
              name="children"
              label={`${translate('General_Children')} (${
                isEU && isP2P ? CHILD_P2P : CHILD_REGULAR
              } ${translate('General_Years')})`}
              max={cabin.maxChildren}
              handleClick={(field, value) =>
                onGuestIncrement(field, value, cabin.id)
              }
              handleChange={() => {}}
            />
            <IncrementableInput
              value={cabin.infants.toString()}
              name="infants"
              label={`${translate('General_Infants')} (${
                isEU && isP2P ? INFANT_P2P : INFANT_REGULAR
              } ${translate('General_Years')})`}
              max={cabin.maxInfants}
              handleClick={(field, value) =>
                onGuestIncrement(field, value, cabin.id)
              }
              handleChange={() => {}}
            />
            {isEU && isP2P && (
              <IncrementableInput
                value={cabin.benefitFares.toString()}
                name="benefitFares"
                label={translate('General_BenefitFares')}
                max={cabin.maxBenefitFares}
                handleClick={(field, value) =>
                  onGuestIncrement(field, value, cabin.id)
                }
                handleChange={() => {}}
              />
            )}
            {isP2P && isNo && (
              <>
                <IncrementableInput
                  value={cabin.companions.toString()}
                  name="companions"
                  label={translate('General_Companions')}
                  max={cabin.maxCompanions}
                  handleClick={(field, value) =>
                    onGuestIncrement(field, value, cabin.id)
                  }
                  handleChange={() => {}}
                  isDisabled={cabin.benefitFares === 0}
                />
                <IncrementableInput
                  value={cabin.militaries.toString()}
                  name="militaries"
                  label={translate('General_Militaries')}
                  max={cabin.maxMilitaries}
                  handleClick={(field, value) =>
                    onGuestIncrement(field, value, cabin.id)
                  }
                  handleChange={() => {}}
                />
                <IncrementableInput
                  value={cabin.students.toString()}
                  name="students"
                  label={translate('General_Students')}
                  max={cabin.maxStudents}
                  handleClick={(field, value) =>
                    onGuestIncrement(field, value, cabin.id)
                  }
                  handleChange={() => {}}
                />
              </>
            )}
          </div>
          {cabins[lengthOfCabinList - 1].id === cabin.id &&
            !isOverCabinLimit(lengthOfCabinList, isP2P) && (
              <div
                data-testid="cabins-selector__add-button"
                className={styles.addCabinButton}
              >
                <FlatButton
                  onClick={addCabin}
                  icon="plus"
                  iconWidth={13}
                  iconHeight={14}
                >
                  {translate('SearchBar_CabinGuestSelector_AddCabin')}
                </FlatButton>
              </div>
            )}
        </div>
      ))}
    </div>
  );
};
