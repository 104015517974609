import {
  CABIN_TYPE,
  CABIN_GRADE,
  IBookedCabin,
  BOOKING_STEPS,
  NOT_CABIN_STEPS,
} from 'interfaces/IBooking';
import { ICabinCategory } from 'interfaces/ICabin';

const handleResetBookedCabins = (
  bookedCabins: IBookedCabin[],
  newCabin?: IBookedCabin
): IBookedCabin[] => {
  const cabins = [...bookedCabins];
  cabins.pop();

  if (newCabin) cabins.push(newCabin);

  return cabins;
};

const getPriceFromCabinType = (
  cabinCategories: ICabinCategory[],
  cabinTypeCode: string
): number => {
  let price = 0;

  cabinCategories.forEach((cabin) => {
    if (cabin.code === cabinTypeCode) price = cabin.fromPrice;
  });

  return price;
};

const getPriceFromCabinGrade = (
  cabinCategories: ICabinCategory[],
  cabinTypeCode: string,
  cabinGradeCode: string
): number => {
  let price = 0;
  let cabinCategory: ICabinCategory;

  if (!cabinGradeCode || !cabinTypeCode || !cabinCategories) return price;

  cabinCategories.forEach((cabin) => {
    if (cabin.code === cabinTypeCode) cabinCategory = cabin;
  });

  if (cabinCategory)
    cabinCategory.cabinGradesExtended.forEach((cabin) => {
      if (cabin.code === cabinGradeCode) price = cabin.priceDetail.price;
    });

  return price;
};

export const resetBookingStepHandler = (
  selectedBookingStep: BOOKING_STEPS,
  cabinIndex: number,
  bookedCabins: IBookedCabin[],
  cabinCategories: ICabinCategory[]
): IBookedCabin[] => {
  const currentCabin: IBookedCabin = bookedCabins[cabinIndex];
  let resetBookedCabins: IBookedCabin[] = [];
  const isEditSameCabin = cabinIndex + 1 === bookedCabins.length;

  if (NOT_CABIN_STEPS.includes(selectedBookingStep)) {
    return bookedCabins;
  }

  if (isEditSameCabin) {
    switch (selectedBookingStep) {
      case CABIN_TYPE:
        resetBookedCabins = handleResetBookedCabins(bookedCabins, {
          ...currentCabin,
          cabinGrade: '',
          cabinGradeTitle: '',
          cabinNumber: '',
          fareStructure: '',
          price: getPriceFromCabinType(
            cabinCategories,
            currentCabin?.cabinType
          ),
        });
        break;

      case CABIN_GRADE:
        resetBookedCabins = handleResetBookedCabins(bookedCabins, {
          ...currentCabin,
          cabinNumber: '',
          price: getPriceFromCabinGrade(
            cabinCategories,
            currentCabin?.cabinType,
            currentCabin?.cabinGrade
          ),
        });
        break;

      default:
        resetBookedCabins = [...bookedCabins];
        break;
    }
  } else {
    resetBookedCabins = handleResetBookedCabins(bookedCabins);
  }

  return resetBookedCabins;
};
