import { useEffect } from 'react';
import { useLocation } from 'react-router';
import { usePrevious } from 'hooks/usePrevious';

const expeditionPath = '/book/expedition';
const coastalPath = '/book/coastal';
const bookingFunnelPath = '/book/progress';

const ScrollToTop = (): null => {
  const { pathname } = useLocation();
  const prevPathName = usePrevious(pathname);

  useEffect(() => {
    if (
      pathname !== prevPathName &&
      !(prevPathName === expeditionPath && pathname === coastalPath) &&
      !(prevPathName === coastalPath && pathname === expeditionPath) &&
      !pathname.startsWith(bookingFunnelPath)
    ) {
      window.scrollTo(0, 0);
    }
  }, [pathname, prevPathName]);

  return null;
};

export default ScrollToTop;
