import { SVGProps } from 'react';

const ArrowUpIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    data-icon="arrow-up"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.3536 17.3536C22.1583 17.5488 21.8417 17.5488 21.6464 17.3536L12 7.70711L2.35355 17.3536C2.15829 17.5488 1.84171 17.5488 1.64645 17.3536C1.45119 17.1583 1.45119 16.8417 1.64645 16.6464L11.6464 6.64645C11.8417 6.45118 12.1583 6.45118 12.3536 6.64645L22.3536 16.6464C22.5488 16.8417 22.5488 17.1583 22.3536 17.3536Z"
    />
  </svg>
);

export default ArrowUpIcon;
