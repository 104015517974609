import { IPaymentUrlRequest } from 'interfaces/IPayment';
import { Action, ActionFunctionAny, createActions } from 'redux-actions';
import axios from '../../axios-instance';

export const {
  fetchPaymentTypesStart,
  fetchPaymentTypesSuccess,
  fetchPaymentTypesFailed,
  fetchPaymentOptionsStart,
  fetchPaymentOptionsSuccess,
  fetchPaymentOptionsFailed,
  fetchPaymentInformationBasedOnQuoteIdStart,
  fetchPaymentInformationBasedOnQuoteIdSuccess,
  fetchPaymentInformationBasedOnQuoteIdFailed,
  resetPaymentInformation,
  fetchPaymentUrlFailed,
  fetchPaymentUrlStart,
  fetchPaymentUrlSuccess,
  getPaymentStatusFailed,
  getPaymentStatusStart,
  getPaymentStatusSuccess,
} = createActions({
  FETCH_PAYMENT_TYPES_START: undefined,
  FETCH_PAYMENT_TYPES_SUCCESS: (paymentTypes) => ({ paymentTypes }),
  FETCH_PAYMENT_TYPES_FAILED: (error) => ({ error }),
  FETCH_PAYMENT_OPTIONS_START: undefined,
  FETCH_PAYMENT_OPTIONS_SUCCESS: (paymentOptions) => ({ paymentOptions }),
  FETCH_PAYMENT_OPTIONS_FAILED: (error) => ({ error }),
  FETCH_PAYMENT_INFORMATION_BASED_ON_QUOTE_ID_START: undefined,
  FETCH_PAYMENT_INFORMATION_BASED_ON_QUOTE_ID_SUCCESS: (
    paymentInformation
  ) => ({
    paymentInformation,
  }),
  FETCH_PAYMENT_INFORMATION_BASED_ON_QUOTE_ID_FAILED: undefined,
  RESET_PAYMENT_INFORMATION: undefined,
  FETCH_PAYMENT_URL_START: undefined,
  FETCH_PAYMENT_URL_SUCCESS: ({ paymentUrl }) => ({ paymentUrl }),
  FETCH_PAYMENT_URL_FAILED: (error) => ({ error }),
  GET_PAYMENT_STATUS_START: undefined,
  GET_PAYMENT_STATUS_SUCCESS: ({ paymentStatus }) => ({ paymentStatus }),
  GET_PAYMENT_STATUS_FAILED: (error) => ({ error }),
});

export const fetchPaymentOptions =
  (bookingId: string, passengerGroupId: string) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchPaymentOptionsStart());
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}bookings/PaymentOptions/${bookingId}/${passengerGroupId}`
      )
      .then((res) => {
        dispatch(fetchPaymentOptionsSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchPaymentOptionsFailed(err));
      });
  };

export const fetchPaymentTypes =
  () => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchPaymentTypesStart());
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}paymentOptions`)
      .then((res) => {
        dispatch(fetchPaymentTypesSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchPaymentTypesFailed(err));
      });
  };

export const fetchPaymentInformationBasedOnQuoteId =
  (quoteId: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchPaymentInformationBasedOnQuoteIdStart());
    axios
      .get(
        `${process.env.REACT_APP_API_BASE_URL}Quote/paymentoptions/${quoteId}`
      )
      .then(({ data }) => {
        dispatch(fetchPaymentInformationBasedOnQuoteIdSuccess(data));
      })
      .catch(() => {
        dispatch(fetchPaymentInformationBasedOnQuoteIdFailed());
      });
  };

export const fetchPaymentUrl =
  (paymentUrlRequest: IPaymentUrlRequest) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchPaymentUrlStart());

    const { REACT_APP_COMPANY } = process.env;

    axios
      .post(
        `${process.env.REACT_APP_API_BASE_URL}bookings/paymenturl`,
        paymentUrlRequest,
        {
          params: {
            productType: REACT_APP_COMPANY,
          },
        }
      )
      .then((res) => {
        dispatch(fetchPaymentUrlSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchPaymentUrlFailed(err));
      });
  };

export const getPayment =
  (paymentString: string | string[]) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(getPaymentStatusStart());
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}Bookings/payment`, {
        paymentString,
      })
      .then((res) => {
        dispatch(getPaymentStatusSuccess(res.data));
      })
      .catch((err) => {
        dispatch(getPaymentStatusFailed(err));
      });
  };
