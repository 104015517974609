import { SVGAttributes } from 'react';

const FilledCircleWithMinusInverted = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#currentColor" />
    <path d="M10.34 12.982H14.232V11.848H10.34V12.982Z" fill="white" />
  </svg>
);

export default FilledCircleWithMinusInverted;
