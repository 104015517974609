import { SVGProps } from 'react';

const CircleWithCrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.69995C6.31142 1.69995 1.69995 6.31142 1.69995 12C1.69995 17.6885 6.31142 22.2999 12 22.2999C17.6885 22.2999 22.2999 17.6885 22.2999 12C22.2999 6.31142 17.6885 1.69995 12 1.69995ZM0.699951 12C0.699951 5.75913 5.75913 0.699951 12 0.699951C18.2408 0.699951 23.2999 5.75913 23.2999 12C23.2999 18.2408 18.2408 23.2999 12 23.2999C5.75913 23.2999 0.699951 18.2408 0.699951 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.8536 7.14645C17.0488 7.34171 17.0488 7.65829 16.8536 7.85355L7.85355 16.8536C7.65829 17.0488 7.34171 17.0488 7.14645 16.8536C6.95118 16.6583 6.95118 16.3417 7.14645 16.1464L16.1464 7.14645C16.3417 6.95118 16.6583 6.95118 16.8536 7.14645Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.14645 7.14645C7.34171 6.95118 7.65829 6.95118 7.85355 7.14645L16.8536 16.1464C17.0488 16.3417 17.0488 16.6583 16.8536 16.8536C16.6583 17.0488 16.3417 17.0488 16.1464 16.8536L7.14645 7.85355C6.95118 7.65829 6.95118 7.34171 7.14645 7.14645Z"
    />
  </svg>
);

export default CircleWithCrossIcon;
