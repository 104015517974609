import { SVGProps } from 'react';

const CircleMoneyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="currentColor"
    {...props}
  >
    <path
      d="M11 0c6.075 0 11 4.926 11 11s-4.925 11-11 11S0 17.074 0 11 4.925 0 11 0Zm0 .973C5.462.973.973 5.461.973 11c0 5.539 4.489 10.027 10.027 10.027 5.537 0 10.027-4.488 10.027-10.027C21.027 5.461 16.537.973 11 .973Zm.459 4.263v1.04c1.755.156 2.743 1.196 2.821 2.704h-1.144c-.104-.845-.559-1.599-1.677-1.768v3.393l.377.065c1.924.338 2.626 1.248 2.626 2.613 0 1.638-1.04 2.665-3.003 2.808v1.417h-.858v-1.417c-1.989-.13-2.925-1.274-3.068-2.847H8.69c.143.897.65 1.716 1.911 1.859v-3.549l-.234-.039c-1.599-.299-2.496-1.118-2.496-2.691 0-1.352 1.118-2.392 2.73-2.548v-1.04h.858Zm0 6.487v3.367h.078c1.274-.143 1.755-.806 1.755-1.703 0-.832-.494-1.417-1.677-1.638l-.156-.026Zm-.858-4.511c-1.04.143-1.612.819-1.612 1.612 0 .988.611 1.456 1.521 1.612l.091.013Z"
      fillRule="evenodd"
    />
  </svg>
);

export default CircleMoneyIcon;
