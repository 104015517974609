import { handleActions, ReduxCompatibleReducer } from 'redux-actions';
import {
  fetchClassicVoyagesStart,
  fetchClassicVoyagesSuccess,
  fetchClassicVoyagesFailed,
} from '../actions/classicVoyages';
import { IClassicVoyagesState } from '../../interfaces/IClassicVoyage';

const initialState: IClassicVoyagesState = {
  classicVoyagesList: [],
  loading: false,
};

const reducer: ReduxCompatibleReducer<
  IClassicVoyagesState,
  IClassicVoyagesState
> = handleActions(
  {
    [fetchClassicVoyagesStart.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchClassicVoyagesSuccess.toString()]: (state, action) => ({
      ...state,
      classicVoyagesList: action.payload.classicVoyagesList,
      loading: false,
    }),
    [fetchClassicVoyagesFailed.toString()]: (state) => ({
      ...state,
      error: true,
      loading: false,
    }),
  },
  initialState
);

export default reducer;
