import languageKeys from './languageKeys';

enum Region {
  Americas = 1,
  APACs = 2,
  Germany = 3,
  UK = 4,
  EMEA = 5,
  Switzerland = 6,
  France = 7,
  Norway = 8,
  Sweden = 9,
  Denmark = 10,
  Austria = 11,
}

const getRegionName = (code: number): string => {
  switch (code) {
    case 11:
      return 'AT';
    case 10:
      return 'DK';
    case 9:
      return 'SE';
    case 8:
      return 'NO';
    case 7:
      return 'FR';
    case 6:
      return 'CH';
    case 5:
      return 'EMEA';
    case 4:
      return 'UK';
    case 3:
      return 'DE';
    case 2:
      return 'APAC';
    case 1:
    default:
      return 'Americas';
  }
};

const getRegionId = (name: string): number => {
  switch (name.toLowerCase()) {
    case 'at':
      return 11;
    case 'dk':
      return 10;
    case 'se':
      return 9;
    case 'no':
      return 8;
    case 'fr':
      return 7;
    case 'ch':
      return 6;
    case 'emea':
      return 5;
    case 'uk':
      return 4;
    case 'de':
      return 3;
    case 'us':
      return 1;
    case 'en-americas':
      return 1;
    case 'apac':
      return 2;
    default:
      return -1;
  }
};

const getRegionById = (id: number): Region => {
  switch (id) {
    case 11:
      return Region.Austria;
    case 10:
      return Region.Denmark;
    case 9:
      return Region.Sweden;
    case 8:
      return Region.Norway;
    case 7:
      return Region.France;
    case 6:
      return Region.Switzerland;
    case 5:
      return Region.EMEA;
    case 4:
      return Region.UK;
    case 3:
      return Region.Germany;
    case 2:
      return Region.APACs;
    case 1:
    default:
      return Region.Americas;
  }
};

export const getRegionByLocale = (locale: string) => {
  switch (locale) {
    case languageKeys.EN_APAC:
      return Region.APACs;
    case languageKeys.EN_UK:
      return Region.UK;
    case languageKeys.EN_EMEA:
      return Region.EMEA;
    case languageKeys.de:
      return Region.Germany;
    case languageKeys.DE_CH:
      return Region.Switzerland;
    case languageKeys.FR:
      return Region.France;
    case languageKeys.AT:
      return Region.Austria;
    case languageKeys.NO:
      return Region.Norway;
    case languageKeys.DK:
      return Region.Denmark;
    case languageKeys.SE:
      return Region.Sweden;
    case languageKeys.en:
    case languageKeys.EN_AMERICAS:
    default:
      return Region.Americas;
  }
};

const getRegionByName = (name: string): Region => {
  switch (name.toLowerCase()) {
    case 'de':
      return Region.Germany;
    case 'apac':
      return Region.APACs;
    case 'uk':
      return Region.UK;
    case 'emea':
      return Region.EMEA;
    case 'ch':
      return Region.Switzerland;
    case 'fr':
      return Region.France;
    case 'at':
      return Region.Austria;
    case 'no':
      return Region.Norway;
    case 'se':
      return Region.Sweden;
    case 'dk':
      return Region.Denmark;
    case 'en-americas':
    default:
      return Region.Americas;
  }
};

export { Region, getRegionId, getRegionName, getRegionByName, getRegionById };
