import { memo } from 'react';
import { Header1, Header2, Header4, Subtitle } from 'components/UI/Brand/text';
import CustomImage from 'components/UI/CustomImage/CustomImage';
import Tag from 'components/UI/Tags/Tag';
import { IPromotion } from 'interfaces/IPromotions';
import translate from 'translate';
import { getFullDateFormat } from 'utils/multilanguage-support';
import { convertToUTC, getCalendarLocale } from 'utils/dates';
import { format } from 'date-fns';
import { getLanguage } from 'utils/getLanguage';
import styles from './styles.module.scss';

type IPromotionsHeader = Pick<
  IPromotion,
  'title' | 'description' | 'product' | 'promotionEndDate' | 'image'
>;

const PromotionsHeader = (props: IPromotionsHeader) => {
  const { title, description, product, promotionEndDate, image } = props;
  const utcDate = convertToUTC(promotionEndDate);
  const language = getLanguage();
  const locale = getCalendarLocale(language);

  return (
    <div className={styles.promotionsHeader}>
      <Tag
        title={
          product === 'Coastal express'
            ? translate('BookingSummary_CoastalExpress')
            : translate('BookingSummary_Expedition')
        }
        type="voyage"
        isCoastal={product === 'Coastal express'}
      />
      {promotionEndDate && (
        <Header4 className={styles.date}>
          {`${translate('General_ValidUntil')} ${format(utcDate, getFullDateFormat(), { locale })}`}
        </Header4>
      )}
      <div className={styles.descriptionSection}>
        <div>
          <Header2 as={Header1} className={styles.title}>
            {title}
          </Header2>
          <Subtitle marginRight="five" className={styles.descriptionContent}>
            {description}
          </Subtitle>
        </div>
        {image && (
          <CustomImage
            className={styles.descriptionContent}
            isPresentation
            width={456}
            height={264}
            src={image}
          />
        )}
      </div>
    </div>
  );
};

export default memo(PromotionsHeader);
