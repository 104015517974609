/* eslint-disable jsx-a11y/control-has-associated-label */
import { BodyText, Header4 } from 'components/UI/Brand/text';
import { getJwtPayload } from 'utils/analytics';
import translate from 'translate';
import LinkifyMatchInSentence from 'components/UI/Links/LinkifyMatchInSentence';
import { CHILD_P2P, INFANT_P2P } from 'utils/constants';
import { Icon } from 'components';
import { DiscountVoyageList } from './DiscountVoyageList';
import styles from './styles.module.scss';

interface ICabinDiscountOverlayProps {
  onClose: (value: boolean) => void;
}

export const CabinDiscountOverlay = ({
  onClose,
}: ICabinDiscountOverlayProps) => {
  const discountItemsEU = [
    {
      сategory: `${translate('General_Children')} (${INFANT_P2P} ${translate(
        'General_Years'
      )})`,
      value: `100% ${translate('General_Discount')}`,
    },
    {
      сategory: `${translate('General_Children')} (${CHILD_P2P} ${translate(
        'General_Years'
      )})`,
      value: `50% ${translate('General_Discount')}`,
    },
    {
      сategory: translate('General_BenefitFare'),
      value: `50% ${translate('General_Discount')}`,
    },
  ];

  const discountItemsNO = [
    ...discountItemsEU,
    {
      сategory: translate('General_Companion'),
      value: `100% ${translate('General_Discount')}`,
    },
    {
      сategory: translate('General_Military'),
      value: `50% ${translate('General_Discount')}`,
    },
    {
      сategory: translate('General_Student'),
      value: `50% ${translate('General_Discount')}`,
    },
  ];

  const marketId = getJwtPayload()['http://user/market'];
  return (
    <div className={styles.overlay}>
      <button className={styles.closeButton}>
        <Icon
          iconType="cross"
          height={20}
          width={20}
          onClick={() => onClose(false)}
        />
      </button>
      <div className={styles.overlayForm}>
        <Header4 className={styles.overlayHeaderText}>
          {translate('SearchBar_Overlay_Title')}
        </Header4>
        <DiscountVoyageList
          discountItems={marketId === 'NO' ? discountItemsNO : discountItemsEU}
        />
        <BodyText className={styles.discountLink}>
          <LinkifyMatchInSentence
            text={translate('SearchBar_Benefit_Program_FullSentence')}
            textToLinkify={translate('SearchBar_Benefit_Program')}
            href="/learn/article/coa-discounts-p2p"
            rel="noreferrer"
            target="_blank"
          />
        </BodyText>
      </div>
    </div>
  );
};
