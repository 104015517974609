import { SVGProps } from 'react';

const PhoneIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#000"
      d="M2.863.158c.2-.21.537-.21.737 0L7.663 4.21c.21.21.21.547 0 .747L5.326 7.295l7.38 7.379 2.336-2.337c.2-.21.537-.21.747 0l4.053 4.063c.21.2.21.537 0 .737l-2.705 2.705a.494.494 0 0 1-.369.158C7.505 20 0 12.495 0 3.232c0-.137.053-.274.158-.369L2.863.158Zm-1.81 3.295c.115 8.505 6.99 15.379 15.494 15.494l2.18-2.179-3.316-3.315-2.337 2.336c-.2.2-.537.2-.737 0L4.21 7.663c-.2-.2-.2-.537 0-.737L6.547 4.59 3.232 1.274l-2.18 2.179Z"
      fillRule="evenodd"
    />
  </svg>
);

export default PhoneIcon;
