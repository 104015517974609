export default {
  contentPaddingBottom: 150,
  desktopContainerWidth: 1136,
  wideDesktopWidth: 1440,
  articleContainerSize: 752,
};

export const breakpoints = {
  mobile: '375px',
  tablet: '768px',
  laptop: '1170px',
  desktop: '1440px',
};

export const media = {
  minMobile: `(min-width: ${breakpoints.mobile})`,
  minTablet: `(min-width: ${breakpoints.tablet})`,
  minLaptop: `(min-width: ${breakpoints.laptop})`,
  minDesktop: `(min-width: ${breakpoints.desktop})`,
  maxMobile: `(max-width: ${breakpoints.mobile})`,
  maxTablet: `(max-width: ${breakpoints.tablet})`,
  maxLaptop: `(max-width: ${breakpoints.laptop})`,
  maxDesktop: `(max-width: ${breakpoints.desktop})`,
};

export const footerHeight = '426px';
export const headerHeight = '73px';

// depricated
export enum ScreenSize {
  DESKTOP = 1440,
  TABLET = 1023,
  MOBILE = 767,
}

export const FROM_DESKTOP = `@media (min-width: ${ScreenSize.DESKTOP}px)`;
export const UP_TO_DESKTOP = `@media (max-width: ${ScreenSize.DESKTOP}px)`;

export const FROM_TABLET = `@media (min-width: ${ScreenSize.TABLET}px)`;
export const UP_TO_TABLET = `@media (max-width: ${ScreenSize.TABLET}px)`;

export const FROM_MOBILE = `@media (min-width: ${ScreenSize.MOBILE}px)`;
export const UP_TO_MOBILE = `@media (max-width: ${ScreenSize.MOBILE}px)`;
