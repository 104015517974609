import { SVGProps } from 'react';

const WheelchairSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.00293 1C4.45064 1 4.00293 1.44772 4.00293 2C4.00293 2.55228 4.45064 3 5.00293 3C5.55521 3 6.00293 2.55228 6.00293 2C6.00293 1.44772 5.55521 1 5.00293 1ZM3.00293 2C3.00293 0.89543 3.89836 0 5.00293 0C6.1075 0 7.00293 0.89543 7.00293 2C7.00293 3.10457 6.1075 4 5.00293 4C3.89836 4 3.00293 3.10457 3.00293 2Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.5029 14C10.2268 14 10.0029 14.2239 10.0029 14.5C10.0029 14.7761 10.2268 15 10.5029 15C10.7791 15 11.0029 14.7761 11.0029 14.5C11.0029 14.2239 10.7791 14 10.5029 14ZM9.00293 14.5C9.00293 13.6716 9.6745 13 10.5029 13C11.3314 13 12.0029 13.6716 12.0029 14.5C12.0029 15.3284 11.3314 16 10.5029 16C9.6745 16 9.00293 15.3284 9.00293 14.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.97445 5.3419C5.06178 5.60387 4.9202 5.88703 4.65822 5.97436L1.8547 6.90886L0.947323 8.72362C0.823829 8.97061 0.523492 9.07072 0.276503 8.94723C0.0295135 8.82373 -0.0705987 8.5234 0.0528959 8.27641L1.0529 6.27641C1.1122 6.1578 1.21619 6.06761 1.342 6.02567L4.342 5.02567C4.60397 4.93835 4.88713 5.07993 4.97445 5.3419Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.55551 9.58986C2.13108 9.20527 2.80777 9 3.5 9C3.77614 9 4 9.22386 4 9.5C4 9.77614 3.77614 10 3.5 10C3.00555 10 2.5222 10.1466 2.11108 10.4213C1.69995 10.696 1.37952 11.0865 1.1903 11.5433C1.00108 12.0001 0.951575 12.5028 1.04804 12.9877C1.1445 13.4727 1.3826 13.9181 1.73223 14.2678C2.08187 14.6174 2.52732 14.8555 3.01228 14.952C3.49723 15.0484 3.9999 14.9989 4.45671 14.8097C4.91353 14.6205 5.30397 14.3 5.57868 13.8889C5.85338 13.4778 6 12.9945 6 12.5C6 12.2239 6.22386 12 6.5 12C6.77614 12 7 12.2239 7 12.5C7 13.1922 6.79473 13.8689 6.41014 14.4445C6.02556 15.0201 5.47893 15.4687 4.83939 15.7336C4.19985 15.9985 3.49612 16.0678 2.81719 15.9327C2.13825 15.7977 1.51461 15.4644 1.02513 14.9749C0.535644 14.4854 0.202301 13.8617 0.0672531 13.1828C-0.0677952 12.5039 0.00151649 11.8001 0.266423 11.1606C0.53133 10.5211 0.979934 9.97444 1.55551 9.58986Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.68061 6L5.10991 6L5.8299 9.6C5.85308 9.71262 5.91453 9.81461 6.00361 9.88733C6.0929 9.96022 6.20464 10 6.3199 10H9.5C9.89783 10 10.2794 10.158 10.5607 10.4393C10.842 10.7206 11 11.1022 11 11.5C11 11.7761 10.7761 12 10.5 12C10.2239 12 10 11.7761 10 11.5C10 11.3674 9.94733 11.2402 9.85356 11.1464C9.75979 11.0527 9.63261 11 9.5 11H6.32011C5.97435 11 5.63908 10.8806 5.37124 10.662C5.10336 10.4433 4.91927 10.1388 4.85011 9.8L4.84971 9.79806L4.00971 5.59806C3.98034 5.45117 4.01836 5.29885 4.11333 5.18301C4.2083 5.06716 4.35021 5 4.5 5H5.67973"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.3172 6.11251C6.2278 6.0   961 6.11595 5.99986 6.0006 6C5.72446 6.00033 5.50033 5.77674 5.5 5.5006C5.49967 5.22446 5.72326 5.00033 5.9994 5C6.34545 4.99958 6.681 5.11883 6.94919 5.33753C7.21737 5.55624 7.40169 5.86094 7.4709 6.2L7.47134 6.20216L7.91033 8.40216C7.96437 8.67296 7.78865 8.9363 7.51784 8.99033C7.24704 9.04437 6.9837 8.86865 6.92967 8.59784L6.49092 6.39911C6.46771 6.28645 6.40636 6.18522 6.3172 6.11251Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4 5.5C4 5.22386 4.22386 5 4.5 5H6C6.27614 5 6.5 5.22386 6.5 5.5C6.5 5.77614 6.27614 6 6 6H4.5C4.22386 6 4 5.77614 4 5.5Z"
    />
  </svg>
);

export default WheelchairSmallIcon;
