import { IArrivalAndDeparturePackages } from 'interfaces/IArrivalAndDeparturePackages';
import {
  ISearchMappedResult,
  ISearchParams,
  ISearchResults,
} from 'interfaces/ISearch';
import { COASTAL_OPTIONS, SEARCH_TYPE } from 'utils/constants';
import {
  transformSearchData,
  transformCabinCategories,
} from 'utils/mappers/searchMapper';
import { ICabinData } from 'interfaces/ICabin';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { ISearchDates } from 'interfaces/ISearchDates';
import { api } from './api';

export const searchApi = api.injectEndpoints({
  endpoints: (build) => ({
    getArrivalAndDeparturePackages: build.query<
      IArrivalAndDeparturePackages,
      string
    >({
      query: (quoteId) => ({ url: `Search/package/arrdep/${quoteId}` }),
    }),
    getSelectedCabinDetails: build.query<
      ICabinData,
      {
        quoteId: string;
        voyageId: string;
        shipCode: string;
        isRefurbished: string;
      }
    >({
      query: ({ quoteId, voyageId, shipCode, isRefurbished }) => ({
        url: 'Search/cabins',
        params: { quoteId, voyageId, shipCode, isRefurbished },
      }),
      transformResponse: (response: ICabinData) => ({
        ...response,
        cabinCategories: transformCabinCategories(response.cabinCategories),
      }),
    }),
    getCoastalPackageCodes: build.query<string[], string>({
      query: (voyageId) => ({
        url: 'Search/coastal/packagecodes',
        params: { voyageId },
      }),
    }),
    getPackageList: build.mutation<ISearchDates, string>({
      query: (codes) => ({
        url: 'Search/referenceData/packageList',
        params: { codes },
        method: 'GET',
      }),
    }),
    getCoastalResults: build.query<
      ISearchMappedResult,
      {
        searchParams: ISearchParams;
        keepPreviousResults: boolean;
        voyageType: COASTAL_OPTIONS;
      }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, fetchCoastal) {
        const { searchParams, voyageType } = _arg;

        let url: string;
        if (voyageType === COASTAL_OPTIONS.CLASSIC_VOYAGE) {
          url = 'Search/coastal/Classic';
        } else if (voyageType === COASTAL_OPTIONS.P2P) {
          url = 'Search/coastal/porttoport';
        } else if (
          voyageType === COASTAL_OPTIONS.PACKAGE_VOYAGE ||
          voyageType === COASTAL_OPTIONS.NORTH_CAPE_EXPRESS ||
          voyageType === COASTAL_OPTIONS.SVALBARD_EXPRESS ||
          voyageType === COASTAL_OPTIONS.SPITSBERGEN_ADVENTURER ||
          voyageType === COASTAL_OPTIONS.HURTIGRUTEN_HAMBURG
        ) {
          url = 'Search/coastal/package';
        }

        const response = await fetchCoastal({
          method: 'POST',
          url,
          body: {
            ...searchParams,
          },
        });

        if (response.error) {
          return { error: response.error as FetchBaseQueryError };
        }

        const searchResults = response.data as ISearchResults;

        return {
          data: {
            cabins: searchResults.cabins,
            tours:
              transformSearchData(searchResults, SEARCH_TYPE.COASTAL) || [],
          },
        };
      },
    }),
    getExpeditionResults: build.query<ISearchMappedResult, ISearchParams>({
      async queryFn(_arg, _queryApi, _extraOptions, fetchExpedition) {
        const searchBody = { ..._arg };

        if (searchBody?.periods?.length) {
          delete searchBody.startDate;
          delete searchBody.endDate;
        } else {
          delete searchBody.periods;
        }

        const getResult = async ({
          endDate,
          startDate,
        }: {
          endDate: number;
          startDate: number;
        }): Promise<ISearchMappedResult> => {
          const result = await fetchExpedition({
            method: 'POST',
            url: 'Search/expedition',
            body: {
              ...searchBody,
              startDate,
              endDate,
            },
          });
          if (result.error) {
            return null;
          }
          const searchResults = result.data as ISearchResults;
          return {
            destination: searchBody.destinationLabel,
            cabins: searchResults.cabins,
            tours:
              transformSearchData(searchResults, SEARCH_TYPE.EXPEDITION) || [],
          };
        };

        const dates = searchBody.periods?.length
          ? _arg.periods
          : [{ startDate: _arg.startDate, endDate: _arg.endDate }];

        const promises = dates.map(({ startDate, endDate }) =>
          getResult({ startDate, endDate })
        );

        const results = await Promise.all(promises);

        return {
          data: { ...results[0], tours: results.flatMap(({ tours }) => tours) },
        };
      },
    }),
  }),
});

export const {
  useGetArrivalAndDeparturePackagesQuery,
  useGetExpeditionResultsQuery,
  useGetSelectedCabinDetailsQuery,
  useGetCoastalPackageCodesQuery,
  useGetCoastalResultsQuery,
  useGetPackageListMutation,
} = searchApi;

export const {
  endpoints: {
    getArrivalAndDeparturePackages,
    getSelectedCabinDetails,
    getCoastalPackageCodes,
    getExpeditionResults,
    getPackageList,
    getCoastalResults,
  },
} = searchApi;
