import { HTMLAttributes, MouseEvent } from 'react';
import styled from 'styled-components';

import { typography } from 'style/typography';
import { Icon } from 'components';
import AlertIcon from './AlertIcon';
import { TAlertType } from './utils/alertType';
import { getAlertColor } from './utils/getAlertColor';

export interface BannerProps extends HTMLAttributes<HTMLDivElement> {
  type: TAlertType;
  onClose: (e: MouseEvent<HTMLButtonElement>) => void;
}

interface BannerContainerProps {
  type: TAlertType;
}

const BannerContainer = styled.div<BannerContainerProps>`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  background-color: ${(props) => getAlertColor(props.type)};
  font-family: ${typography?.normal?.fontFamily};
  font-size: ${typography?.normal?.fontSize?.small};
`;

const BannerContent = styled.div`
  margin: 0 35px 0 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  right: 40px;
  top: 23px;
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
`;

/**
 * Use `AlertBanner` to display global messages at the top a page
 */
const AlertBanner = ({ type, onClose, children, ...rest }: BannerProps) => (
  <BannerContainer type={type} {...rest}>
    <AlertIcon alertType={type} />
    <BannerContent>{children}</BannerContent>
    <CloseButton onClick={onClose} type="button">
      <Icon iconType="cross" />
    </CloseButton>
  </BannerContainer>
);

export default AlertBanner;
