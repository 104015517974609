import { format } from 'date-fns';
import { ICabinCategory } from 'interfaces/ICabin';
import { IPackage } from 'interfaces/IPackage';
import { IPort } from 'interfaces/IPort';
import { IPrice } from 'interfaces/IPrice';
import { IPromotionTag } from 'interfaces/IPromotionTag';
import { ISearchResults } from 'interfaces/ISearch';
import { IShipDetail } from 'interfaces/IShip';
import { ITour } from 'interfaces/ITour';
import { IDetailedVoyage } from 'interfaces/IVoyage';
import translate from 'translate';
import { SEARCH_TYPE, getThirtyUnixMinutesFromNow } from 'utils/constants';
import { getCalendarLocale } from 'utils/dates';
import { getLanguage } from 'utils/getLanguage';
import { checkDateRange } from 'utils/promotions/getManualPromotions';
import { getPromotions, getStrikeThroughPrice } from 'utils/search';

export const transformCabinCategories = (
  cabinCategories: ICabinCategory[]
): ICabinCategory[] =>
  cabinCategories.map((cabinCategory) => ({
    ...cabinCategory,
    cabinGradesExtended: cabinCategory.cabinGrades,
  }));

const transformShip = (ship: IShipDetail): IShipDetail => {
  if (!ship) {
    return null;
  }

  return {
    ...ship,
    description: ship.body,
    shipName: ship.heading,
    shipPassengerCapacity: ship.passengerCapacity,
    cabinCategories: ship.cabinCategories
      ? transformCabinCategories(ship.cabinCategories)
      : [],
  };
};
const transformVoyages = (
  voyages: IDetailedVoyage[],
  fromPort: IPort,
  toPort: IPort
): IPackage[] => {
  const expireDate = getThirtyUnixMinutesFromNow();
  return voyages
    .filter((voyage) => voyage.ship)
    .map((voyage) => ({
      ...voyage,
      prices: voyage?.prices ?? [
        {
          price: voyage?.price,
          isAvailable: true,
          isSoldOut: false,
          priceDetail: voyage?.priceDetail,
        } as IPrice,
      ],
      ship: transformShip(voyage.ship),
      passengerCapacity: voyage.ship.passengerCapacity,
      facilities: voyage.ship.facilities,
      fromPort,
      toPort,
      isViaGeiranger: voyage.isViaGeiranger,
      isViaHjorundfjorden: voyage.isViaHjorundfjorden,
      hasVehicleAvailability: voyage.hasVehicleAvailability,
      cabins:
        voyage.ship && voyage.ship.cabinCategories
          ? transformCabinCategories(voyage.ship.cabinCategories)
          : [],
      priceDetail: {
        price: voyage.priceDetail ? voyage.priceDetail.price : null,
        strikeThroughPrice: voyage.priceDetail
          ? voyage.priceDetail.strikeThroughPrice
          : null,
        promotions: voyage.priceDetail ? voyage.priceDetail.promotions : null,
      },
      travelSuggestionCode: voyage.travelSuggestionCode,
      expireDate,
    }));
};

const getDepartureDate = (packages: IPackage[], tour: ITour) => {
  const language = getLanguage();
  const locale = getCalendarLocale(language);
  const { departures } = tour;
  if (packages.length > 1) {
    const startDateYear =
      packages[0] && packages[0]?.departureDate
        ? format(packages[0].departureDate, 'y')
        : '';
    const firstMonth =
      packages[0] && packages[0]?.departureDate
        ? format(packages[0].departureDate, 'MMMM', { locale })
        : '';
    const lastCount = packages.length - 1;
    const endDateYear =
      packages[lastCount] && packages[lastCount]?.departureDate
        ? format(packages[lastCount].departureDate, 'y')
        : '';
    const lastMonth =
      packages[lastCount] && packages[lastCount]?.departureDate
        ? format(packages[lastCount].departureDate, 'MMMM', { locale })
        : '';

    return `${firstMonth}, ${startDateYear} - ${lastMonth}, ${endDateYear}`;
  }
  if (packages.length === 1) {
    const year2 =
      packages[0] && packages[0]?.departureDate
        ? format(packages[0].departureDate, 'y')
        : '';
    const firstMonth2 =
      packages[0] && packages[0]?.departureDate
        ? format(packages[0].departureDate, 'MMMM', { locale })
        : '';

    return `${firstMonth2}, ${year2}`;
  }
  return departures;
};

const hasUKPortDeparture = (tourCodes: string[], ukHomePortCodes: string[]) =>
  tourCodes.some((tourCode) => ukHomePortCodes.includes(tourCode));

const mapPackagesWithPromotions = (
  packages: IPackage[],
  promotionTags: IPromotionTag[]
): IPackage[] =>
  packages.map((_package) => {
    let packagePromotions: IPromotionTag[] = [];

    if (_package.priceDetail.promotions && promotionTags) {
      packagePromotions = _package.priceDetail.promotions.map((promotion) =>
        promotionTags.find((code) => code.key === promotion)
      );
    }

    const tourCodePromotions = promotionTags
      .filter((tag) => !tag.key && tag.travelSuggestionCodes?.length)
      .filter((tag) =>
        tag.travelSuggestionCodes.includes(_package.travelSuggestionCode)
      )
      .filter((promotion) =>
        checkDateRange(
          promotion.startDate,
          promotion.endDate,
          _package.departureDate
        )
      );

    const manualPromotions = promotionTags
      .filter((tag) => !tag.key && !tag.travelSuggestionCodes?.length)
      .filter((promotion) =>
        checkDateRange(
          promotion.startDate,
          promotion.endDate,
          _package.departureDate
        )
      );

    const allPackagePromotions = [
      ...packagePromotions,
      ...tourCodePromotions,
      ...manualPromotions,
    ].filter((promotion) => !!promotion);

    return {
      ..._package,
      promotions: allPackagePromotions,
    };
  });

export const transformSearchData = (
  response: ISearchResults,
  searchType: SEARCH_TYPE
): ITour[] => {
  const ukHomePortTravelCodes =
    response.expeditionManualPromotions?.find(
      (promotion) => promotion.title === 'UK Home Port'
    )?.travelSuggestionCodes ?? [];
  return (
    response.tours &&
    response.tours.map((tour) => {
      let detailsImages = [];
      if (tour.largeMap) {
        detailsImages.push(tour.largeMap);
      }
      if (tour.mediaContent) {
        detailsImages = detailsImages.concat(tour.mediaContent);
      }
      const packages = transformVoyages(
        tour.voyages,
        tour.fromPort,
        tour.toPort
      );
      const strikeThroughPrice = getStrikeThroughPrice(packages);
      const promotions = getPromotions(
        { ...tour, packages },
        response.promotionTags,
        searchType === SEARCH_TYPE.COASTAL,
        response.coastalManualPromotions,
        response.expeditionManualPromotions
      );

      const packagesWithPromotions = mapPackagesWithPromotions(
        packages,
        promotions
      );

      return {
        ...tour,
        isHomePortDeparture:
          searchType === SEARCH_TYPE.EXPEDITION
            ? tour.isHomePortDeparture ??
              hasUKPortDeparture(
                tour.travelSuggestionCodes,
                ukHomePortTravelCodes
              )
            : false,
        packages: packagesWithPromotions,
        strikeThroughPrice,
        promotions,
        packageCode: tour.travelSuggestionCodes,
        cabinCategories: tour?.cabins?.map(
          (cabin) =>
            ({
              title: cabin.split(/(?=[A-Z])/).join(' '),
            }) as ICabinCategory
        ),
        duration: tour.duration,
        departures: tour.departures,
        departureLabel: `${translate('DepartureLabel')}: ${getDepartureDate(
          packages,
          tour
        )}`,
        destination: tour.destinationName,
        shipCode: tour.voyages.length > 0 ? tour.voyages[0].ship.shipCode : '',
        shipName: tour.voyages.length > 0 ? tour.voyages[0].ship.heading : '',
        // This object contains information about the tour which is appearing on header of the the Voyages page, above of the voyages list.
        voyagesInfo: {
          fromPort: tour.fromPort,
          departures: tour.departures,
          shortDescription: tour.shortDescription,
          longDescription: tour.longDescription,
          notes: tour.notes,
          duration: tour.duration,
          shipNames: tour.ships
            ? tour.ships
                .filter((ship) => ship)
                .map((ship) => ship.heading)
                .join(', ')
            : [],
          title: tour.heading,
          usps: tour.usps,
          tabs: {
            details: {
              images: detailsImages,
              itinerary: tour.itinerary,
            },
            whatIncluded: {
              included: tour.included,
              notIncluded: tour.notIncluded,
            },
            ships: tour.ships
              ? tour.ships
                  .filter((ship) => ship)
                  .map((ship) => transformShip(ship))
              : [],
            optionalExcursions: [],
            practicalInfo: [],
          },
        },
      };
    })
  );
};
