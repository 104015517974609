import clsx from 'clsx';
import { Header4, InfoText, BodyText } from 'components/UI/Brand/text';
import translate from 'translate';
import { StrikeThroughPrice } from 'components/UI/StrikeThroughPrice';
import { getPriceFormat } from 'utils/search';
import { IPriceDetail } from 'interfaces/IPrice';
import { MaxPaxNotification } from '../MaxPaxNotification';
import styles from './styles.module.scss';

interface ISearchResultButtonProps {
  isMobile?: boolean;
  hasMaxPaxNotification: boolean;
  isSoldOut: boolean;
  genericCabinCategory: string;
  voyageId: string;
  cabinCode: string;
  onPriceClicked: (voyageId: string, cabinType?: string) => void;
  maxOccupancy: number;
  index: number;
  hasDiscountInTableRow: boolean;
  priceDetail: IPriceDetail;
}

export const SearchResultButton = ({
  isMobile,
  hasMaxPaxNotification,
  isSoldOut,
  genericCabinCategory,
  cabinCode,
  onPriceClicked,
  maxOccupancy,
  hasDiscountInTableRow,
  index,
  priceDetail,
  voyageId,
}: ISearchResultButtonProps) => {
  const { price, strikeThroughPrice } = priceDetail;

  return (
    <button
      className={clsx(styles.tableCellButton, {
        [styles.isSoldOut]: isSoldOut,
        [styles.hasMaxPaxNotification]: hasMaxPaxNotification,
        [styles.active]: !hasMaxPaxNotification && !isSoldOut,
      })}
      disabled={isSoldOut || hasMaxPaxNotification}
      id={`departure-${index}-cabin-category-${genericCabinCategory}-button`}
      onClick={() => onPriceClicked(voyageId, cabinCode)}
    >
      {!isSoldOut ? (
        <>
          {hasMaxPaxNotification && (
            <MaxPaxNotification maxOccupancy={maxOccupancy} />
          )}

          <div>
            {hasDiscountInTableRow && (
              <div className={styles.discount}>
                {strikeThroughPrice > price ? (
                  <StrikeThroughPrice strikeThroughPrice={strikeThroughPrice} />
                ) : null}
              </div>
            )}
            <Header4>{getPriceFormat(priceDetail.price)}</Header4>
            <InfoText>{translate('General_PrPerson')}</InfoText>
          </div>
          {!isMobile && (
            <div className={styles.alertMessageContainer}>
              {hasMaxPaxNotification && (
                <InfoText>
                  {translate(
                    'SearchResultItemRow_CabinSearchOverCapacityHelperText'
                  )}
                </InfoText>
              )}
            </div>
          )}
        </>
      ) : (
        <Header4 as={BodyText} className={styles.disabledButtonLabel}>
          {translate('SearchResultItemRow_Soldout')}
        </Header4>
      )}
    </button>
  );
};
