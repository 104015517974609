import {
  addDays,
  getDaysInMonth,
  set,
  startOfDay,
  getUnixTime,
  fromUnixTime,
  format,
  endOfMonth,
  differenceInMonths,
  addYears,
  getYear,
  setMonth,
} from 'date-fns';
import {
  de as deLocale,
  fr as frLocale,
  da as daLocale,
  nb as nbLocale,
  sv as svLocale,
} from 'date-fns/locale';
import languageKeys from './languageKeys';

export const getCalendarLocale = (languageCode: string) => {
  switch (languageCode) {
    case languageKeys.AT:
    case languageKeys.DE_CH:
    case languageKeys.de:
      return deLocale;
    case languageKeys.FR:
      return frLocale;
    case languageKeys.DK:
      return daLocale;
    case languageKeys.NO:
      return nbLocale;
    case languageKeys.SE:
      return svLocale;
    default:
      return undefined; // default to English
  }
};

export const getMonths = () => {
  const list = [];
  for (let i = 1; i <= 12; i += 1) {
    list.push({ value: `${i}`, description: `${i}` });
  }
  return list;
};

export const addMonths = (date: Date, months: number) => {
  date.setMonth(date.getMonth() + months);
  return date;
};

export const getYearsFromNow = (numberOfYears: number) => {
  const list = [];
  for (
    let i = new Date().getFullYear();
    i <= new Date().getFullYear() + numberOfYears;
    i += 1
  ) {
    list.push({ value: `${i}`, description: `${i}` });
  }
  return list;
};

export const getYearsTillNow = (numberOfYears: number) => {
  const list = [];
  for (
    let i = new Date().getFullYear();
    i >= new Date().getFullYear() - numberOfYears;
    i -= 1
  ) {
    list.push({ value: `${i}`, description: `${i}` });
  }
  return list;
};

export const getDays = () => {
  const list = [];
  for (let i = 1; i <= 31; i += 1) {
    list.push({ value: `${i}`, description: `${i}` });
  }
  return list;
};

export const getFlexibleYears = () => {
  const years: number[] = [];
  for (let i = 0; i < 4; i++) {
    years.push(new Date(addYears(new Date(), i)).getFullYear());
  }
  return years;
};

const numberAcsFn = (a: number, b: number) => a - b;

interface IConsecutivePeriodsResult {
  startDate: number;
  endDate: number;
}

export const getConsecutivePeriods = (
  date: number[],
  isFormatted: boolean,
  language: string
) => {
  const sortedDates = [...date].sort(numberAcsFn);
  const locale = getCalendarLocale(language);

  let length = 1;
  const result: (string | IConsecutivePeriodsResult)[] = [];

  if (sortedDates.length === 0) return result;

  for (let i = 1; i <= sortedDates.length; i++) {
    const current = fromUnixTime(sortedDates[i - 1]);
    const next = i < sortedDates.length ? fromUnixTime(sortedDates[i]) : null;

    const difference = next ? differenceInMonths(next, current) : null;

    if (i === sortedDates.length || (difference && difference > 1)) {
      if (length === 1) {
        result.push(
          isFormatted
            ? format(current, 'MMMM yyyy', { locale })
            : {
                startDate: sortedDates[i - length],
                endDate: Math.floor(endOfMonth(current).getTime() / 1000),
              }
        );
      } else {
        result.push(
          isFormatted
            ? `${format(fromUnixTime(sortedDates[i - length]), 'MMMM yyyy', { locale })} - ${format(endOfMonth(current), 'MMMM yyyy', { locale })}`
            : {
                startDate: sortedDates[i - length],
                endDate: Math.floor(endOfMonth(current).getTime() / 1000),
              }
        );
      }
      length = 1;
    } else {
      length++;
    }
  }

  return isFormatted ? result.join(', ') : result;
};

export const getNextAvailableSailingDate = (
  monthsAvailableForDestinationType: string[]
): Date => {
  if (
    !monthsAvailableForDestinationType ||
    monthsAvailableForDestinationType.length === 0
  ) {
    return addDays(new Date(), 1);
  }

  const todaysUnixDate = getUnixTime(new Date());
  const sailingDays: number[] = [];

  const getDaysInMonthFunc = (month: string) => {
    const months = [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
    ];
    const monthIndex = months.indexOf(month);
    const today = new Date();
    const currentYear = today.getFullYear();
    const daysInMonth = getDaysInMonth(new Date(currentYear, monthIndex));

    for (let day = 1; day <= daysInMonth; day++) {
      const dayDateThisYear = startOfDay(
        set(today, { year: currentYear, month: monthIndex, date: day })
      );
      const dayDateNextYear = startOfDay(
        set(today, { year: currentYear + 1, month: monthIndex, date: day })
      );

      sailingDays.push(getUnixTime(dayDateThisYear));
      sailingDays.push(getUnixTime(dayDateNextYear));
    }

    const todayUnixDate = getUnixTime(today);
    return sailingDays.filter((date) => date >= todayUnixDate);
  };

  monthsAvailableForDestinationType.forEach((month) =>
    getDaysInMonthFunc(month)
  );

  const closestAvailableDates = sailingDays
    .filter((date) => date >= todaysUnixDate)
    .sort(numberAcsFn);

  return closestAvailableDates.length > 0
    ? new Date(closestAvailableDates[0] * 1000)
    : new Date();
};

export const getNextAvailableSailingYear = (
  monthsAvailableForDestinationType: string[]
): number => {
  if (
    !monthsAvailableForDestinationType ||
    monthsAvailableForDestinationType?.length === 0
  ) {
    return getYear(addDays(new Date(), 1));
  }

  const todaysUnixDate = getUnixTime(new Date());
  const sailingPeriods: number[] = [];

  monthsAvailableForDestinationType.forEach((monthString) => {
    const month =
      new Date().toLocaleString('en-US', { month: 'long' }).toLowerCase() ===
      monthString.toLowerCase()
        ? new Date().getMonth()
        : new Date(`1 ${monthString} 2000`).getMonth();

    const thisYear = getUnixTime(endOfMonth(setMonth(new Date(), month)));
    const nextYear = getUnixTime(
      endOfMonth(setMonth(addYears(new Date(), 1), month))
    );

    sailingPeriods.push(thisYear);
    sailingPeriods.push(nextYear);
  });

  const closestAvailablePeriods = sailingPeriods
    .filter((date) => date >= todaysUnixDate)
    .sort(numberAcsFn);

  return getYear(new Date(closestAvailablePeriods[0] * 1000));
};

export const convertToUTC = (date: string) => {
  const dateObject = new Date(date);
  return new Date(
    Date.UTC(
      dateObject.getUTCFullYear(),
      dateObject.getUTCMonth(),
      dateObject.getUTCDate(),
      dateObject.getUTCHours(),
      dateObject.getUTCMinutes(),
      dateObject.getUTCSeconds()
    )
  );
};
