import { createActions } from 'redux-actions';
import axios from '../../axios-instance';

export const {
  fetchTrainingDataStart,
  fetchTrainingDataSuccess,
  fetchTrainingDataFailed,
  fetchCompletedModulesFailed,
  setNextPage,
  setPreviousPage,
  setPreviousPageById,
  setResetPage,
  setCurrentModule,
  setModuleCompleted,
  setModuleIncompleted,
} = createActions({
  FETCH_TRAINING_DATA_START: undefined,
  FETCH_TRAINING_DATA_SUCCESS: (data) => data,
  FETCH_TRAINING_DATA_FAILED: (error) => ({ error }),
  FETCH_COMPLETED_MODULES_FAILED: (error) => ({ error }),
  SET_NEXT_PAGE: undefined,
  SET_PREVIOUS_PAGE: undefined,
  SET_PREVIOUS_PAGE_BY_ID: (pageId) => ({ pageId }),
  SET_RESET_PAGE: undefined,
  SET_CURRENT_MODULE: (moduleId) => moduleId,
  SET_MODULE_COMPLETED: (moduleId) => moduleId,
  SET_MODULE_INCOMPLETED: (moduleId) => moduleId,
});

export const fetchTrainingData = () => async (dispatch) => {
  dispatch(fetchTrainingDataStart());
  let trainingDataResponse;
  let completedModulesResponse;
  try {
    trainingDataResponse = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}trainings`
    );

    completedModulesResponse = await axios.get(
      `${process.env.REACT_APP_API_BASE_URL}trainings/modules`
    );
  } catch (error) {
    return dispatch(fetchTrainingDataFailed(error));
  }

  return dispatch(
    fetchTrainingDataSuccess({
      trainingData: trainingDataResponse.data,
      completedModules: Object.keys(completedModulesResponse.data),
    })
  );
};

export const nextPage = () => (dispatch) => {
  dispatch(setNextPage());
};

export const previousPage = () => (dispatch) => {
  dispatch(setPreviousPage());
};

export const previousPageById = (pageId) => (dispatch) => {
  dispatch(setPreviousPageById(pageId));
};

export const resetPage = () => (dispatch) => {
  dispatch(setResetPage());
};

export const currentModule = (moduleId) => (dispatch) => {
  dispatch(setCurrentModule(moduleId));
};

export const setIncompleted = (moduleId) => (dispatch) => {
  dispatch(setModuleIncompleted(moduleId));
};

export const setCompleted = (moduleId) => (dispatch) => {
  dispatch(setModuleCompleted(moduleId));
};
