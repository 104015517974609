import { ICabin } from 'interfaces/ICabin';
import translate from 'translate';
import { getTotalPasssengersInCabin } from 'utils/getTotalPeopleInCabin';

export const getCabinValidationMessage = (cabinsParam: ICabin[]): string => {
  if (!cabinsParam) return translate('CabinGuestSelector_NoCabinSelected');
  const validationErrors: string[] = [];
  let allGuestCount = 0;

  cabinsParam.forEach((cabin) => {
    const { totalPassengers: cabinGuestCount } = getTotalPasssengersInCabin([
      cabin,
    ]);
    allGuestCount += cabinGuestCount;
    if (cabinGuestCount === 0) {
      validationErrors.push(translate('CabinGuestSelector_NoPassengers'));
    }
  });

  if (allGuestCount > 9) {
    validationErrors.push(
      translate('CabinGuestSelector_YouCanNotSelectMoreThan9Guest')
    );
  }

  if (!cabinsParam.every((cabin) => cabin.adults)) {
    validationErrors.push(translate('CabinGuestSelector_AtLeastOneAdult'));
  }

  return validationErrors.length > 0 ? validationErrors.shift() : '';
};
