import { BodyText, Overline } from 'components/UI/Brand/text';
import CustomImage from 'components/UI/CustomImage/CustomImage';
import { ILink } from 'interfaces/IFooter';
import { IImage } from 'interfaces/IImage';
import { Link } from 'react-router-dom';
import { UP_TO_TABLET } from 'style/sizes';
import styled from 'styled-components';

const AwardCard = styled.li`
  list-style: none;
  max-width: 200px;
  ${UP_TO_TABLET} {
    min-width: 250px;
    margin-left: 20px;
  }
`;

const Image = styled(CustomImage)`
  mix-blend-mode: multiply;
`;
const Title = styled(Overline)`
  flex: 999;
`;
const Description = styled(BodyText)`
  flex: 999;
`;

const LinkStyle = styled(Link)`
  all: unset;
  cursor: ${({ to }) => (to ? 'pointer' : 'default')};
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 20px;
  height: 100%;
  &:hover {
    text-decoration-line: ${({ to }) => (to ? 'underline' : 'none')};
  }
`;

export interface IAwardsProps {
  title: string;
  image: IImage;
  description?: string;
  awardLink?: ILink;
}

const Award = ({ title, image, description, awardLink }: IAwardsProps) => (
  <AwardCard key={title.replace(/\s/g, '')}>
    <LinkStyle to={awardLink?.hyperlink}>
      <Title data-testid="awards-title">{title} </Title>
      <Image
        dataTestid="awards-image"
        fit={'pad'}
        height={130}
        width={200}
        src={image.url}
        isPresentation
      />
      {description && (
        <Description data-testid="awards-description">
          {description}
        </Description>
      )}
    </LinkStyle>
  </AwardCard>
);

export default Award;
