import { SVGProps } from 'react';

const MailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={13}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M.088.152V11.83h15.806V.151H.088ZM7.99 6.955l-6.65-6.1h13.3l-6.65 6.1Zm-2.09-.964L.79 10.679V1.303L5.9 5.99Zm.52.477 1.57 1.44 1.57-1.44 5.08 4.659H1.34l5.08-4.66Zm3.66-.477 5.11-4.688v9.377l-5.11-4.689Z"
      fill="#000"
    />
  </svg>
);

export default MailIcon;
