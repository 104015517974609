import { SVGProps } from 'react';

const SpringIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={22}
    viewBox="0 0 18 22"
    fill="currentColor"
    {...props}
  >
    <g fillRule="evenodd">
      <path d="M16.59 4.648c-3.54-.224-6.75 2.323-7.23 6.016v.025l-.17.931c3.51.186 6.71-2.452 7.31-6.018l.09-.954zm-8.22 5.875c.58-4.402 4.56-7.388 8.83-6.819a.49.49 0 0 1 .43.541l-.13 1.467c0 .012 0 .024-.01.037-.7 4.248-4.67 7.384-8.96 6.813a.495.495 0 0 1-.33-.201.482.482 0 0 1-.09-.384l.26-1.454z" />
      <path d="M.5 1C.5.724.72.5 1 .5c4.41 0 7.97 3.557 7.97 7.967v1.466c0 .277-.23.5-.5.5A7.957 7.957 0 0 1 .5 2.467V1zm1 .518v.949a6.95 6.95 0 0 0 6.47 6.949v-.949A6.958 6.958 0 0 0 1.5 1.518z" />
      <path d="M8.47 7.7c.27 0 .5.224.5.5V21c0 .276-.23.5-.5.5-.28 0-.5-.224-.5-.5V8.2c0-.276.22-.5.5-.5z" />
    </g>
  </svg>
);

export default SpringIcon;
