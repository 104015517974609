import { createSlice } from '@reduxjs/toolkit';
import { IFeatureState } from 'interfaces/IFeature';
import { getFeatureFlags } from 'store/services/features';

const initialState: IFeatureState = {
  flags: null,
};

// this slice should be removed after updating all uses of state.feature with useGetFeatureFlagsQuery
const featureFlagsSlice = createSlice({
  name: 'feature',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(getFeatureFlags.matchFulfilled, (state, action) => {
      state.flags = action.payload;
    });
  },
});

export default featureFlagsSlice.reducer;
