import { useRef, useEffect, useState } from 'react';

/**
 * @param is the function that will be called on each interval
 * @param tickRate is how often the interval will run in seconds (optional) - default is 1 second
 * @param maxSeconds is the maximum number of seconds the interval will run for (optional) - if maxSeconds is not provided, the interval will run indefinitely
 *
 * @returns how many seconds have elapsed
 *
 */

export const useInterval = ({
  callback,
  options: { tickRate = 1, maxSeconds },
}: {
  callback: () => void;
  options?: { tickRate?: number; maxSeconds?: number };
}) => {
  const savedCallback = useRef(callback);
  const intervalRef = useRef(null);
  const [secondsElapsed, setSecondsElapsed] = useState(0);
  const startTimeRef = useRef(new Date().getTime());

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
      setSecondsElapsed((prevCounter) => prevCounter + 1);
    }

    intervalRef.current = setInterval(tick, tickRate * 1000);

    return () => clearInterval(intervalRef.current);
  }, [tickRate]);

  useEffect(() => {
    const currentTime = new Date().getTime();
    const elapsedTime = (currentTime - startTimeRef.current) / 1000;

    if (maxSeconds && elapsedTime >= maxSeconds) {
      clearInterval(intervalRef.current);
    }
  }, [secondsElapsed, maxSeconds]);

  return {
    secondsElapsed,
  };
};
