import { SVGProps } from 'react';

const SearchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="20"
    viewBox="0 0 19 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8.5" cy="8.5" r="7.5" stroke="currentColor" strokeWidth="2" />
    <line
      x1="13.7071"
      y1="14.2929"
      x2="17.9497"
      y2="18.5355"
      stroke="currentColor"
      strokeWidth="2"
    />
  </svg>
);

export default SearchIcon;
