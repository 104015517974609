import { ILegalPayload } from 'interfaces/ILegal';
import { Action, ActionFunctionAny, createActions } from 'redux-actions';
import axios from '../../axios-instance';

export const { fetchLegalStart, fetchLegalSuccess, fetchLegalFailed } =
  createActions({
    FETCH_LEGAL_START: undefined,
    FETCH_LEGAL_SUCCESS: (legal) => ({ legal }),
    FETCH_LEGAL_FAILED: (error) => ({ error }),
  });

export const fetchLegal =
  () => (dispatch: ActionFunctionAny<Action<ILegalPayload>>) => {
    dispatch(fetchLegalStart());
    axios
      .get<ILegalPayload>(`${process.env.REACT_APP_API_BASE_URL}Legal`)
      .then((res) => {
        dispatch(fetchLegalSuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchLegalFailed(err));
      });
  };
