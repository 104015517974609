import { LinkText } from 'components';
import { Fragment } from 'react';

interface IAnchorLink {
  onClick?: () => void;
  type?: string;
  text: string;
  textToLinkify: string;
  target?: string;
  rel?: string;
  href: string;
}

/**
 * Add a link anywhere in a sentence - useful for i18n.
 * caveat:
 * - textToLinkify should not appear more than once in the sentence (unless you want multiple links)
 */

const escapeRegExp = (string: string) =>
  string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string

const LinkifyMatchInSentence = ({
  onClick,
  type,
  text,
  textToLinkify,
  target,
  rel,
  href,
}: IAnchorLink) => {
  const regex = new RegExp(`(${escapeRegExp(textToLinkify)})`, 'gi');
  const parts = text.split(regex);

  return (
    <>
      {parts
        .filter((part: string) => part)
        .map((part: string) =>
          regex.test(part) ? (
            <LinkText
              key={`${part}`}
              type={type}
              onClick={onClick}
              target={target}
              rel={rel}
              href={href}
            >
              {part}
            </LinkText>
          ) : (
            <Fragment key={`${part}`}>{part}</Fragment>
          )
        )}
    </>
  );
};

export default LinkifyMatchInSentence;
