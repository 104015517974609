import {
  IQuotePayload,
  IQuotePayloadDataLayer,
} from 'containers/Book/BookingProgress/BookingFunnel/PassengerDetails/PassengerDetails.types';
import { IAnalyticsPromotion } from 'interfaces/IAnalytics';
import { IBookedCabin } from 'interfaces/IBooking';
import { ICabinsData } from 'interfaces/ICabin';
import { IExtraData, IExtrasList } from 'interfaces/IExtras';
import { IPassengerPrice } from 'interfaces/IPassenger';
import { ITour, ITourData } from 'interfaces/ITour';
import { IDetailedVoyage } from 'interfaces/IVoyage';
import { differenceInCalendarDays } from 'date-fns';
import { getToken, hasValidToken } from './getToken';
import localStorageKeys from './localStorageKeys';

//
// Example JWT payload
//
// {
//   "http://user/agencyId": "0010C000002ch90QAA",
//   "http://user/islead": false,
//   "http://user/agentId": "0030C000005yeJZQAY",
//   "nickname": "edimoldovan",
//   "name": "edimoldovan@gmail.com",
//   "picture": "https://s.gravatar.com/avatar/09d51b91367985461ddcb85109ef2d5f?s=480&r=pg&d=https%3A%2F%2Fcdn.auth0.com%2Favatars%2Fed.png",
//   "updated_at": "2019-11-25T12:28:11.423Z",
//   "email": "edimoldovan@gmail.com",
//   "email_verified": false,
//   "iss": "https://b2b-portal-poc.eu.auth0.com/",
//   "sub": "auth0|5dcb1e5ec7ff980ec8002ce3",
//   "aud": "Ydj1W0YbbY9PjKKMxfCn7buZwWRkAKIs",
//   "iat": 1574684891,
//   "exp": 1574720891
// }

interface IProduct {
  title: string;
  passengers: IPassengerPrice[];
  code?: string;
  id: string;
  category: string;
}

interface IAnalyticItem {
  title: string;
  position: string;
  id: string;
  hero: string;
}

const getJwtPayloadCore = (token: string) => {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(
    // eslint-disable-next-line no-undef
    window
      .atob(base64)
      .split('')
      .map((code) => `%${`00${code.charCodeAt(0).toString(16)}`.slice(-2)}`)
      .join('')
  );
  const jwtPayload = JSON.parse(jsonPayload);
  return jwtPayload;
};

const getJwtPayload = () => {
  const token = getToken();
  if (token) {
    return getJwtPayloadCore(token);
  }
  return {};
};

const getUserEmail = () => {
  const jwtPayload = getJwtPayload();
  return jwtPayload.email;
};

const getAgentName = () => {
  const jwtPayload = getJwtPayload();
  return jwtPayload['http://user/agentName'];
};

const getHasSeenWelcomeModal = () => {
  const jwtPayload = getJwtPayload();
  return jwtPayload['http://user/seenLightOnboarding'];
};

const getAgentId = () => {
  const jwtPayload = getJwtPayload();
  if (jwtPayload['http://user/agentId']) {
    const agentId = jwtPayload['http://user/agentId'];
    return agentId;
  }
  return null;
};

const getSearchString = () =>
  window.sessionStorage.getItem('utmParameters') || '';

const track = (eventName: string, payload?: unknown, ecommerce?: unknown) => {
  const isAuthenticated = hasValidToken();
  if (!isAuthenticated) return;
  const eventPayload = getJwtPayload();
  eventPayload.search = getSearchString();

  if (eventPayload) {
    eventPayload.path = window.location.pathname;
    eventPayload.userId = getAgentId();
    eventPayload.projectId = process.env.REACT_APP_SEGMENT_PROJECT_ID;
    eventPayload.event = eventName;
    eventPayload.type = 'track';
    eventPayload.email = null;
    eventPayload.name = null;
    eventPayload.nickname = null;
    if (payload) {
      eventPayload.actionPayload = payload;
    }

    if (ecommerce) {
      eventPayload.ecommerce = ecommerce;
    }
    window.dataLayer.push({
      event: eventName,
      ...eventPayload,
      isAuthenticated,
    });
  }
};

const pageTypes = {
  BOOKING: 'Booking',
  HOME: 'Home',
  LANDING: 'Landing',
  LEARN: 'Learn',
  MARKETING: 'Marketing',
  MY_BOOKINGS: 'My Bookings',
  MY_PROFILE: 'My Profile',
  PROMOTION: 'Promotion',
  TRAINING: 'Training',
};

const getPageType = (pageType: string) => {
  switch (pageType) {
    case 'promotion':
    case 'promotions':
    case 'offers':
      return pageTypes.PROMOTION;
    case 'learn':
      return pageTypes.LEARN;
    case 'marketing':
      return pageTypes.MARKETING;
    case 'training':
      return pageTypes.TRAINING;
    case 'mybookings':
      return pageTypes.MY_BOOKINGS;
    case 'myprofile':
      return pageTypes.MY_PROFILE;
    case 'progress':
    case 'search':
      return pageTypes.BOOKING;
    case 'book':
      return pageTypes.HOME;
    default:
      return pageTypes.LANDING;
  }
};

const page = (location: string) => {
  const isAuthenticated = hasValidToken();
  if (!isAuthenticated) return;
  const search = getSearchString();
  const { href, pathname } = window.location;
  const locationString = href.includes(search) ? href : `${href}?${search}`;

  const pageString = location || pathname;

  const pathnameList = pageString.split('/');
  let pagePathname = pathnameList[1];
  if (pathnameList?.length > 2 && pathnameList[1] === 'book') {
    // eslint-disable-next-line prefer-destructuring
    pagePathname = pathnameList[2];
  }
  const pageType = getPageType(pagePathname);

  if (window.dataLayer) {
    window.dataLayer.push({
      eventName: 'pageView',
      location: locationString,
      page: pageString,
      pageType,
      search,
      isAuthenticated,
    });
  }
};

const getCurrencyCode = () => localStorage.getItem(localStorageKeys.currency);

const setGoogleAnalyticsExtraObject = (
  extra: IExtraData,
  extraList: IExtrasList,
  idName: string
) => {
  let product = {} as IProduct;

  if (extra.addOnId === -1 || extra.excursionId === -1 || extra.mealId === -1) {
    return null;
  }

  switch (idName) {
    case 'addOnId': {
      product = {
        ...extraList.addons.find(({ addOnId }) => addOnId === extra.addOnId),
        id: extra.addOnId as string,
        category: 'Extra/Add on',
      };
      break;
    }
    case 'excursionId': {
      product = {
        ...extraList.excursions.find(
          ({ excursionId }) => excursionId === extra.excursionId
        ),
        id: extra.excursionId as string,
        category: 'Extra/Excursion',
      };
      break;
    }
    case 'mealId': {
      product = {
        ...extraList.meals.find(({ mealId }) => mealId === extra.mealId),
        id: extra.mealId as string,
        category: 'Extra/Meal',
      };
      break;
    }
    default:
      break;
  }

  const { title, passengers, code, id, category } = product;

  return {
    name: title,
    price: passengers[0].price,
    quantity: extra.passengers.length,
    brand: '',
    variant: code,
    id,
    category,
  };
};

const setGoogleAnalyticsObject = (
  cabinsData: ICabinsData,
  bookingCabinData: IBookedCabin[],
  voyageData: IDetailedVoyage,
  destintaionLabel: string,
  step: number,
  isCostal: boolean,
  id: string
) => {
  const voyageType = isCostal ? 'Costal' : 'Expedition';
  const baseCategoryString = `${voyageType}/${destintaionLabel}`;
  const currencyCode = getCurrencyCode();

  const cabins = bookingCabinData.map(({ cabinType, price, cabinGrade }) => ({
    name: cabinType,
    variant: voyageData.travelSuggestionCode,
    price,
    id: cabinGrade || null,
    category: `${baseCategoryString}/Cabin`,
    quantity: bookingCabinData.length,
    brand: voyageData.ship.shipName,
  }));

  const passengers = cabinsData?.partyMixDetails?.cabinsRequested?.flatMap(
    (cabin) => {
      const passengersList = cabin.passengers.map(
        ({ ageCategory, price, guestType }) => ({
          name: ageCategory,
          id: ageCategory,
          price,
          brand: voyageData.ship.shipName,
          category: `${baseCategoryString}/Passengers`,
          variant: guestType,
          quantity: 1,
        })
      );

      return passengersList.reduce((aggregatedPassengers, passenger) => {
        const existingPassenger = aggregatedPassengers.find(
          (pass) => pass.name === passenger.name
        );

        if (existingPassenger) {
          existingPassenger.quantity += 1;
        } else {
          aggregatedPassengers.push(passenger);
        }

        return aggregatedPassengers;
      }, []);
    }
  );
  const departureDate = voyageData.departureDate.split('T')[0];
  const arrivalDate = voyageData.arrivalDate.split('T')[0];

  return {
    voyageType,
    voyageName: voyageData.voyageTitle,
    voyageId: id,
    packageCode: voyageData.travelSuggestionCode,
    baseCategoryString,
    passengers: cabinsData.partyMixDetails.cabinsRequested[0].passengers.length,
    departureDate,
    departurePort: voyageData.fromPort,
    arrivalPort: voyageData.toPort,
    shipName: voyageData.ship.shipName,
    voyageDuration:
      differenceInCalendarDays(new Date(arrivalDate), new Date(departureDate)) +
      1,
    daysUntilDeparture: differenceInCalendarDays(
      new Date(departureDate),
      new Date()
    ),
    ecommerce: {
      checkout: {
        actionField: { step },
        currencyCode,
        products: [
          {
            name: voyageData.voyageTitle,
            id,
            price: 0,
            brand: voyageData.ship.shipName,
            category: `${baseCategoryString}/Voyage`,
            variant: voyageData.travelSuggestionCode,
            quantity: 1,
          },
        ].concat(cabins, passengers),
      },
    },
  };
};

const getAnalyticsProductImpression = (
  tours: ITour[],
  isExpedition: boolean
) => {
  const currencyCode = getCurrencyCode();
  const analyticsObject = {
    ecommerce: {
      currencyCode,
      impressions: tours.map(
        (
          { heading, id, price, shipName, destination, packageCode },
          index
        ) => ({
          name: heading,
          id,
          price,
          brand: shipName,
          category: `${
            isExpedition ? 'Expedition' : 'Costal'
          }/${destination}/Voyage`,
          variant: packageCode,
          position: index + 1,
        })
      ),
    },
  };
  return analyticsObject;
};

const getAnalyticsProductDetails = (
  voyage: ITourData,
  isCostal: boolean,
  type: string
) => {
  const voyageType = isCostal ? 'Costal' : 'Expedition';
  const { heading, id, destination, packages, price, shipName } = voyage;
  let { packageCode } = voyage;
  const baseCategoryString = `${voyageType}/${destination}`;

  const products = packages?.map(
    ({ priceDetail, ship, travelSuggestionCode }) => ({
      name: heading,
      id,
      price: priceDetail.price,
      brand: ship.shipName,
      category: `${baseCategoryString}/Voyage`,
      variant: travelSuggestionCode,
    })
  );

  let ecommerce = null;

  switch (type) {
    case 'detail':
      ecommerce = {
        detail: {
          products,
        },
      };
      break;
    case 'click':
      ecommerce = {
        click: {
          products,
        },
      };
      break;
    case 'add':
      packageCode = null;
      ecommerce = {
        currencyCode: getCurrencyCode(),
        add: {
          products: [
            {
              name: heading,
              id,
              price,
              brand: shipName,
              category: `${baseCategoryString}/Voyage`,
              variant: null,
              quantity: 1,
            },
          ],
        },
      };
      break;
    default:
      ecommerce = {};
      break;
  }

  const result = {
    voyageType,
    voyageName: heading,
    voyageId: id,
    packageCode,
    baseCategoryString,
    ecommerce,
  };
  return result;
};

const getAnalyticsPromotion = (
  items: IAnalyticItem[],
  type: string
): IAnalyticsPromotion => {
  if (type === 'click') {
    return {
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: items.map(({ title, id, hero, position }) => ({
            id,
            name: title,
            creative: hero,
            position: +position,
          })),
        },
      },
    };
  }

  return {
    event: 'promotionView',
    ecommerce: {
      promoView: {
        promotions: items.map(({ title, id, hero }, index) => ({
          id,
          name: title,
          creative: hero,
          position: +index + 1,
        })),
      },
    },
  };
};

const getPurchaseAnalyticsObject = (
  meta: IQuotePayload
): IQuotePayloadDataLayer => ({
  ...meta,
  ecommerce: {
    currencyCode: getCurrencyCode(),
    purchase: {
      actionField: meta.ecommerce.actionField,
      products: [],
    },
  },
});

const getMarketingAnalyticsObject = (action: string, label: string) => ({
  eventCategory: 'Downloads',
  eventAction: action,
  eventLabel: label,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const dataLayerPush = (event: string, data: any) => {
  const isAuthenticated = hasValidToken();
  if (!isAuthenticated) return;
  const search = getSearchString();
  window.dataLayer.push({
    event,
    ...data,
    search,
    isAuthenticated,
  });
};

const UTMParameters = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
];

const setPersistentUTMQuery = (query: string) => {
  if (query.length > 0 && query.includes('&')) {
    const test = query
      .replace('?', '')
      .split('&')
      .map((utm) => UTMParameters.includes(utm.split('=')[0]) && utm)
      .join('&');

    window.sessionStorage.setItem('utmParameters', test);
  }
};

export {
  page,
  track,
  getAgentId,
  getUserEmail,
  getAgentName,
  getJwtPayload,
  dataLayerPush,
  getJwtPayloadCore,
  getAnalyticsPromotion,
  setPersistentUTMQuery,
  getHasSeenWelcomeModal,
  setGoogleAnalyticsObject,
  getPurchaseAnalyticsObject,
  getAnalyticsProductDetails,
  getMarketingAnalyticsObject,
  setGoogleAnalyticsExtraObject,
  getAnalyticsProductImpression,
};
