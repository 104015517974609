import { useState } from 'react';

import { ButtonText, LabelText } from 'components/UI/Brand/text';
import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import { getCompanyColor } from 'utils/getCompanyColor';
import { PrimaryButton } from '../Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

export interface IDropdownOption {
  title: string;
}

interface IDropDown {
  options: IDropdownOption[];
  placeholder: string;
  onClick: (item: IDropdownOption) => void;
}

const MarketingDropdown = ({ options, placeholder, onClick }: IDropDown) => {
  const [isOpen, setIsOpen] = useState(false);
  const [hoverItem, setHoverItem] = useState(null);

  const onItemClick = (item: IDropdownOption) => {
    onClick(item);
    setIsOpen(false);
  };
  return (
    <div className={styles.dropdown}>
      <PrimaryButton
        onClick={() => setIsOpen(!isOpen)}
        size="small"
        style={{ width: '100%', boxSizing: 'border-box' }}
      >
        <div className={styles.buttonLabel}>
          <ButtonText marginRight="one">{placeholder}</ButtonText>
          <Icon iconType="arrowDown" />
        </div>
      </PrimaryButton>
      {isOpen && (
        <div
          className={styles.dropdownItems}
          onBlur={() => setTimeout(() => setIsOpen(false), 2000)}
        >
          {options.map((item, index) => (
            <button
              key={item.title}
              onClick={() => onItemClick(item)}
              className={styles.dropdownItem}
              onMouseEnter={() => setHoverItem(index)}
              onMouseLeave={() => setHoverItem(null)}
            >
              <LabelText className={styles.dropdownItemLabel}>
                {item.title}
              </LabelText>
              <Icon
                iconType="download"
                color={
                  hoverItem === index ? colors.shared.white : getCompanyColor()
                }
                width={16}
                height={16}
                className={styles.dropdownItemIcon}
              />
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default MarketingDropdown;
