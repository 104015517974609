import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  changeExtraItems,
  concatenateSelectedExtraItems,
} from 'containers/Book/BookingProgress/BookingFunnel/Extras/utils/extras.util';
import { IExtraData, IExtrasList, IExtrasState } from 'interfaces/IExtras';
import {
  IAddOnSelection,
  IExcursionSelection,
  IMealSelection,
} from 'interfaces/IQuote';
import { getExtras } from 'store/services/extras';
import { quoteExtras } from 'store/services/quote';

const initialState: IExtrasState = {
  currentQuoteData: null,
  error: false,
  extrasData: {
    quoteId: '',
    voyageId: '',
    excursions: [],
    meals: [],
    addons: [],
  },
  extrasList: {
    excursions: [],
    meals: [],
    addons: [],
  },
  loading: false,
  quoteExtrasSuccess: false,
};

const extrasSlice = createSlice({
  name: 'extras',
  initialState,
  reducers: {
    addExtra: (
      state,
      action: PayloadAction<{
        extrasQuoteData: IExtraData;
        extraType: string;
      }>
    ) => {
      const { extraType, extrasQuoteData } = action.payload;
      state.extrasData = {
        ...state.extrasData,
        [extraType]: concatenateSelectedExtraItems(
          extraType,
          state.extrasData,
          extrasQuoteData
        ),
      };
    },
    removeAllExtras: (state) => {
      state.extrasData = {
        ...state.extrasData,
        excursions: [],
        meals: [],
        addons: [],
      };
    },
    removeExtra: (state, action: PayloadAction<{ id: string }>) => {
      const { id } = action.payload;
      let excursions: IExcursionSelection[] = [];
      let meals: IMealSelection[] = [];
      let addons: IAddOnSelection[] = [];
      if (state.extrasData.excursions) {
        excursions = state.extrasData.excursions.filter(
          (excursion) => excursion.excursionId !== id
        );
      }
      if (state.extrasData.meals) {
        meals = state.extrasData.meals.filter(({ mealId }) => mealId !== id);
      }
      if (state.extrasData.addons) {
        addons = state.extrasData.addons.filter(
          ({ addOnId }) => addOnId !== id
        );
      }
      state.extrasData = {
        ...state.extrasData,
        excursions,
        meals,
        addons,
      };
    },
    editExtra: (
      state,
      action: PayloadAction<{
        extraType: string;
        extrasQuoteData: IExcursionSelection | IMealSelection | IAddOnSelection;
      }>
    ) => {
      const { extraType, extrasQuoteData } = action.payload;
      state.extrasData = {
        ...state.extrasData,
        [extraType]: changeExtraItems(
          extraType,
          state.extrasData,
          extrasQuoteData
        ),
      };
    },
    setVoyageId: (
      state,
      action: PayloadAction<{ quoteId: string; voyageId: string }>
    ) => {
      state.extrasData = {
        ...state.extrasData,
        quoteId: action.payload.quoteId,
        voyageId: action.payload.voyageId,
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(quoteExtras.matchFulfilled, (state, action) => {
        state.currentQuoteData = action.payload;
        state.quoteExtrasSuccess = true;
      })
      .addMatcher(getExtras.matchFulfilled, (state, action) => {
        state.extrasList = action.payload as IExtrasList;
      });
  },
});

export const {
  addExtra,
  removeAllExtras,
  editExtra,
  removeExtra,
  setVoyageId,
} = extrasSlice.actions;
export default extrasSlice.reducer;
