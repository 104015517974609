import { SVGAttributes } from 'react';

const CircleWithCrossSmallIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.5 2C4.91015 2 2 4.91015 2 8.5C2 12.0899 4.91015 15 8.5 15C12.0899 15 15 12.0899 15 8.5C15 4.91015 12.0899 2 8.5 2ZM1 8.5C1 4.35786 4.35786 1 8.5 1C12.6421 1 16 4.35786 16 8.5C16 12.6421 12.6421 16 8.5 16C4.35786 16 1 12.6421 1 8.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.8536 5.14645C12.0488 5.34171 12.0488 5.65829 11.8536 5.85355L5.85355 11.8536C5.65829 12.0488 5.34171 12.0488 5.14645 11.8536C4.95118 11.6583 4.95118 11.3417 5.14645 11.1464L11.1464 5.14645C11.3417 4.95118 11.6583 4.95118 11.8536 5.14645Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.14645 5.14645C5.34171 4.95118 5.65829 4.95118 5.85355 5.14645L11.8536 11.1464C12.0488 11.3417 12.0488 11.6583 11.8536 11.8536C11.6583 12.0488 11.3417 12.0488 11.1464 11.8536L5.14645 5.85355C4.95118 5.65829 4.95118 5.34171 5.14645 5.14645Z"
    />
  </svg>
);

export default CircleWithCrossSmallIcon;
