import { ICabin } from 'interfaces/ICabin';
import { getTotalPasssengersInCabin } from 'utils/getTotalPeopleInCabin';

export const getPassengersAmountPerSearchCabin = (
  cabinSearchParams: ICabin[],
  isInfantsIncluded = true
): number[] => {
  const passengersAmountPerSearchCabin: number[] = [];
  if (cabinSearchParams)
    cabinSearchParams.forEach((cabin) => {
      const { totalPassengers: passengersAmount } = getTotalPasssengersInCabin(
        [cabin],
        isInfantsIncluded
      );
      passengersAmountPerSearchCabin.push(passengersAmount);
    });

  return passengersAmountPerSearchCabin;
};
