import { colors } from 'style/colors';
import translate from 'translate';
import { getSupportEmailSync } from './supportInfo';

const getErrorMessage = (errorCode: string) => {
  const supportEmail = getSupportEmailSync();
  const defaultMessage = translate('Signup_InvalidEmailOrPassword');
  switch (errorCode) {
    case '401A':
      return translate('Signup_AccountProblem', supportEmail);
    case '401B':
      return (
        <div
          style={{
            fontFamily: 'Atlas Grotesk Regular',
            fontSize: 12,
            lineHeight: 1.33,
            color: colors.shared.red,
          }}
        >
          {translate('Signup_AccountReview')}
          &nbsp;
          <a
            href={`mailto:${supportEmail}`}
            style={{
              fontWeight: 'bold',
              color: colors.hrn.clearBlue1,
            }}
          >
            {translate('Signup_AccountReviewContactUs')}
          </a>
        </div>
      );
    case '401C':
    case '401SS':
    case '401L':
    case '401M':
      return errorCode;
    case '403':
      return translate('Signup_InvalidEmailOrPassword');
    case '500':
      return translate('Signup_SomethingWrong');
    default:
      return defaultMessage;
  }
};

export default getErrorMessage;
