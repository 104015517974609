import { SVGProps } from 'react';

const DownloadIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={16}
    height={15}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 16 15"
    {...props}
  >
    <rect y={14} width={16} height={1} rx={0.5} fill="#E21710" />
    <path
      d="m12 8-4 4-4-4M8 12V1"
      stroke="#E21710"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default DownloadIcon;
