import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';

export { PhoneNumberFormat };

/**
 * @throws If unable to convert
 */
export const formatPhoneNumber = (
  phoneNumber: string,
  phoneNumberFormat: PhoneNumberFormat = PhoneNumberFormat.INTERNATIONAL
): string => {
  const phoneUtil = PhoneNumberUtil.getInstance();
  const phoneNumberObject = phoneUtil.parse(phoneNumber);
  return phoneUtil.format(phoneNumberObject, phoneNumberFormat);
};
