import { SVGAttributes } from 'react';

const FilledCircleWithPlus = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#F6F6F6" />
    <path
      d="M12.484 12.534H15.144V11.456H12.484V8.782H11.35V11.456H8.704V12.534H11.35V15.222H12.484V12.534Z"
      fill="black"
    />
  </svg>
);

export default FilledCircleWithPlus;
