import {
  IIncludedVoyage,
  IPromotionPayload,
  IPromotionState,
  IPromotion,
} from 'interfaces/IPromotions';
import { handleActions, ReduxCompatibleReducer } from 'redux-actions';
import {
  fetchPromotionFailed,
  fetchPromotionStart,
  fetchPromotionSuccess,
  fetchPromotionVoyagesSuccess,
  fetchPromotionVoyagesFailed,
  fetchPromotionVoyagesStart,
  fetchPromotionDestinationsSuccess,
  fetchPromotionDestinationsFailed,
  fetchPromotionDestinationsStart,
  resetPromotions,
} from '../actions/promotion';

const initialState: IPromotionState = {
  promotions: [],
  promotionCodesForSearch: [],
  error: false,
  loading: false,
  includedSailingsLoading: false,
  totalPromotions: 0,
};

const reducer: ReduxCompatibleReducer<IPromotionState, IPromotionPayload> =
  handleActions<IPromotionState, IPromotionPayload>(
    {
      [fetchPromotionStart.toString()]: (state) => ({
        ...state,
        loading: true,
      }),
      [fetchPromotionSuccess.toString()]: (state, action) => {
        const { promotions, total } = action.payload.promotionsCollection;
        const { shouldCombinePreviouslyFetchedWithRecentlyFetchedPromotions } =
          action.payload;

        const combinedPromotionMap = new Map<string, IPromotion>(
          state.promotions
        );
        promotions.forEach((promotion) =>
          combinedPromotionMap.set(promotion.urlName.trim(), promotion)
        );

        const promotionMap = new Map<string, IPromotion>();
        promotions.forEach((promotion) =>
          promotionMap.set(promotion.urlName.trim(), promotion)
        );

        return {
          ...state,
          promotions:
            !shouldCombinePreviouslyFetchedWithRecentlyFetchedPromotions
              ? Array.from(combinedPromotionMap.entries())
              : Array.from(promotionMap.entries()),
          error: false,
          loading: false,
          totalPromotions: total || state.totalPromotions,
        };
      },
      [fetchPromotionFailed.toString()]: (state) => ({
        ...state,
        loading: false,
        error: true,
      }),
      [fetchPromotionVoyagesStart.toString()]: (state) => ({
        ...state,
        includedSailingsLoading: true,
      }),
      [fetchPromotionVoyagesSuccess.toString()]: (state, action) => {
        const {
          includedSailings: {
            urlName,
            voyages,
            offset,
            total,
            limit,
            hasFilter,
          },
        } = action.payload;

        const promotionsMap = new Map<string, IPromotion>(state.promotions);
        const promotion = promotionsMap.get(urlName);

        const promotionVoyages: IIncludedVoyage[] = [];
        promotion.includedSailings.voyages.forEach(
          (voyage: IIncludedVoyage) => {
            promotionVoyages.push(voyage);
          }
        );
        voyages.forEach((voyage: IIncludedVoyage) => {
          promotionVoyages.push(voyage);
        });

        const updatedPromotion = {
          ...promotion,
          includedSailings: {
            ...promotion.includedSailings,
            voyages: Array.from(promotionVoyages.values()),
            offset: hasFilter ? promotion.includedSailings.offset : offset,
            total,
            limit,
          },
        };

        promotionsMap.set(urlName, updatedPromotion);
        return {
          ...state,
          promotions: Array.from(promotionsMap.entries()),
          includedSailingsLoading: false,
          error: false,
          loading: false,
        };
      },
      [fetchPromotionVoyagesFailed.toString()]: (state) => ({
        ...state,
        loading: false,
        error: true,
      }),
      [fetchPromotionDestinationsSuccess.toString()]: (state, action) => {
        const { destinations, promotionId } = action.payload;
        const { promotions } = state;

        const promotionsMap = new Map<string, IPromotion>(promotions);
        const listOfPromotions = Array.from(promotionsMap.values());

        const selectedPromotion = listOfPromotions.find(
          (promotion) => promotion.id === promotionId
        );

        promotionsMap.set(selectedPromotion.urlName, {
          ...selectedPromotion,
          destinations,
        });

        return {
          ...state,
          promotions: Array.from(promotionsMap.entries()),
          loading: false,
          error: false,
        };
      },
      [fetchPromotionDestinationsFailed.toString()]: (state) => ({
        ...state,
        error: true,
      }),
      [fetchPromotionDestinationsStart.toString()]: (state) => ({
        ...state,
      }),
      [resetPromotions.toString()]: () => ({
        ...initialState,
      }),
    },
    initialState
  );

export default reducer;
