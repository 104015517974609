import { IFlags } from 'interfaces/IFeature';
import { api } from './api';

export const featuresApi = api.injectEndpoints({
  endpoints: (build) => ({
    getFeatureFlags: build.query<IFlags, void>({
      query: () => ({
        url: `Features`,
      }),
    }),
  }),
});

export const { useGetFeatureFlagsQuery } = featuresApi;

export const {
  endpoints: { getFeatureFlags },
} = featuresApi;
