import { SVGProps } from 'react';

const AccessibilityIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path
      d="M9.837 12.201a.596.596 0 0 1 .639.541.588.588 0 0 1-.568.616c-2.25.128-3.999 1.836-3.999 3.888 0 2.145 1.905 3.895 4.268 3.895 1.75 0 3.314-.973 3.962-2.443a.614.614 0 0 1 .775-.315.572.572 0 0 1 .338.767c-.839 1.903-2.843 3.15-5.075 3.15-3.02 0-5.477-2.257-5.477-5.054 0-2.604 2.138-4.762 4.905-5.027l.232-.018Zm2.46-2.851c.293 0 .541.21.588.49l.008.095-.001 2.264 3.849.001c.261 0 .486.166.565.4l.023.09.008.095a.588.588 0 0 1-.5.577l-.096.008-3.849-.001v2.266a.56.56 0 0 0 .402.526l.091.022.096.007h4.054c.43 0 .824.225 1.034.584l.057.112 1.624 3.645c.134.3-.01.648-.315.773a.603.603 0 0 1-.732-.221l-.046-.084-1.622-3.64-4.054.001c-.88 0-1.615-.62-1.756-1.437l-.02-.146-.005-.142v-5.7a.59.59 0 0 1 .596-.585ZM12.25 2.7c1.543 0 2.8 1.194 2.8 2.675 0 1.48-1.257 2.675-2.8 2.675s-2.8-1.194-2.8-2.675c0-1.48 1.257-2.675 2.8-2.675Zm0 1.075c-.942 0-1.7.72-1.7 1.6 0 .88.758 1.6 1.7 1.6s1.7-.72 1.7-1.6c0-.88-.758-1.6-1.7-1.6Z"
      fillRule="evenodd"
    />
  </svg>
);

export default AccessibilityIcon;
