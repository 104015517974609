import React, { ErrorInfo } from 'react';
import { ErrorBanner } from 'components/ErrorBanner/ErrorBanner';

interface IErrorBoundaryState {
  hasError: boolean;
}

interface IErrorBoundaryProps {
  children: React.ReactNode;
  setIsAppError: (value: boolean) => void;
}

class ErrorBoundary extends React.Component<
  IErrorBoundaryProps,
  IErrorBoundaryState
> {
  constructor(props: IErrorBoundaryProps) {
    super(props);
    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    this.props.setIsAppError(true);
    if (errorInfo && errorInfo.componentStack) {
      // The component stack is sometimes useful in development mode
      // In production it can be somewhat obfuscated, so feel free to omit this line.
      console.warn(errorInfo.componentStack);
    }
  }

  render() {
    if (this.state.hasError) {
      return <ErrorBanner />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
