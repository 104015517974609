import { handleActions } from 'redux-actions';
import { IModalsState } from 'interfaces/IModals';
import { openAddExtraModal, closeAddExtraModal } from '../actions/modals';

const initialState: IModalsState = {
  isAddExtraModalOpen: false,
  addExtraModalData: null,
};

const reducer = handleActions(
  {
    [openAddExtraModal.toString()]: (state, action) => ({
      ...state,
      addExtraModalData: action.payload.addExtraModalData,
      isAddExtraModalOpen: true,
    }),
    [closeAddExtraModal.toString()]: (state) => ({
      ...state,
      isAddExtraModalOpen: false,
      addExtraModalData: null,
    }),
  },
  initialState
);

export default reducer;
