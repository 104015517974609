import {
  IExtraItemsData,
  IExtrasItemSummary,
  IExtrasList,
  IExtrasSectionData,
  IExtrasType,
  ISelectedExtrasItems,
} from 'interfaces/IExtras';
import {
  IAddOnSelection,
  IExcursionSelection,
  IMealSelection,
  IQuoteExtras,
} from 'interfaces/IQuote';
import translate from 'translate';
import { getLanguage } from 'utils/getLanguage';

const excludedPetLocales = ['de-at', 'de-ch', 'de-de'];

export const createExtrasSectionData = (
  extrasList: IExtrasList,
  isP2P: boolean
): IExtrasSectionData[] => {
  let result: IExtrasSectionData[] = [];

  let meals: IExtrasItemSummary[] = [];
  let excursions: IExtrasItemSummary[] = [];
  let addons: IExtrasItemSummary[] = [];
  const locale = getLanguage();
  const isPetForbidden = excludedPetLocales.includes(locale?.toLowerCase());

  if (!extrasList) return result;

  if (extrasList.meals?.length > 0)
    meals = extrasList.meals.filter(({ title }) => !!title);

  if (extrasList.excursions?.length > 0) {
    excursions = [...extrasList.excursions];
  }

  if (extrasList.addons?.length > 0) {
    addons = extrasList.addons.filter(({ title }) => !!title);

    if (isPetForbidden && !isP2P) {
      addons = addons.filter((addon) => addon.bookingCode !== 'PET');
    }
  }

  result = [
    {
      ariaLabelledby: 'list-of-meals-extras',
      extras: meals,
      title: translate('Extras_FoodTitle'),
      extraType: IExtrasType.Meals,
      caption: translate('Extras_FoodTitle'),
    },
    {
      ariaLabelledby: 'list-of-excursions-extras',
      extras: excursions,
      title: translate('Extras_Excursions'),
      extraType: IExtrasType.Excursion,
      caption: translate('Extras_Excursions'),
    },
    {
      ariaLabelledby: 'list-of-other-extras',
      extras: addons,
      title: translate('Extras_AddonTitle'),
      extraType: IExtrasType.Addons,
      caption: translate('Extras_Addons_caption'),
    },
  ];

  return result;
};

export const createExtrasItemData = (
  extraItem: IExtrasItemSummary,
  extraType: string,
  caption: string
): IExtraItemsData => {
  let extraItemsData: IExtraItemsData = null;

  if (!extraItem) return extraItemsData;

  const images = extraItem.media?.map(
    ({ highResolutionUri, alternateText }) => ({
      imageUrl: highResolutionUri,
      alternateText,
    })
  );

  extraItemsData = {
    extra: extraItem,
    extraType,
    images,
    caption,
  };

  if (extraType === IExtrasType.Excursion) {
    extraItemsData = {
      ...extraItemsData,
      duration: extraItem?.duration ?? '',
    };
  }

  return extraItemsData;
};

export const isExtraAdded = (extrasData: IQuoteExtras, id: string): boolean => {
  if (!extrasData) return false;

  const extraIds = [
    ...(extrasData.excursions || []).map(({ excursionId }) => excursionId),
    ...(extrasData.meals || []).map(({ mealId }) => mealId),
    ...(extrasData.addons || []).map(({ addOnId }) => addOnId),
  ];

  return extraIds.includes(id);
};

export const getAmountOfBookedTravelers = (
  extrasData: IQuoteExtras,
  id: string
): number => {
  if (!extrasData) return 0;

  const selectedExtras =
    extrasData.excursions.find((excursion) => excursion.excursionId === id) ||
    extrasData.meals.find((meal) => meal.mealId === id) ||
    extrasData.addons.find((addOn) => addOn.addOnId === id);

  if (!selectedExtras) return 0;

  return selectedExtras.passengers.length;
};

export const getFreeExtrasLabel = (extraType: string): string => {
  let label = '';

  switch (extraType) {
    case IExtrasType.Excursion:
      label = translate('Extras_FreeExcursion');
      break;

    case IExtrasType.Meals:
      label = translate('Extras_FreeMeal');
      break;

    case IExtrasType.Addons:
      label = translate('Extras_FreeAddOn');
      break;

    default:
      break;
  }

  return label;
};

export const getEmptyExtraMessage = (extraType: string): string => {
  let message = '';

  if (!extraType) return message;

  switch (extraType) {
    case IExtrasType.Excursion:
      message = translate('Extras_NotAvailable_Excursion');
      break;

    case IExtrasType.Meals:
      message = translate('Extras_NotAvailable_Food');
      break;

    case IExtrasType.Addons:
      message = translate('Extras_NotAvailable_AddOn');
      break;

    default:
      break;
  }

  return message;
};

export const concatenateSelectedExtraItems = (
  extraType: string,
  prevSelectedExtraItems: IQuoteExtras,
  selectedItem: IExcursionSelection | IMealSelection | IAddOnSelection
): ISelectedExtrasItems => {
  let selectedExtraItems: ISelectedExtrasItems = [];

  if (!extraType || !prevSelectedExtraItems || !selectedItem)
    return selectedExtraItems;

  switch (extraType) {
    case IExtrasType.Excursion:
      selectedExtraItems =
        prevSelectedExtraItems.excursions.concat(selectedItem);
      break;
    case IExtrasType.Meals:
      selectedExtraItems = prevSelectedExtraItems.meals.concat(selectedItem);
      break;
    case IExtrasType.Addons:
      selectedExtraItems = prevSelectedExtraItems.addons.concat(selectedItem);
      break;

    default:
      break;
  }

  return selectedExtraItems;
};

export const changeExtraItems = (
  extraType: string,
  prevSelectedExtraItems: IQuoteExtras,
  selectedItem: IExcursionSelection | IMealSelection | IAddOnSelection
): ISelectedExtrasItems => {
  let selectedExtraItems: ISelectedExtrasItems = [];

  switch (extraType) {
    case IExtrasType.Excursion:
      if (selectedItem.passengers.length) {
        const foundIndex = prevSelectedExtraItems.excursions.findIndex(
          (element) =>
            element.excursionId ===
            (selectedItem as IExcursionSelection).excursionId
        );
        selectedExtraItems = [
          ...prevSelectedExtraItems.excursions.slice(0, foundIndex),
          {
            ...prevSelectedExtraItems.excursions[foundIndex],
            passengers: selectedItem.passengers,
          },
          ...prevSelectedExtraItems.excursions.slice(foundIndex + 1),
        ];
      } else {
        selectedExtraItems = prevSelectedExtraItems.excursions.filter(
          (excursion) =>
            excursion.excursionId !==
            (selectedItem as IExcursionSelection).excursionId
        );
      }
      break;
    case IExtrasType.Meals:
      if (selectedItem.passengers.length) {
        const foundIndex = prevSelectedExtraItems.meals.findIndex(
          (element) =>
            element.mealId === (selectedItem as IMealSelection).mealId
        );
        selectedExtraItems = [
          ...prevSelectedExtraItems.meals.slice(0, foundIndex),
          {
            ...prevSelectedExtraItems.meals[foundIndex],
            passengers: selectedItem.passengers,
          },
          ...prevSelectedExtraItems.meals.slice(foundIndex + 1),
        ];
      } else {
        selectedExtraItems = prevSelectedExtraItems.meals.filter(
          (meal) => meal.mealId !== (selectedItem as IMealSelection).mealId
        );
      }
      break;
    case IExtrasType.Addons:
      if (selectedItem.passengers.length) {
        const foundIndex = prevSelectedExtraItems.addons.findIndex(
          (element) =>
            element.addOnId === (selectedItem as IAddOnSelection).addOnId
        );
        selectedExtraItems = [
          ...prevSelectedExtraItems.addons.slice(0, foundIndex),
          {
            ...prevSelectedExtraItems.addons[foundIndex],
            passengers: selectedItem.passengers,
          },
          ...prevSelectedExtraItems.addons.slice(foundIndex + 1),
        ];
      } else {
        selectedExtraItems = prevSelectedExtraItems.addons.filter(
          (addon) => addon.addOnId !== (selectedItem as IAddOnSelection).addOnId
        );
      }
      break;

    default:
      break;
  }

  return selectedExtraItems;
};
