import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { ITabItem } from 'interfaces/ITab';
import TabHeader from './TabHeader';
import styles from './styles.module.scss';

interface ITabProps {
  tabItems: ITabItem[];
  desktopContainer?: boolean;
  destinationName?: string;
  separatorLine?: boolean;
  activeTab?: number;
  hideHeader?: boolean;
  handlerMethod?: (index: number) => void;
}
const Tab = (props: ITabProps) => {
  const {
    tabItems,
    desktopContainer = false,
    destinationName,
    separatorLine = true,
    activeTab = 0,
    hideHeader = false,
    handlerMethod = () => {},
  } = props;
  const [activeTabIdx, setActiveTabIdx] = useState(activeTab);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setActiveTabIdx(activeTab);
  }, [activeTab]);

  useEffect(() => {
    const index = tabItems.findIndex((item) => item.path === location.pathname);
    if (index >= 0) {
      setActiveTabIdx(index);
    }
  }, [location]);

  const headers = tabItems.map((ti, idx) => ({
    name: ti.title || ti.name,
    id: idx,
  }));

  const handlerMethodWrapper = (idx: number) => {
    const newPath = tabItems[idx].path;
    if (newPath) navigate(newPath);
    handlerMethod(idx);
  };

  const tabContent = tabItems[activeTabIdx]
    ? tabItems[activeTabIdx].content
    : null;

  return (
    <div className={styles.tabContainer}>
      {!hideHeader && (
        <TabHeader
          headerItems={headers}
          setActiveTabIdx={(idx) => {
            setActiveTabIdx(idx);
            handlerMethodWrapper(idx);
          }}
          activeTabIdx={activeTabIdx}
          desktopContainer={desktopContainer}
          destinationName={destinationName}
        />
      )}
      {separatorLine && <hr className={styles.separatorLine} />}
      {tabContent}
    </div>
  );
};

export default Tab;
