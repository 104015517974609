import { ITokenData } from 'interfaces/IAuth';
import localStorageKeys from './localStorageKeys';

export const setItemsToLocalStorage = (data: ITokenData) => {
  localStorage.setItem(localStorageKeys.idToken, data.id_token);

  if (data?.languages?.length > 0) {
    localStorage.setItem(localStorageKeys.currentLanguage, data.languages[0]);

    const languages = data.languages.join(';');
    localStorage.setItem(localStorageKeys.languageOptions, languages);
  }

  if (data?.currencies?.length > 0) {
    localStorage.setItem(
      localStorageKeys.currency,
      data.currencies.find(({ origin }) => origin === 'account').value
    );
  }

  if (data?.dateNotations?.length > 0) {
    localStorage.setItem(
      localStorageKeys.fullDateNotation,
      data.dateNotations[0]
    );

    if (data.dateNotations.length > 1) {
      localStorage.setItem(
        localStorageKeys.monthAndYearDateNotation,
        data.dateNotations[1]
      );
    }

    if (data.dateNotations.length > 2) {
      localStorage.setItem(
        localStorageKeys.fullHoursDateNotation,
        data.dateNotations[2]
      );
    }
  }

  if (data?.isMetricSystem !== undefined) {
    localStorage.setItem(localStorageKeys.isMetric, `${data.isMetricSystem}`);
  }

  if (data?.permissions) {
    localStorage.setItem(localStorageKeys.permissions, `${data.permissions}`);
  }

  if (data?.seenNews !== null && data?.seenNews !== undefined) {
    localStorage.setItem(localStorageKeys.seenNews, `${data.seenNews}`);
  }
};
