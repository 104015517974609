import { SVGProps } from 'react';

const MenuIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={13}
    viewBox="0 0 19 13"
    {...props}
  >
    <path
      fill="#000"
      d="M17.6 12c.276 0 .5.22.5.5s-.224.5-.5.5H6.2c-.276 0-.5-.22-.5-.5s.224-.5.5-.5h11.4ZM2.4 12c.276 0 .5.22.5.5s-.224.5-.5.5H.5c-.276 0-.5-.22-.5-.5s.224-.5.5-.5h1.9Zm15.2-6c.276 0 .5.22.5.5s-.224.5-.5.5H6.2c-.276 0-.5-.22-.5-.5s.224-.5.5-.5h11.4ZM2.4 6c.276 0 .5.22.5.5s-.224.5-.5.5H.5C.224 7 0 6.78 0 6.5S.224 6 .5 6h1.9Zm15.2-6c.276 0 .5.22.5.5s-.224.5-.5.5H6.2c-.276 0-.5-.22-.5-.5s.224-.5.5-.5h11.4ZM2.4 0c.276 0 .5.22.5.5s-.224.5-.5.5H.5C.224 1 0 .78 0 .5S.224 0 .5 0h1.9Z"
      fillRule="evenodd"
    />
  </svg>
);

export default MenuIcon;
