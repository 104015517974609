import { SVGProps } from 'react';

const SearchAlertIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    viewBox="0 0 19 19"
    {...props}
  >
    <path
      fill="#000"
      d="M7.045 0a7.044 7.044 0 0 1 7.046 7.05 7.01 7.01 0 0 1-1.722 4.612l6.485 6.488c.195.19.195.51 0 .7-.196.2-.512.2-.707 0l-6.486-6.48a7.023 7.023 0 0 1-4.616 1.72A7.041 7.041 0 0 1 0 7.05C0 3.15 3.154 0 7.045 0Zm0 1A6.049 6.049 0 0 0 1 7.05a6.047 6.047 0 0 0 12.091 0c0-3.34-2.707-6.05-6.046-6.05Zm.429 8.187c.12.128.193.304.193.474a.717.717 0 0 1-.193.48.714.714 0 0 1-.474.192.714.714 0 0 1-.474-.192.715.715 0 0 1-.193-.48c0-.17.073-.346.193-.474a.69.69 0 0 1 .948 0ZM7 3.667c.264 0 .485.22.526.509l.007.099v3.05c0 .336-.24.608-.533.608-.264 0-.485-.22-.526-.509l-.007-.099v-3.05c0-.336.24-.608.533-.608Z"
      fillRule="evenodd"
    />
  </svg>
);

export default SearchAlertIcon;
