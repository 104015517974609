import { SVGProps } from 'react';

const CrossIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3536 0.646447C15.5488 0.841709 15.5488 1.15829 15.3536 1.35355L1.35355 15.3536C1.15829 15.5488 0.841709 15.5488 0.646447 15.3536C0.451184 15.1583 0.451184 14.8417 0.646447 14.6464L14.6464 0.646447C14.8417 0.451184 15.1583 0.451184 15.3536 0.646447Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.646447 0.646447C0.841709 0.451184 1.15829 0.451184 1.35355 0.646447L15.3536 14.6464C15.5488 14.8417 15.5488 15.1583 15.3536 15.3536C15.1583 15.5488 14.8417 15.5488 14.6464 15.3536L0.646447 1.35355C0.451184 1.15829 0.451184 0.841709 0.646447 0.646447Z"
    />
  </svg>
);

export default CrossIcon;
