import { SVGProps } from 'react';

const BrochureIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={20}
    viewBox="0 0 16 20"
    fill="currentColor"
    {...props}
  >
    <path
      d="M13.161.115c.125.104.198.25.198.408v2.059h2.12a.53.53 0 0 1 .521.523v16.372a.53.53 0 0 1-.522.523H.522A.53.53 0 0 1 0 19.477V3.105c0-.127.049-.245.128-.337a.502.502 0 0 1 .29-.175L12.732.01a.524.524 0 0 1 .428.105Zm1.795 3.513H1.044v15.327h13.912V3.628ZM4.04 11.197c.292 0 .522.23.522.523v4.307c0 .293-.23.523-.522.523a.524.524 0 0 1-.522-.523V11.72c0-.293.24-.523.522-.523Zm2.64-2.582c.293 0 .523.23.523.522v6.89c0 .293-.23.523-.522.523a.524.524 0 0 1-.522-.523v-6.89c0-.292.24-.522.522-.522Zm2.641.857c.293 0 .522.24.522.523v6.032c0 .293-.23.523-.522.523a.517.517 0 0 1-.522-.523V9.995c0-.283.23-.523.522-.523Zm2.64-3.44c.283 0 .523.23.523.523v9.472c0 .293-.24.523-.522.523a.517.517 0 0 1-.522-.523V6.555c0-.293.23-.523.522-.523Zm.356-4.861L5.563 2.582h6.753V1.171Z"
      fillRule="evenodd"
    />
  </svg>
);

export default BrochureIcon;
