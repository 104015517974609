import { useEffect, useState } from 'react';
import TabSecondaryHeader from './TabSecondaryHeader';
import { ITabItem } from '../../../../interfaces/ITab';
import styles from './styles.module.scss';

interface ITabProps {
  tabItems: ITabItem[];
  desktopContainer?: boolean;
  destinationName?: string;
  activeTab?: number;
  hideHeader?: boolean;
  handleActiveTabIndex?: (index: number, key?: number) => void;
  className?: string;
}

const TabSecondary = (props: ITabProps) => {
  const {
    tabItems,
    desktopContainer = false,
    destinationName,
    activeTab = 0,
    hideHeader = false,
    handleActiveTabIndex,
    className,
  } = props;
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    const tabIndex = tabItems.findIndex((tab) => tab.key === activeTab);
    setActiveTabIndex(tabIndex === -1 ? activeTab : tabIndex);
  }, [activeTab]);

  const headers = tabItems.map((ti, index) => ({
    name: ti.title || ti.name,
    id: index,
  }));

  const tabContent = tabItems[activeTabIndex]
    ? tabItems[activeTabIndex].content
    : null;

  return (
    <div className={styles.tabSecondaryContainer}>
      {!hideHeader && (
        <TabSecondaryHeader
          headerItems={headers}
          setActiveTabIndex={(index) => {
            setActiveTabIndex(index);
            if (handleActiveTabIndex)
              handleActiveTabIndex(index, tabItems[index].key);
          }}
          activeTabIndex={activeTabIndex}
          desktopContainer={desktopContainer}
          destinationName={destinationName}
          className={className}
        />
      )}
      {tabContent}
    </div>
  );
};

export default TabSecondary;
