import { SVGProps } from 'react';

const SofaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <g fillRule="evenodd">
      <path d="M4.7 11.11c1.1-.19 2.79-.39 5.09-.39 2.29 0 3.99.2 5.09.39a.503.503 0 1 1-.18.99c-1.04-.18-2.68-.38-4.91-.38-2.24 0-3.88.2-4.91.38a.503.503 0 0 1-.18-.99zM5.5 1c-.91 0-1.64.67-1.64 1.65v5c0 .27-.22.5-.5.5-.27 0-.5-.23-.5-.5v-5C2.86 1.09 4.06 0 5.5 0h8.58c1.44 0 2.64 1.09 2.64 2.65v5c0 .27-.23.5-.5.5-.28 0-.5-.23-.5-.5v-5c0-.98-.73-1.65-1.64-1.65H5.5z" />
      <path d="M2.65 8.15C1.74 8.15 1 8.89 1 9.79v6.64h3.29V9.79c0-.9-.74-1.64-1.64-1.64zM0 9.79c0-1.45 1.19-2.64 2.65-2.64a2.65 2.65 0 0 1 2.64 2.64v7.14c0 .28-.22.5-.5.5H.5c-.27 0-.5-.22-.5-.5V9.79zm16.93-1.64c-.9 0-1.64.74-1.64 1.64v6.64h3.29V9.79c0-.9-.74-1.64-1.65-1.64zm-2.64 1.64a2.65 2.65 0 0 1 2.64-2.64c1.46 0 2.65 1.19 2.65 2.64v7.14c0 .28-.23.5-.5.5h-4.29c-.28 0-.5-.22-.5-.5V9.79z" />
      <path d="M4.29 16.93c0-.27.22-.5.5-.5h10c.28 0 .5.23.5.5 0 .28-.22.5-.5.5h-10c-.28 0-.5-.22-.5-.5zm-1.64-.5c.27 0 .5.23.5.5v2.15c0 .27-.23.5-.5.5-.28 0-.5-.23-.5-.5v-2.15c0-.27.22-.5.5-.5zm14.28 0c.28 0 .5.23.5.5v2.15c0 .27-.22.5-.5.5-.27 0-.5-.23-.5-.5v-2.15c0-.27.23-.5.5-.5z" />
    </g>
  </svg>
);

export default SofaIcon;
