import { hasValidToken } from './getToken';

export const getHomeLink = () => {
  if (!hasValidToken()) {
    return '/';
  }
  return process.env.REACT_APP_COMPANY === 'HRN'
    ? '/book/coastal'
    : '/book/expedition';
};
