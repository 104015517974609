import localStorageKeys from './localStorageKeys';

const getFullDateFormat = () => {
  const dateFormat = localStorage.getItem(localStorageKeys.fullDateNotation);
  const date = dateFormat?.replace('DD', 'dd')?.replace('YYYY', 'yyyy');
  return date || 'MMM dd, yyyy';
};

const getMonthAndYearFormat = () => {
  const dateFormat = localStorage.getItem(
    localStorageKeys.monthAndYearDateNotation
  );
  const date = dateFormat?.replace('YYYY', 'yyyy');
  return date || 'MMM, yyyy';
};

const getDayAndMonthFormat = () => {
  const dateFormat = localStorage.getItem(
    localStorageKeys.dayAndMonthDateNotation
  );
  return dateFormat || 'MMM dd';
};

const getFullHoursDateNotation = () => {
  const dateFormat = localStorage.getItem(
    localStorageKeys.fullHoursDateNotation
  );
  const date = dateFormat?.replace('DD', 'dd')?.replace('YYYY', 'yyyy');
  return date || 'EEEE, MMMM dd, yyyy HH:mm';
};

const getDayMonthYearWeekdayFormat = () => {
  const dateFormat = localStorage.getItem(
    localStorageKeys.dayMonthYearWeekdayNotation
  );
  const date = dateFormat?.replace('DD', 'dd')?.replace('YYYY', 'yyyy');
  return date || 'EEEE dd MMMM, yyyy';
};

const getTimeFormat = () => {
  const dateFormat = localStorage.getItem(localStorageKeys.TimeNotation);
  return dateFormat || 'HH:mm';
};

const getCurrencyFormat = () => {
  const currencyFormat = localStorage.getItem(localStorageKeys.currency);
  return currencyFormat || '$';
};

const getMetricSystem = () => {
  const isMetric = localStorage.getItem(localStorageKeys.isMetric);
  return isMetric === 'true';
};

export {
  getFullDateFormat,
  getMonthAndYearFormat,
  getDayAndMonthFormat,
  getCurrencyFormat,
  getMetricSystem,
  getFullHoursDateNotation,
  getDayMonthYearWeekdayFormat,
  getTimeFormat,
};
