import { useSelector } from 'react-redux';
import { IRootState } from 'store/types/root';
import translate from 'translate';
import clsx from 'clsx';
import OnboardinPopupCarousel from '../../Carousels/OnboardinPopupCarousel';
import OnBoardingPopupShimmerLoading from '../../Loading/OnboardingPopupShimmerLoader';
import styles from './styles.module.scss';

const { REACT_APP_COMPANY } = process.env;

const OnBoardingModal = ({ hideModal }: { hideModal: () => void }) => {
  const { loading } = useSelector((state: IRootState) => state.onboardingPopup);

  return (
    <section
      className={styles.carousel}
      aria-label={translate('Onboarding_HowToPortal')}
      aria-roledescription="carousel"
      aria-live="polite"
    >
      <button
        className={clsx(styles.closeButton, {
          [styles.hx]: REACT_APP_COMPANY === 'HX',
        })}
        disabled={loading}
        onClick={hideModal}
      >
        {translate('General_Skip')}
      </button>
      {loading ? (
        <OnBoardingPopupShimmerLoading />
      ) : (
        <OnboardinPopupCarousel hideModal={hideModal} />
      )}
    </section>
  );
};

export default OnBoardingModal;
