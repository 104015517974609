import { BodyText } from 'components/UI/Brand/text';
import translate from 'translate';
import { IPasswordValidation } from 'interfaces/IAuth';
import styled from 'styled-components';
import { ValidationRow } from './ValidationRow';

const Container = styled.div`
  margin: 16px 0 24px 0;
`;

interface IPasswordValidationProps {
  passwordValidation: IPasswordValidation;
}

const PasswordValidation = ({
  passwordValidation,
}: IPasswordValidationProps) => (
  <Container>
    <BodyText marginBottom="three">
      {translate('SetPasswordForm_ValidationLabel')}
    </BodyText>
    <div>
      <ValidationRow
        isValidationPassed={passwordValidation?.min8Chars}
        text={translate('SetPasswordForm_Validation_MinimumChars')}
      />
      <ValidationRow
        isValidationPassed={passwordValidation?.atLeastOneLowerCase}
        text={translate('SetPasswordForm_Validation_LowerCase')}
      />
      <ValidationRow
        isValidationPassed={passwordValidation?.atLeastOneCapital}
        text={translate('SetPasswordForm_Validation_CapitalLetter')}
      />
      <ValidationRow
        isValidationPassed={passwordValidation?.atLeastOneNumber}
        text={translate('SetPasswordForm_Validation_AtleastOneNumber')}
      />
    </div>
  </Container>
);

export default PasswordValidation;
