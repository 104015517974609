import { SVGProps } from 'react';

const FloaterIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    fill="currentColor"
    {...props}
  >
    <path
      d="M11 0c6.07 0 11 4.93 11 11s-4.93 11-11 11S0 17.07 0 11 4.93 0 11 0ZM7.595 15.136l-.011.011-.205.21a.538.538 0 0 1-.035.04l-1.569 1.564-1.433 1.438A9.918 9.918 0 0 0 11 20.955a9.918 9.918 0 0 0 6.657-2.555l-1.643-1.649-1.358-1.353-.051-.061-.2-.2A5.335 5.335 0 0 1 11 16.358a5.335 5.335 0 0 1-3.405-1.223ZM1.045 11c0 2.559.967 4.894 2.556 6.658l3.241-3.252.014-.011A5.335 5.335 0 0 1 5.64 11c0-1.292.46-2.479 1.223-3.405L5.12 5.851 3.605 4.337A9.918 9.918 0 0 0 1.045 11Zm17.35-6.662-2.997 3.006a.537.537 0 0 1-.08.063l-.186.183A5.335 5.335 0 0 1 16.36 11c0 1.292-.46 2.479-1.223 3.405l.202.2c.02.015.04.032.06.051l1.354 1.359 1.648 1.642A9.918 9.918 0 0 0 20.955 11c0-2.56-.969-4.897-2.56-6.662ZM11 6.686A4.316 4.316 0 0 0 6.686 11 4.316 4.316 0 0 0 11 15.314a4.316 4.316 0 0 0 3.107-7.306.42.42 0 0 1-.067-.058l-.056-.065A4.3 4.3 0 0 0 11 6.685Zm0-5.641c-2.561 0-4.898.969-6.663 2.56L5.95 5.22l1.645 1.645A5.335 5.335 0 0 1 11 5.64c1.29 0 2.474.457 3.399 1.218l3.015-3.015.05-.042.198-.198A9.918 9.918 0 0 0 11 1.044Z"
      fillRule="evenodd"
    />
  </svg>
);

export default FloaterIcon;
