import { SVGProps } from 'react';

const CheckmarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M21.2437 2.57614C21.4778 2.72256 21.5489 3.03106 21.4025 3.26518L10.4297 20.8108C10.3456 20.9452 10.2027 21.0318 10.0446 21.0442C9.88655 21.0565 9.73198 20.9931 9.62807 20.8733L2.64356 12.8223C2.46261 12.6137 2.48501 12.2979 2.6936 12.1169C2.90219 11.936 3.21797 11.9584 3.39893 12.167L9.94035 19.7073L20.5547 2.73495C20.7011 2.50082 21.0096 2.42972 21.2437 2.57614Z"
    />
  </svg>
);

export default CheckmarkIcon;
