import React from 'react';
import { TIcon } from '../Icon.type';
import {
  AccessibilityIcon,
  AreaIcon,
  ArrowDownIcon,
  ArrowDownLongIcon,
  ArrowLeftIcon,
  ArrowLeftLongIcon,
  ArrowLeftShortIcon,
  ArrowRightIcon,
  ArrowRightLongIcon,
  ArrowRightShortIcon,
  ArrowUpDownIcon,
  ArrowUpIcon,
  ArrowUpLongIcon,
  ArticleIcon,
  BalconyIcon,
  BathroomIcon,
  BearIcon,
  BinocularIcon,
  BrochureIcon,
  CalendarIcon,
  CarIcon,
  CheckmarkIcon,
  CheckPaperIcon,
  CircleInformationIcon,
  CircleMoneyIcon,
  CirclePlayIcon,
  CircleQuestionIcon,
  CircleWithCheckmarkIcon,
  CircleWithCheckmarkSmallIcon,
  CircleWithCrossIcon,
  CircleWithExclamationIcon,
  CircleWithExclamationSmallIcon,
  ClockIcon,
  CreditcardIcon,
  CrossIcon,
  DimensionsIcon,
  DoubleBedIcon,
  DownloadIcon,
  EarthIcon,
  EyeIcon,
  EyeLessIcon,
  FacebookIcon,
  FilledCircleWithMinus,
  FilledCircleWithMinusInverted,
  FilledCircleWithPlus,
  FilledCircleWithPlusInverted,
  FlagIcon,
  FloaterIcon,
  InstagramIcon,
  LocationIcon,
  MailIcon,
  MenuIcon,
  MessageIcon,
  MinusIcon,
  NewTabIcon,
  NoWindowIcon,
  PaperIcon,
  PaymentIcon,
  PhoneIcon,
  PipeIcon,
  PlusIcon,
  SandGlassIcon,
  SearchAlertIcon,
  SearchIcon,
  ShareIcon,
  ShipFrontIcon,
  SingleBedIcon,
  SnowflakeIcon,
  SofaIcon,
  SpringIcon,
  SummerIcon,
  TrashIcon,
  TvIcon,
  TwitterIcon,
  UserIcon,
  WatchIcon,
  WheelchairSmallIcon,
  WifiIcon,
  WindowIcon,
  YoutubeIcon,
  BusIcon,
  RailIcon,
  FlightIcon,
  CircleWithCrossSmallIcon,
  ExclamationInTriangleSmallIcon,
} from '../IconComponents';
import LinkedInIcon from '../IconComponents/LinkedInIcon';

const iconMap: Record<TIcon, React.ComponentType> = {
  accessibility: AccessibilityIcon,
  area: AreaIcon,
  arrowDown: ArrowDownIcon,
  arrowDownLong: ArrowDownLongIcon,
  arrowLeft: ArrowLeftIcon,
  arrowLeftShort: ArrowLeftShortIcon,
  arrowLeftLong: ArrowLeftLongIcon,
  arrowRight: ArrowRightIcon,
  arrowRightShort: ArrowRightShortIcon,
  arrowRightLong: ArrowRightLongIcon,
  arrowUp: ArrowUpIcon,
  arrowUpLong: ArrowUpLongIcon,
  arrowUpDown: ArrowUpDownIcon,
  article: ArticleIcon,
  balcony: BalconyIcon,
  bathroom: BathroomIcon,
  bear: BearIcon,
  binocular: BinocularIcon,
  brochure: BrochureIcon,
  circlePlay: CirclePlayIcon,
  calendar: CalendarIcon,
  checkmark: CheckmarkIcon,
  check: CheckmarkIcon,
  checkPaper: CheckPaperIcon,
  circleMoney: CircleMoneyIcon,
  circleWithCheckmark: CircleWithCheckmarkIcon,
  circleWithCheckmarkSmall: CircleWithCheckmarkSmallIcon,
  circleWithCross: CircleWithCrossIcon,
  circleWithCrossSmall: CircleWithCrossSmallIcon,
  circleWithExclamation: CircleWithExclamationIcon,
  clock: ClockIcon,
  download: DownloadIcon,
  location: LocationIcon,
  linkedin: LinkedInIcon,
  'linkedin hx': LinkedInIcon,
  circleWithExclamationSmall: CircleWithExclamationSmallIcon,
  creditCard: CreditcardIcon,
  payment: PaymentIcon,
  earth: EarthIcon,
  floater: FloaterIcon,
  sandGlass: SandGlassIcon,
  cross: CrossIcon,
  minus: MinusIcon,
  flag: FlagIcon,
  plus: PlusIcon,
  shipFront: ShipFrontIcon,
  ship: ShipFrontIcon,
  ferry: ShipFrontIcon,
  wheelchairSmall: WheelchairSmallIcon,
  search: SearchIcon,
  circleInformation: CircleInformationIcon,
  doubleBed: DoubleBedIcon,
  message: MessageIcon,
  sofa: SofaIcon,
  tv: TvIcon,
  window: WindowIcon,
  dimensions: DimensionsIcon,
  menu: MenuIcon,
  newTab: NewTabIcon,
  noWindow: NoWindowIcon,
  user: UserIcon,
  searchAlert: SearchAlertIcon,
  pipe: PipeIcon,
  facebook: FacebookIcon,
  'facebook hx': FacebookIcon,
  'hx facebook': FacebookIcon,
  phone: PhoneIcon,
  instagram: InstagramIcon,
  'instagram hx': InstagramIcon,
  twitter: TwitterIcon,
  youtube: YoutubeIcon,
  'youtube hx': YoutubeIcon,
  eyeLess: EyeLessIcon,
  eye: EyeIcon,
  car: CarIcon,
  circleQuestion: CircleQuestionIcon,
  watch: WatchIcon,
  share: ShareIcon,
  singleBed: SingleBedIcon,
  snowflake: SnowflakeIcon,
  spring: SpringIcon,
  summer: SummerIcon,
  paper: PaperIcon,
  wifi: WifiIcon,
  filledCircleWithMinus: FilledCircleWithMinus,
  filledCircleWithMinusInverted: FilledCircleWithMinusInverted,
  filledCircleWithPlus: FilledCircleWithPlus,
  filledCircleWithPlusInverted: FilledCircleWithPlusInverted,
  trash: TrashIcon,
  flight: FlightIcon,
  rail: RailIcon,
  transfer: BusIcon,
  bus: BusIcon,
  mail: MailIcon,
  triangleWithExclamationSmall: ExclamationInTriangleSmallIcon,
};

export const getIcon = (iconType: TIcon) => iconMap[iconType] || null;
