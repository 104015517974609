import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { IBookingDetailsData } from 'interfaces/IBookingDetails';
import {
  IBookingSearchResult,
  IBookingsPayload,
  INewBookingsPayload,
} from 'interfaces/IBookings';
import { INewBooking } from 'interfaces/INewBooking';
import { api } from './api';

export const bookingsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAgencyBookings: build.query<
      IBookingsPayload,
      { limit: number; offset: number }
    >({
      query: ({ limit, offset }) => ({
        url: `Bookings/Agency?limit=${limit}&offset=${offset}`,
      }),
      extraOptions: { maxRetries: 0 },
    }),

    getNewAgencyBookings: build.query<
      INewBookingsPayload,
      { limit: number; offset: number }
    >({
      query: ({ limit, offset }) => ({
        url: `Bookings/Agency/v2?limit=${limit}&offset=${offset}`,
      }),
      extraOptions: { maxRetries: 0 },
    }),

    getAgentBookings: build.query<
      IBookingsPayload,
      { limit: number; offset: number }
    >({
      query: ({ limit, offset }) => ({
        url: `Bookings/Agent?limit=${limit}&offset=${offset}`,
      }),
      extraOptions: { maxRetries: 0 },
    }),

    getNewAgentBookings: build.query<
      INewBookingsPayload,
      { limit: number; offset: number }
    >({
      query: ({ limit, offset }) => ({
        url: `Bookings/Agent/v2?limit=${limit}&offset=${offset}`,
      }),
      extraOptions: { maxRetries: 0 },
    }),

    getBookingDetails: build.query<
      IBookingDetailsData,
      { bookingId: string; isAgencyBooking: boolean }
    >({
      query: ({ bookingId, isAgencyBooking }) => {
        const subPath = isAgencyBooking ? 'detail' : 'agent';
        return {
          url: `Bookings/${subPath}/${bookingId}`,
        };
      },
      extraOptions: { maxRetries: 0 },
    }),

    searchBooking: build.query<
      IBookingSearchResult,
      { bookingId: string; isAgencyBooking: boolean }
    >({
      async queryFn(_arg, _queryApi, _extraOptions, searchBooking) {
        const { bookingId, isAgencyBooking } = _arg;

        const response = await searchBooking({
          method: 'GET',
          url: `Bookings/${bookingId}`,
        });

        if (response.error) {
          return { error: response.error as FetchBaseQueryError };
        }

        const booking = response.data as INewBooking;

        return {
          data: {
            booking,
            isAgencyBookingSearch: isAgencyBooking,
          },
        };
      },
    }),
  }),
});

export const {
  useGetAgencyBookingsQuery,
  useGetNewAgencyBookingsQuery,
  useGetAgentBookingsQuery,
  useGetNewAgentBookingsQuery,
  useGetBookingDetailsQuery,
  useSearchBookingQuery,
} = bookingsApi;

export const {
  endpoints: {
    getAgencyBookings,
    getNewAgencyBookings,
    getAgentBookings,
    getNewAgentBookings,
    getBookingDetails,
    searchBooking,
  },
} = bookingsApi;
