import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import styles from './styles.module.scss';

interface IAnimatedArrow {
  children: JSX.Element;
  color?: string;
  height?: number;
}

const AnimatedArrow = ({ children, color, height }: IAnimatedArrow) => (
  <div className={styles.animatedArrow}>
    {children}
    <div className={styles.linkArrow}>
      <Icon
        iconType="arrowRightLong"
        color={color || colors.hrn.clearBlue1}
        height={height || 24}
      />
    </div>
  </div>
);

export default AnimatedArrow;
