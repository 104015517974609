import clsx from 'clsx';
import Icon from 'components/UI/Icons/Icon';
import { TIcon } from 'components/UI/Icons/Icon.type';
import styles from './styles.module.scss';

interface IFilterSearchButton {
  active: boolean;
  disabled?: boolean;
  id?: string;
  iconType?: TIcon;
  onClick: () => void;
  title: string;
}

const FilterSearchButton = ({
  active,
  disabled,
  id,
  iconType,
  onClick,
  title,
}: IFilterSearchButton) => (
  <button
    className={clsx(styles.button, {
      [styles.active]: active,
      [styles.disabled]: disabled,
      [styles.unactive]: !disabled && !active,
    })}
    id={id}
    onClick={onClick}
    disabled={disabled || false}
    data-active={active}
  >
    {iconType && <Icon iconType={iconType} />}

    {title}
  </button>
);

export default FilterSearchButton;
