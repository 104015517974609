import { Link } from 'react-router-dom';
import { colors } from 'style/colors';
import styled from 'styled-components';
import { LinkText } from 'components';
import { getJwtPayload } from 'utils/analytics';

interface IFaresLabel {
  fareStructure: string;
}
const getColor = (fareStructure: string) => {
  if (fareStructure === 'BASIC') {
    return colors.shared.warmGray4;
  }

  if (fareStructure === 'SELECT') {
    return colors.shared.snowBlue;
  }

  if (fareStructure === 'PLATINUM') {
    return colors.shared.midnightYellow3;
  }
  return colors.shared.snowBlue;
};

const Fares = styled.div<IFaresLabel>`
  border-left: 78px solid transparent;
  border-right: 78px solid
    ${(props: { fareStructure: string }) => getColor(props.fareStructure)};
  border-bottom: 78px solid transparent;
  height: 0;
  width: 0;
  position: absolute;
  right: 0;
  z-index: 2;
`;

const FaresTextContainer = styled.div`
  transform: rotate(45deg);
  font-family: Atlas Grotesk Medium;
  font-size: 12px;
  text-align: center;
  text-decoration: none;
  margin-top: 15px;
  width: 66px;
  height: 25px;
  line-height: 25px;
  margin-left: 15px;
  padding-bottom: 10px;
`;

const FaresLabel = ({ fareStructure }: IFaresLabel) => {
  const marketId = getJwtPayload()['http://user/market'];

  const faresTextContainer = (
    <FaresTextContainer>{fareStructure}</FaresTextContainer>
  );
  return (
    <LinkText
      target="_blank"
      rel="noopener noreferrer"
      to={
        marketId === 'US'
          ? `/learn/article/hurtigrutens-price-structure-us`
          : `/learn/article/hurtigrutens-price-structure`
      }
      as={Link}
    >
      <Fares fareStructure={fareStructure}>{faresTextContainer}</Fares>
    </LinkText>
  );
};

export default FaresLabel;
