import { SVGProps } from 'react';

const WifiIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={17}
    viewBox="0 0 21 17"
    {...props}
  >
    <path
      fill="#000"
      d="M10.4 12a2.5 2.5 0 1 1 0 5 2.5 2.5 0 0 1 0-5Zm0 1a1.5 1.5 0 1 0-.001 2.999A1.5 1.5 0 0 0 10.4 13Zm0-7c2.34 0 4.458.96 6.041 2.43.202.19.213.51.025.71a.51.51 0 0 1-.707.03C14.342 7.84 12.461 7 10.4 7c-2.06 0-3.942.84-5.359 2.17a.509.509 0 0 1-.706-.03.503.503 0 0 1 .024-.71C5.942 6.96 8.061 6 10.4 6Zm0-6c4.039 0 7.665 1.66 10.254 4.25.195.19.195.51 0 .7-.196.2-.512.2-.707 0A13.481 13.481 0 0 0 10.4 1C6.639 1 3.265 2.54.854 4.95c-.196.2-.512.2-.707 0a.49.49 0 0 1 0-.7A14.473 14.473 0 0 1 10.4 0Z"
      fillRule="evenodd"
    />
  </svg>
);

export default WifiIcon;
