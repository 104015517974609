import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import { LoadingGif } from 'components';
import * as serviceWorker from './serviceWorker';
import App from './App';
import ScrollToTop from './components/ScrollToTop';
import './style/reset.scss';
import './style/global.scss';
import store from './store';

const { REACT_APP_COMPANY } = process.env;

// Dirty trick to fix broken middleware on HX www url
if (REACT_APP_COMPANY === 'HX') {
  const { host, pathname, search } = window.location;

  // Check if the host starts with 'www.'
  if (host.startsWith('www.')) {
    // Redirect to the non-www version
    const newHost = host.substring(4); // Remove 'www.'
    const newUrl = `${window.location.protocol}//${newHost}${pathname}${search}`;
    window.location.replace(newUrl);
  }
}

const BaseApp = () => {
  const persistor = persistStore(store);

  return (
    <Provider store={store}>
      <PersistGate loading={<LoadingGif />} persistor={persistor}>
        <BrowserRouter>
          <App />
          <ScrollToTop />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

const container = document.getElementById('root');
const root = createRoot(container);
root.render(<BaseApp />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
