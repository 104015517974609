import { SVGProps } from 'react';

const BearIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={15}
    viewBox="0 0 22 15"
    fill="currentColor"
    {...props}
  >
    <path
      d="M21.43 3.95c-.53-.14-1.47-.4-1.74-.52-.09-.04-.18-.09-.28-.14-.44-.24-1.03-.57-2.21-.57h-.01c-.03 0-2.98.2-4.67-1.28C11.11.19 8.86 0 7.65 0 3.67 0 2.58 2.31 1.79 3.99l-.12.25c-.3.64-.96 1.94-.97 1.94L.05 7.49c-.03.07-.05.15-.05.22 0 .32.21.98 1.05 1.15.1.02.21.03.32.03.35 2.34 1.12 4.75 1.15 4.86.07.21.26.35.48.35h5.64c.28 0 .5-.22.5-.5 0-.58.34-1.32.91-2.06 1.53.54 4.21 2.46 4.24 2.48.09.06.19.09.29.09h3.49a.495.495 0 0 0 .44-.73c-.06-.12-1.53-2.83-3.46-4.93.53-.09 1.27-.17 2.51-.24.02 0 .05 0 .07-.01 1.15-.03 3.09-.28 3.79-1.5.28-.49.38-1.84.4-2.24 0-.23-.15-.45-.39-.51Zm-7.45 4.84c1.39 1.42 2.6 3.31 3.21 4.33h-2.47c-.53-.38-2.51-1.74-3.98-2.37.79-.78 1.8-1.48 2.93-1.9.1-.04.19-.07.27-.1.03.01.03.02.04.04Zm6.56-2.58c-.34.6-1.48.97-3.03 1-.03 0-.07 0-.1.01-2.54.14-3.09.31-4.08.68-2.55.94-4.81 3.26-5.16 5.2H5.53c.01-2.46.6-4.42 1.67-5.52.19-.2.19-.52-.01-.71a.504.504 0 0 0-.71.01c-1.25 1.29-1.94 3.5-1.95 6.22H3.36c-.24-.81-.83-2.92-1.07-4.79a.477.477 0 0 0-.18-.33.5.5 0 0 0-.37-.1c-.13.01-.38.03-.49 0a.405.405 0 0 1-.22-.1l.57-1.15s.67-1.31.98-1.96l.12-.25C3.45 2.82 4.3 1 7.65 1c.86 0 3 .12 4.22 1.19 2.01 1.76 5.26 1.54 5.36 1.53.88 0 1.31.24 1.69.45.12.07.24.13.35.18.31.14 1.03.34 1.5.47-.04.64-.14 1.24-.23 1.39Z"
      fillRule="evenodd"
    />
  </svg>
);

export default BearIcon;
