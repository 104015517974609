import { SVGProps } from 'react';

const CircleWithCheckmarkSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 1C4.13401 1 1 4.13401 1 8C1 11.866 4.13401 15 8 15C11.866 15 15 11.866 15 8C15 4.13401 11.866 1 8 1ZM0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.3536 4.64645C12.5488 4.84171 12.5488 5.15829 12.3536 5.35355L7.35355 10.3536C7.15829 10.5488 6.84171 10.5488 6.64645 10.3536L4.14645 7.85355C3.95118 7.65829 3.95118 7.34171 4.14645 7.14645C4.34171 6.95118 4.65829 6.95118 4.85355 7.14645L7 9.29289L11.6464 4.64645C11.8417 4.45118 12.1583 4.45118 12.3536 4.64645Z"
    />
  </svg>
);

export default CircleWithCheckmarkSmallIcon;
