import { createActions, ActionFunctionAny, Action } from 'redux-actions';
import axios from '../../axios-instance';

export const {
  fetchPortsStart,
  fetchPortsSuccess,
  fetchPortsFailed,
  fetchDeckAvailabilityStart,
  fetchDeckAvailabilitySuccess,
  fetchDeckAvailabilityFailed,
} = createActions({
  FETCH_PORTS_START: undefined,
  FETCH_PORTS_SUCCESS: (portsList) => ({ portsList }),
  FETCH_PORTS_FAILED: undefined,
  Fetch_DECK_AVAILABILITY_START: undefined,
  FETCH_DECK_AVAILABILITY_SUCCESS: ({
    isOnlyDeckSpaceAvailable,
    isDeckSpaceAndCabinAvailable,
  }) => ({
    isOnlyDeckSpaceAvailable,
    isDeckSpaceAndCabinAvailable,
  }),
  Fetch_DECK_AVAILABILITY_FAILED: undefined,
});

export const fetchPortsByCountry =
  (country: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchPortsStart());
    const portParams = new URLSearchParams({ country });
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}ports?${portParams}`)
      .then((response) => {
        dispatch(fetchPortsSuccess(response.data));
      })
      .catch(() => {
        dispatch(fetchPortsFailed());
      });
  };

export const fetchDeckAvailability =
  (fromPort: string, toPort: string, isViaKirkenes = false) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchDeckAvailabilityStart());
    const portParams = new URLSearchParams({
      fromPort,
      toPort,
      isViaKirkenes: `${isViaKirkenes}`,
    });
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}ports/deckspace?${portParams}`)
      .then((response) => {
        dispatch(fetchDeckAvailabilitySuccess(response.data));
      })
      .catch(() => {
        dispatch(fetchDeckAvailabilityFailed());
      });
  };
