import clsx from 'clsx';
import { BodyText, Header3 } from 'components/UI/Brand/text';
import { Fluid, LinkText, CustomImage, ContentfulRenderer } from 'components';
import { ICampaignArticleContainer } from 'containers/Learn/CampaignPage/CampaignPage.types';
import TitleAndDescription from 'containers/Learn/TitleAndDescription';
import { Link } from 'react-router-dom';
import sizes from 'style/sizes';
import translate from 'translate';
import { getUrl } from './utils/getUrl';
import styles from './styles.module.scss';

interface IGridArticleContainerProps {
  articleSection: ICampaignArticleContainer;
}

const GridArticleContainer = ({
  articleSection,
}: IGridArticleContainerProps) => (
  <Fluid maxWidth={sizes.desktopContainerWidth} hasNoSpace>
    <TitleAndDescription
      title={articleSection.title}
      descriptionAsNode={articleSection.richHeading}
      titleClassName={styles.titleClassName}
    />
    <div className={styles.gridArticleContainer}>
      {articleSection.articles.map((article) => (
        <div
          key={article.title}
          className={clsx(styles.item, {
            [styles.gridOneItem]: articleSection.articles.length === 1,
            [styles.gridTwoItems]: articleSection.articles.length === 2,
            [styles.gridThreeItems]: articleSection.articles.length === 3,
            [styles.gridFourItems]: articleSection.articles.length === 4,
            [styles.gridFiveItems]: articleSection.articles.length === 5,
          })}
        >
          <div className={styles.imageContainer}>
            <CustomImage
              src={article?.hero?.url ?? article?.heroWithFocalPoint?.image.url}
              className={styles.image}
              isPresentation
            />
          </div>
          <span className={styles.textContainer}>
            <Header3>{article.title}</Header3>
            <span className={styles.text}>
              {!article?.shipDescription ? (
                <BodyText className={styles.truncate}>
                  {`${article?.intro ?? article?.destinationDescription} `}
                </BodyText>
              ) : (
                <ContentfulRenderer>
                  {article?.shipDescription}
                </ContentfulRenderer>
              )}
              {article?.urlName && (
                <LinkText
                  as={Link}
                  to={getUrl(article.typeName, article.urlName)}
                  className={styles.linkText}
                >
                  {translate('General_ReadMore')}
                </LinkText>
              )}
            </span>
          </span>
        </div>
      ))}
    </div>
  </Fluid>
);

export default GridArticleContainer;
