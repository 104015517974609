import clsx from 'clsx';
import { BodyText, Caption, Header4, Overline } from 'components/UI/Brand/text';
import { colors } from 'style/colors';
import CustomImage from 'components/UI/CustomImage/CustomImage';
import getLocaleNumberFormat from 'utils/numberWithCommas';
import placeholderImage from 'assets/images/no_image.png';
import translate from 'translate';
import { IExtraItemsData } from 'interfaces/IExtras';
import { getCurrencyFormat } from 'utils/multilanguage-support';
import { getFreeExtrasLabel } from 'containers/Book/BookingProgress/BookingFunnel/Extras/utils/extras.util';
import Icon from 'components/UI/Icons/Icon';
import { RoundButtonInverted } from 'components/UI/Buttons/RoundButtonInverted/RoundButtonInverted';
import { useAppSelector } from 'hooks/storeHooks';
import { isSearchTypeCoastal } from 'utils/constants';
import { useState } from 'react';
import { FlatButton } from 'components/UI';
import { countParagraphs } from 'utils/countParagraphs';
import { truncateParagraphs } from 'utils/truncateParagraph';
import IconList from './IconList';
import styles from './styles.module.scss';

export interface IExtraItemProps {
  amountOfBookedTravelers: number;
  extraItem: IExtraItemsData;
  isAdded: boolean;
  index: number;
  onOpenAddExtraModal: (data: IExtraItemsData, isEdit: boolean) => void;
  onRemoveExtra: () => void;
}

const ExtraItem = ({
  amountOfBookedTravelers,
  extraItem,
  isAdded,
  index,
  onOpenAddExtraModal,
  onRemoveExtra,
}: IExtraItemProps) => {
  const { caption, extra, extraType, images } = extraItem;
  const { title, passengers } = extra;
  let { price } = extra;
  const isIncludedExcursion =
    extraType === 'excursions' && extra.code?.startsWith('INCL');

  const { searchType } = useAppSelector((state) => state.search);
  const isCoastal = isSearchTypeCoastal(searchType);

  const image = images?.[0]?.imageUrl;
  const altText = images?.[0]?.alternateText;

  if (passengers?.length > 0) price = passengers[0].price;

  const [expanded, setExpanded] = useState(false);
  const { description } = extra;
  const paragraphs = description?.split('</p>')?.filter(Boolean) || [];
  const paragraphsAmount = countParagraphs(description);
  const isShowMoreButtonVisible = paragraphsAmount > 1;

  const getButtonLabel = () => {
    if (isAdded) {
      return translate('Extras_EditBooking');
    }
    return isIncludedExcursion
      ? translate('General_Reserve')
      : translate('Extras_AddToBooking');
  };

  return (
    <article className={styles.extraItem}>
      <div
        className={clsx(styles.card, {
          [styles.cardAdded]: isAdded,
        })}
      >
        {isAdded && (
          <div className={styles.bookedTravelersContainer}>
            <Overline>
              {translate('Extras_BookedPassengers')}{' '}
              <span className={styles.bold}>{amountOfBookedTravelers}</span>
            </Overline>
            <Icon
              iconType="trash"
              color={colors.shared.black}
              width={20}
              height={20}
              onClick={onRemoveExtra}
            />
          </div>
        )}

        <div className={styles.imageContainer}>
          <CustomImage
            src={image || placeholderImage}
            alt={altText || title}
            height={800}
            width={800}
            className={image ? styles.image : styles.imagePlaceholder}
            isPresentation={!image}
          />
        </div>

        <div
          className={clsx(styles.informationContainer, {
            [styles.isAddedCoastal]: isAdded && isCoastal,
            [styles.isAddedHX]: isAdded && !isCoastal,
          })}
        >
          <div className={styles.textContainer}>
            <div className={styles.headerTitle}>
              <Caption
                className={clsx(styles.extraType, {
                  [styles.isAdded]: isAdded,
                })}
              >
                {caption}
              </Caption>
            </div>
            <div className={styles.headerTitle}>
              {extraType === 'excursions' && (
                <Header4
                  className={clsx(styles.title, {
                    [styles.isAdded]: isAdded,
                  })}
                  id={`excursion_test_id_${extra.code}`}
                >
                  {extra.code}
                </Header4>
              )}
              <Header4
                className={clsx(styles.title, {
                  [styles.isAdded]: isAdded,
                })}
              >
                {title}
              </Header4>
            </div>
            <div className={styles.descriptionContainer}>
              {paragraphs && (
                <div
                  className={clsx(styles.description, {
                    [styles.isAdded]: isAdded,
                  })}
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: truncateParagraphs(
                        paragraphs,
                        expanded,
                        paragraphsAmount
                      ),
                    }}
                  />
                </div>
              )}
              {isShowMoreButtonVisible && (
                <div className={styles.flatButtonContainer}>
                  <FlatButton
                    classes={clsx(styles.showMoreButton, {
                      [styles.isAdded]: isAdded,
                    })}
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded
                      ? translate('General_ShowLess')
                      : translate('General_ShowMore')}
                  </FlatButton>
                </div>
              )}
              <IconList data={extraItem} isAdded={isAdded} />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            {price > 0 ? (
              <BodyText
                className={clsx(styles.price, {
                  [styles.isAdded]: isAdded,
                })}
              >
                {translate('Extras_PerPerson')}{' '}
                <span
                  className={clsx(styles.priceCurrency, {
                    [styles.isAdded]: isAdded,
                  })}
                  data-testid={`price_test_id_${price}`}
                >
                  {`${getCurrencyFormat()} ${getLocaleNumberFormat(price)}`}
                </span>
              </BodyText>
            ) : null}
            {price === 0 ? (
              <Caption
                className={clsx(styles.freeExcursion, {
                  [styles.isAdded]: isAdded,
                })}
                id={`caption_${extraType}`}
              >
                {getFreeExtrasLabel(extraType)}
              </Caption>
            ) : null}

            <RoundButtonInverted
              onClick={() => {
                onOpenAddExtraModal(extraItem, !!isAdded);
              }}
              size="small"
              type="button"
              buttonColor={colors.b2b.frozenGreen}
              id={`select_extra_${extraType.slice(0, -1)}_${index}`}
              className={clsx(styles.buttonGreen, {
                [styles.isAddedGreenButton]: isAdded,
              })}
            >
              {getButtonLabel()}
            </RoundButtonInverted>
          </div>
        </div>
      </div>
    </article>
  );
};

export default ExtraItem;
