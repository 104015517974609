import { ButtonHTMLAttributes } from 'react';
import { typography } from 'style/typography';
import { colors } from 'style/colors';
import styled, { keyframes } from 'styled-components';

type TButtonSize = 'normal' | 'small';

interface IRoundButtonLoadable extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonColor?: string;
  size?: TButtonSize;
  classes?: string;
  loading?: string | boolean;
  disabled?: boolean;
  dataTestid?: string;
}

const Button = styled.button<IRoundButtonLoadable>`
  color: ${colors.shared.white};
  font-size: ${({ size }) =>
    size === 'normal' ? '16px' : typography.normal.fontSize.small};
  line-height: ${({ size }) =>
    size === 'normal'
      ? typography.normal.lineHeight.normal
      : typography.normal.lineHeight.small};
  font-family: ${typography.normal.fontFamily};
  font-weight: ${({ size }) => (size === 'normal' ? 500 : 300)};
  background-color: ${process.env.REACT_APP_COMPANY === 'HX'
    ? colors.hrx.blue
    : colors.shared.hurtigrutenRed2};
  padding: 0px 4px;
  border: none;
  height: 44px;
  min-width: 172px;
  border-radius: 50px;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  &:hover:not([disabled]) {
    background-color: ${process.env.REACT_APP_COMPANY === 'HX'
      ? colors.hrx.blueDark
      : colors.shared.hurtigrutenRed3};
  }
  &:disabled {
    background: ${colors.hrx.volcanoBlack4};
    color: ${colors.shared.white};
    cursor: not-allowed;
  }
`;

const ButtonChildren = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
`;

const RotateSpinner = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Spinner = styled.div`
  border: 4px solid
    ${process.env.REACT_APP_COMPANY === 'HX'
      ? colors.hrx.blue
      : colors.shared.hurtigrutenRed2};
  border-radius: 50%;
  border-top-color: white;
  width: 16px;
  height: 16px;
  animation: ${RotateSpinner} 1s linear;
  animation-iteration-count: infinite;
  &:disabled {
    border: 4px solid ${colors.hrx.volcanoBlack4};
  }
`;

export const RoundButtonLoadable = ({
  size = 'normal',
  children,
  classes,
  type,
  loading,
  disabled,
  onClick,
  dataTestid,
  ...rest
}: IRoundButtonLoadable) => {
  const buttonChildren = <ButtonChildren>{children}</ButtonChildren>;
  return (
    <Button
      data-testid={dataTestid}
      className={classes}
      type={type}
      size={size}
      onClick={onClick}
      loading={loading ? 'true' : undefined}
      disabled={disabled}
      {...rest}
    >
      {loading ? <Spinner /> : buttonChildren}
    </Button>
  );
};
