import { handleActions } from 'redux-actions';
import { ISummaryState } from 'interfaces/ISummary';
import {
  fetchSummaryFailed,
  fetchSummaryStart,
  fetchSummarySuccess,
  resetSummary,
  shareSummaryFailed,
  shareSummaryStart,
  shareSummarySuccess,
} from 'store/actions/summary';

const initialState: ISummaryState = {
  summaryData: null,
  error: null,
  loading: false,
  sendingSummary: false,
};

const reducer = handleActions<ISummaryState, Partial<ISummaryState>>(
  {
    [fetchSummaryStart.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchSummarySuccess.toString()]: (state, action) => ({
      ...state,
      summaryData: action.payload?.summaryData || null,
      error: null,
      loading: false,
    }),
    [fetchSummaryFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload?.error || null,
      loading: false,
    }),
    [shareSummaryStart.toString()]: (state) => ({
      ...state,
      sendingSummary: true,
    }),
    [shareSummarySuccess.toString()]: (state) => ({
      ...state,
      sendingSummary: false,
      error: null,
    }),
    [shareSummaryFailed.toString()]: (state, action) => ({
      ...state,
      sendingSummary: false,
      error: action.payload?.error || null,
    }),
    [resetSummary.toString()]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
