import { SVGProps } from 'react';

const BathroomIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={11}
    height={20}
    viewBox="0 0 11 20"
    {...props}
  >
    <g fillRule="evenodd">
      <path d="M1.02 10.43h8.39c-.23-2.4-2.06-4.21-4.2-4.21-2.13 0-3.96 1.81-4.19 4.21zm-1.02.5c0-3.1 2.29-5.71 5.21-5.71 2.93 0 5.22 2.61 5.22 5.71 0 .28-.23.5-.5.5H.5c-.28 0-.5-.22-.5-.5zm2.07 2.11c.28 0 .5.23.5.5v1.74c0 .28-.22.5-.5.5-.27 0-.5-.22-.5-.5v-1.74c0-.27.23-.5.5-.5zm3.14 0c.28 0 .5.23.5.5v.87c0 .28-.22.5-.5.5-.27 0-.5-.22-.5-.5v-.87c0-.27.23-.5.5-.5zm3.15 0c.27 0 .5.23.5.5v1.74c0 .28-.23.5-.5.5-.28 0-.5-.22-.5-.5v-1.74c0-.27.22-.5.5-.5zm-6.29 4.35c.28 0 .5.22.5.5v.87c0 .28-.22.5-.5.5-.27 0-.5-.22-.5-.5v-.87c0-.28.23-.5.5-.5zm3.14-.87c.28 0 .5.23.5.5v1.74c0 .28-.22.5-.5.5-.27 0-.5-.22-.5-.5v-1.74c0-.27.23-.5.5-.5zm3.15.87c.27 0 .5.22.5.5v.87c0 .28-.23.5-.5.5-.28 0-.5-.22-.5-.5v-.87c0-.28.22-.5.5-.5z" />
      <path d="M3.14 2.24C3.14.94 4.04 0 5.21 0s2.08.94 2.08 2.24v3.48c0 .27-.23.5-.5.5-.28 0-.5-.23-.5-.5V2.24C6.29 1.43 5.77 1 5.21 1c-.55 0-1.07.43-1.07 1.24v3.51c0 .28-.22.5-.5.5-.27 0-.5-.22-.5-.5V2.24z" />
    </g>
  </svg>
);

export default BathroomIcon;
