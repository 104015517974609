import TabTertiary from 'components/UI/Tab/TabTertiary/TabTertiary';
import { ITabItem } from 'interfaces/ITab';
import CalendarFlexibleMonths from 'components/UI/Calendar/CalendarFlexibleMonths';
import { getFlexibleYears, getNextAvailableSailingYear } from 'utils/dates';
import styles from './styles.module.scss';

interface ICalendarFlexible {
  selectedUnixPeriods: number[];
  setSelectedUnixPeriods: (unixPeriods: number[]) => void;
  monthsAvailableForDestinationType?: string[];
}

const CalendarFlexible = ({
  selectedUnixPeriods,
  setSelectedUnixPeriods,
  monthsAvailableForDestinationType,
}: ICalendarFlexible) => {
  const nextAvailableSailingYear = getNextAvailableSailingYear(
    monthsAvailableForDestinationType
  );
  const differenceBetweenCurrentYearAndNextAvailableSailingYear =
    nextAvailableSailingYear - new Date().getFullYear();

  const tabItems: ITabItem[] = getFlexibleYears()?.map((year) => ({
    name: year.toString(),
    content: (
      <CalendarFlexibleMonths
        year={year}
        selectedUnixPeriods={selectedUnixPeriods}
        setSelectedUnixPeriods={setSelectedUnixPeriods}
        monthsAvailableForDestinationType={monthsAvailableForDestinationType}
      />
    ),
  }));

  return (
    <div className={styles.calendarFlexibleContainer}>
      <TabTertiary
        tabItems={tabItems}
        activeTab={
          differenceBetweenCurrentYearAndNextAvailableSailingYear <= 3
            ? differenceBetweenCurrentYearAndNextAvailableSailingYear
            : 0
        }
      />
    </div>
  );
};

export default CalendarFlexible;
