import VehiclesInput from 'components/UI/VehiclesInput/VehiclesInput';
import translate from 'translate';
import { IVehicle } from 'interfaces/IVehicle';
import { Label } from 'components/UI/Brand/text';

interface IVehicleDropdownProps {
  setVehicles: (setVehicles: IVehicle) => void;
  maxVehicles: number;
  vehicles: IVehicle;
}

export const VehicleDropdown = ({
  setVehicles,
  maxVehicles,
  vehicles,
}: IVehicleDropdownProps) => (
  <>
    <Label htmlFor="vehicle-input">
      {translate('Coastal_Search_Iconic_Vehicle_Label')}
    </Label>
    <VehiclesInput
      vehicles={vehicles}
      onChange={setVehicles}
      maxVehicles={maxVehicles}
    />
  </>
);
