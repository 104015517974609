import clsx from 'clsx';
import translate from 'translate';
import { COASTAL_OPTIONS, VOYAGES_WITH_VEHICLES } from 'utils/constants';
import { useEffect, useState } from 'react';
import { IVehicle } from 'interfaces/IVehicle';
import { ICabinBaseSelector } from 'interfaces/ICabin';
import { IDepartureAndArrivalPort } from 'interfaces/IPort';
import { fetchClassicVoyages } from 'store/actions';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { useModal } from 'hooks/useModal';
import {
  CabinAndPassengersSelector,
  DepartureDate,
  VoyageDropdown,
  ShipDropdown,
  VehicleDropdown,
  ItineraryDropdown,
  AccessibleCabinInfoModal,
  PromotionCodeModal,
  ShortVoyageFields,
} from 'components';
import { PrimaryButton } from 'components/UI/Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

export interface IconicCoastalSearchProps {
  startDate: Date;
  setStartDate: (date: Date) => void;
  setShipCodes: (ships: string[]) => void;
  setVehicles: (setVehicles: IVehicle) => void;
  maxVehicles: number;
  isDeckSpaceBookingEnabled: boolean;
  onSearch: () => void;
  buttonDisabled: boolean;
  ports: IDepartureAndArrivalPort;
  setPorts: (ports: IDepartureAndArrivalPort) => void;
  setIsViaKirkenes: (isViaKirkenes: boolean) => void;
  isViaKirkenes: boolean;
  classicVoyageId: string;
  setClassicVoyageId: (classicVoyageId: string) => void;
  setDeckSpace: (deckPassengers: ICabinBaseSelector) => void;
  selectedVoyage: COASTAL_OPTIONS;
  setSelectedVoyage: (setSelectedVoyage: COASTAL_OPTIONS) => void;
  deckSpace: ICabinBaseSelector;
  vehicles: IVehicle;
  isDateLoading?: boolean;
  setPromotionSearch: (value: boolean) => void;
  hasUrlQuery: boolean;
  isDatesDropdown: boolean;
}
export const IconicCoastalSearch = ({
  setStartDate,
  startDate,
  isDeckSpaceBookingEnabled,
  setVehicles,
  maxVehicles,
  setShipCodes,
  onSearch,
  buttonDisabled,
  ports,
  setPorts,
  setIsViaKirkenes,
  isViaKirkenes,
  classicVoyageId,
  setClassicVoyageId,
  setDeckSpace,
  selectedVoyage,
  setSelectedVoyage,
  deckSpace,
  vehicles,
  isDateLoading = false,
  setPromotionSearch,
  hasUrlQuery,
  isDatesDropdown,
}: IconicCoastalSearchProps) => {
  const { CLASSIC_VOYAGE, P2P } = COASTAL_OPTIONS;
  const dispatch = useAppDispatch();
  const [isP2P, setIsP2P] = useState(selectedVoyage === COASTAL_OPTIONS.P2P);
  const [allowVehicleBooking, setAllowVehicleBooking] = useState(true);
  const isShipAvailable = [
    COASTAL_OPTIONS.P2P,
    COASTAL_OPTIONS.CLASSIC_VOYAGE,
    COASTAL_OPTIONS.PACKAGE_VOYAGE,
  ];
  const {
    isModalVisible: isAccessibleModalVisible,
    showModal: showAccessibleModal,
    hideModal: hideAccessibleModal,
    RenderModal: RenderAccessibleModal,
  } = useModal();
  const {
    isModalVisible: isPromotionCodeModalVisible,
    showModal: showPromotionCodeModal,
    hideModal: hidePromotionCodeModal,
    RenderModal: RenderPromotionCodeModal,
  } = useModal();
  const { classicVoyagesList } = useAppSelector(
    (state) => state.classicVoyages
  );

  useEffect(() => {
    if (classicVoyagesList.length === 0 && selectedVoyage === CLASSIC_VOYAGE)
      dispatch(fetchClassicVoyages());

    if (
      VOYAGES_WITH_VEHICLES.includes(classicVoyageId) ||
      selectedVoyage === P2P
    ) {
      setAllowVehicleBooking(true);
    } else setAllowVehicleBooking(false);
  }, [selectedVoyage, classicVoyageId]);

  return (
    <>
      <div className={styles.voyageDropdown} data-testid="voyage-dropdown">
        <VoyageDropdown
          setSelectedVoyage={setSelectedVoyage}
          selectedVoyage={selectedVoyage}
          setIsP2p={setIsP2P}
          setClassicVoyageId={setClassicVoyageId}
          setIsViaKirkenes={setIsViaKirkenes}
          setVehicles={setVehicles}
          setPromotionSearch={setPromotionSearch}
        />
      </div>
      {!isP2P ? (
        <div
          className={styles.itineraryDropdown}
          data-testid="itinerary-dropdow"
        >
          <ItineraryDropdown
            selectedVoyage={selectedVoyage}
            classicVoyageId={classicVoyageId}
            setClassicVoyageId={setClassicVoyageId}
            setPorts={setPorts}
            setIsViaKirkenes={setIsViaKirkenes}
            setPromotionSearch={setPromotionSearch}
            hasUrlQuery={hasUrlQuery}
          />
        </div>
      ) : (
        <div className={styles.p2p} data-testid="p2p-dropdowns">
          <ShortVoyageFields
            ports={ports}
            setPorts={setPorts}
            setIsViaKirkenes={setIsViaKirkenes}
            isViaKirkenes={isViaKirkenes}
          />
        </div>
      )}
      <div className={styles.departureDropdown} data-testid="departure-date">
        <DepartureDate
          onDateChange={setStartDate}
          value={startDate}
          isLoading={isDateLoading}
          isDatesDropdown={isDatesDropdown}
        />
      </div>
      <div className={styles.capSelector} data-testid="cabin-selector">
        <CabinAndPassengersSelector
          isDeckSpaceBookingEnabled={isDeckSpaceBookingEnabled}
          onDeckSelected={setDeckSpace}
          deckPassengers={deckSpace}
          isP2P={isP2P}
          showAccessibleModal={showAccessibleModal}
          showPromotionCodeModal={showPromotionCodeModal}
        />
      </div>
      <div className={styles.shipVehicleContainer}>
        {allowVehicleBooking && (
          <div
            className={styles.vehicleDropdown}
            data-testid="vehicle-dropdown"
          >
            <VehicleDropdown
              setVehicles={setVehicles}
              maxVehicles={maxVehicles}
              vehicles={vehicles}
            />
          </div>
        )}
        {isShipAvailable.includes(selectedVoyage) && (
          <div
            className={clsx(styles.shipDropdown, {
              [styles.allowVehicleBooking]: allowVehicleBooking,
            })}
            data-testid="ship-dropdown"
          >
            <ShipDropdown setShipCodes={setShipCodes} />
          </div>
        )}
      </div>
      <div className={styles.searchButtonContainer} data-testid="search-button">
        <PrimaryButton
          className={styles.submitButton}
          type="button"
          name="search"
          onClick={onSearch}
          disabled={buttonDisabled}
        >
          {translate('SearchBar_SearchLabel')}
        </PrimaryButton>
      </div>
      {isAccessibleModalVisible && (
        <RenderAccessibleModal>
          <AccessibleCabinInfoModal onModalClose={hideAccessibleModal} />
        </RenderAccessibleModal>
      )}
      {isPromotionCodeModalVisible && (
        <RenderPromotionCodeModal>
          <PromotionCodeModal onClose={hidePromotionCodeModal} />
        </RenderPromotionCodeModal>
      )}
    </>
  );
};
