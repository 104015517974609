import { AlertBanner } from 'components';
import { useNavigate } from 'react-router-dom';
import translate from 'translate';

interface ISomethingWentWrongBanner {
  timer?: number;
  alertText?: string;
  redirectPath?: string;
  isRedirect?: boolean;
}

const SomethingWentWrongBanner = ({
  timer,
  alertText,
  redirectPath,
  isRedirect = true,
}: ISomethingWentWrongBanner) => {
  const navigate = useNavigate();
  setTimeout(() => {
    if (!isRedirect) return;
    if (redirectPath) navigate(redirectPath);
    else navigate(-1);
  }, timer || 5000);
  return (
    <AlertBanner
      aria-live="assertive"
      type={'error'}
      onClose={() => {
        if (redirectPath) navigate(redirectPath);
        else navigate(-1);
      }}
    >
      {alertText ? (
        <div>{alertText}</div>
      ) : (
        translate('MyBookingsList_SomethingWentWrong')
      )}
    </AlertBanner>
  );
};

export default SomethingWentWrongBanner;
