import { SVGProps } from 'react';

const ArrowLeftLongIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="24"
    viewBox="0 0 36 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M35.5 12C35.5 12.2761 35.2761 12.5 35 12.5L2.88889 12.5C2.61275 12.5 2.38889 12.2761 2.38889 12C2.38889 11.7239 2.61275 11.5 2.88889 11.5L35 11.5C35.2761 11.5 35.5 11.7239 35.5 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.84331 19.3635C9.64255 19.5531 9.3261 19.5441 9.13649 19.3433L2.52538 12.3433C2.34339 12.1506 2.34339 11.8494 2.52538 11.6567L9.13649 4.65669C9.3261 4.45593 9.64255 4.44689 9.84331 4.63649C10.0441 4.8261 10.0531 5.14255 9.86351 5.34331L3.57663 12L9.86351 18.6567C10.0531 18.8574 10.0441 19.1739 9.84331 19.3635Z"
    />
  </svg>
);

export default ArrowLeftLongIcon;
