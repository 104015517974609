import { Header1 } from 'components/UI/Brand/text';
import styles from './styles.module.scss';

interface IOnboardHeader {
  title: string;
}

const OnboardHeader = ({ title }: IOnboardHeader) => (
  <div className={styles.menu}>
    <div className={styles.headerRoot}>
      <Header1 id="signup_title" className={styles.signUpTitle}>
        {title}
      </Header1>
    </div>
  </div>
);

export default OnboardHeader;
