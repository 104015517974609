import clsx from 'clsx';
import styles from './styles.module.scss';

type IShimmerLoadingType = 'title' | 'text' | 'image' | 'button';

interface IShimmerLoadingProps {
  type: IShimmerLoadingType;
  width?: string;
  height?: string;
  className?: string;
}

const ShimmerLoading = ({
  type,
  width,
  height,
  className,
}: IShimmerLoadingProps) => (
  <>
    {type === 'title' && (
      <div className={clsx(styles.shimmer, styles.title, className)} />
    )}
    {type === 'text' && (
      <div className={clsx(styles.paragraph, className)}>
        <div className={styles.shimmer} />
        <div className={styles.shimmer} />
        <div className={styles.shimmer} />
      </div>
    )}
    {type === 'image' && (
      <div
        className={clsx(styles.shimmer, className)}
        style={{
          width: width || '100px',
          height: height || '100px',
        }}
      />
    )}
    {type === 'button' && (
      <div className={clsx(styles.shimmer, styles.button, className)} />
    )}
  </>
);

export default ShimmerLoading;
