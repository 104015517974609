export const dangerousConverter = (
  text: string,
  type: string,
  color?: string
): string => {
  if (!text) {
    return '';
  }
  let className = '';
  switch (type) {
    case 'Text':
      className = 'brand-body';
      break;
    case 'Info':
      className = 'brand-info';
      break;
    default:
      break;
  }

  return text.replace(
    /<p[^>]*>/g,
    `<p class="${className}" style="color: ${color};">`
  );
};
