import { FormEvent, useState } from 'react';
import Dropdown from 'components/UI/Select/Dropdown';
import { Header2 } from 'components/UI/Brand/text';
import { setLanguage } from 'utils/getLanguage';
import { getMarkets } from 'utils/markets';
import { getRegionById } from 'utils/regions';
import { IRegion } from 'interfaces/IOnboarding';
import { setSupportInfo } from 'utils/supportInfo';
import translate from 'translate';
import { PrimaryButton } from 'components/UI/Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

interface IRegionSelectorProps {
  onSubmit: (region: IRegion) => void;
}

const RegionSelector = ({ onSubmit }: IRegionSelectorProps) => {
  const regions = getMarkets();
  const [selectedRegion, setSelectedRegion] = useState(regions[0]);

  const selectItem = (regionInfo: string) => {
    const region = regions.find(({ name }) => name === regionInfo);
    setSelectedRegion(region);
  };

  const submit = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSubmit(selectedRegion);
    setSupportInfo(getRegionById(selectedRegion.id));
    setLanguage(selectedRegion.language);
  };

  return (
    <div className={styles.container}>
      <Header2>{translate('SignUp_RegionSelector_Title')}</Header2>
      <form onSubmit={submit} className={styles.form}>
        <Dropdown
          id="selectRegion"
          name="regionSelector"
          label={translate('SignUp_RegionSelector_Subtitle')}
          helperText={translate('SignUp_RegionSelector_HelperText')}
          onChange={(event) => {
            event.preventDefault();
            selectItem(event.currentTarget.value);
          }}
          options={regions.map(({ name, description }) => ({
            value: name,
            description: `${name} ${description}`,
          }))}
        />
        <div>
          <PrimaryButton type="submit" id="onboarding_region_submit">
            {translate('General_Continue')}
          </PrimaryButton>
        </div>
      </form>
    </div>
  );
};

export default RegionSelector;
