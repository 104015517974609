import { SVGProps } from 'react';

const ArrowLeftShortIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5 12C22.5 12.2761 22.2761 12.5 22 12.5L2 12.5C1.72386 12.5 1.5 12.2761 1.5 12C1.5 11.7239 1.72386 11.5 2 11.5L22 11.5C22.2761 11.5 22.5 11.7239 22.5 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.35355 19.3536C9.15829 19.5488 8.84171 19.5488 8.64645 19.3536L1.64645 12.3536C1.45118 12.1583 1.45118 11.8417 1.64645 11.6464L8.64645 4.64645C8.84171 4.45118 9.15829 4.45118 9.35355 4.64645C9.54882 4.84171 9.54882 5.15829 9.35355 5.35355L2.70711 12L9.35355 18.6464C9.54882 18.8417 9.54882 19.1583 9.35355 19.3536Z"
    />
  </svg>
);

export default ArrowLeftShortIcon;
