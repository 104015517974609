import { ICabinBaseSelector, ICabin } from 'interfaces/ICabin';
import translate from 'translate';

export const generatePassengerOverviewLabel = ({
  cabins,
  passengers,
  hasCabins = true,
  isCabinLabelVisible = true,
}: {
  cabins?: ICabin[];
  passengers?: ICabinBaseSelector;
  hasCabins?: boolean;
  isCabinLabelVisible?: boolean;
}): string => {
  let adults = 0;
  let infants = 0;
  let children = 0;
  let benefitFares = 0;
  let companions = 0;
  let militaries = 0;
  let students = 0;

  if (hasCabins) {
    cabins?.forEach((cabin) => {
      adults += cabin.adults;
      infants += cabin.infants;
      children += cabin.children;
      benefitFares += cabin.benefitFares;
      companions = cabin.companions;
      militaries = cabin.militaries;
      students = cabin.students;
    });
  } else {
    adults = passengers.adults;
    infants = passengers.infants;
    children = passengers.children;
    benefitFares = passengers.benefitFares;
    companions = passengers.companions;
    militaries = passengers.militaries;
    students = passengers.students;
  }

  const adultLabel =
    adults > 1
      ? ` ${adults} ${translate('General_Adults')}`
      : adults === 1
        ? ` ${adults} ${translate('General_Adult')}`
        : '';
  const childrenLabel =
    children > 1
      ? `${children} ${translate('General_Children')}`
      : children === 1
        ? ` ${children} ${translate('General_Child')}`
        : '';
  const infantLabel =
    infants > 1
      ? `${infants} ${translate('General_Infants')}`
      : infants === 1
        ? `${infants} ${translate('General_Infant')}`
        : '';
  const benefitFareLabel =
    benefitFares > 1
      ? `${benefitFares} ${translate('General_BenefitFares')}`
      : benefitFares === 1
        ? `${benefitFares} ${translate('General_BenefitFare')}`
        : '';
  const companionsLabel =
    companions > 1
      ? `${companions} ${translate('General_Companions')}`
      : companions === 1
        ? `${companions} ${translate('General_Companion')}`
        : '';
  const militariesLabel =
    militaries > 1
      ? `${militaries} ${translate('General_Militaries')}`
      : militaries === 1
        ? `${militaries} ${translate('General_Military')}`
        : '';
  const studentsLabel =
    students > 1
      ? `${students} ${translate('General_Students')}`
      : students === 1
        ? `${students} ${translate('General_Student')}`
        : '';

  const cabinLabelText = hasCabins
    ? cabins?.length > 1
      ? `${cabins.length} ${translate(
          'SearchBar_CabinGuestSelector_CabinPlural'
        )}`
      : cabins?.length === 1
        ? ` ${cabins.length} ${translate(
            'SearchBar_CabinGuestSelector_CabinSingle'
          )}`
        : ''
    : '';

  const cabinLabel = isCabinLabelVisible ? cabinLabelText : '';

  const finalLabel = [
    adultLabel,
    childrenLabel,
    infantLabel,
    benefitFareLabel,
    companionsLabel,
    militariesLabel,
    studentsLabel,
    cabinLabel,
  ]
    .filter((label) => label !== '')
    .join(', ');

  return finalLabel;
};
