import { SVGProps } from 'react';

const ArrowDownLongIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.5C12.2761 1.5 12.5 1.72386 12.5 2L12.5 22C12.5 22.2761 12.2761 22.5 12 22.5C11.7239 22.5 11.5 22.2761 11.5 22L11.5 2C11.5 1.72386 11.7239 1.5 12 1.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19.3536 14.6464C19.5488 14.8417 19.5488 15.1583 19.3536 15.3536L12.3536 22.3536C12.1583 22.5488 11.8417 22.5488 11.6464 22.3536L4.64645 15.3536C4.45118 15.1583 4.45118 14.8417 4.64645 14.6464C4.84171 14.4512 5.15829 14.4512 5.35355 14.6464L12 21.2929L18.6464 14.6464C18.8417 14.4512 19.1583 14.4512 19.3536 14.6464Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowDownLongIcon;
