import { colors } from 'style/colors';
import { IBrandColors } from '../interfaces/IBrandColors';

export const coastalColors: IBrandColors = {
  black: {
    regular: colors.shared.black,
    header: colors.shared.black,
    text: colors.shared.textBlack,
  },
  white: colors.shared.white,
  default: {
    regular: colors.shared.hurtigrutenRed1,
    dark: colors.hrn.redDark,
  },
  grey: { regular: colors.shared.warmGray1, dark: colors.b2b.greyDark },
  offer: colors.shared.frozenBlue,
  menu: { regular: colors.hrn.navyBlue1, dark: colors.hrn.menu },
  link: colors.hrn.clearBlue1,
};
