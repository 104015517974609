import React, { ReactNode } from 'react';
import {
  Header2,
  Header3,
  Header4,
  Header1,
  Subtitle,
} from 'components/UI/Brand/text';
import { HEADER_SIZE } from 'containers/Learn/Articles/utils/getHeader.util';
import { BrandProvider } from 'components';
import { TBrand } from './Brand/brand';

interface IGetHeighestHeadingSize {
  fixedBrand: TBrand;
  headerBlock: HEADER_SIZE;
  highestHeadingSize: HEADER_SIZE;
  highestDOMHeadingSize: HEADER_SIZE;
  children: ReactNode;
}

export const getHighestHeadingSize = ({
  fixedBrand,
  headerBlock,
  highestHeadingSize,
  highestDOMHeadingSize,
  children,
}: IGetHeighestHeadingSize): React.ReactElement => {
  let heading;
  switch (highestHeadingSize || headerBlock) {
    case HEADER_SIZE.H1:
      heading = <Header1>{children}</Header1>;
      break;
    case HEADER_SIZE.H2:
      heading = (
        <Header2 as={highestDOMHeadingSize || highestHeadingSize}>
          {children}
        </Header2>
      );
      break;
    case HEADER_SIZE.H3:
      heading = (
        <Header3 as={highestDOMHeadingSize || highestHeadingSize}>
          {children}
        </Header3>
      );
      break;
    case HEADER_SIZE.H4:
      heading = (
        <Header4 as={highestDOMHeadingSize || highestHeadingSize}>
          {children}
        </Header4>
      );
      break;
    case HEADER_SIZE.H5:
    case HEADER_SIZE.H6:
      heading = <Subtitle>{children}</Subtitle>;
      break;
    default:
      heading = <Header1>{children}</Header1>;
  }
  return fixedBrand === 'coastal' ? (
    <BrandProvider fixedBrand="coastal">{heading}</BrandProvider>
  ) : (
    heading
  );
};
