import { SVGProps } from 'react';

const CircleWithExclamationSmallIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.00005 1.30005C4.29974 1.30005 1.30005 4.29974 1.30005 8.00005C1.30005 11.7004 4.29974 14.7 8.00005 14.7C11.7004 14.7 14.7 11.7004 14.7 8.00005C14.7 4.29974 11.7004 1.30005 8.00005 1.30005ZM0.300049 8.00005C0.300049 3.74746 3.74746 0.300049 8.00005 0.300049C12.2526 0.300049 15.7 3.74746 15.7 8.00005C15.7 12.2526 12.2526 15.7 8.00005 15.7C3.74746 15.7 0.300049 12.2526 0.300049 8.00005Z"
    />
    <path d="M8.05005 4C7.63755 4 7.30005 4.32143 7.30005 4.71429V8.28571C7.30005 8.67857 7.63755 9 8.05005 9C8.46255 9 8.80005 8.67857 8.80005 8.28571V4.71429C8.80005 4.32143 8.46255 4 8.05005 4Z" />
    <path d="M7.29 10.2792C7.11 10.4704 7 10.7321 7 10.9937C7 11.2553 7.11 11.517 7.29 11.7082C7.48 11.8893 7.74 12 8 12C8.26 12 8.52 11.8893 8.71 11.7082C8.89 11.517 9 11.2553 9 10.9937C9 10.7321 8.89 10.4704 8.71 10.2792C8.33 9.90692 7.66 9.90692 7.29 10.2792Z" />
  </svg>
);

export default CircleWithExclamationSmallIcon;
