export const truncateParagraphs = (
  paragraphs: string[],
  isExpanded: boolean,
  paragraphsAmount: number
) => {
  if (isExpanded || paragraphsAmount === 1) {
    return paragraphs.join(' ');
  }

  const nonEmptyParagraphs = paragraphs.filter(
    (paragraph) => paragraph.trim() !== ''
  );

  return `${nonEmptyParagraphs[0]}...`;
};
