import { useEffect, useState, MouseEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'store/types/root';
import localStorageKeys from 'utils/localStorageKeys';
import { createHash } from 'utils/hashString';
import axios from 'axios-instance';
import { AlertBanner, ContentfulRenderer } from 'components';
import { onInfoBanner } from 'store/features/header/headerSlice';
import { Document } from '@contentful/rich-text-types';
import { TAlertType } from './utils/alertType';

interface IAlertBannerData {
  type: TAlertType;
  text: string;
  link: string;
  formatedText: Document;
}

const getHashValue = (bannerData: IAlertBannerData) => {
  const { text, formatedText } = bannerData;
  const valueToHash = formatedText ? JSON.stringify(formatedText) : text;

  return valueToHash ? createHash(valueToHash, 'SHA-1') : null;
};

export const AlertInfoBanner = () => {
  const [bannerData, setBannerData] = useState<IAlertBannerData>(null);
  const publicIp = useSelector((state: IRootState) => state.home.publicIp);
  const auth = useSelector((state: IRootState) => state.auth);
  const dispatch = useDispatch();
  const { REACT_APP_COMPANY } = process.env;

  const onClick = () => {
    const { link } = bannerData;
    if (link) {
      window.location.href = link;
    }
  };

  const onClose = async (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    // when user closes alert, hash the text of the alert and add it to localStorage
    const hash = await getHashValue(bannerData);
    if (hash) {
      localStorage.setItem(localStorageKeys.hideAlertInfoBanner, hash);
    }

    setBannerData(null);
    dispatch(onInfoBanner(false));
  };

  useEffect(() => {
    const getBannerData = async () => {
      let companyTag = REACT_APP_COMPANY;

      if (companyTag === 'HX') {
        companyTag = 'HRX';
      } else if (!companyTag) {
        companyTag = 'HRG';
      }

      const res = await axios.get<IAlertBannerData>(
        `${process.env.REACT_APP_API_BASE_URL}AlertInfoBanner`,
        {
          headers: { 'x-fallback-ip': publicIp },
          params: { tag: companyTag.toLowerCase() },
        }
      );

      const hash = await getHashValue(res.data);
      const localStorageHash = localStorage.getItem(
        localStorageKeys.hideAlertInfoBanner
      );

      if (hash && localStorageHash !== hash) {
        // before showing alert, we need to check that the user hasn't already closed it
        // only show banner if hashes are different
        setBannerData(res.data);
        dispatch(onInfoBanner(true));
      }
    };

    getBannerData();
  }, [publicIp, auth, REACT_APP_COMPANY, dispatch]);

  return (
    <>
      {bannerData && (
        <AlertBanner
          type={bannerData.type}
          onClose={onClose}
          onClick={onClick}
          style={{ cursor: bannerData.link ? 'pointer' : 'default' }}
        >
          {bannerData.formatedText ? (
            <ContentfulRenderer>{bannerData.formatedText}</ContentfulRenderer>
          ) : (
            bannerData.text
          )}
        </AlertBanner>
      )}
    </>
  );
};
