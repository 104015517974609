import { IBrandTypography } from '../interfaces/IBrandTypography';

const coastalTheme: IBrandTypography = {
  h0: {
    family: 'Austin Text',
    size: {
      desktop: 52,
      tablet: 36,
      mobile: 30,
    },
    weight: 600,
    lineHeight: 1.2,
  },
  h1: {
    family: 'Austin Text',
    size: {
      desktop: 52,
      tablet: 36,
      mobile: 30,
    },
    weight: 600,
    lineHeight: 1.2,
  },
  h2: {
    family: 'Austin Text',
    size: {
      desktop: 40,
      tablet: 28,
      mobile: 26,
    },
    weight: 600,
    lineHeight: 1.2,
  },
  h3: {
    family: 'Austin Text',
    size: {
      desktop: 24,
      tablet: 22,
      mobile: 22,
    },
    weight: 600,
    lineHeight: 1.2,
  },
  h4: {
    family: 'Austin Text',
    size: {
      desktop: 18,
      tablet: 18,
      mobile: 18,
    },
    weight: 600,
    lineHeight: 1.2,
  },
  body: {
    family: 'Austin Text',
    size: {
      desktop: 18,
      tablet: 18,
      mobile: 18,
    },
    weight: 500,
    lineHeight: 1.5,
  },
  info: {
    family: 'Austin Text',
    size: {
      desktop: 16,
      tablet: 16,
      mobile: 16,
    },
    weight: 500,
    lineHeight: 1.5,
  },
  subtitle: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 18,
      tablet: 16,
      mobile: 16,
    },
    weight: 400,
    lineHeight: 1.5,
  },
  label: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 16,
      tablet: 14,
      mobile: 14,
    },
    weight: 400,
    lineHeight: 1.5,
  },
  button: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 16,
      tablet: 16,
      mobile: 16,
    },
    weight: 500,
    lineHeight: 1.4,
  },
  caption: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 12,
      tablet: 12,
      mobile: 12,
    },
    weight: 400,
    lineHeight: 1.5,
  },
  overline: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 14,
      tablet: 12,
      mobile: 12,
    },
    weight: 400,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  overlineBold: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 14,
      tablet: 12,
      mobile: 12,
    },
    weight: 500,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  tag: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 14,
      tablet: 12,
      mobile: 12,
    },
    weight: 400,
    lineHeight: 1.5,
  },
  tabular: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 16,
      tablet: 16,
      mobile: 14,
    },
    weight: 400,
    lineHeight: 1.5,
  },
  tabularHeader: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 16,
      tablet: 16,
      mobile: 14,
    },
    weight: 700,
    lineHeight: 1.5,
  },
};

export default coastalTheme;
