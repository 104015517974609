import { LinkText } from 'components';
import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import { typography } from 'style/typography';
import styled from 'styled-components';

const StyledIcon = styled(Icon)`
  cursor: pointer;
  padding-top: 4px;
`;

const ButtonLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: center;
  margin-bottom: 15px;
`;
const StyledLinkText = styled((props) => <LinkText {...props} />)`
  text-decoration: none;
  margin-left: 6px;
  font-weight: 500;
  font-size: ${typography.normal.fontSize.small};
  font-family: ${typography.normal.fontFamily};
  color: ${colors.shared.textBlack};
  background: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;
const EmailLink = ({
  onClick,
  buttonText,
}: {
  onClick: () => void;
  buttonText: string;
}) => (
  <ButtonLink data-testid="link-button-email">
    <StyledIcon iconType="mail" onClick={onClick} />
    <StyledLinkText onClick={onClick}>{buttonText}</StyledLinkText>
  </ButtonLink>
);

export default EmailLink;
