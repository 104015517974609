import clsx from 'clsx';
import { LabelText } from 'components/UI/Brand/text';
import loading from 'assets/images/loading_whitebackground.gif';
import loadingGrey from 'assets/images/loading_icons_gray_background.gif';
import translate from 'translate';
import styles from './styles.module.scss';

interface ILoadingGifProps {
  text?: string;
  isGrey?: boolean;
  scroll?: boolean;
  dataTestid?: string;
}

const { REACT_APP_COMPANY } = process.env;

const LoadingGif = ({
  text = translate('General_LoadingData'),
  isGrey = false,
  scroll = true,
  dataTestid,
}: ILoadingGifProps) => {
  if (scroll) window.scrollTo(0, 0);
  return (
    <div className={styles.loadingGif} data-testid={dataTestid}>
      <img
        className={styles.image}
        src={isGrey ? loadingGrey : loading}
        alt={translate('General_LoadingData')}
      />
      {text && (
        <LabelText
          className={clsx(styles.text, {
            [styles.textGrey]: isGrey,
            [styles.hx]: REACT_APP_COMPANY === 'HX',
          })}
        >
          {text}
        </LabelText>
      )}
    </div>
  );
};

export default LoadingGif;
