import styled from 'styled-components';
import translate from 'translate';
import { CloseModalIcon } from 'components';
import { useNavigate } from 'react-router-dom';
import { colors } from 'style/colors';
import { PrimaryButton } from 'components/UI/Buttons/PrimaryButton/PrimaryButton';

const Title = styled.h3`
  font-family: 'Atlas Grotesk Medium';
  font-size: 24px;
  color: ${colors.shared.black};
`;

const Message = styled.span`
  font-family: 'Atlas Grotesk Regular';
  font-size: 14px;
  color: ${colors.shared.warmGray6};
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const Header = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TimesupModal = ({ closeModal }: { closeModal: () => void }) => {
  const navigate = useNavigate();
  const onNewSearch = () => {
    navigate('/');
    closeModal();
  };

  return (
    <Container>
      <Header>
        <Title>{translate('TimesupModal_Title')}</Title>
        <CloseModalIcon onClose={closeModal} />
      </Header>
      <Message>{translate('TimesupModal_Message')}</Message>
      <PrimaryButton onClick={onNewSearch}>
        {translate('TimesupModal_Btn')}
      </PrimaryButton>
    </Container>
  );
};

export default TimesupModal;
