import { handleActions } from 'redux-actions';
import * as actions from '../actions/training';

const initialState = {
  trainingData: {
    benefits: [],
    media: [],
    introduction: '',
    title: '',
    moduleContainers: [],
  },
  completedModules: [],
  currentModule: null,
  currentPage: null,
  error: null,
  loading: false,
  trainingsCompleted: false,
  trainingIsFetched: false,
  visitedPage: null,
};

const reducer = handleActions(
  {
    [actions.fetchTrainingDataStart]: () => ({
      ...initialState,
      loading: true,
    }),
    [actions.fetchTrainingDataSuccess]: (
      state,
      { payload: { trainingData, completedModules } }
    ) => {
      const $trainingData = {
        ...trainingData,
        moduleContainers: trainingData.moduleContainers.reduce(
          (acc, moduleContainer) => {
            const [moduleName, title] = moduleContainer.title.split(' - ');
            acc.push({
              ...moduleContainer,
              moduleName,
              title,
              description:
                moduleContainer?.trainingModules?.[0]
                  ?.trainingModuleSections?.[0]?.description,
              image:
                moduleContainer?.trainingModules?.[0]
                  ?.trainingModuleSections?.[0]?.media,
              isCompleted: completedModules.includes(moduleContainer.id),
            });

            return acc;
          },
          []
        ),
      };

      const trainingsCompleted = $trainingData.moduleContainers.every(
        ({ isCompleted }) => isCompleted
      );

      return {
        ...state,
        trainingData: $trainingData,
        completedModules,
        error: null,
        loading: false,
        trainingsCompleted,
        trainingIsFetched: true,
      };
    },
    [actions.fetchTrainingDataFailed]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
      trainingIsFetched: true,
    }),
    [actions.fetchCompletedModulesFailed]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
    }),
    [actions.setNextPage]: (state) => ({
      ...state,
      currentPage: state.currentPage + 1,
      visitedPage: state.currentPage + 1,
    }),
    [actions.setPreviousPage]: (state) => ({
      ...state,
      currentPage: state.currentPage - 1,
    }),
    [actions.setPreviousPageById]: (state, action) => ({
      ...state,
      currentPage: action.payload.pageId,
    }),
    [actions.setResetPage]: (state) => ({
      ...state,
      currentPage: 0,
      visitedPage: 0,
    }),
    [actions.setCurrentModule]: (state, { payload }) => ({
      ...state,
      currentModule: payload,
      currentPage: 0,
      visitedPage: 0,
    }),
    [actions.setModuleCompleted]: (state, { payload }) => ({
      ...state,
      completedModules: [...state.completedModules, payload],
    }),
    [actions.setModuleIncompleted]: (state, { payload }) => ({
      ...state,
      completedModules: state.completedModules.filter((id) => id !== payload),
    }),
  },
  initialState
);

export default reducer;
