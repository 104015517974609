import { Header3 } from 'components/UI/Brand/text';
import { IImageUrl } from 'interfaces/IImage';
import styled from 'styled-components';
import { CloseModalIcon, CustomCarousel } from 'components';

const Title = styled(Header3)`
  margin-bottom: 24px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

interface IImageData {
  title: string;
  imageData: IImageUrl[];
}

interface IImageModal {
  onClose: () => void;
  data: IImageData;
}

const ImageModal = ({ onClose, data }: IImageModal) => (
  <div id="image_modal">
    <HeaderContainer>
      <Title>{data.title}</Title>
      <CloseModalIcon onClose={onClose} width={16} height={16} />
    </HeaderContainer>
    <CustomCarousel imageData={data.imageData} />
  </div>
);

export default ImageModal;
