import { handleActions, ReduxCompatibleReducer } from 'redux-actions';
import { ITourNamesState } from 'interfaces/ITour';
import {
  fetchTourNamesStart,
  fetchTourNamesSuccess,
  fetchTourNamesFailed,
} from '../actions/tourNames';

const initialState: ITourNamesState = {
  tourNames: [],
  loading: false,
  error: false,
};

const reducer: ReduxCompatibleReducer<ITourNamesState, ITourNamesState> =
  handleActions(
    {
      [fetchTourNamesStart.toString()]: (state) => ({
        ...state,
        loading: true,
        error: false,
      }),
      [fetchTourNamesSuccess.toString()]: (state, action) => ({
        ...state,
        tourNames: action.payload.tourNames,
        loading: false,
      }),
      [fetchTourNamesFailed.toString()]: (state) => ({
        ...state,
        error: true,
        loading: false,
      }),
    },
    initialState
  );

export default reducer;
