import { StyleHTMLAttributes } from 'react';
import styled from 'styled-components';
import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import { typography } from 'style/typography';

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Label = styled.label`
  font-family: ${typography.normal.fontFamily};
  font-size: 16px;
  line-height: 1.2;
  color: ${colors.shared.textBlack};
  margin: 0;
  @media (max-width: 1024px) {
    font-size: 14px;
    line-height: 1.2;
  }
`;

const Button = styled.button`
  color: ${colors.shared.black};
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 10px 0;
  &:hover:not([disabled]) {
    outline: none !important;
    svg {
      color: ${colors.hrx.volcanoBlack8};
    }
  }
  &:focus {
    outline: none !important;
    svg {
      color: ${colors.hrx.volcanoBlack8};
    }
  }
`;

const Row = styled.div`
  display: flex;
  margin-bottom: 0;
  margin-right: 20px;
  align-items: center;
`;

const Input = styled.input`
  font-size: ${typography.normal.fontSize.normal};
  line-height: ${typography.normal.lineHeight.normal};
  font-family: ${typography.normal.fontFamily};
  border: none;
  border-radius: 4px;
  background: ${colors.hrx.volcanoBlack3};
  box-sizing: border-box;
  text-align: center;
  padding: 0;
  color: black;
  line-height: 35px;
  margin: 5px 0;
  width: 29px;
  height: 30px;
  &:focus {
    outline: none !important;
    box-shadow: 0 0 0 2px cornflowerblue;
  }
`;

const onMinusClick =
  (onChange: (field: string, value: string) => void, min: number) =>
  (field: string, value: string) => {
    const valueAsNumber = Number.isNaN(parseInt(value, 10))
      ? 0
      : parseInt(value, 10);
    if (valueAsNumber > min) {
      onChange(field, `${valueAsNumber - 1}`);
    } else {
      onChange(field, `${valueAsNumber}`);
    }
  };

const onPlusClick =
  (onChange: (field: string, value: string) => void, max: number) =>
  (field: string, value: string) => {
    const valueAsNumber = Number.isNaN(parseInt(value, 10))
      ? 0
      : parseInt(value, 10);
    if (valueAsNumber < max) {
      onChange(field, `${valueAsNumber + 1}`);
    } else {
      onChange(field, `${valueAsNumber}`);
    }
  };

export interface IncrementableInputProps {
  className?: string;
  style?: StyleHTMLAttributes<HTMLDivElement>;
  handleClick: (field: string, value: string) => void;
  handleChange: (value: unknown) => void;
  value: string;
  name: string;
  label: string;
  hasError?: boolean;
  min?: number;
  max?: number;
  isDisabled?: boolean;
}

const IncrementableInput = ({
  className,
  style,
  handleClick,
  handleChange,
  value,
  name,
  label,
  hasError = false,
  min = 0,
  max = 4,
  isDisabled,
}: IncrementableInputProps) => (
  <InputContainer aria-invalid={hasError} className={className} style={style}>
    <Row data-testid="incrementableInput-row">
      <Button
        type="button"
        onClick={() => onMinusClick(handleClick, min)(name, value)}
        aria-label="-"
        style={{
          paddingRight: 8,
        }}
        disabled={value === '0' || value === `${min}` || isDisabled}
      >
        <Icon
          iconType={
            value === '0' || value === `${min}` || isDisabled
              ? 'filledCircleWithMinus'
              : 'filledCircleWithMinusInverted'
          }
        />
      </Button>
      <Input
        name={name}
        value={value}
        onChange={handleChange}
        aria-label={label}
      />
      <Button
        type="button"
        onClick={() => onPlusClick(handleClick, max)(name, value)}
        aria-label="+"
        style={{
          paddingLeft: 8,
          paddingRight: 20,
        }}
        disabled={value === `${max}` || isDisabled}
      >
        <Icon
          iconType={
            value === `${max}` || isDisabled
              ? 'filledCircleWithPlus'
              : 'filledCircleWithPlusInverted'
          }
        />
      </Button>
      <Label>{label}</Label>
    </Row>
  </InputContainer>
);

export default IncrementableInput;
