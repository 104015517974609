import { format } from 'date-fns';
import { colors } from 'style/colors';
import translate from 'translate';
import { getCalendarLocale } from 'utils/dates';
import { getFullDateFormat } from 'utils/multilanguage-support';

interface IGetStatusTextProps {
  depositAmount: number;
  totalPaymentsReceived: number;
  balance: number;
  status: string;
  departureDate: string;
  daysLeft: number | null;
  payDate: string;
  language: string;
}

export const getStatusTextInfo = ({
  depositAmount,
  totalPaymentsReceived,
  balance,
  status,
  departureDate,
  daysLeft,
  payDate,
  language,
}: IGetStatusTextProps) => {
  const locale = getCalendarLocale(language);
  const isDepositPending =
    depositAmount > 0 && totalPaymentsReceived < depositAmount;
  const isPartlyPaid =
    balance > 0 && depositAmount > 0 && totalPaymentsReceived >= depositAmount;
  const isFullyPaid = balance <= 0 && new Date() < new Date(departureDate);
  const isCancelled = status === 'Cancelled';
  const isSailed = balance <= 0 && new Date() > new Date(departureDate);
  const isPaymentPending = balance > 0 && depositAmount === 0;
  const isError =
    !isDepositPending &&
    !isPartlyPaid &&
    !isFullyPaid &&
    !isCancelled &&
    !isSailed &&
    !isPaymentPending;

  const getStatusText = () => {
    if (!isCancelled) {
      if (
        (isDepositPending || isPartlyPaid) &&
        daysLeft !== null &&
        daysLeft <= 0 &&
        !isFullyPaid
      ) {
        return translate('MyBookings_PaymentDeadlinePassed');
      }
      if (isDepositPending && daysLeft !== null && daysLeft > 0) {
        return translate('MyBookings_DaysLeftToPayDeposit', daysLeft);
      }
      if (isPartlyPaid && daysLeft !== null && daysLeft > 0) {
        return translate('MyBookings_DaysLeftToPayRemaining', daysLeft);
      }
      if (isFullyPaid && payDate) {
        return translate(
          'MyBookings_PaidOn',
          format(payDate, getFullDateFormat(), { locale })
        );
      }
    }
    return null;
  };

  const statusText = getStatusText();

  const getTextColor = () => {
    if (isCancelled || isError) return colors.shared.warmGray5;
    if (isSailed) return colors.hrn.clearBlue1;
    if (isFullyPaid) return colors.b2b.clearGreen;
    if (isPartlyPaid || (!isFullyPaid && daysLeft >= 14))
      return colors.b2b.clearBlue;
    if (isDepositPending) return colors.shared.midnightYellow5;
    return colors.shared.white;
  };

  const textColor = getTextColor();

  return {
    statusText,
    textColor,
  };
};
