import localStorageKeys from './localStorageKeys';

export const getToken = () => {
  const idToken = localStorage.getItem(localStorageKeys.idToken);
  return idToken || null;
};

export const hasValidToken = () => {
  const token = getToken();
  if (!token) return false;
  const expirationDate = localStorage.getItem(localStorageKeys.expirationDate);
  if (!expirationDate) return false;
  const currentDate = new Date().getTime() / 1000;
  return currentDate < +expirationDate;
};
