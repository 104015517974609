import { useState } from 'react';
import { Link } from 'react-router-dom';
import { BodyText, Header4, LabelText } from 'components/UI/Brand/text';
import FocalPointImage from 'components/Header/FocalPointImage';
import { INews } from 'interfaces/INewsState';
import { FROM_TABLET } from 'style/sizes';
import styled from 'styled-components';
import { CustomImage } from 'components';
import { colors } from 'style/colors';

interface IRoot {
  'aria-labelledby': string;
  hasItemsBorder: boolean;
}

const Root = styled.li<IRoot>`
  width: 100%;
  background-color: ${colors.shared.white};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  box-sizing: border-box;
  border: ${({ hasItemsBorder }) =>
    hasItemsBorder ? `solid 1px ${colors.shared.warmGray2}` : ''};
  cursor: pointer;
  ${FROM_TABLET} {
    margin-bottom: 15px;
    width: 365px;
  }
`;
const ImageFill = styled.div`
  height: 200px;
  background: ${colors.shared.warmGray2};
  ${FROM_TABLET} {
    width: 100%;
  }
`;
const Title = styled(Header4)`
  padding: 0;
`;

const LinkStyle = styled(Link)`
  all: unset;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px;
  gap: 16px;
  height: 100%;
`;

const Text = styled(BodyText)`
  opacity: 0.9;
  color: ${colors.shared.textBlack};
`;

const ImageContainer = styled.div`
  position: relative;
`;

const OfferType = styled(LabelText)`
  border-radius: 12px;
  display: inline;
  background-color: ${colors.shared.midnightYellow3};
  text-align: center;
  color: ${colors.shared.warmBrown};
  position: absolute;
  top: 8px;
  left: 8px;
  padding: 8px 11px;
  font-weight: 500;
  text-transform: uppercase;
  font-size: 12px;
`;

export interface INewsCardProps
  extends Pick<INews, 'id' | 'title' | 'intro' | 'image'> {
  link: string;
  labelledby: string;
  hasItemsBorder: boolean;
  imageURL?: string;
  badge?: string;
  onTrackPromotion?: () => void;
}

const NewsCard = ({
  link,
  labelledby,
  title,
  intro,
  image,
  hasItemsBorder = false,
  imageURL,
  badge,
  onTrackPromotion,
}: INewsCardProps) => {
  const [hover, setHover] = useState(false);
  const handleOnClick = () => {
    if (badge) {
      onTrackPromotion();
    }
  };

  return (
    <Root aria-labelledby={labelledby} hasItemsBorder={hasItemsBorder}>
      <LinkStyle
        to={link}
        onMouseOver={() => setHover(true)}
        onFocus={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={handleOnClick}
      >
        {image && (
          <FocalPointImage
            dataTestid="newscard-image"
            image={image?.image}
            focalPoint={image?.focalPoint}
            title={image?.title}
            isPresentation
            height={200}
          />
        )}
        {imageURL && (
          <ImageContainer>
            <CustomImage
              dataTestid="newscard-image"
              width={732}
              height={414}
              src={imageURL}
              alt={title}
            />
            {badge && <OfferType>{badge}</OfferType>}
          </ImageContainer>
        )}
        {!image && !imageURL && <ImageFill data-testid="newscard-image-fill" />}
        <TextContainer>
          <Title
            data-testid="newscard-title"
            style={{ textDecorationLine: hover && 'underline' }}
          >
            {title}
          </Title>
          {intro && <Text data-testid="newscard-introtext">{intro}</Text>}
        </TextContainer>
      </LinkStyle>
    </Root>
  );
};

export default NewsCard;
