import { IAgencyState } from 'interfaces/IAgencyInfo';
import { handleActions } from 'redux-actions';
import {
  fetchAgencyFailed,
  fetchAgencyStart,
  fetchAgencySuccess,
} from '../actions/agency';

const initialState: IAgencyState = {
  agency: null,
  error: null,
  loading: false,
};

const reducer = handleActions<IAgencyState, IAgencyState>(
  {
    [fetchAgencyStart.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchAgencySuccess.toString()]: (state, action) => ({
      ...state,
      agency: action.payload.agency,
      loading: false,
    }),
    [fetchAgencyFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
    }),
  },
  initialState
);

export default reducer;
