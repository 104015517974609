import { SVGAttributes } from 'react';

const FilledCircleWithMinus = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="12" cy="12" r="12" fill="#F6F6F6" />
    <path d="M10.34 12.982H14.232V11.848H10.34V12.982Z" fill="black" />
  </svg>
);

export default FilledCircleWithMinus;
