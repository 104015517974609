import { SVGProps } from 'react';

const UserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={21}
    viewBox="0 0 21 21"
    {...props}
  >
    <path
      fill="#000"
      d="m5.115 21-.012-.001L.5 21c-.276 0-.5-.22-.5-.5 0-4.394 2.721-8.68 6.402-10.396A5.881 5.881 0 0 1 10.5 0a5.881 5.881 0 0 1 4.092 10.112l-.129-.062C18.144 11.74 21 16.09 21 20.5c0 .28-.224.5-.5.5h-4.615l-.011-.001H5.126L5.115 21Zm8.703-10.256-.114.076c-.922.6-2.022.95-3.204.95a5.852 5.852 0 0 1-3.32-1.027.506.506 0 0 1-.231.217C3.716 12.4 1.212 16.1 1.013 20l3.601-.001L4.615 18c0-.28.224-.5.5-.5s.5.22.5.5l-.001 1.999h9.771V18c0-.28.224-.5.5-.5s.5.22.5.5v1.999l3.602.001c-.206-3.85-2.76-7.58-5.942-9.05a.482.482 0 0 1-.227-.206ZM10.5 1a4.886 4.886 0 0 0 0 9.77 4.886 4.886 0 0 0 0-9.77Z"
      fillRule="evenodd"
    />
  </svg>
);

export default UserIcon;
