export default {
  EN_APAC: 'EN-APAC',
  EN_AMERICAS: 'EN-AMERICAS',
  EN_UK: 'en-GB',
  EN_EMEA: 'en-EU',
  en: 'en',
  de: 'de-DE',
  DE_CH: 'de-CH',
  FR: 'fr-FR',
  NO: 'nb-NO',
  SE: 'sv-SE',
  DK: 'da-DK',
  AT: 'de-AT',
};
