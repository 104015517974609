import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ISearchMappedResult, ISearchState } from 'interfaces/ISearch';
import { ITour } from 'interfaces/ITour';
import {
  COASTAL_OPTIONS,
  DEFAULT_CABIN_SELECTOR_VALUE,
  getThirtyUnixMinutesFromNow,
  SEARCH_TYPE,
} from 'utils/constants';
import { ICabin } from 'interfaces/ICabin';
import getRandomId from 'utils/getRandomId';
import { IDetailedVoyage } from 'interfaces/IVoyage';
import {
  getCoastalPackageCodes,
  getCoastalResults,
  getExpeditionResults,
  getPackageList,
  getSelectedCabinDetails,
} from 'store/services/search';
import { track } from 'utils/analytics';
import {
  deletePackage,
  quoteCabin,
  quoteDeckSpace,
  quoteExtras,
  quotePackages,
  quotePassengers,
} from 'store/services/quote';
import { updateVoyageExpireDate } from '../bookings/utils/updateVoyageExpireDate';

const initialResultsState: ISearchMappedResult = {
  cabins: null,
  tours: [],
};

export const initialState: ISearchState = {
  params: null,
  results: initialResultsState,
  searchType: null,
  isDeckSearch: false,
  packageCodes: [],
  packageDates: null,
  packageDateType: null,
  packageCodesLoading: false,
  showHomePortDepartures: false,
  cabinData: {
    cabinCategories: [],
    decks: [],
    passengers: [],
  },
  tourData: null,
  voyageData: null,
  isShowAccessibleCabins: false,
  cabins: [DEFAULT_CABIN_SELECTOR_VALUE],
  promotionCode: '',
  expireDate: getThirtyUnixMinutesFromNow(),
  coastalItinerary: null,
  coastalVoyage: COASTAL_OPTIONS.CLASSIC_VOYAGE,
};

const searchSlice = createSlice({
  name: 'search',
  initialState,
  reducers: {
    setVoyageData: (state, action: PayloadAction<IDetailedVoyage>) => {
      state.voyageData = action.payload;
    },
    setTourData: (state, action: PayloadAction<ITour>) => {
      state.tourData = action.payload;
    },
    setSearchType: (
      state,
      action: PayloadAction<{ searchType: SEARCH_TYPE }>
    ) => {
      let cabins = [...state.cabins];
      if (action.payload.searchType !== state.searchType) {
        cabins = [DEFAULT_CABIN_SELECTOR_VALUE];
      }
      state.searchType = action.payload.searchType;
      state.cabins = cabins;
    },
    setIsDeckSearch: (state, action: PayloadAction<boolean>) => {
      state.isDeckSearch = action.payload;
    },
    setShowAccessibleCabins: (state, action: PayloadAction<boolean>) => {
      state.isShowAccessibleCabins = action.payload;
    },
    setShowHomePortDepartures: (state, action: PayloadAction<boolean>) => {
      state.showHomePortDepartures = action.payload;
    },
    resetSearch: (state) => ({ ...initialState, searchType: state.searchType }),
    resetSearchParams: (state) => {
      state.params = null;
    },
    updateCabinToSearch: (
      state,
      action: PayloadAction<{ cabin: ICabin; isForceUpdate: boolean }>
    ) => {
      const updateCabin = action.payload.cabin;
      if (action.payload.isForceUpdate) {
        state.cabins = [updateCabin];
      } else {
        const cabins = state.cabins.map((cabin) => {
          if (cabin.id === updateCabin.id) return updateCabin;
          return { ...cabin };
        });
        state.cabins = cabins;
      }
    },
    addCabinToSearch: (state) => {
      state.cabins = [
        ...state.cabins,
        { ...DEFAULT_CABIN_SELECTOR_VALUE, adults: 1, id: getRandomId() },
      ];
    },
    removeCabinFromSearch: (state, action: PayloadAction<string>) => {
      const { cabins } = state;
      const cabinIndex = cabins.findIndex(
        (cabin) => cabin.id === action.payload
      );

      const updatedCabinList = [...cabins];
      updatedCabinList.splice(cabinIndex, 1);
      state.cabins = updatedCabinList;
    },
    setPromotionCode: (state, action: PayloadAction<string>) => {
      state.promotionCode = action.payload;
    },
    setTours: (state, action: PayloadAction<ITour[]>) => {
      state.results.tours = action.payload;
    },
    extendExpireDate: (state, action: PayloadAction<number>) => {
      state.expireDate = action.payload;
    },
    setCoastalItinerary: (state, action: PayloadAction<string>) => {
      state.coastalItinerary = action.payload;
    },
    setCoastalVoyage: (state, action: PayloadAction<COASTAL_OPTIONS>) => {
      state.coastalVoyage = action.payload;
    },
    resetPackageStartDay: (state) => {
      state.packageDates = null;
      state.packageDateType = null;
      state.packageCodes = [];
    },
    resetVehicles: (state) => {
      if (state.params !== null) {
        state.params = {
          ...state.params,
          vehicles: [],
        };
      }
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getCoastalResults.matchPending, (state, action) => {
      const { keepPreviousResults, searchParams } =
        action.meta.arg.originalArgs;

      state.searchType = SEARCH_TYPE.COASTAL;

      if (!keepPreviousResults) {
        state.results = initialResultsState;
      }

      state.params = { ...searchParams };
    });
    builder.addMatcher(getCoastalResults.matchFulfilled, (state, action) => {
      const { keepPreviousResults } = action.meta.arg.originalArgs;
      const { tours } = action.payload;
      let allTours: ITour[];

      if (keepPreviousResults && !state.results.tours.length && !tours.length) {
        allTours = state.results.tours.concat(tours);
      } else {
        allTours = tours;
      }
      state.results = { ...action.payload, tours: allTours };
      state.cabinData = {
        ...state.cabinData,
        cabinCategories: tours.length ? tours[0].cabinCategories : [],
      };
      state.expireDate = getThirtyUnixMinutesFromNow();
    });
    builder.addMatcher(getExpeditionResults.matchPending, (state, action) => {
      state.results = initialResultsState;
      state.searchType = SEARCH_TYPE.EXPEDITION;
      state.params = { ...action.meta.arg.originalArgs, deckSpace: null };
    });
    builder.addMatcher(getExpeditionResults.matchFulfilled, (state, action) => {
      const { tours, destination } = action.payload;
      const searchParams = action.meta.arg.originalArgs;
      state.cabinData = {
        ...state.cabinData,
        cabinCategories: tours.length ? tours[0].cabinCategories : [],
      };
      state.results = action.payload;

      if (tours && Array.isArray(tours) && tours.length === 0) {
        track('Search cruises no results', {
          destination: destination || '',
          dateFrom:
            searchParams &&
            searchParams.startDate &&
            new Date(searchParams.startDate * 1000),
          dateTo:
            searchParams &&
            searchParams.endDate &&
            new Date(searchParams.endDate * 1000),
          cabins: searchParams && searchParams.cabins,
        });
      }
      state.expireDate = getThirtyUnixMinutesFromNow();
    });
    builder.addMatcher(
      getSelectedCabinDetails.matchFulfilled,
      (state, action) => {
        state.cabinData = action.payload;
      }
    );
    builder.addMatcher(
      getCoastalPackageCodes.matchFulfilled,
      (state, action) => {
        state.packageCodes = action.payload;
      }
    );
    builder.addMatcher(getPackageList.matchFulfilled, (state, action) => {
      state.packageDates = action.payload?.dates
        ?.filter((date) => new Date(date) >= new Date())
        ?.sort((a, b) => a.localeCompare(b));

      state.packageDateType = action.payload?.type;
    });
    // when any of the following actions are fulfilled, update the expire date of the current voyage/"package"
    builder
      .addMatcher(quoteCabin.matchFulfilled, (state, action) => {
        state.results.tours = updateVoyageExpireDate(
          action.payload.quoteId,
          state.results.tours
        );
      })
      .addMatcher(quoteDeckSpace.matchFulfilled, (state, action) => {
        state.results.tours = updateVoyageExpireDate(
          action.payload.quoteId,
          state.results.tours
        );
      })
      .addMatcher(deletePackage.matchFulfilled, (state, action) => {
        state.results.tours = updateVoyageExpireDate(
          action.payload.quoteId,
          state.results.tours
        );
      })
      .addMatcher(quotePackages.matchFulfilled, (state, action) => {
        state.results.tours = updateVoyageExpireDate(
          action.payload.quoteId,
          state.results.tours
        );
      })
      .addMatcher(quotePassengers.matchFulfilled, (state, action) => {
        state.results.tours = updateVoyageExpireDate(
          action.payload.quoteId,
          state.results.tours
        );
      })
      .addMatcher(quoteExtras.matchFulfilled, (state, action) => {
        state.results.tours = updateVoyageExpireDate(
          action.payload.quoteId,
          state.results.tours
        );
      });
  },
});

export const {
  resetSearch,
  setPromotionCode,
  addCabinToSearch,
  removeCabinFromSearch,
  updateCabinToSearch,
  setTourData,
  setVoyageData,
  setSearchType,
  setIsDeckSearch,
  setShowAccessibleCabins,
  setShowHomePortDepartures,
  setTours,
  extendExpireDate,
  resetVehicles,
  setCoastalItinerary,
  setCoastalVoyage,
  resetPackageStartDay,
  resetSearchParams,
} = searchSlice.actions;
export default searchSlice.reducer;
