import { ChangeEvent, useEffect, useState } from 'react';
import translate from 'translate';
import { fetchShips } from 'store/actions';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import { isUSMarket } from 'components/Search/CabinGuestSelection/utils/constants';
import { Label } from 'components/UI/Brand/text';
import Dropdown from 'components/UI/Select/Dropdown';

interface IShipDropdownProps {
  setShipCodes: (ships: string[]) => void;
}

export const ShipDropdown = ({ setShipCodes }: IShipDropdownProps) => {
  const [selectedShipCode, setSelectedShipCode] = useState('all');
  const [shipsDropdownOptions, setShipsDropdownOptions] = useState([]);
  const { shipsList, error, loading } = useAppSelector((state) => state.ships);
  const { params: previousSearchData } = useAppSelector(
    (state) => state.search
  );

  const onShipChange = (shipCode: string) => {
    setSelectedShipCode(shipCode);

    if (shipCode === 'all') {
      setShipCodes([]);
    } else {
      setShipCodes([shipCode]);
    }
  };

  useEffect(() => {
    if (!shipsList) return;

    const shipsOptions = shipsList
      .filter((ship) => (isUSMarket ? ship.shipCode !== 'VA' : true))
      .map(({ heading, shipCode }) => ({
        value: shipCode,
        description: heading,
      }));

    shipsOptions.unshift({
      value: 'all',
      description: loading
        ? translate('Coastal_Search_Ships_Loading')
        : translate('Coastal_Search_All_Ships'),
    });

    setShipsDropdownOptions(shipsOptions);
  }, [shipsList, loading]);

  const dispatch = useAppDispatch();
  useEffect(() => {
    if (shipsList.length === 0) {
      dispatch(fetchShips(true));
    }
  }, [shipsList?.length]);

  useEffect(() => {
    if (!previousSearchData?.shipCodes) return;
    setSelectedShipCode(previousSearchData.shipCodes[0]);
  }, [previousSearchData]);

  return (
    <>
      <Label htmlFor="ship-dropdown">
        {translate('Coastal_Search_Iconic_Ship_Label')}
      </Label>
      <Dropdown
        id="ship-dropdown"
        value={selectedShipCode}
        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
          onShipChange(event.currentTarget.value)
        }
        options={shipsDropdownOptions}
        disabled={loading || error}
      />
    </>
  );
};
