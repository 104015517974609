import { IDestinationDetails, IHomeDestination } from 'interfaces/IDestination';
import { api } from './api';

export const destinationsApi = api.injectEndpoints({
  endpoints: (build) => ({
    getDestinations: build.query<IHomeDestination[], void>({
      query: () => ({
        url: `destinations/destinations-home`,
      }),
    }),
    getCurrentDestination: build.query<
      IDestinationDetails,
      { destinationId: string }
    >({
      query: ({ destinationId }) => ({
        url: `Destinations/${destinationId}`,
      }),
    }),
  }),
});

export const { useGetDestinationsQuery, useGetCurrentDestinationQuery } =
  destinationsApi;

export const {
  endpoints: { getDestinations, getCurrentDestination },
} = destinationsApi;
