import { Header1, Subtitle } from 'components/UI/Brand/text';
import clsx from 'clsx';
import { ImageWithFocalPoint } from 'interfaces/IImage';
import { useCallback, useEffect, useRef, useState } from 'react';
import { BrandProvider, Tag } from 'components';
import { media } from 'style/sizes';
import translate from 'translate';
import { IVideo } from 'interfaces/IVideo';
import { useMediaQuery } from 'hooks/useMediaQuery';
import FocalPointImage from '../FocalPointImage';
import styles from './styles.module.scss';

interface ICampaignHeaderProps {
  focalPointImage?: ImageWithFocalPoint;
  isCoastal: boolean;
  title: string;
  subtitle: string;
  video?: IVideo;
}

const CampaignHeader = ({
  focalPointImage,
  isCoastal,
  title,
  subtitle,
  video,
}: ICampaignHeaderProps) => {
  const videoRef = useRef();
  const [videoClasses, setVideoClasses] = useState('');
  const hasVideo = video?.url && focalPointImage;
  const isMobile = useMediaQuery(media.maxTablet);

  const onVideoClick = useCallback(() => {
    const videoEl = videoRef.current as HTMLVideoElement;
    if (videoEl.paused) {
      setVideoClasses(styles.focalPointAssetContainerAppear);
      videoEl.play();
    } else {
      videoEl.pause();
      setVideoClasses(`${styles.focalPointAssetContainerAppear}`);
    }
  }, []);

  const listenEnterClick = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      onVideoClick();
    }
  }, []);

  useEffect(() => {
    setTimeout(() => {
      (videoRef?.current as HTMLVideoElement)?.play();
      setVideoClasses(styles.focalPointAssetContainerAppear);
    }, 500);
  }, []);

  useEffect(() => {
    document.addEventListener('keyup', listenEnterClick);
    return () => document.removeEventListener('keyup', listenEnterClick);
  }, []);

  return (
    <div className={styles.headerContainer}>
      <div className={styles.textContainer}>
        <Tag
          title={
            isCoastal
              ? translate('BookingSummary_CoastalExpress')
              : translate('BookingSummary_Expedition')
          }
          type="invertedvoyage"
          isCoastal={isCoastal}
        />
        <BrandProvider fixedBrand={isCoastal ? 'coastal' : 'expedition'}>
          <Header1 className={styles.title}>{title}</Header1>
        </BrandProvider>
        <Subtitle>{subtitle}</Subtitle>
      </div>
      <div className={styles.focalPointAssetContainer}>
        {hasVideo ? (
          <div
            className={clsx(
              styles.focalPointAssetContainer,
              styles.videoContainer
            )}
            style={{ backgroundImage: `url(${focalPointImage?.image?.url})` }}
          >
            <video
              ref={videoRef}
              className={`${styles.focalPointAssetContainerHidden} ${styles.video} ${videoClasses}`}
              tabIndex={0}
              muted
              loop
              data-lazy-src={video.url}
              aria-label={translate('General_VideoPause')}
              src={video.url}
              width={832}
              height={isMobile ? 193 : 468}
              onClick={onVideoClick}
            >
              <source src={video.url} />
            </video>
          </div>
        ) : (
          <FocalPointImage {...focalPointImage} isPresentation />
        )}
      </div>
    </div>
  );
};

export default CampaignHeader;
