import { ButtonHTMLAttributes, MouseEventHandler } from 'react';
import { Link } from 'react-router-dom';
import { typography } from 'style/typography';
import { colors } from 'style/colors';
import styled, { css } from 'styled-components';
import { media } from 'style/sizes';

type TButtonSize = 'normal' | 'small';

type ClickType =
  | ButtonHTMLAttributes<HTMLButtonElement>['onClick']
  | MouseEventHandler;

interface IPrimaryButton
  extends Omit<ButtonHTMLAttributes<HTMLButtonElement>, 'onClick'> {
  buttonColor?: string;
  size?: TButtonSize;
  classes?: string;
  selected?: boolean;
  isClickable?: boolean;
  disabled?: boolean;
  dataTestid?: string;
  to?: string;
  onClick?: ClickType;
}

interface IStyledProps extends IPrimaryButton {
  $isClickable?: boolean;
}

const baseButtonStyles = css<IStyledProps>`
  color: ${colors.shared.white};
  font-size: 16px;
  line-height: ${({ size }) =>
    size === 'normal'
      ? typography.normal.lineHeight.normal
      : typography.normal.lineHeight.small};
  font-family: ${typography.normal.fontFamily};
  font-weight: 500;
  background-color: ${process.env.REACT_APP_COMPANY === 'HX'
    ? colors.hrx.blue
    : colors.shared.hurtigrutenRed1};
  padding: 0px 4px;
  border: none;
  height: 44px;
  min-width: 172px;
  border-radius: 50px;
  text-align: center;
  cursor: ${({ $isClickable }) => ($isClickable ? 'auto' : 'pointer')};
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  cursor: ${({ selected }) => !selected && 'pointer'};
  &:hover:not([disabled]) {
    background-color: ${process.env.REACT_APP_COMPANY === 'HX'
      ? colors.hrx.blueDark
      : colors.hrn.redDark};
  }
  &:disabled {
    background: ${colors.hrx.volcanoBlack4};
    color: ${colors.shared.white};
    cursor: not-allowed;
  }
  @media ${media.maxTablet} {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const Button = styled.button<IStyledProps>`
  ${baseButtonStyles}
`;

const LinkWrapper = styled(Link)<IStyledProps>`
  ${baseButtonStyles}
`;

const ButtonChildren = styled.span`
  padding: 0;
  margin: 0;
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const PrimaryButton = ({
  size = 'normal',
  children,
  classes,
  type,
  disabled,
  onClick,
  isClickable = true,
  dataTestid,
  to,
  ...rest
}: IPrimaryButton) => {
  const buttonChildren = <ButtonChildren>{children}</ButtonChildren>;

  return to ? (
    <LinkWrapper
      to={to}
      data-testid={dataTestid}
      className={classes}
      size={size}
      $isClickable={isClickable}
      {...({
        onClick: onClick as MouseEventHandler,
      } as {
        onClick: MouseEventHandler;
      })}
    >
      {buttonChildren}
    </LinkWrapper>
  ) : (
    <Button
      data-testid={dataTestid}
      className={classes}
      type={type}
      size={size}
      onClick={onClick as ButtonHTMLAttributes<HTMLButtonElement>['onClick']}
      $isClickable={isClickable}
      disabled={disabled}
      {...rest}
    >
      {buttonChildren}
    </Button>
  );
};
