import { ICabin } from 'interfaces/ICabin';
import { AgeCategory } from 'interfaces/IPassenger';
import { add, getTime } from 'date-fns';
import getRandomId from './getRandomId';

export const FAILED = 'FAILED';
export const SUCCESS = 'SUCCESS';

export const COASTAL_DESTINATION_ID = '20927';
export const COASTAL_DESTINATION_LABEL = 'Norway';
export const VOYAGES_WITH_VEHICLES = ['20941', '20942'];

export const getThirtyUnixMinutesFromNow = () =>
  Math.floor(getTime(add(new Date(), { minutes: 30 })) / 1000);
export enum SEARCH_TYPE {
  COASTAL = 'COASTAL',
  EXPEDITION = 'EXPEDITION',
}

export const enum PACKAGE_DATES_TYPE {
  RANGE = 'Date range',
  SPECIFIC = 'Specific dates',
}

export enum COASTAL_OPTIONS {
  CLASSIC_VOYAGE = 'CLASSIC_VOYAGE',
  PACKAGE_VOYAGE = 'PACKAGE_VOYAGE',
  SVALBARD_EXPRESS = 'SVALBARD_EXPRESS',
  NORTH_CAPE_EXPRESS = 'NORTH_CAPE_EXPRESS',
  P2P = 'P2P',
  SPITSBERGEN_ADVENTURER = 'SPITSBERGEN_ADVENTURER',
  HURTIGRUTEN_HAMBURG = 'HURTIGRUTEN_HAMBURG',
}

export const isSearchTypeCoastal = (searchType: SEARCH_TYPE) =>
  searchType === SEARCH_TYPE.COASTAL;

export const INFANT_P2P = '0-3';
export const INFANT_REGULAR = '0-1';
export const CHILD_P2P = '4-15';
export const CHILD_REGULAR = '2-15';

export enum GUEST_TYPES {
  REGULAR = 'REGULAR',
  STUDENT = 'STUDENT',
  BENEFIT_FARE = 'BENEFIT FARE',
  MILITARY = 'MILITARY',
  LEDSAGER = 'LEDSAGER',
}
export const { REGULAR, STUDENT, BENEFIT_FARE, MILITARY, LEDSAGER } =
  GUEST_TYPES;

export const { child, adult, infant } = AgeCategory;

export const passengersByAge = [
  {
    translationKey: 'Adult',
    ageCategory: adult,
    guestType: REGULAR,
  },
  {
    translationKey: 'Child',
    ageCategory: child,
    guestType: REGULAR,
  },
  {
    translationKey: 'Infant',
    ageCategory: infant,
    guestType: REGULAR,
  },
  {
    translationKey: 'Student',
    ageCategory: adult,
    guestType: STUDENT,
  },
  {
    translationKey: 'BenefitFare',
    ageCategory: adult,
    guestType: BENEFIT_FARE,
  },
  {
    translationKey: 'Military',
    ageCategory: adult,
    guestType: MILITARY,
  },
  {
    translationKey: 'Ledsager',
    ageCategory: adult,
    guestType: LEDSAGER,
  },
];

export const MAX_PASSENGERS_CABIN = 4;
export const MAX_PASSENGERS_DECKSPACE = 9;
export const DEBOUNCE_TIME = 600;
export const DISCOUNT_DEPOSIT_AMOUNT = 500;
export const INCLUDED_CABINS = [
  'A2',
  'A3',
  'A4',
  'AJ',
  'D1',
  'D2',
  'D3',
  'D4',
  'E2',
  'F2',
  'FJ',
  'I1',
  'I2',
  'I2D',
  'I3',
  'I4',
  'J2',
  'J2D',
  'J3',
  'K2D',
  'K3',
  'K4D',
  'L1',
  'L2',
  'L3',
  'L4',
  'M2',
  'M4',
  'MA',
  'MB',
  'MC',
  'MD',
  'ME',
  'MF',
  'MG',
  'MX',
  'N1',
  'N2',
  'N3',
  'N4',
  'O2',
  'O2D',
  'O3',
  'P2',
  'P2D',
  'Q2',
  'Q3',
  'Q4',
  'QJ',
  'RR',
  'RS',
  'TT',
  'TY',
  'U1',
  'U2',
  'U2D',
  'U3',
  'XT',
  'XTD',
  'XTJ',
  'XY',
  'M3',
  'VM3',
  'VH',
  'FH',
  'EP',
  'EE',
  'EH',
  'DS',
];

export const NORDIC_MARKETS = ['NO', 'DK', 'SE'];
export const UNSPECIFIED_CABIN_NUMBER = 'unspecifiedCabinNumber';

export const DEFAULT_PASSENGERS = {
  adults: 2,
  children: 0,
  infants: 0,
  benefitFares: 0,
  companions: 0,
  militaries: 0,
  students: 0,
};
export const DEFAULT_CABIN_MAX_GUESTS = {
  maxAdults: MAX_PASSENGERS_CABIN,
  maxChildren: MAX_PASSENGERS_CABIN,
  maxInfants: MAX_PASSENGERS_CABIN,
  maxBenefitFares: MAX_PASSENGERS_CABIN,
  maxCompanions: MAX_PASSENGERS_CABIN,
  maxMilitaries: MAX_PASSENGERS_CABIN,
  maxStudents: MAX_PASSENGERS_CABIN,
};
export const DEFAULT_DECKSPACE_MAX_GUESTS = {
  adults: MAX_PASSENGERS_DECKSPACE,
  children: MAX_PASSENGERS_DECKSPACE,
  infants: MAX_PASSENGERS_DECKSPACE,
  benefitFares: MAX_PASSENGERS_DECKSPACE,
  companions: MAX_PASSENGERS_DECKSPACE,
  militaries: MAX_PASSENGERS_DECKSPACE,
  students: MAX_PASSENGERS_DECKSPACE,
};
export const DEFAULT_CABIN_SELECTOR_VALUE: ICabin = {
  ...DEFAULT_PASSENGERS,
  ...DEFAULT_CABIN_MAX_GUESTS,
  accessibility: false,
  id: getRandomId(),
};

export const BOOKINGS_LIST_LIMIT = 10;

export const WHITE_LIST_LINKS = [
  '/learn/article/about-us',
  '/learn/article/terms-and-conditions',
  '/learn/article/terms-and-conditions-nor',
  '/learn/article/terms-and-conditions-apac',
  '/learn/article/terms-and-conditions-uk',
  '/learn/article/allgemeine-geschäftsbedingungen',
  '/learn/article/terms-and-conditions-ch',
  '/learn/article/privacy-policy',
  '/learn/article/3AzMjzm2D7fOxu5eTbABbp',
  '/learn/article/5av2tYhXmmvW9p4BYpRDbB',
  '/learn/article/4D6IrC1besrRvZtImTEesh',
  '/learn/article/new-design',
  '/learn/article/sie-sind-uns-wichtig',
  '/learn/article/faqs-scheduled-maintenance',
  '/learn/article/maintenance',
];

export const LONG_AVAILABLE_SHORT_ROUTS = {
  Kirkenes: ['Svolvær', 'Tromsø'],
  Trondheim: ['Tromsø', 'Bergen'],
  Svolvær: ['Bergen', 'Kirkenes'],
  Tromsø: ['Kirkenes', 'Trondheim', 'Bergen'],
  Bergen: ['Tromsø', 'Trondheim', 'Svolvær'],
};

type TLONG_AVAILABLE_SHORT_ROUTS = {
  Kirkenes: string[];
  Trondheim: string[];
  Svolvær: string[];
  Tromsø: string[];
  Bergen: string[];
};
export type TPortNames = keyof TLONG_AVAILABLE_SHORT_ROUTS;

export const onboardingStep = {
  AgentRegistration: 'AgentRegistration',
  AgencyDetails: 'AgencyDetails',
};

export const CLASSICAL_VOYAGE_IDS = ['20940', '20941', '20942', '20943'];

export const TITLES = {
  'de-DE': [
    'Frau',
    'Frau Dr.',
    'Frau Dr. Dr.',
    'Frau Prof.',
    'Frau Prof. Dr.',
    'Frau Prof. Dr. Dr.',
    'Frau Dipl. Ing.',
    'Frau Gräfin',
    'Herr',
    'Herr Dr.',
    'Herr Dr. Dr.',
    'Herr Konsul',
    'Herr Prof.',
    'Herr Prof. Dr.',
    'Herr Prof. Dr. Dr.',
    'Herr Dipl.-Ing.',
    'Herr Graf',
  ],
  'de-AT': [
    'Frau',
    'Frau Dr.',
    'Frau Dr. Dr.',
    'Frau Prof.',
    'Frau Prof. Dr.',
    'Frau Prof. Dr. Dr.',
    'Frau Dipl. Ing.',
    'Frau Gräfin',
    'Herr',
    'Herr Dr.',
    'Herr Dr. Dr.',
    'Herr Konsul',
    'Herr Prof.',
    'Herr Prof. Dr.',
    'Herr Prof. Dr. Dr.',
    'Herr Dipl.-Ing.',
    'Herr Graf',
  ],
  en: ['Mr.', 'Mrs.', 'Ms.'],
};
type TTITLES = {
  'de-DE': string[];
  'de-AT': string[];
  en: string[];
};

export type TTitleLanguages = keyof TTITLES;
