import { ICabinBaseSelector } from 'interfaces/ICabin';
import { ILink } from 'interfaces/ILink';

const getValidatedStartDate = (startDate: string): string => {
  let validatedStartDate = startDate;

  const currentUnixTimestamp = new Date().getTime();
  const startUnixTimestamp = new Date(startDate).getTime();

  if (!startDate || startUnixTimestamp < currentUnixTimestamp) {
    validatedStartDate = new Date().toISOString();
  }

  return validatedStartDate;
};

export const getExpeditionQuery = (
  destination: string,
  endDate: string,
  startDate: string,
  voyageIds: string,
  cabins: ICabinBaseSelector[]
) => {
  const validatedStartDate = getValidatedStartDate(startDate);
  const params = new URLSearchParams({
    destination,
    endDate,
    startDate: validatedStartDate,
    voyageIds,
  });
  if (cabins && cabins.length > 0) {
    const cabinsToString = JSON.stringify(cabins);
    params.set('cabins', cabinsToString);
  }
  return `expedition?${params}`;
};

export const getCoastalQuery = (
  coastalType: string,
  startDate: string,
  voyageIds: string,
  fromPort?: string,
  toPort?: string,
  isViaKirkenes?: boolean,
  cabins?: ICabinBaseSelector[]
) => {
  const validatedStartDate = getValidatedStartDate(startDate);
  const params = new URLSearchParams({
    coastalType,
    startDate: validatedStartDate,
    voyageIds,
    fromPort,
    toPort,
    isViaKirkenes: isViaKirkenes?.toString(),
  });
  if (cabins?.length) {
    const cabinsToString = JSON.stringify(cabins);
    params.set('cabins', cabinsToString);
  }
  return `coastal?${params}`;
};

export const searchQueryBuilder = (link: ILink) => {
  const {
    cabins,
    coastalType,
    destination,
    endDate,
    searchType,
    startDate,
    voyageIds,
  } = link;

  const validatedStartDate = getValidatedStartDate(startDate);

  if (searchType === 'Coastal') {
    return getCoastalQuery(
      coastalType,
      validatedStartDate,
      voyageIds.join(',')
    );
  }

  if (searchType === 'Expedition' && !destination) {
    return null;
  }

  if (searchType === 'Expedition') {
    return getExpeditionQuery(
      destination,
      endDate,
      validatedStartDate,
      voyageIds.join(','),
      cabins
    );
  }

  return 'expedition';
};
