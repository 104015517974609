import { colors } from 'style/colors';
import { IButtonColors } from '../interfaces/IButtonColors';
import { coastalColors } from './coastalColors';
import { expeditionColors } from './expeditionColors';
import { groupColors } from './groupColors';

export const groupButtonColors: IButtonColors = {
  primaryButton: {
    regular: {
      default: {
        font: groupColors.white,
        background: groupColors.default.regular,
        border: groupColors.default.regular,
      },
      active: {
        font: groupColors.white,
        background: groupColors.default.dark,
        border: groupColors.default.dark,
      },
    },
    inverted: {
      default: {
        font: groupColors.white,
        background: groupColors.default.regular,
        border: groupColors.default.regular,
      },
      active: {
        font: groupColors.white,
        background: groupColors.default.dark,
        border: groupColors.default.dark,
      },
    },
  },
  secondaryButton: {
    regular: {
      default: {
        font: groupColors.default.regular,
        background: groupColors.white,
        border: groupColors.default.regular,
      },
      active: {
        font: groupColors.white,
        background: groupColors.default.regular,
        border: groupColors.default.regular,
      },
    },
    inverted: {
      default: {
        font: groupColors.default.regular,
        background: groupColors.white,
        border: groupColors.default.regular,
      },
      active: {
        font: groupColors.white,
        background: groupColors.default.regular,
        border: groupColors.default.regular,
      },
    },
  },
  datePicker: {
    selectedDate: {
      font: groupColors.white,
      background: groupColors.black.regular,
      border: groupColors.black.regular,
    },
  },
  formElements: {
    default: {
      font: groupColors.white,
      background: groupColors.black.regular,
    },
    disabled: {
      font: groupColors.black.regular,
      background: colors?.b2b.grey1,
    },
    active: {
      font: groupColors.white,
      background: colors?.hrx.volcanoBlack7,
    },
    hover: {
      font: groupColors.white,
      background: colors?.hrx.volcanoBlack9,
    },
  },
};

export const coastalButtonColors: IButtonColors = {
  primaryButton: {
    regular: {
      default: {
        font: coastalColors.white,
        background: coastalColors.default.regular,
        border: coastalColors.default.regular,
      },
      active: {
        font: coastalColors.white,
        background: coastalColors.default.dark,
        border: coastalColors.default.dark,
      },
    },
    inverted: {
      default: {
        font: coastalColors.white,
        background: coastalColors.default.regular,
        border: coastalColors.default.regular,
      },
      active: {
        font: coastalColors.white,
        background: coastalColors.default.dark,
        border: coastalColors.default.dark,
      },
    },
  },
  secondaryButton: {
    regular: {
      default: {
        font: coastalColors.default.regular,
        background: coastalColors.white,
        border: coastalColors.default.regular,
      },
      active: {
        font: coastalColors.white,
        background: coastalColors.default.regular,
        border: coastalColors.default.regular,
      },
    },
    inverted: {
      default: {
        font: coastalColors.default.regular,
        background: coastalColors.white,
        border: coastalColors.default.regular,
      },
      active: {
        font: coastalColors.white,
        background: coastalColors.default.regular,
        border: coastalColors.default.regular,
      },
    },
  },
  datePicker: {
    selectedDate: {
      font: coastalColors.white,
      background: coastalColors.menu.regular,
      border: coastalColors.menu.regular,
    },
  },
  formElements: {
    default: {
      font: coastalColors.white,
      background: colors?.hrn.navyBlue1,
    },
    disabled: {
      font: coastalColors.black.regular,
      background: colors.b2b.grey1,
    },
    active: {
      font: coastalColors.white,
      background: colors?.hrn.navyBlue5,
    },
    hover: {
      font: coastalColors.white,
      background: colors?.hrn.navyBlue2,
    },
  },
};

export const expeditionButtonColors: IButtonColors = {
  primaryButton: {
    regular: {
      default: {
        font: expeditionColors.white,
        background: expeditionColors.black.regular,
        border: expeditionColors.black.regular,
      },
      active: {
        font: expeditionColors.white,
        background: expeditionColors.grey.dark,
        border: expeditionColors.grey.dark,
      },
    },
    inverted: {
      default: {
        font: expeditionColors.black.regular,
        background: expeditionColors.white,
        border: expeditionColors.white,
      },
      active: {
        font: expeditionColors.black.regular,
        background: expeditionColors.grey.regular,
        border: expeditionColors.grey.regular,
      },
    },
  },
  secondaryButton: {
    regular: {
      default: {
        font: expeditionColors.black.regular,
        background: expeditionColors.white,
        border: expeditionColors.black.regular,
      },
      active: {
        font: expeditionColors.white,
        background: expeditionColors.grey.dark,
        border: expeditionColors.grey.dark,
      },
    },
    inverted: {
      default: {
        font: expeditionColors.white,
        background: 'transparent',
        border: expeditionColors.white,
      },
      active: {
        font: expeditionColors.black.regular,
        background: expeditionColors.white,
        border: expeditionColors.white,
      },
    },
  },
  datePicker: {
    selectedDate: {
      font: expeditionColors.white,
      background: expeditionColors.menu.regular,
      border: expeditionColors.menu.regular,
    },
  },
  formElements: {
    default: {
      font: expeditionColors.white,
      background: expeditionColors.black.regular,
    },
    disabled: {
      font: expeditionColors.black.regular,
      background: colors?.b2b.grey1,
    },
    active: {
      font: expeditionColors.white,
      background: colors?.hrx.volcanoBlack7,
    },
    hover: {
      font: expeditionColors.white,
      background: colors.hrx.volcanoBlack8,
    },
  },
};
