import { ICabinCategory, ICabinsData } from 'interfaces/ICabin';
import { transformCabinCategories } from 'utils/mappers/searchMapper';
import { api } from './api';

export const voyagesApi = api.injectEndpoints({
  endpoints: (build) => ({
    getCabins: build.query<
      { cabinsData: ICabinsData },
      { quoteId: string; voyageId: string; isRefurbished?: boolean }
    >({
      query: (params) => ({
        url: `Voyages`,
        params,
      }),
      keepUnusedDataFor: 0, // this will ensure a refetch after component unsubscribes
      transformResponse: (response: ICabinsData) => ({
        cabinsData: {
          ...response,
          ship: {
            ...response.ship,
            cabinCategories: transformCabinCategories(
              response.ship.cabinCategories
            ) as ICabinCategory[],
          },
        },
      }),
    }),
  }),
});

export const { useGetCabinsQuery } = voyagesApi;

export const {
  endpoints: { getCabins },
} = voyagesApi;
