export default {
  expirationDate: 'expirationDate',
  idToken: 'idToken',
  currentLanguage: 'language',
  languageOptions: 'languageOptions',
  currency: 'currency',
  fullDateNotation: 'fullDateNotation',
  monthAndYearDateNotation: 'monthAndYearDateNotation',
  dayAndMonthDateNotation: 'dayAndMonthDateNotation',
  fullHoursDateNotation: 'fullHoursDateNotation',
  dayMonthYearWeekdayNotation: 'dayMonthYearWeekdayNotation',
  TimeNotation: 'TimeNotation',
  isMetric: 'isMetric',
  permissions: 'permissions',
  seenNews: 'seenNews',
  supportEmail: 'supportEmail',
  supportPhone: 'supportPhone',
  supportAddress: 'supportAddress',
  bookingState: 'bookingState',
  hideAlertInfoBanner: 'hideAlertInfoBanner',
  seenWelcomeModal: 'seenWelcomeModal',
};
