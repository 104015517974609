import { SVGProps } from 'react';

const AreaIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={20}
    viewBox="0 0 21 20"
    fill="#000"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="m17.52 13.81 2.5 2.5c.03.03.056.063.077.098l.01.02a.31.31 0 0 1 .026.05l.01.031c.008.02.014.041.018.062l.002.017c.004.02.006.041.007.061v.021l-.005.062-.008.048-.007.024a.505.505 0 0 1-.164.25L17.52 19.52c-.19.2-.51.2-.71 0a.513.513 0 0 1 0-.71l1.647-1.641-3.787.001c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5l3.789-.001-1.649-1.649a.513.513 0 0 1 0-.71c.2-.19.52-.19.71 0zM3.83 11.17c.28 0 .5.22.5.5v4.5h4.5c.28 0 .5.22.5.5 0 .27-.22.5-.5.5h-5c-.27 0-.5-.23-.5-.5v-5c0-.28.23-.5.5-.5zM19.67.33c.27 0 .5.23.5.5v10c0 .28-.23.5-.5.5h-10c-.28 0-.5-.22-.5-.5v-10c0-.27.22-.5.5-.5h10zm-.5 1h-9v9h9v-9zM3.919.338 3.882.333l.017.002a.497.497 0 0 1 .066.014l.024.008a.46.46 0 0 1 .13.065l.026.02A.52.52 0 0 1 4.19.48l2.5 2.501c.19.2.19.51 0 .71-.2.19-.52.19-.71 0L4.33 2.039V5.83c0 .249-.174.45-.41.492l-.09.008a.502.502 0 0 1-.492-.41l-.008-.09V2.041L1.69 3.69c-.2.19-.52.19-.71 0-.2-.2-.2-.51 0-.71L3.51.45a.503.503 0 0 1 .32-.12l.089.008zM3.83.33l.045.002.007.001L3.83.33z"
    />
  </svg>
);

export default AreaIcon;
