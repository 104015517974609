import { TBrand } from '../brand';
import { coastalColors } from '../colorThemes/coastalColors';
import { expeditionColors } from '../colorThemes/expeditionColors';
import { groupColors } from '../colorThemes/groupColors';

export const getBrandColors = (brand: TBrand) => {
  if (brand === 'coastal') return coastalColors;
  if (brand === 'expedition') return expeditionColors;
  if (brand === 'group') return groupColors;
  return groupColors;
};
