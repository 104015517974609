import { handleActions, ReduxCompatibleReducer } from 'redux-actions';
import {
  fetchTourStart,
  fetchTourSuccess,
  fetchTourFailed,
  fetchTourTypeStart,
  fetchTourTypeSuccess,
  fetchTourTypeFailed,
  resetTour,
} from 'store/actions/tour';
import { ITourState, ITourPayload } from 'interfaces/ITour';

const initialState: ITourState = {
  tour: {
    destinationId: '',
    destinationName: '',
    duration: '',
    durationText: '',
    fromPort: null,
    heading: '',
    id: '',
    included: null,
    intro: null,
    isClassicVoyage: false,
    isCoastal: false,
    isHomePortDeparture: false,
    isViaKirkenes: false,
    itinerary: [],
    itineraryIntro: '',
    largeMap: { highResolutionUri: '' },
    longDescription: null,
    mediaContent: null,
    notIncluded: null,
    notes: null,
    price: null,
    ships: null,
    shortDescription: null,
    toPort: null,
    travelSuggestionCodes: [],
    url: '',
    usps: null,
    departureLabel: '',
    body: null,
    cabins: [],
    departures: '',
    voyages: [],
    promotions: [],
    strikeThroughPrice: null,
  },
  loading: false,
  error: false,
  types: null,
};

const reducer: ReduxCompatibleReducer<ITourState, ITourPayload> = handleActions<
  ITourState,
  ITourPayload
>(
  {
    [fetchTourStart.toString()]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [fetchTourSuccess.toString()]: (state, action) => ({
      ...state,
      tour: action.payload.tourData,
      loading: false,
    }),
    [fetchTourFailed.toString()]: (state) => ({
      ...state,
      error: true,
      loading: false,
    }),
    [fetchTourTypeStart.toString()]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [fetchTourTypeSuccess.toString()]: (state, action) => ({
      ...state,
      types: action.payload.type,
      loading: false,
    }),
    [fetchTourTypeFailed.toString()]: (state) => ({
      ...state,
      error: true,
      loading: false,
    }),
    [resetTour.toString()]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
