import { SVGProps } from 'react';

const EyeLessIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={18}
    viewBox="0 0 24 18"
    {...props}
  >
    <path
      fill="#000"
      d="M20.985.515a.5.5 0 0 1 0 .707L4.722 17.485a.5.5 0 0 1-.707-.707L20.278.515a.5.5 0 0 1 .707 0ZM19.127 4.7c.353.263.705.545 1.055.845a23.29 23.29 0 0 1 2.976 3.018l.378.473.219.328-.219.327a23.29 23.29 0 0 1-3.354 3.49c-2.7 2.319-5.537 3.546-8.182 3.546-1.306 0-2.658-.299-4.017-.88l.835-.836c1.009.386 2.078.625 3.182.625 5.018 0 9.327-4.936 10.39-6.272-.562-.708-2.035-2.425-4.042-3.884l.779-.78Zm-2.94 2.942A4.526 4.526 0 0 1 12 13.891c-.61 0-1.192-.12-1.723-.34l.862-.86c.277.071.566.109.861.109a3.41 3.41 0 0 0 3.436-3.436c0-.295-.037-.584-.109-.861l.86-.86ZM12 2c1.14 0 2.315.228 3.499.672l-.853.855A8.602 8.602 0 0 0 12 3.091c-5.018 0-9.327 4.936-10.39 6.273a22.19 22.19 0 0 0 3.626 3.571l-.775.776c-.215-.17-.43-.346-.643-.53a23.29 23.29 0 0 1-2.976-3.016L.464 9.69l-.219-.327.219-.328a23.29 23.29 0 0 1 3.354-3.49C6.518 3.226 9.355 2 12 2Zm0 2.836c.402 0 .799.054 1.18.157l-.944.942A3.436 3.436 0 0 0 8.572 9.6l-.944.944A4.533 4.533 0 0 1 12 4.836Z"
      fillRule="evenodd"
    />
  </svg>
);

export default EyeLessIcon;
