import { SVGProps } from 'react';

const ArrowUpDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0013 19C5.81721 19 5.66797 18.8508 5.66797 18.6667L5.66797 5.33333C5.66797 5.14924 5.81721 5 6.0013 5C6.1854 5 6.33463 5.14924 6.33463 5.33333L6.33464 18.6667C6.33464 18.8508 6.1854 19 6.0013 19Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.09763 10.2357C0.967456 10.1055 0.967456 9.89447 1.09763 9.7643L5.7643 5.09763C5.89447 4.96746 6.10553 4.96746 6.2357 5.09763L10.9024 9.7643C11.0325 9.89447 11.0325 10.1055 10.9024 10.2357C10.7722 10.3659 10.5611 10.3659 10.431 10.2357L6 5.80474L1.56904 10.2357C1.43886 10.3659 1.22781 10.3659 1.09763 10.2357Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.9987 5C18.1828 5 18.332 5.14924 18.332 5.33333L18.332 18.6667C18.332 18.8508 18.1828 19 17.9987 19C17.8146 19 17.6654 18.8508 17.6654 18.6667L17.6654 5.33333C17.6654 5.14924 17.8146 5 17.9987 5Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.8075 13.5637C23.9496 13.7058 23.9496 13.9361 23.8075 14.0782L18.7152 19.1705C18.5731 19.3125 18.3428 19.3125 18.2008 19.1705L13.1084 14.0782C12.9664 13.9361 12.9664 13.7058 13.1084 13.5637C13.2505 13.4217 13.4808 13.4217 13.6228 13.5637L18.458 18.3989L23.2931 13.5637C23.4352 13.4217 23.6655 13.4217 23.8075 13.5637Z"
      fill="currentColor"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default ArrowUpDownIcon;
