import { NavLink } from 'react-router-dom';
import { ButtonText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import translate from 'translate';
import { track } from 'utils/analytics';
import styles from './styles.module.scss';

const { REACT_APP_COMPANY } = process.env;

const trackMyBookings = () => track('Click my bookings');
const trackMyProfile = () => track('Click my profile');

interface INavItemsRightProps {
  setShowLearnNavigation: (value: boolean) => void;
}

const NavItemsRight = ({ setShowLearnNavigation }: INavItemsRightProps) => (
  <ul className={styles.list}>
    <li>
      <NavLink
        to="/bookings"
        end
        className={({ isActive }) =>
          clsx(styles.link, {
            [styles.linkActive]: isActive,
            [styles.hx]: REACT_APP_COMPANY === 'HX' && isActive,
          })
        }
        onClick={() => {
          trackMyBookings();
          setShowLearnNavigation(false);
        }}
      >
        <ButtonText>{translate('Navbar_MyBookings')}</ButtonText>
      </NavLink>
    </li>
    <li>
      <NavLink
        to="/myprofile"
        id="desktopheader_myprofile"
        end
        className={({ isActive }) =>
          clsx(styles.link, {
            [styles.linkActive]: isActive,
            [styles.hx]: REACT_APP_COMPANY === 'HX' && isActive,
          })
        }
        onClick={() => {
          trackMyProfile();
          setShowLearnNavigation(false);
        }}
      >
        <ButtonText>{translate('General_MyProfile')}</ButtonText>
      </NavLink>
    </li>
  </ul>
);

export default NavItemsRight;
