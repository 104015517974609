import { BodyText } from 'components/UI/Brand/text';
import styles from './styles.module.scss';

const NoAvailableExtraItem = ({ message }: { message: string }) => (
  <div className={styles.noAvailableExtraItem}>
    <BodyText className={styles.message}>{message}</BodyText>
  </div>
);

export default NoAvailableExtraItem;
