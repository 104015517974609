import { ChangeEvent, ReactNode } from 'react';
import { colors } from 'style/colors';
import { typography } from 'style/typography';
import styled from 'styled-components';
import { Label } from '../Brand/text';

const StyledRadio = styled.div`
  position: relative;
  min-height: 20px;
  margin-top: 10px;
  cursor: pointer;
  font-family: ${typography.normal.fontFamily};

  /* Hide the browser's default radio button */
  input {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    box-sizing: border-box;
  }

  /* Create a custom radio button */
  .checkmark {
    box-sizing: border-box;
    position: relative;
    height: 20px;
    width: 20px;
    background-color: ${colors.shared.white};
    border: solid 2px ${colors.hrx.volcanoBlack7};
    border-radius: 50%;
    margin-right: 15px;
    flex: 0 0 auto;

    &:hover {
      border: solid 2px
        ${({ theme }) =>
          theme?.buttonColors?.formElements?.default?.background ||
          colors.shared.black};
    }
  }

  input:checked ~ .checkmark {
    box-sizing: border-box;
    border: solid 2px
      ${({ theme }) =>
        theme?.buttonColors?.formElements?.default?.background ||
        colors.shared.black};
  }

  /* Create the indicator (the dot/circle - hidden when not checked) */
  .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  input:focus ~ .checkmark {
    box-shadow: 0 0 0 2px cornflowerblue;
  }

  /* Show the indicator (dot/circle) when checked */
  input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the indicator (dot/circle) */
  .checkmark:after {
    top: 3px;
    left: 3px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-sizing: border-box;
    background: ${({ theme }) =>
      theme?.buttonColors?.formElements?.default?.background ||
      colors.shared.black};
  }
`;

const RadioOptionLabel = styled.label`
  display: flex;
  cursor: pointer;
  align-items: center;
  margin: 0;
  width: 100%;
`;

const RadioOptionGroupContainer = styled.fieldset`
  cursor: pointer;
  padding: 0;
  border: none;
`;

const RadioOptionsList = styled.div<{ hasError: boolean }>`
  border: 1.4px solid
    ${(props) => (props.hasError ? colors.shared.red : 'transparent')};
  padding: 0 10px 10px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 2em;
  row-gap: 1em;
  padding: 0;
  box-sizing: border-box;
`;

interface RadioOption {
  value: string;
  label: string | ReactNode;
}

export interface RadioOptionGroupProps {
  name: string;
  options: RadioOption[];
  defaultValue?: string;
  legend?: string;
  className?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
}

const RadioOptionGroup = ({
  name,
  options,
  defaultValue,
  legend,
  className,
  onChange,
  hasError = false,
}: RadioOptionGroupProps) => (
  <RadioOptionGroupContainer className={className}>
    {legend && (
      <Label as="legend" hasError={hasError}>
        {legend}
      </Label>
    )}
    <RadioOptionsList hasError={hasError} aria-invalid={hasError}>
      {options.map((option) => (
        <StyledRadio key={option.value} data-testid={option.value}>
          <RadioOptionLabel>
            <input
              type="radio"
              name={name}
              value={option.value}
              defaultChecked={option.value === defaultValue}
              onChange={onChange}
            />
            <span className="checkmark" />
            {option.label}
          </RadioOptionLabel>
        </StyledRadio>
      ))}
    </RadioOptionsList>
  </RadioOptionGroupContainer>
);

export default RadioOptionGroup;
