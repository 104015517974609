import LoadingGif from './LoadingGif';
import styles from './styles.module.scss';

const LoadingComponent = () => (
  <div className={styles.loadingComponent}>
    <LoadingGif />
  </div>
);

export default LoadingComponent;
