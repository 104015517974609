import styled from 'styled-components';
import { ReactNode } from 'react';
import { typography } from 'style/typography';
import { colors } from 'style/colors';

interface HelperTextProps {
  hasError?: boolean;
  children?: ReactNode;
}

export const HelperText = styled.p<HelperTextProps>`
  display: block;
  padding: 5px 0 5px 15px;
  margin: 0;
  color: ${(props) =>
    props.hasError ? colors.shared.red : colors.shared.textBlack};
  font-weight: 400;
  font-family: ${typography.normal.fontFamily};
  font-size: ${typography.normal.fontSize.tiny};
  line-height: ${typography.normal.lineHeight.tiny};
`;
