import { SecondaryButton } from 'components/UI/Buttons/SecondaryButton/SecondaryButton';
import { BodyText, Header2 } from 'components/UI/Brand/text';
import { BOOKING_STEPS, IChangeBookingStepParams } from 'interfaces/IBooking';
import { useState } from 'react';
import translate from 'translate';
import { Checkbox } from 'components';
import {
  getBookingStepDescription,
  getBookingStepTitle,
} from '../utils/EditBookingStepModal.util';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

interface IEditBookingStepModalProps {
  hideModal: () => void;
  changeBookingStep: ({ direction, goTo }: IChangeBookingStepParams) => void;
  selectedBookingStep: BOOKING_STEPS;
  cabinIndex: number;
}

const EditBookingStepModal = ({
  hideModal,
  changeBookingStep,
  selectedBookingStep,
  cabinIndex,
}: IEditBookingStepModalProps) => {
  const [checked, setChecked] = useState(false);

  return (
    <section className={styles.root}>
      <Header2>{getBookingStepTitle(selectedBookingStep)}</Header2>
      <BodyText>{getBookingStepDescription(selectedBookingStep)}</BodyText>
      <Checkbox
        label={translate('General_IUnderstand')}
        onChange={() => setChecked(!checked)}
      />
      <div className={styles.buttonContainer}>
        <SecondaryButton type="button" onClick={hideModal}>
          {translate('EditModal_Cancel')}
        </SecondaryButton>
        <PrimaryButton
          disabled={!checked}
          type="button"
          onClick={() => {
            changeBookingStep({
              goTo: { step: selectedBookingStep, cabinIndex },
            });
            localStorage.setItem(
              'hasAcceptedTermsForEditingBookingSteps',
              'true'
            );
            hideModal();
          }}
        >
          {translate('BookingSummary_Edit')}
        </PrimaryButton>
      </div>
    </section>
  );
};

export default EditBookingStepModal;
