import clsx from 'clsx';
import { ReactNode } from 'react';
import { Header3 } from 'components/UI/Brand/text';
import translate from 'translate';
import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

interface IAlertModalProps {
  title: string;
  children: ReactNode;
  onClose: () => void;
  isAlert?: boolean;
  onConfirmClick?: () => void;
  confirmButtonLabel?: string;
}

const AlertModal = ({
  title,
  isAlert = false,
  children,
  onClose,
  confirmButtonLabel,
  onConfirmClick,
}: IAlertModalProps) => (
  <div className={styles.root}>
    <div
      className={clsx(styles.headerSection, {
        [styles.headerSectionAlert]: isAlert,
      })}
    >
      <button className={styles.closeButton} onClick={onClose}>
        <Icon iconType="cross" color={colors.shared.white} />
      </button>
      <Icon
        iconType={isAlert ? 'circleWithExclamation' : 'circleWithCheckmark'}
        width={58}
        height={58}
      />
      <Header3 as="h1">{title}</Header3>
    </div>
    <div className={styles.contentSection}>
      <div>{children}</div>
      <PrimaryButton onClick={onConfirmClick || onClose}>
        {confirmButtonLabel || translate('General_GotIt')}
      </PrimaryButton>
    </div>
  </div>
);

export default AlertModal;
