import { SVGProps } from 'react';

const ArrowRightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.6,22.4c-0.2-0.2-0.2-0.5,0-0.7l9.6-9.6L6.6,2.4c-0.2-0.2-0.2-0.5,0-0.7c0.2-0.2,0.5-0.2,0.7,0l10,10
      c0.2,0.2,0.2,0.5,0,0.7l-10,10C7.2,22.5,6.8,22.5,6.6,22.4z"
    />
  </svg>
);

export default ArrowRightIcon;
