import { IBookingDetailsData } from 'interfaces/IBookingDetails';
import { INewBooking } from 'interfaces/INewBooking';

export const removeBookingsDuplicates = (
  allBookings: IBookingDetailsData[]
): IBookingDetailsData[] => [
  ...new Map(
    allBookings.map((booking) => [booking.bookingId, booking])
  ).values(),
];

export const removeNewBookingsDuplicates = (
  allBookings: INewBooking[]
): INewBooking[] => [
  ...new Map(
    allBookings.map((booking) => [booking.bookingId, booking])
  ).values(),
];
