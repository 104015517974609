interface Sizes {
  tiny: string;
  small: string;
  normal: string;
  large: string;
}

interface FontSettings {
  fontFamily: string;
  fontSize: Sizes;
  lineHeight: Sizes;
}

interface Typography {
  hurtigruten: FontSettings;
  header: FontSettings;
  normal: FontSettings;
  body: FontSettings;
}

export const typography: Typography = {
  hurtigruten: {
    fontFamily: 'Hurtigruten Display',
    fontSize: {
      tiny: '',
      small: '',
      normal: '60px',
      large: '110px',
    },
    lineHeight: {
      tiny: '',
      small: '',
      normal: '',
      large: '',
    },
  },
  header: {
    fontFamily: 'Austin, Georgia, serif',
    fontSize: {
      tiny: '',
      small: '26px',
      normal: '30px',
      large: '35px',
    },
    lineHeight: {
      tiny: '',
      small: '',
      normal: '',
      large: '',
    },
  },
  normal: {
    fontFamily: 'Atlas Grotesk, sans-serif',
    fontSize: {
      tiny: '12px',
      small: '14px',
      normal: '18px',
      large: '26px',
    },
    lineHeight: {
      tiny: '15px',
      small: '23px',
      normal: '27px',
      large: '32px',
    },
  },
  body: {
    fontFamily: 'Lyon, Georgia, serif',
    fontSize: {
      tiny: '',
      small: '16px',
      normal: '20px',
      large: '',
    },
    lineHeight: {
      tiny: '',
      small: '',
      normal: '',
      large: '',
    },
  },
};
