import { SVGProps } from 'react';

const PipeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={14}
    viewBox="0 0 17 14"
    fill="currentColor"
    {...props}
  >
    <path d="M2.473 5.213.5 6.747l5.273 6.78L16.15 2.46 14.325.75 5.95 9.683z" />
  </svg>
);

export default PipeIcon;
