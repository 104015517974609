import { SVGProps } from 'react';

const CarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={13}
    viewBox="0 0 22 13"
    {...props}
  >
    <path
      fill="#000"
      d="M10.7 0c1.764 0 3.259 1.12 4.269 2.13.513.52.924 1.04 1.206 1.42.12.17.217.31.289.42h2.081c.603 0 1.407.02 2.128.75.297.3.489.62.599 1.01.104.38.128.8.128 1.3v2.98c0 .28-.224.51-.5.51h-1.898a2.86 2.86 0 0 1-2.811 2.37 2.86 2.86 0 0 1-2.813-2.38H8.02a2.86 2.86 0 0 1-2.812 2.38 2.86 2.86 0 0 1-2.812-2.37H.5c-.276 0-.5-.23-.5-.5V7.68C0 5.04.471 3.09 1.634 1.8 2.809.51 4.585 0 6.913 0H10.7ZM5.209 8.14c-1.016 0-1.85.825-1.855 1.87v.01l-.001.007.007.14a1.86 1.86 0 0 0 1.849 1.723 1.86 1.86 0 0 0 1.854-1.87c0-1.05-.835-1.88-1.854-1.88Zm10.982 0c-1.019 0-1.855.83-1.855 1.88 0 1.04.836 1.87 1.855 1.87a1.86 1.86 0 0 0 1.854-1.87c0-1.05-.835-1.88-1.854-1.88ZM1 7.68v1.84h1.397a2.86 2.86 0 0 1 2.812-2.38 2.859 2.859 0 0 1 2.81 2.37h5.361a2.86 2.86 0 0 1 2.811-2.37 2.859 2.859 0 0 1 2.811 2.38H20.4V7.03c0-.48-.026-.79-.091-1.03a1.245 1.245 0 0 0-.347-.58c-.418-.42-.854-.45-1.417-.45h-2.354l-.01-.001-14.895.001-.05-.005C1.079 5.727 1 6.625 1 7.68Zm1.375-5.21c-.357.397-.646.89-.864 1.499h3.198V1.211c-1.021.23-1.78.647-2.334 1.259ZM9.413 1h-2.5c-.43 0-.831.018-1.204.055v2.914h3.704V1ZM10.7 1h-.287v2.969h4.828a11.12 11.12 0 0 0-.982-1.129C13.301 1.87 12.052 1 10.7 1Z"
      fillRule="evenodd"
    />
  </svg>
);

export default CarIcon;
