import { colors } from 'style/colors';
import { TAlertType } from './alertType';

export const getAlertColor = (type: TAlertType) => {
  if (type === 'error') {
    return colors.shared.lightRed;
  }

  if (type === 'success') {
    return colors.shared.clearGreen;
  }

  if (type === 'warning') {
    return colors.shared.penguinYellow;
  }

  if (type === 'info') {
    return colors.shared.snowBlue;
  }

  return colors.shared.snowBlue;
};
