import { InfoText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import DepartureInformation from 'components/Search/SearchResult/DepartureInformation/DepartureInformation';
import translate from 'translate';
import { Fragment } from 'react';
import AccessibilityLabel from 'components/UI/AccessibilityLabel/AccessibilityLabel';
import { ITableSearchResult } from 'interfaces/ITableSearchResult';
import { SearchResultButton } from '../../SearchResultButton/SearchResultButton';
import styles from './styles.module.scss';

const SearchResultDesktopTable = ({
  arrivalDate,
  departureDate,
  index,
  isCoastal,
  isShowAccessibleCabins,
  onPriceClicked,
  prices,
  promotionList,
  shipDetail,
  isPortToPortVoyage,
  hasSearchedWithDeckSpace,
  tableHeader,
  isViaGeiranger,
  isViaHjorundfjorden,
  hasVehicleAvailability,
  voyageId,
  travelSuggestionCode,
}: ITableSearchResult) => (
  <tr className={styles.tableRow} data-testid={`search-result-row-${index}`}>
    <td className={styles.tableCell}>
      <DepartureInformation
        arrivalDate={arrivalDate}
        departureDate={departureDate}
        index={index}
        isCoastal={isCoastal}
        onPriceClicked={onPriceClicked}
        prices={prices}
        promotionList={promotionList}
        shipDetail={shipDetail}
        isPortToPortVoyage={isPortToPortVoyage}
        hasSearchedWithDeckSpace={hasSearchedWithDeckSpace}
        isViaGeiranger={isViaGeiranger}
        isViaHjorundfjorden={isViaHjorundfjorden}
        hasVehicleAvailability={hasVehicleAvailability}
        voyageId={voyageId}
        travelSuggestionCode={travelSuggestionCode}
      />
    </td>
    {!isPortToPortVoyage &&
      prices.map(
        (
          {
            isAvailable,
            genericCabinCategory,
            cabinCode,
            hasAccessibleCabin,
            isSoldOut,
            priceDetail,
            price,
            maxOccupancy,
            hasDiscountInTableRow,
            hasMaxPaxNotification,
          },
          i
        ) => (
          <Fragment key={`departure-${index + 1}-${genericCabinCategory}-cell`}>
            {isAvailable ? (
              <td
                className={styles.tableCell}
                data-testid={`table-result-cell-${i}`}
              >
                <div
                  className={clsx(styles.tableCellBodySecondary, {
                    [styles.accessibleLabel]: isShowAccessibleCabins,
                  })}
                >
                  {isShowAccessibleCabins && hasAccessibleCabin && (
                    <AccessibilityLabel />
                  )}
                  <SearchResultButton
                    hasMaxPaxNotification={hasMaxPaxNotification}
                    isSoldOut={isSoldOut}
                    genericCabinCategory={genericCabinCategory}
                    cabinCode={cabinCode}
                    onPriceClicked={onPriceClicked}
                    maxOccupancy={maxOccupancy}
                    index={index}
                    hasDiscountInTableRow={hasDiscountInTableRow}
                    priceDetail={priceDetail}
                    voyageId={voyageId}
                  />
                </div>
              </td>
            ) : (
              tableHeader.length === prices.length && (
                <td
                  className={styles.tableCell}
                  key={price}
                  data-testid={`table-result-cell-${i}`}
                >
                  <button
                    className={clsx(
                      styles.disabledButton,
                      styles.tableCellButton
                    )}
                    disabled
                  >
                    <InfoText className={styles.disabledButtonLabel}>
                      {translate('SearchResultItemRow_NoAvailableCabins')}
                    </InfoText>
                  </button>
                </td>
              )
            )}
          </Fragment>
        )
      )}
  </tr>
);

export default SearchResultDesktopTable;
