import { BRAND_TYPE } from 'interfaces/IProduct';
import { createActions, ActionFunctionAny, Action } from 'redux-actions';
import axios from '../../axios-instance';

export const { fetchProductsStart, fetchProductsSuccess, fetchProductsFailed } =
  createActions({
    FETCH_PRODUCTS_START: undefined,
    FETCH_PRODUCTS_SUCCESS: (products) => products,
    FETCH_PRODUCTS_FAILED: undefined,
  });

export const fetchProductDescription =
  (isCoastal?: boolean) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchProductsStart());
    const params = new URLSearchParams();
    if (isCoastal !== null) {
      params.set(
        'productCategory',
        isCoastal ? BRAND_TYPE.COSTAL_EXPRESS : BRAND_TYPE.EXPEDITION
      );
    }
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}products`, { params })
      .then((response) => {
        dispatch(fetchProductsSuccess({ products: response.data, isCoastal }));
      })
      .catch(() => {
        dispatch(fetchProductsFailed());
      });
  };
