import { SVGProps } from 'react';

const BalconyIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={21}
    viewBox="0 0 24 21"
    fill="currentColor"
    {...props}
  >
    <path
      d="M2 14v7H1v-7h1Zm3 0v7H4v-7h1Zm3 0v7H7v-7h1Zm3 0v7h-1v-7h1Zm3 0v7h-1v-7h1Zm3 0v7h-1v-7h1Zm3 0v7h-1v-7h1Zm3 0v7h-1v-7h1ZM18 .5A1.5 1.5 0 0 1 19.5 2v9.5H24v1H0v-1h4.5V2A1.5 1.5 0 0 1 5.856.507L6 .5Zm0 1H6a.5.5 0 0 0-.5.5v9.5h13V2a.5.5 0 0 0-.41-.492L18 1.5Z"
      fillRule="evenodd"
    />
  </svg>
);

export default BalconyIcon;
