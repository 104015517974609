import { handleActions } from 'redux-actions';
import { IRegistration } from 'interfaces/IOnboarding';
import {
  leadCreated,
  registrationClear,
  registrationFailed,
  registrationStart,
  registrationSuccess,
} from '../actions/registration';

const initialState: IRegistration = {
  registrationResponse: null,
  error: null,
  loading: false,
  isLead: false,
};

const reducer = handleActions(
  {
    [leadCreated.toString()]: (state) => ({
      ...state,
      isLead: true,
    }),
    [registrationStart.toString()]: () => ({
      ...initialState,
      loading: true,
    }),
    [registrationSuccess.toString()]: (state, action) => ({
      ...state,
      registrationResponse: action.payload.registrationResponse,
      loading: false,
    }),
    [registrationFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
    }),
    [registrationClear.toString()]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
