import { Carousel } from 'react-responsive-carousel';
import './custom-carousel.css';
import CustomImage from 'components/UI/CustomImage/CustomImage';
import { IImageUrl } from 'interfaces/IImage';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { UP_TO_TABLET } from 'style/sizes';

interface ICustomCarousel {
  imageData: IImageUrl[];
}

const CustomCarousel = ({ imageData }: ICustomCarousel) => {
  const isMobile = useMediaQuery(UP_TO_TABLET);

  const images = imageData.map(({ imageUrl }) => (
    <CustomImage
      key={imageUrl.split('/')[0]}
      src={imageUrl}
      height={366}
      width={550}
      isPresentation
    />
  ));

  return (
    <Carousel
      infiniteLoop={images.length > 1}
      emulateTouch
      showThumbs={false}
      showStatus={false}
      showIndicators={images.length > 1}
      showArrows={!isMobile}
    >
      {images}
    </Carousel>
  );
};

export default CustomCarousel;
