import styled, { css } from 'styled-components';

interface Spacing {
  none: string;
  half: string;
  one: string;
  two: string;
  three: string;
  four: string;
  five: string;
  six: string;
  seven: string;
  eight: string;
  nine: string;
  ten: string;
  eleven: string;
}

export const spacings: Spacing = {
  none: '0px',
  half: '3px',
  one: '6px',
  two: '12px',
  three: '18px',
  four: '24px',
  five: '30px',
  six: '36px',
  seven: '42px',
  eight: '48px',
  nine: '54px',
  ten: '60px',
  eleven: '66px',
};

export type AvailableSpacings = keyof Spacing;

export interface MarginProps {
  marginBottom?: AvailableSpacings;
  marginLeft?: AvailableSpacings;
  marginRight?: AvailableSpacings;
  marginTop?: AvailableSpacings;
  margin?: AvailableSpacings;
}

export const spacingBottom = css`
  ${({ marginBottom }: MarginProps) =>
    marginBottom && `margin-bottom: ${spacings[marginBottom]}`};
`;

export const spacingLeft = css`
  ${({ marginLeft }: MarginProps) =>
    marginLeft && `margin-left: ${spacings[marginLeft]}`};
`;

export const spacingRight = css`
  ${({ marginRight }: MarginProps) =>
    marginRight && `margin-right: ${spacings[marginRight]}`};
`;

export const spacingTop = css`
  ${({ marginTop }: MarginProps) =>
    marginTop && `margin-top: ${spacings[marginTop]}`};
`;

export const spacing = css`
  ${({ margin }: MarginProps) => margin && `margin: ${spacings[margin]}`};
  ${spacingBottom}
  ${spacingLeft}
    ${spacingRight}
    ${spacingTop}
`;

export const Margin = styled.div`
  ${spacing}
  ${spacingBottom}
    ${spacingLeft}
    ${spacingRight}
    ${spacingTop}
`;
