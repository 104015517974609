import { SecondaryButton } from 'components/UI/Buttons/SecondaryButton/SecondaryButton';
import { ButtonText } from 'components/UI/Brand/text';
import { ReactNode } from 'react';
import clsx from 'clsx';
import translate from 'translate';
import { BrandProvider, OutlinedButton } from 'components';
import { PrimaryButton } from '../Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

interface IExpandableList {
  buttonClass?: string;
  buttonTheme?: 'primary' | 'secondary' | 'text';
  children: ReactNode;
  childrenLength: number;
  className?: string;
  fixedBrand?: 'group' | 'expedition' | 'coastal';
  ariaLabelledby?: string;
  initialVisibleItemsCount: number;
  setVisibleItemsCount: (initialVisibleItemsCount: number) => void;
  visibleItemsCount: number;
  isCoastal?: boolean;
}

const ExpandableList = ({
  buttonClass,
  buttonTheme,
  children,
  childrenLength,
  className,
  fixedBrand = 'group',
  ariaLabelledby,
  initialVisibleItemsCount,
  setVisibleItemsCount,
  visibleItemsCount,
  isCoastal,
}: IExpandableList) => {
  const isExpanded: boolean = visibleItemsCount === childrenLength;
  let Button: JSX.Element;

  const buttonText = isExpanded
    ? translate('General_Minimize')
    : translate('General_ShowAll', childrenLength - visibleItemsCount);

  const buttonProps = {
    'aria-expanded': isExpanded,
    className: buttonClass,
    onClick: () =>
      setVisibleItemsCount(
        !isExpanded ? childrenLength : initialVisibleItemsCount
      ),
  };

  switch (buttonTheme) {
    case 'primary': {
      Button = (
        <PrimaryButton type="button" {...buttonProps}>
          {buttonText}
        </PrimaryButton>
      );
      break;
    }
    case 'secondary': {
      Button = (
        <SecondaryButton type="button" {...buttonProps}>
          {buttonText}
        </SecondaryButton>
      );
      break;
    }
    case 'text': {
      Button = <ButtonText {...buttonProps}>{buttonText}</ButtonText>;
      break;
    }
    default: {
      Button = (
        <OutlinedButton size="small" isCoastal={isCoastal} {...buttonProps}>
          {buttonText}
        </OutlinedButton>
      );
    }
  }

  return (
    <ul
      aria-labelledby={ariaLabelledby}
      className={clsx(styles.root, className)}
    >
      {children}
      {childrenLength > initialVisibleItemsCount && (
        <BrandProvider fixedBrand={fixedBrand}>
          <div className={styles.buttonContainer}>{Button}</div>
        </BrandProvider>
      )}
    </ul>
  );
};

export default ExpandableList;
