import { IPort } from './IPort';

export interface IItineraryByVoyage {
  id: string;
  name: string;
  fromPort: IPort;
  toPort: IPort;
}

export interface ICoastalOptions {
  [key: string]: string;
}

// Itinerary is gotten by list type (which should be a key instead so we don't have to do this)
export const COASTAL_OPTIONS_MAP: ICoastalOptions = {
  PACKAGE_VOYAGE: 'Package Voyage',
  SVALBARD_EXPRESS: 'Svalbard Express',
  NORTH_CAPE_EXPRESS: 'North Cape Express',
  CLASSIC_VOYAGE: 'Classic Voyage',
  P2P: 'Port to Port',
  SPITSBERGEN_ADVENTURER: 'Spitsbergen Adventurer',
  HURTIGRUTEN_HAMBURG: 'Hurtigruten Hamburg',
};
