import { ReactNode } from 'react';
import clsx from 'clsx';
import styles from './styles.module.scss';

export const Fluid = ({
  children,
  className,
  maxWidth = 0,
  hasNoSpace = false,
}: {
  children: ReactNode;
  className?: string;
  maxWidth?: number;
  hasNoSpace?: boolean;
}) => (
  <div
    className={clsx(styles.fluid, className, {
      [styles.hasNoSpace]: hasNoSpace,
      [styles.maxWidth]: maxWidth !== 0,
    })}
  >
    {children}
  </div>
);
