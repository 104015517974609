export const getBookingStatus = (
  balance: number,
  departureDate: string | Date,
  depositAmount: number,
  status: string,
  totalPaymentsReceived: number
) => {
  const isCancelled = status === 'Cancelled';
  const isClosed = status === 'Closed';
  const isConfirmed = status === 'Confirmed';
  const isInvoiceable = status === 'Invoiceable';
  const isManualHold = status === 'ManualHold';
  const isSystemHold = status === 'SystemHold';
  const isUnconfirmed = status === 'Unconfirmed';

  const isDepositPending =
    depositAmount > 0 && totalPaymentsReceived < depositAmount;
  const isFullyPaid = balance <= 0 && new Date() < new Date(departureDate);
  const isPartlyPaid =
    balance > 0 && depositAmount > 0 && totalPaymentsReceived >= depositAmount;
  const isPaymentPending = balance > 0 && depositAmount === 0;
  const isSailed = balance <= 0 && new Date() > new Date(departureDate);
  const isError =
    !isCancelled &&
    !isClosed &&
    !isConfirmed &&
    !isDepositPending &&
    !isFullyPaid &&
    !isInvoiceable &&
    !isManualHold &&
    !isPartlyPaid &&
    !isPaymentPending &&
    !isSailed &&
    !isSystemHold &&
    !isUnconfirmed;

  // show on the portal
  // const mainStatuses = {
  //   isCancelled,
  //   isDepositPending,
  //   isFullyPaid,
  //   isManualHold,
  //   isPartlyPaid,
  //   isPaymentPending,
  //   isSailed,
  // };

  // use them only inside isError, but do not show them
  // const additionalStatuses = {
  //   isClosed,
  //   isConfirmed,
  //   isInvoiceable,
  //   isSystemHold,
  //   isUnconfirmed,
  // };

  return {
    isCancelled,
    isClosed,
    isConfirmed,
    isDepositPending,
    isError,
    isFullyPaid,
    isInvoiceable,
    isManualHold,
    isPartlyPaid,
    isPaymentPending,
    isSailed,
    isSystemHold,
    isUnconfirmed,
  };
};
