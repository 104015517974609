import clsx from 'clsx';
import { ReactNode } from 'react';
import styles from './styles.module.scss';

const Shimmer = ({
  children,
  isShimmering,
  className,
}: {
  children: ReactNode;
  isShimmering: boolean;
  className?: string;
}) => (
  <div
    className={clsx({
      [styles.animateShimmer]: isShimmering,
    })}
  >
    <div
      className={clsx(styles.base, {
        [styles.noOpacity]: isShimmering,
        [className || '']: Boolean(className),
      })}
    >
      {children}
    </div>
  </div>
);

export default Shimmer;
