import { SecondaryButton } from 'components/UI/Buttons/SecondaryButton/SecondaryButton';
import { BodyText, Header2 } from 'components/UI/Brand/text';
import clsx from 'clsx';
import { Fluid } from 'components/Fluid';
import CustomImage from 'components/UI/CustomImage/CustomImage';
import { ICampaignArticleContainer } from 'containers/Learn/CampaignPage/CampaignPage.types';
import TitleAndDescription from 'containers/Learn/TitleAndDescription';
import sizes from 'style/sizes';
import translate from 'translate';
import ContentfulRenderer from 'components/ContentfulRenderer';
import Icon from 'components/UI/Icons/Icon';
import { getUrl } from './utils/getUrl';
import styles from './styles.module.scss';

const ZigZagArticleContainer = ({
  articleSection,
}: {
  articleSection: ICampaignArticleContainer;
}) => (
  <Fluid maxWidth={sizes.desktopContainerWidth} hasNoSpace>
    <TitleAndDescription
      title={articleSection.title}
      descriptionAsNode={articleSection.richHeading}
      titleClassName={styles.titleClassName}
    />
    <div className={styles.zigZagArticleContainer}>
      {articleSection.articles.map((article, i) => (
        <div
          key={article.title}
          className={clsx(styles.item, {
            [styles.itemReverse]: i % 2 === 0,
          })}
        >
          <div className={styles.imageContainer}>
            <CustomImage
              src={article?.hero?.url ?? article?.heroWithFocalPoint?.image.url}
              isPresentation
              className={styles.image}
            />
          </div>
          <div className={styles.textContainer}>
            <Header2 className={styles.title}>{article.title}</Header2>
            {!article?.shipDescription ? (
              <BodyText className={styles.truncate}>
                {`${article?.intro ?? article?.destinationDescription} `}
              </BodyText>
            ) : (
              <ContentfulRenderer>
                {article?.shipDescription}
              </ContentfulRenderer>
            )}
            {article?.urlName && (
              <SecondaryButton
                to={getUrl(article.typeName, article.urlName)}
                classes={styles.button}
              >
                <span className={styles.buttonContent}>
                  {translate('General_ReadMore')}
                  <Icon iconType="arrowRightShort" />
                </span>
              </SecondaryButton>
            )}
          </div>
        </div>
      ))}
    </div>
  </Fluid>
);

export default ZigZagArticleContainer;
