import { handleActions } from 'redux-actions';
import * as actions from '../actions/home';

const initialState = {
  landingPage: null,
  error: null,
  loading: false,
  publicIp: null,
  footerData: null,
  contactDataError: null,
};

const reducer = handleActions(
  {
    [actions.fetchLandingPageStart]: (state, action) => ({
      ...state,
      loading: true,
      publicIp: action.payload.publicIp,
    }),
    [actions.fetchLandingPageSuccess]: (state, action) => ({
      ...state,
      landingPage: action.payload.landingPageData,
      error: null,
      loading: false,
    }),
    [actions.fetchLandingPageFailed]: (state, action) => ({
      ...state,
      landingPage: null,
      error: action.payload.error,
      loading: false,
    }),
    [actions.setFooterData]: (state, action) => ({
      ...state,
      footerData: {
        ...state.footerData,
        ...action.payload.footerData,
      },
    }),
    [actions.fetchContactDataFailed]: (state, action) => ({
      ...state,
      contactDataError: action.payload.error,
    }),
  },
  initialState
);

export default reducer;
