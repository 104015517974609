import { IBookingDetailsData } from 'interfaces/IBookingDetails';
import { IPayment } from 'interfaces/INewBooking';

export const getPayDate = (booking: IBookingDetailsData) => {
  let payDate = null;
  if (booking?.payments?.transactions?.length > 0) {
    const lastTransaction = booking.payments?.transactions
      .filter((transaction) => transaction.paymentType === 'Payment')
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime())
      .shift();

    if (lastTransaction) {
      payDate = lastTransaction.date;
    }
  }

  return payDate;
};

export const getLastTransactionDate = (
  transactions: IPayment[]
): string | null => {
  const lastTransaction = transactions
    ?.filter((transaction) => transaction.type === 'Payment')
    ?.shift();

  return lastTransaction?.paymentDate || null;
};
