import { RefObject, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { BodyText, Header3 } from 'components/UI/Brand/text';
import { LinkText, LoadingGif, SomethingWentWrongBanner } from 'components';

import NoBookings from 'containers/Bookings/NoBookings';
import translate from 'translate';
import { useGetNewAgentBookingsQuery } from 'store/services/bookings';
import { colors } from 'style/colors';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';
import styled from 'styled-components';
import { media } from 'style/sizes';
import { resetAgentBookings } from 'store/actions';
import { BOOKINGS_LIST_LIMIT } from 'utils/constants';
import { BookingCard } from './UI/Cards/ActivityCard/BookingCard';

interface IActivityCardContainerProps {
  isActiveBookings: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  margin-top: 55px;
  @media ${media.maxMobile} {
    align-items: center;
  }
`;

const ActivitesContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  margin-bottom: 20px;
`;

const ActivityCardContainer = styled.div<IActivityCardContainerProps>`
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: ${({ isActiveBookings }) =>
    isActiveBookings ? 'flex-start' : 'center'};
  width: 100%;
  flex-wrap: wrap;
  @media ${media.maxMobile} {
    width: 100%;
    max-width: 100vw;
    overflow-x: auto;
    display: flex;
    flex-wrap: nowrap;
    align-items: stretch;
    flex-direction: row;
    justify-content: space-between;
  }
`;

const NoActiveBookingsContainer = styled(BodyText)`
  text-align: center;
  color: ${colors.shared.warmGray5};
  margin-bottom: 20px;
  max-width: 450px;
`;

const TOTAL_VISIBLE_BOOKINGS = 4;

const scrollToRef = (ref: RefObject<HTMLDivElement>) =>
  window.scrollTo({ top: ref.current.offsetTop - 100, behavior: 'smooth' });

const BookingsHome = ({
  cruiseRef,
}: {
  cruiseRef: RefObject<HTMLDivElement>;
}) => {
  const dispatch = useAppDispatch();
  const agencyData = useAppSelector(
    (state) => state.profile.profileData?.agency
  );

  const isCreditAgency = agencyData?.isCreditAgency;

  const executeScroll = () => scrollToRef(cruiseRef);

  const { isError, isLoading, data } = useGetNewAgentBookingsQuery({
    limit: BOOKINGS_LIST_LIMIT,
    offset: 0,
  });

  const activeBookings = data?.bookings?.filter(
    (booking) => booking.status !== 'Cancelled'
  );

  const activeVisibleBookings =
    activeBookings?.length > TOTAL_VISIBLE_BOOKINGS
      ? activeBookings.slice(0, TOTAL_VISIBLE_BOOKINGS)
      : activeBookings;

  useEffect(
    () => () => {
      dispatch(resetAgentBookings());
    },
    []
  );

  if (isLoading) {
    return <LoadingGif />;
  }

  if (isError) {
    return <SomethingWentWrongBanner isRedirect={false} />;
  }

  if (!data.totalNumberOfBookings) {
    return (
      <NoBookings
        startToExplore={() => {
          executeScroll();
        }}
      />
    );
  }

  return (
    <Container>
      <ActivitesContainer>
        <Header3>{translate('Home_YourActivities')}</Header3>
        <LinkText to="/bookings" as={Link}>
          {translate('Home_SeeAllBookings')}
        </LinkText>
      </ActivitesContainer>
      <ActivityCardContainer isActiveBookings={!!activeBookings.length}>
        {!activeBookings?.length ? (
          <NoActiveBookingsContainer>
            {translate('Home_NoRecentActiveBookings')}
          </NoActiveBookingsContainer>
        ) : (
          activeVisibleBookings.map((booking) => (
            <BookingCard
              key={booking.bookingId}
              bookingId={booking.bookingId}
              isCreditAgency={isCreditAgency}
              balance={booking.financialOverview.netBalance}
              totalPaymentsReceived={
                booking.financialOverview.totalPaymentsReceived
              }
              title={booking.title}
              passengers={
                booking.passengerGroups.find((group) => !group.isCancelled)
                  ?.passengers ?? []
              }
              status={booking.status}
              depositAmount={booking.financialOverview.depositAmount}
              departureDate={booking.departureDateTime}
              depositDueDate={booking.depositDueDate}
              finalDueDate={booking.finalDueDate}
              payments={booking.payments ?? []}
            />
          ))
        )}
      </ActivityCardContainer>
    </Container>
  );
};

export default BookingsHome;
