import { IProfileData } from 'interfaces/IProfile';
import { api } from './api';

const { REACT_APP_COMPANY } = process.env;

export const userApi = api.injectEndpoints({
  endpoints: (build) => ({
    updateHasSeenWelcomeModal: build.mutation<void, void>({
      query: () => ({
        method: 'GET',
        url: `/User/validatefirstlogin`,
      }),
    }),
    validateUser: build.mutation<void, { email: string; productType?: string }>(
      {
        query: ({ email }) => ({
          url: `user/validate`,
          method: 'POST',
          body: { email },
          params: { productType: REACT_APP_COMPANY },
        }),
        extraOptions: { maxRetries: 0 },
      }
    ),
    validateTokenForAccountMigratin: build.query<
      IProfileData[],
      { token: string }
    >({
      query: ({ token }) => ({
        url: `user/accountmigration/validate/${token}`,
      }),
    }),
    verifyEmail: build.mutation<void, { email: string }>({
      query: ({ email }) => ({
        url: `user/isverified`,
        method: 'POST',
        body: { email },
      }),
      extraOptions: { maxRetries: 0 },
    }),
    resetPassword: build.mutation<
      void,
      { token: string; email: string; password: string }
    >({
      query: ({ token, email, password }) => ({
        url: `user/password`,
        method: 'POST',
        body: { token, email, password },
      }),
      extraOptions: { maxRetries: 0 },
    }),
    sendForgotPasswordLink: build.mutation<
      void,
      { email: string; productType: string }
    >({
      query: ({ email }) => ({
        url: `user/send/password`,
        method: 'POST',
        body: { email },
        params: { productType: REACT_APP_COMPANY },
      }),
      extraOptions: { maxRetries: 0 },
    }),
  }),
});

export const {
  useUpdateHasSeenWelcomeModalMutation,
  useValidateTokenForAccountMigratinQuery,
  useVerifyEmailMutation,
  useResetPasswordMutation,
  useSendForgotPasswordLinkMutation,
  useValidateUserMutation,
} = userApi;

export const {
  endpoints: {
    updateHasSeenWelcomeModal,
    validateTokenForAccountMigratin,
    verifyEmail,
    resetPassword,
    sendForgotPasswordLink,
    validateUser,
  },
} = userApi;
