import { BodyText } from 'components/UI/Brand/text';
import styles from './styles.module.scss';

interface IDiscountVoyageList {
  discountItems: {
    сategory: string;
    value: string;
  }[];
}

export const DiscountVoyageList = ({ discountItems }: IDiscountVoyageList) => (
  <ul className={styles.discountList}>
    {discountItems.map(({ сategory, value }) => (
      <li key={сategory}>
        <BodyText className={styles.discountItems}>
          <span className={styles.сategory}>{сategory}</span>
          <span className={styles.value}>{value}</span>
        </BodyText>
      </li>
    ))}
  </ul>
);
