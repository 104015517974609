import translate from 'translate';
import OnboardHeader from 'containers/Onboard/OnboardHeader/OnboardHeader';
import { LOGIN_STATUSES, LoginForm } from 'containers/Login';
import styles from './styles.module.scss';

const LoginWaitUserEmail = ({
  setLoginState,
}: {
  setLoginState: (loginState: LOGIN_STATUSES, email: string) => void;
}) => (
  <div data-testid="loginWaitUserEmail-container">
    <OnboardHeader title={translate('EmailVerifiedPage_LoginBtn')} />
    <div className={styles.container}>
      <LoginForm
        title={translate('Login_Title')}
        desc={translate('Login_Desc')}
        setLoginState={setLoginState}
      />
    </div>
  </div>
);

export default LoginWaitUserEmail;
