import { Header2, InfoText, BodyText } from 'components/UI/Brand/text';
import { CloseModalIcon } from 'components';
import translate from 'translate';
import styled from 'styled-components';
import { IRegion } from 'interfaces/IOnboarding';
import { colors } from 'style/colors';
import { PrimaryButton } from '../Buttons/PrimaryButton/PrimaryButton';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const Description = styled(BodyText)`
  color: ${colors.shared.warmGray6};
  margin-bottom: 16px;
`;

const Title = styled(Header2)`
  font-weight: 500;
  color: ${colors.shared.black};
  margin-bottom: 16px;
`;

export interface ICallHurtigrutenModalProps {
  onClose: () => void;
  phoneNumbers: string[];
  region: IRegion;
}

const CallHurtigrutenModal = ({
  onClose,
  phoneNumbers,
  region,
}: ICallHurtigrutenModalProps) => {
  if (!phoneNumbers?.length) return null;
  const visiblePhoneNumbers =
    region?.name === 'DE' ? [phoneNumbers[0]] : phoneNumbers;

  return (
    <Container>
      <HeaderContainer>
        <Title>{translate('CallHurtigrutenModal_CallHurtigruten')}</Title>
        <CloseModalIcon onClose={onClose} />
      </HeaderContainer>
      <Description data-testid={'CallHurtigrutenModal-phone'}>
        <BodyText>{translate('CallHurtigrutenModal_WeAreReachable')}</BodyText>
        {visiblePhoneNumbers.map((phone) => (
          <InfoText key={phone}>{phone}</InfoText>
        ))}
      </Description>

      <PrimaryButton onClick={onClose}>
        {translate('CallHurtigrutenModal_GotIt')}
      </PrimaryButton>
    </Container>
  );
};

export default CallHurtigrutenModal;
