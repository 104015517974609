import translate from 'translate';
import languageKeys from 'utils/languageKeys';
import { IRegion } from '../interfaces/IOnboarding';
import { getLanguage } from './getLanguage';
import { getRegionByLocale, Region } from './regions';

const getMarkets = () => {
  const marketList: IRegion[] = [
    {
      id: Region.Americas,
      name: 'Americas',
      description: translate('SignUp_Region_Americas'),
      language: languageKeys.EN_AMERICAS,
    },
    {
      id: Region.APACs,
      name: 'APAC',
      description: translate('SignUp_Region_APAC'),
      language: languageKeys.EN_APAC,
    },
    {
      id: Region.Germany,
      name: 'DE',
      description: translate('SignUp_Region_DE'),
      language: languageKeys.de,
    },
    {
      id: Region.UK,
      name: 'UK',
      description: translate('SignUp_Region_UK'),
      language: languageKeys.EN_UK,
    },
    {
      id: Region.EMEA,
      name: 'EMEA',
      description: translate('SignUp_Region_EMEA'),
      language: languageKeys.EN_EMEA,
    },
    {
      id: Region.Switzerland,
      name: 'CH',
      description: translate('SignUp_Region_CH'),
      language: languageKeys.DE_CH,
    },
    {
      id: Region.France,
      name: 'FR',
      description: translate('SignUp_Region_FR'),
      language: languageKeys.FR,
    },
    {
      id: Region.Norway,
      name: 'NO',
      description: translate('SignUp_Region_NO'),
      language: languageKeys.NO,
    },
    {
      id: Region.Sweden,
      name: 'SE',
      description: translate('SignUp_Region_SE'),
      language: languageKeys.SE,
    },
    {
      id: Region.Denmark,
      name: 'DK',
      description: translate('SignUp_Region_DK'),
      language: languageKeys.DK,
    },
    {
      id: Region.Austria,
      name: 'AT',
      description: translate('SignUp_Region_AT'),
      language: languageKeys.AT,
    },
  ];
  return marketList;
};

const getAgencyIdsForRegion = (region: Region) => {
  const regions = [
    Region.EMEA,
    Region.Switzerland,
    Region.France,
    Region.Norway,
    Region.Sweden,
    Region.Denmark,
  ];
  if (region === Region.UK) {
    return ['ABTA'];
  }
  if (region === Region.Austria || region === Region.Germany) {
    return ['Agentnummer'];
  }
  if (regions.indexOf(region) !== -1) {
    return [];
  }
  return ['IATA', 'TIDS', 'CLIA', 'TRUE'];
};

const getAgencyId = (
  iataNumber: string,
  accountNumber: string,
  pgId: string
) => {
  const region = getRegionByLocale(getLanguage());
  if (region === Region.Americas) {
    return iataNumber;
  }
  if (region === Region.UK) {
    return accountNumber;
  }

  return pgId;
};

const getAddressState = (state: string) => {
  const region = getRegionByLocale(getLanguage());
  if (
    region !== Region.Germany &&
    region !== Region.Austria &&
    region !== Region.France
  ) {
    return `${state},`;
  }
  return '';
};

export { getMarkets, getAgencyIdsForRegion, getAgencyId, getAddressState };
