import { handleActions, ReduxCompatibleReducer } from 'redux-actions';
import { IMapState, IMapPayload } from 'interfaces/IMap';
import {
  fetchMapDataStart,
  fetchMapDataSuccess,
  fetchMapDataFailed,
  clearMapData,
} from '../actions/map';

const initialState: IMapState = {
  fromPlace: null,
  toPlace: null,
  quays: null,
  polyline: null,
  returnPlace: null,
  polylineReturn: null,
  loading: false,
};

const reducer: ReduxCompatibleReducer<IMapState, IMapPayload> = handleActions<
  IMapState,
  IMapPayload
>(
  {
    [fetchMapDataStart.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchMapDataSuccess.toString()]: (state, action) => ({
      ...state,
      loading: false,
      quays: action.payload.intermediateQuays,
      polyline: action.payload.polyline,
      polylineReturn: action.payload.polylineReturn,
      fromPlace: action.payload.fromPlace,
      toPlace: action.payload.toPlace,
      returnPlace: action.payload.returnPlace,
    }),
    [fetchMapDataFailed.toString()]: (state) => ({
      ...state,
      error: true,
      loading: false,
    }),
    [clearMapData.toString()]: () => initialState,
  },
  initialState
);

export default reducer;
