import { IExtrasList } from 'interfaces/IExtras';
import { api } from './api';

export const extrasApi = api.injectEndpoints({
  endpoints: (build) => ({
    getExtras: build.query<IExtrasList, { quoteId: string; voyageId: string }>({
      query: (params) => ({
        url: `Extras/${params.quoteId}/${params.voyageId}`,
      }),
    }),
  }),
});

export const { useGetExtrasQuery } = extrasApi;

export const {
  endpoints: { getExtras },
} = extrasApi;
