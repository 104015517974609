import { Document } from '@contentful/rich-text-types';
import { ILink } from './ILink';
import { ImageWithFocalPoint } from './IImage';

export interface IProductState {
  loading: boolean;
  error: boolean;
  expeditionProducts: IProductDescription[];
  coastalProducts: IProductDescription[];
}

export interface IProductPayload {
  products: IProductDescription[];
  isCoastal?: boolean;
}

export enum BRAND_TYPE {
  GROUP = 'Group',
  EXPEDITION = 'Expedition',
  COSTAL_EXPRESS = 'CostalExpress',
}

type ProductType =
  | 'CoastalClassic'
  | 'SvalbardExpress'
  | 'NorthCapeExpress'
  | 'Expedition'
  | 'PortToPort'
  | 'Exception';

export interface IProductDescription extends IProductDescriptionBase {
  link: ILink;
  image: ImageWithFocalPoint;
  includedProducts: IProductDescriptionBase[];
}

export type IProductDescriptionPartial = Partial<
  Omit<
    IProductDescription,
    'title' | 'longDescription' | 'richDescription' | 'productType'
  >
> &
  Omit<IProductDescriptionBase, 'productType'>;

export interface IProductDescriptionBase {
  productType: ProductType;
  title: string;
  longDescription: string;
  richDescription: Document;
}
