import translate from 'translate';
import { getCurrencyFormat } from 'utils/multilanguage-support';
import getLocaleNumberFormat from 'utils/numberWithCommas';

export const getPriceFormat = (
  priceToFormat: number | string
): number | string =>
  Number.isInteger(priceToFormat)
    ? translate(
        'SearchResultItemRow_Price',
        getCurrencyFormat(),
        getLocaleNumberFormat(priceToFormat)
      )
    : priceToFormat;
