import translate from 'translate';
import { getCurrencyFormat } from 'utils/multilanguage-support';
import getLocaleNumberFormat from 'utils/numberWithCommas';
import styles from './styles.module.scss';

export const AgencyCommission = ({ commission }: { commission: number }) => (
  <span className={styles.agencyCommissionRow}>
    <span>{translate('BookingSummary_TotalCommissionText')}</span>
    <span>{`${getCurrencyFormat()} ${getLocaleNumberFormat(commission)}`}</span>
  </span>
);
