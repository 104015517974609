import { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/storeHooks';
import { logout } from 'store/actions';

const Logout = () => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(logout());
  }, []);

  return <Navigate to="/login" replace />;
};

export default Logout;
