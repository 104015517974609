import { ILegalPayload, ILegalState } from 'interfaces/ILegal';
import { handleActions } from 'redux-actions';
import {
  fetchLegalFailed,
  fetchLegalStart,
  fetchLegalSuccess,
} from 'store/actions/legal';

const initialState: ILegalState = {
  title: null,
  termsAndConditions: null,
  isLegalLoading: false,
  error: false,
};

const reducer = handleActions(
  {
    [fetchLegalStart.toString()]: (state) => ({
      ...state,
      isLegalLoading: true,
    }),
    [fetchLegalSuccess.toString()]: (state, action) => ({
      ...state,
      ...(action.payload as unknown as ILegalPayload).legal,
      error: null,
      isLegalLoading: false,
    }),
    [fetchLegalFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload.error,
      isLegalLoading: false,
    }),
  },
  initialState
);

export default reducer;
