import { ChangeEvent } from 'react';
import RadioOptionGroup from 'components/UI/RadioOptionGroup/RadioOptionGroup';
import { LabelText } from 'components/UI/Brand/text';
import translate from 'translate';
import styles from './styles.module.scss';

interface ISortVoyagesRadioGroup {
  changeHandler: (value: string) => void;
  sortingParam: string;
}

const SortVoyagesRadioGroup = ({
  changeHandler,
  sortingParam,
}: ISortVoyagesRadioGroup) => {
  const sortingOptions = [
    {
      value: 'relevant',
      label: (
        <LabelText className={styles.label}>
          {translate('SearchResult_Sorting_Lable_Relevant')}
        </LabelText>
      ),
    },
    {
      value: 'days',
      label: (
        <LabelText className={styles.label}>
          {translate('SearchResult_Sorting_Lable_Days')}
        </LabelText>
      ),
    },
    {
      value: 'price',
      label: (
        <LabelText className={styles.label}>
          {translate('SearchResult_Sorting_Lable_Price')}
        </LabelText>
      ),
    },
  ];

  return (
    <RadioOptionGroup
      name="SortBy"
      onChange={(event: ChangeEvent<HTMLInputElement>) =>
        changeHandler(event.target.value)
      }
      className={`${styles.sortingGroup} sorting-options`}
      defaultValue={sortingParam}
      options={sortingOptions}
    />
  );
};

export default SortVoyagesRadioGroup;
