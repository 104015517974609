import { Icon, LinkText } from 'components';
import translate from 'translate';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { colors } from 'style/colors';
import { typography } from 'style/typography';

interface IVehicleInputProps {
  onClose: (value: boolean) => void;
}

const Overlay = styled.div`
  position: absolute;
  background-color: ${colors.shared.warmGray2};
  width: 100%;
  z-index: 100;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  padding: 10px;
  border: none;
  background: none;
`;
const OverlayForm = styled.div`
  margin: 30px 30px 60px 30px;
`;

const DiscountLink = styled((props) => <LinkText {...props} />)`
  color: ${colors.shared.warmGray5};
  font-size: 14;
  font-family: ${typography.normal.fontFamily};
  background: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;
export const VehiclesInpitOverlay = ({ onClose }: IVehicleInputProps) => (
  <Overlay>
    <CloseButton>
      <Icon
        iconType="cross"
        height={16}
        width={16}
        onClick={() => onClose(false)}
      />
    </CloseButton>
    <OverlayForm>
      <DiscountLink
        target="_blank"
        rel="noopener noreferrer"
        to={`/learn/article/travel-with-car`}
        as={Link}
      >
        {translate('Search_Vehicle_Rules')}
      </DiscountLink>
    </OverlayForm>
  </Overlay>
);
