import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LOGIN_STATUSES } from 'containers/Login';
import { IAuthState } from 'interfaces/IAuth';
import { IError } from 'interfaces/IError';
import { auth } from 'store/services/auth';
import { dataLayerPush } from 'utils/analytics';
import getErrorMessage from 'utils/getErrorMessage';
import localStorageKeys from 'utils/localStorageKeys';
import { setExpiresData } from 'utils/setExpiresData';
import { setItemsToLocalStorage } from 'utils/setItemsToLocalStorage';

const initialState: IAuthState = {
  loginStatus: LOGIN_STATUSES.WAIT_USER_EMAIL,
  tokenData: null,
  error: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setLoginStatus: (state, action: PayloadAction<LOGIN_STATUSES>) => {
      state.loginStatus = action.payload;
    },
    logout: (state) => {
      localStorage.removeItem(localStorageKeys.idToken);
      localStorage.removeItem(localStorageKeys.expirationDate);
      localStorage.removeItem(localStorageKeys.currentLanguage);
      localStorage.removeItem(localStorageKeys.languageOptions);

      state.tokenData = null;
    },
    resetAuthError: (state) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(auth.matchFulfilled, (state, action) => {
      dataLayerPush('login', {
        eventCategory: 'Login',
        eventAction: 'Success',
      });
      setItemsToLocalStorage(action.payload);
      setExpiresData(action.payload.id_token);
      state.tokenData = action.payload;
      state.error = null;
    });
    builder.addMatcher(auth.matchRejected, (state, action) => {
      const errorMessage = getErrorMessage(
        (action?.payload?.data as IError)?.errorCode
      );

      state.error = errorMessage;
    });
  },
});

export const { setLoginStatus, logout, resetAuthError } = authSlice.actions;

export default authSlice.reducer;
