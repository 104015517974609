import { IOnboardingPopupState } from 'interfaces/IOnboardingPopup';
import { handleActions, ReduxCompatibleReducer } from 'redux-actions';
import {
  fetchOnboardingPopupStart,
  fetchOnboardingPopupSuccess,
  fetchOnboardingPopupFailed,
} from 'store/actions/onboardingPopup';

const initialState: IOnboardingPopupState = {
  infoPopup: {
    title: null,
    description: null,
    pages: [],
  },
  error: false,
  loading: false,
};

const reducer: ReduxCompatibleReducer<
  IOnboardingPopupState,
  IOnboardingPopupState
> = handleActions(
  {
    [fetchOnboardingPopupStart.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchOnboardingPopupSuccess.toString()]: (state, action) => ({
      ...state,
      infoPopup: action.payload.infoPopup,
      loading: false,
    }),
    [fetchOnboardingPopupFailed.toString()]: (state) => ({
      ...state,
      error: true,
      loading: false,
    }),
  },
  initialState
);

export default reducer;
