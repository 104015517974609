import { IInfoPopUp } from 'interfaces/IOnboardingPopup';
import { api } from './api';

export const homeApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAccessibleCabinsInfo: build.query<IInfoPopUp, void>({
      query: () => ({
        url: `Home/AccessibleCabinsInfo`,
      }),
    }),
  }),
});

export const { useGetAccessibleCabinsInfoQuery } = homeApi;

export const {
  endpoints: { getAccessibleCabinsInfo },
} = homeApi;
