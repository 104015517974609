/* eslint-disable import/no-dynamic-require */
/* eslint-disable global-require */
import React, { ReactElement, ReactNode } from 'react';
import { reactStringReplace } from 'utils/reactStringReplace';
import { getLanguage } from 'utils/getLanguage';
import enDefault from './localization/en.json';

type TDictionary = typeof enDefault;
export type TDictionaryKey = {
  [K in keyof TDictionary]: TDictionary[K] extends string ? K : never;
}[keyof TDictionary];

type TTranslationResult<T> = T extends (number | string)[]
  ? string
  : ReactNode | ReactNode[];

const getJson = (): TDictionary => {
  const acceptedLanguage = getLanguage();

  if (acceptedLanguage) {
    try {
      const file = require(`./localization/${acceptedLanguage}.json`);
      return file;
    } catch (error) {
      return enDefault;
    }
  }
  return enDefault;
};

function isClassComponent<T>(item: T) {
  return typeof item === 'function' && !!item.prototype.isReactComponent;
}

function isFunctionComponent<T>(item: T) {
  return (
    typeof item === 'function' &&
    String(item).includes('return React.createElement')
  );
}

function isReactComponent<T>(item: T) {
  return isClassComponent(item) || isFunctionComponent(item);
}

function isElement<T>(item: T) {
  return React.isValidElement(item);
}

function isDOMTypeElement<T>(item: T) {
  return isElement(item) && typeof item === 'string';
}

function isCompositeTypeElement<T>(item: T) {
  return isElement(item) && typeof item === 'function';
}

function isComplexElement<T>(item: T) {
  return (
    isClassComponent(item) ||
    isFunctionComponent(item) ||
    isReactComponent(item) ||
    isElement(item) ||
    isDOMTypeElement(item) ||
    isCompositeTypeElement(item)
  );
}

export function injectReactKeysToArrayResult(
  resultArr: ReactNode[]
): ReactNode[] {
  const resultArrWithKeys = resultArr.map((el: ReactNode, index) => {
    if (Array.isArray(el)) {
      return el.map((_innerElement, innerIndex) => (
        <React.Fragment key={`translate-${index + 1}-${innerIndex + 1}`}>
          {_innerElement}
        </React.Fragment>
      ));
    }

    if (isComplexElement(el)) {
      if ((el as ReactElement).key) {
        return el;
      }
      return React.cloneElement(el as ReactElement, {
        key: `translate-${index + 1}`,
      });
    }

    return <React.Fragment key={`translate-${index + 1}`}>{el}</React.Fragment>;
  });

  return resultArrWithKeys;
}

export function injectTranslateParams<T extends ReactNode[] = string[]>(
  dictionaryValue: string,
  params: T
) {
  let parsedResultValue: TTranslationResult<T> = dictionaryValue;
  params.forEach((param, index) => {
    parsedResultValue = reactStringReplace(
      parsedResultValue,
      `{${index}}`,
      param
    ) as TTranslationResult<T>;
  });

  return parsedResultValue;
}

export default function translate<T extends ReactNode[] = string[]>(
  key: TDictionaryKey,
  ...params: T
): TTranslationResult<T> {
  const dictionaryValue = getJson()[key];
  const translatedValue =
    dictionaryValue && params && params.length > 0
      ? injectTranslateParams(dictionaryValue, params)
      : dictionaryValue;

  if (process.env.NODE_ENV === 'development' && !translatedValue) {
    return 'LOCALIZABLE STRING IS NOT DEFINED!';
  }

  if (Array.isArray(translatedValue)) {
    return injectReactKeysToArrayResult(
      translatedValue as ReactNode[]
    ) as TTranslationResult<T>;
  }

  return translatedValue;
}
