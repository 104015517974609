import { ICabin, ICabinBaseSelector, ICabinCategory } from 'interfaces/ICabin';
import { IPackage } from 'interfaces/IPackage';
import { ITour } from 'interfaces/ITour';
import translate from 'translate';

const getPriceFromCabinGrade = (
  cabins: ICabinCategory,
  cabinSearchParams: ICabin[]
): number => {
  if (!cabinSearchParams) return -1;

  if (
    !cabins.cabinGradesExtended?.length ||
    !cabins.cabinGradesExtended.length
  ) {
    return -1;
  }
  const maximumCabinOccupancy = Math.max(
    ...cabins.cabinGradesExtended.map((cabinGrade) => cabinGrade.maxOccupancy)
  );
  const maxReqGuestNumber = Math.max(
    ...cabinSearchParams.map(
      (cabin) => cabin.adults + cabin.children + cabin.infants
    )
  );

  if (maxReqGuestNumber > maximumCabinOccupancy) return -1;

  const prices = cabins.cabinGradesExtended
    .map((cabinGrade) => cabinGrade.price)
    .filter((cabinGradeProperty) => cabinGradeProperty !== null);

  return Math.min(...prices);
};

const getPriceFromDeparture = (
  tourPackage: IPackage,
  cabinSearchParams: ICabin[]
): number[] | number => {
  const cabinCategories = tourPackage?.ship?.cabinCategories;

  if (!cabinCategories) return -1;

  const res = cabinCategories.map((cabinCategory) =>
    getPriceFromCabinGrade(cabinCategory, cabinSearchParams)
  );
  return res;
};

export const getPriceFromTour = (
  tour: ITour,
  cabinSearchParams: ICabin[],
  deckSpace?: ICabinBaseSelector
): number | string => {
  if (!tour || !tour.packages) {
    return translate('SearchResultItemRow_Soldout');
  }

  const hasCompanionDeckSpace = deckSpace?.companions > 0;
  if (hasCompanionDeckSpace) {
    return 0;
  }

  const prices = tour.packages.map(
    (tourPackage) =>
      tourPackage.priceDetail.price ||
      getPriceFromDeparture(tourPackage, cabinSearchParams)
  );

  if (!prices) {
    return translate('SearchResultItemRow_Soldout');
  }

  const filteredPrices = prices.flat().filter((price) => price !== -1);

  if (filteredPrices && filteredPrices.length === 0) {
    return translate('SearchResultItemRow_Soldout');
  }

  return Math.min(...filteredPrices);
};
