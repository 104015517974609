import clsx from 'clsx';
import { useNavigate } from 'react-router-dom';
import { ButtonText } from 'components/UI/Brand/text';
import { useSelector } from 'react-redux';
import { cancelQuote } from 'store/actions';
import { IRootState } from 'store/types/root';
import Icon from 'components/UI/Icons/Icon';
import { useAppDispatch } from 'hooks/storeHooks';
import translate from 'translate';
import styles from './styles.module.scss';

interface IBackToButton {
  hasIcon?: boolean;
  isCoastal: boolean;
}

const BackToButton = ({ hasIcon, isCoastal }: IBackToButton) => {
  const { quoteID } = useSelector((state: IRootState) => state.bookingFunnel);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const goBackToSearchAndCancelBooking = () => {
    dispatch(cancelQuote(quoteID));
    navigate(isCoastal ? '/book/coastal' : '/book/expedition');
  };

  return (
    <button
      className={clsx(styles.button, {
        [styles.hasIcon]: hasIcon,
      })}
      onClick={() => goBackToSearchAndCancelBooking()}
      data-testid="backToButton"
    >
      {hasIcon && <Icon iconType="arrowUp" className={styles.icon} />}
      <ButtonText>{translate('General_BackToSearch')}</ButtonText>
    </button>
  );
};

export default BackToButton;
