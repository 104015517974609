import { SVGProps } from 'react';

const PaymentIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={23}
    viewBox="0 0 23 23"
    {...props}
  >
    <path
      fill="#000"
      d="M13.5 12.425q-.85 0-1.425-.575-.575-.575-.575-1.425 0-.85.575-1.425.575-.575 1.425-.575.85 0 1.425.575.575.575.575 1.425 0 .85-.575 1.425-.575.575-1.425.575Zm-6.2 3.2q-.65 0-1.125-.475T5.7 14V6.85q0-.675.475-1.15.475-.475 1.125-.475h12.4q.65 0 1.125.475t.475 1.15V14q0 .675-.475 1.15-.475.475-1.125.475Zm1-1h10.4q0-.675.475-1.15Q19.65 13 20.3 13V7.85q-.675 0-1.137-.475-.463-.475-.463-1.15H8.3q0 .675-.475 1.15-.475.475-1.125.475V13q.675 0 1.138.475.462.475.462 1.15Zm10.05 4H4.3q-.65 0-1.125-.475T2.7 17V8.2h1V17q0 .225.188.425.187.2.412.2h14.05Zm-11.05-4h-.6v-8.4h.6q-.25 0-.425.187Q6.7 6.6 6.7 6.85V14q0 .25.175.438.175.187.425.187Z"
      fillRule="evenodd"
    />
  </svg>
);

export default PaymentIcon;
