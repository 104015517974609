import { handleActions } from 'redux-actions';
import { IHeaderMessageState, IOpenHeaderPayload } from 'interfaces/IHeader';
import * as actions from '../actions/headerMessage';

const initialState: IHeaderMessageState = {
  visible: false,
  type: null,
  title: null,
  message: null,
};

const reducer = handleActions<IHeaderMessageState, IOpenHeaderPayload>(
  {
    [actions.openHeader.toString()]: (state, action) => {
      const { type, title, message } = action.payload;
      return {
        ...state,
        type,
        title,
        message,
        visible: true,
      };
    },
    [actions.closeHeader.toString()]: (state) => ({
      ...state,
      visible: false,
    }),
  },
  initialState
);

export default reducer;
