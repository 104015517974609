import { IAgencyInfo } from 'interfaces/IAgencyInfo';
import { createActions, ActionFunctionAny, Action } from 'redux-actions';
import axios from '../../axios-instance';

export const { fetchAgencyStart, fetchAgencySuccess, fetchAgencyFailed } =
  createActions({
    FETCH_AGENCY_START: undefined,
    FETCH_AGENCY_SUCCESS: (agency: IAgencyInfo) => ({ agency }),
    FETCH_AGENCY_FAILED: (error) => ({ error }),
  });

export const fetchAgency =
  () => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchAgencyStart());
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}Agency`)
      .then((res) => {
        dispatch(fetchAgencySuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchAgencyFailed(err));
      });
  };
