import { BodyText, Header2, Header3, Overline } from 'components/UI/Brand/text';
import Dropdown from 'components/UI/Select/Dropdown';
import { IPaginationData } from 'interfaces/IPaginationData';
import { ISearchBarDestination } from 'interfaces/IDestination';
import { IIncludedSailings, IIncludedVoyage } from 'interfaces/IPromotions';
import { useEffect, useState } from 'react';
import { media } from 'style/sizes';
import translate from 'translate';
import {
  BrandProvider,
  LoadingWrapper,
  CustomImage,
  RoundButtonLoadable,
} from 'components';

import { SecondaryButton } from 'components/UI/Buttons/SecondaryButton/SecondaryButton';
import { IOption } from 'interfaces/IOption';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { CLASSICAL_VOYAGE_IDS } from 'utils/constants';
import styles from './styles.module.scss';

interface IPromotionIncludedSailingProps {
  includedSailings: IIncludedSailings;
  includedSailingsLoading: boolean;
  isCoastal: boolean;
  onVoyageClick: (id: string) => void;
  onLoadMorePromotionVoyagesClick: () => void;
  destinations: ISearchBarDestination[];
  selectedDestination: string;
  setSelectedDestination: (selectedDestination: string) => void;
  isDateLoading?: boolean;
}

const hasLoadMoreAvailable = (
  paginationData: IPaginationData,
  isFilterInUse: boolean
) => {
  if (!isFilterInUse && paginationData.offset >= paginationData.total)
    return false;
  if (isFilterInUse) return false;
  return true;
};

const hasFilterEnabled = (selectedDestinationFilter: string) =>
  selectedDestinationFilter && selectedDestinationFilter !== 'All';

const PromotionIncludedSailings = ({
  includedSailingsLoading,
  includedSailings,
  isCoastal,
  onVoyageClick,
  onLoadMorePromotionVoyagesClick,
  destinations,
  selectedDestination,
  setSelectedDestination,
  isDateLoading,
}: IPromotionIncludedSailingProps) => {
  const [destinationsWithAllProperty, setDestinationsWithAllProperty] =
    useState<IOption[]>([]);
  const [filteredVoyages, setFilteredVoyages] = useState<IIncludedVoyage[]>([]);
  const [numberOfVoyagesToGet, setNumberOfVoyagesToGet] = useState<number>(9);
  const [loadMoreAvailable, setLoadMoreAvailable] = useState<boolean>(false);

  const filterUniqueVoyages = (voyages: IIncludedVoyage[]) => {
    const uniqueVoyageIds = new Set();
    return voyages.filter(
      ({ voyageId, travelSuggestionCodes }) =>
        (CLASSICAL_VOYAGE_IDS.includes(voyageId) ||
          travelSuggestionCodes?.length > 0) &&
        !uniqueVoyageIds.has(voyageId) &&
        uniqueVoyageIds.add(voyageId)
    );
  };

  const includedSailingsWithTravelCodes = includedSailings?.voyages
    ? filterUniqueVoyages(includedSailings.voyages)
    : [];

  const totalVoyages = includedSailings?.total ?? 0;

  const getDistinctDestinations = () => {
    if (destinations?.length) {
      const includedDestinations = destinations.map(({ label, value }) => ({
        description: label,
        value,
      }));

      includedDestinations.unshift({
        description: translate('General_SeeAll'),
        value: 'All',
      });

      return includedDestinations;
    }
    return [];
  };

  const getNumberOfVoyagesToGet = () => {
    const totalVoyagesRetrieved = includedSailings?.voyages?.length || 0;
    const voyagesLeft = totalVoyages - totalVoyagesRetrieved;
    const nextNumberOfVoyages = voyagesLeft > 9 ? 9 : voyagesLeft;

    return nextNumberOfVoyages;
  };

  useEffect(() => {
    setNumberOfVoyagesToGet(getNumberOfVoyagesToGet);
  }, [includedSailings?.voyages]);

  useEffect(() => {
    if (destinations) {
      setDestinationsWithAllProperty(getDistinctDestinations);
    }
  }, [destinations]);

  useEffect(() => {
    if (includedSailings) {
      const { offset, total, limit } = includedSailings;
      setLoadMoreAvailable(
        hasLoadMoreAvailable(
          { offset, total, limit },
          hasFilterEnabled(selectedDestination)
        )
      );
    }
  }, [includedSailings, selectedDestination]);

  useEffect(() => {
    const currentFilteredVoyages = includedSailingsWithTravelCodes?.filter(
      (voyage) =>
        !selectedDestination ||
        !hasFilterEnabled(selectedDestination) ||
        voyage.destinationId === selectedDestination
    );

    setFilteredVoyages(currentFilteredVoyages || []);
  }, [selectedDestination, includedSailings?.voyages]);

  const isMobile = useMediaQuery(media.maxMobile);

  return (
    <div className={styles.promotionIncludedSailings}>
      <Header2 as={Header3} id="voyage_list">
        {translate('PromotionsPage_FindYourVoyage')}
      </Header2>
      {!isCoastal && (
        <div className={styles.dropdownSection}>
          <Dropdown
            id="promotion-destination"
            name="promotion-destinations"
            onChange={(event) =>
              setSelectedDestination(event.currentTarget.value)
            }
            placeholder={translate('General_Destination')}
            options={destinationsWithAllProperty}
          />
        </div>
      )}
      <LoadingWrapper
        loading={!loadMoreAvailable && includedSailingsLoading}
        hasScroll={false}
      >
        <div
          className={styles.includedSailingsList}
          aria-labelledby="voyage_list"
          role="list"
        >
          {filteredVoyages.map(
            ({ name, map, tag, usps, hxUsPs, duration, voyageId }) => (
              <article
                className={styles.includedSailingsCard}
                key={name.toLowerCase().replaceAll(' ', '-') + voyageId}
                id={name.toLowerCase().replaceAll(' ', '-')}
              >
                <div
                  key={voyageId}
                  className={styles.includedSailingsCardImageContainer}
                >
                  {!!tag && (
                    <div className={styles.tag}>
                      <BodyText>{tag}</BodyText>
                    </div>
                  )}
                  {!!map && (
                    <CustomImage
                      src={map}
                      height={338}
                      width={338}
                      className={styles.includedSailingsCardImage}
                    />
                  )}
                </div>
                <div className={styles.includedSailingsCardInfoContainer}>
                  <div className={styles.includedSailingsCardInfo}>
                    <Overline>{duration}</Overline>
                    <Header3>{name}</Header3>
                    {!isMobile && usps?.length > 0 && (
                      <ul
                        className={styles.includedSailingsUsp}
                        aria-label={translate('General_Usps')}
                      >
                        {usps
                          .filter((usp) => usp)
                          .map((usp) => (
                            <BodyText
                              as="li"
                              key={usp.toLowerCase().replaceAll(' ', '-')}
                            >
                              {usp}
                            </BodyText>
                          ))}
                      </ul>
                    )}
                    {!isMobile && hxUsPs?.length > 0 && (
                      <ul
                        className={styles.includedSailingsUsp}
                        aria-label={translate('General_Usps')}
                      >
                        {hxUsPs
                          .filter((hxusp) => hxusp)
                          .map((hxusp) => (
                            <BodyText
                              as="li"
                              key={hxusp.toLowerCase().replaceAll(' ', '-')}
                            >
                              {hxusp}
                            </BodyText>
                          ))}
                      </ul>
                    )}
                  </div>
                  <div className={styles.includedSailingsButtonSection}>
                    <BrandProvider
                      fixedBrand={isCoastal ? 'coastal' : 'expedition'}
                    >
                      <RoundButtonLoadable
                        className={styles.button}
                        loading={isDateLoading}
                        onClick={() => onVoyageClick(voyageId)}
                      >
                        {translate('General_CheckPriceAndAvailability')}
                      </RoundButtonLoadable>
                    </BrandProvider>
                  </div>
                </div>
              </article>
            )
          )}
          {numberOfVoyagesToGet > 0 && (
            <div className={styles.seeMoreButtonSection}>
              <LoadingWrapper
                loading={includedSailingsLoading}
                hasScroll={false}
              >
                <BrandProvider
                  fixedBrand={isCoastal ? 'coastal' : 'expedition'}
                >
                  <SecondaryButton onClick={onLoadMorePromotionVoyagesClick}>
                    {translate('General_SeeMore')} ({numberOfVoyagesToGet})
                  </SecondaryButton>
                </BrandProvider>
              </LoadingWrapper>
            </div>
          )}
        </div>
      </LoadingWrapper>
    </div>
  );
};

export default PromotionIncludedSailings;
