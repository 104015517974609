import { SVGProps } from 'react';

const CircleQuestionIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    {...props}
  >
    <path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12 6.477 2 12 2zm0 .875a9.125 9.125 0 1 0 0 18.25 9.125 9.125 0 0 0 0-18.25zm1.054 12.344v1.751h-1.94v-1.751h1.94zM12.266 7c2.151 0 3.052 1.213 3.052 2.577 0 1.2-.55 1.826-1.313 2.464l-.713.588c-.35.288-.463.4-.463.838v.563h-1.476v-.8c0-.8.175-.989.838-1.551l.675-.563c.5-.413.8-.788.8-1.451 0-.776-.525-1.314-1.413-1.314-.963 0-1.65.575-1.638 1.951H9C8.963 8.351 10.177 7 12.266 7z" />
  </svg>
);

export default CircleQuestionIcon;
