import { BodyText } from 'components/UI/Brand/text';
import { AlertBox } from 'components';
import { useNavigate } from 'react-router';
import translate from 'translate';
import { PrimaryButton } from 'components/UI/Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

interface IBackToSearchErrorMessageProps {
  isCoastal: boolean;
}

const BackToSearchErrorMessage = ({
  isCoastal,
}: IBackToSearchErrorMessageProps) => {
  const navigate = useNavigate();
  const route = isCoastal ? '/book/coastal' : '/book/expedition';

  const goBackToSearch = () => {
    navigate(route);
  };

  return (
    <AlertBox aria-live="assertive" type="error" OverrideIcon=" ">
      <div className={styles.error}>
        <BodyText>{translate('MyBookingsList_SomethingWentWrong')}</BodyText>
        <PrimaryButton onClick={goBackToSearch}>
          {translate('General_BackToSearch')}
        </PrimaryButton>
      </div>
    </AlertBox>
  );
};

export default BackToSearchErrorMessage;
