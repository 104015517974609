import { SVGProps } from 'react';

const CheckPaperIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={17}
    viewBox="0 0 16 17"
    {...props}
  >
    <path
      fill="#000"
      d="M10.73 0c.13 0 .25.05.34.14l4.78 4.31c.1.09.15.22.15.35v11.72c0 .26-.22.48-.48.48H.48c-.26 0-.48-.22-.48-.48V.48C0 .22.22 0 .48 0h10.25ZM10 .97H.96v15.06h14.08l-.001-10.421-4.539.001c-.28 0-.5-.22-.5-.5V.97Zm3.46 11.53c.27 0 .5.22.5.5s-.23.5-.5.5H3.54c-.27 0-.5-.22-.5-.5s.23-.5.5-.5h9.92Zm0-3.5c.27 0 .5.22.5.5s-.23.5-.5.5H3.54c-.27 0-.5-.22-.5-.5s.23-.5.5-.5h9.92ZM7.75 5.5c.28 0 .5.22.5.5s-.22.5-.5.5H3.5c-.28 0-.5-.22-.5-.5s.22-.5.5-.5h4.25ZM11 1.383V4.61l3.602-.001L11 1.383Z"
      fillRule="evenodd"
    />
  </svg>
);

export default CheckPaperIcon;
