import {
  memo,
  ReactNode,
  useEffect,
  useRef,
  useState,
  useCallback,
} from 'react';
import { createPortal } from 'react-dom';
import { FocusOn } from 'react-focus-on';
import styles from './styles.module.scss';

export const Modal = memo(
  ({ onClose, children }: { onClose?: () => void; children: ReactNode }) => {
    const domElement = document.getElementById('root');
    const modalRef = useRef<HTMLDivElement>(null);

    return (
      domElement &&
      createPortal(
        <FocusOn
          shards={[modalRef]}
          onClickOutside={onClose}
          onEscapeKey={onClose}
        >
          <div className={styles.useModal} aria-modal="true">
            <div className={styles.card} ref={modalRef}>
              {children}
            </div>
          </div>
        </FocusOn>,
        domElement
      )
    );
  }
);

export const useModal = () => {
  const [isModalVisible, setModalVisible] = useState(false);

  const showModal = () => setModalVisible(true);
  const hideModal = () => setModalVisible(false);

  const handleEsc = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') hideModal();
    },
    [hideModal]
  );

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      const modal = document.querySelector(`.${styles.card}`) as HTMLDivElement;
      if (modal && !modal.contains(event.target as Node)) {
        hideModal();
      }
    },
    [hideModal]
  );

  useEffect(() => {
    if (isModalVisible) {
      window.addEventListener('keydown', handleEsc);
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      window.removeEventListener('keydown', handleEsc);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isModalVisible, handleEsc, handleClickOutside]);

  const RenderModal = ({ children }: { children: ReactNode }) =>
    isModalVisible && <Modal onClose={hideModal}>{children}</Modal>;

  return {
    isModalVisible,
    showModal,
    hideModal,
    RenderModal,
  };
};
