import { ITour } from 'interfaces/ITour';
import { SEARCH_TYPE } from 'utils/constants';
import { dataLayerPush, getAnalyticsProductImpression } from '../analytics';

export const sortResults = (
  results: ITour[],
  sortingType: string,
  searchType: SEARCH_TYPE,
  showAllVoyages: boolean,
  showableVoyagesCount: number
) => {
  let compare;

  switch (sortingType) {
    case 'relevant':
      compare = (a: ITour, b: ITour) => {
        const dateA = +new Date(a.packages[0].departureDate);
        const dateB = +new Date(b.packages[0].departureDate);

        return dateA - dateB;
      };
      break;
    case 'days':
      compare = (a: ITour, b: ITour) => {
        const durationA = parseInt(a.duration.replace(/\D+/g, ''), 10);
        const durationB = parseInt(b.duration.replace(/\D+/g, ''), 10);

        let comparison = 0;
        if (durationA > durationB) {
          comparison = 1;
        } else if (durationA < durationB) {
          comparison = -1;
        }
        return comparison;
      };
      break;
    case 'price':
      compare = (a: ITour, b: ITour) => {
        const priceA = a.price;
        const priceB = b.price;

        let comparison = 0;
        if (priceA > priceB) {
          comparison = 1;
        } else if (priceA < priceB) {
          comparison = -1;
        }
        return comparison;
      };
      break;
    default:
      compare = (a: ITour, b: ITour) => {
        const dateA = new Date(a.packages[0].departureDate).getTime();
        const dateB = new Date(b.packages[0].departureDate).getTime();

        let comparison = 0;
        if (dateA > dateB) {
          comparison = 1;
        } else if (dateA < dateB) {
          comparison = -1;
        }
        return comparison;
      };
  }

  const toursClone = [...results];
  const sortedTours = toursClone.sort(compare);
  const showableTours = showAllVoyages
    ? sortedTours
    : sortedTours.slice(0, showableVoyagesCount);

  const analyticsData = getAnalyticsProductImpression(
    showableTours,
    searchType === 'EXPEDITION'
  );

  dataLayerPush('impressions', analyticsData);

  return showableTours;
};
