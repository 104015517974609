import { format } from 'date-fns';
import { Dispatch, ReactNode, SetStateAction } from 'react';
import { ButtonText } from 'components/UI/Brand/text';
import { getCalendarLocale, getConsecutivePeriods } from 'utils/dates';
import Icon from 'components/UI/Icons/Icon';
import { colors } from 'style/colors';
import translate from 'translate';
import { getLanguage } from 'utils/getLanguage';
import styles from './styles.module.scss';

interface ICalendarInputProps {
  id: string;
  selectedUnixPeriods: number[];
  value: Date;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  focusedInput: string;
  isLoading?: boolean;
}

const CalendarInput = ({
  id,
  selectedUnixPeriods,
  isOpen,
  setIsOpen,
  value,
  focusedInput,
  isLoading = false,
}: ICalendarInputProps) => {
  let buttonText: ReactNode;
  const language = getLanguage();
  const locale = getCalendarLocale(language);

  if (selectedUnixPeriods?.length > 0) {
    buttonText = getConsecutivePeriods(
      selectedUnixPeriods,
      true,
      language
    ) as ReactNode;
  } else {
    buttonText = format(value, 'MMMM, yyyy', { locale });
  }

  return (
    <button
      id={id}
      className={styles.input}
      onClick={() => setIsOpen(!isOpen)}
      disabled={isLoading}
    >
      <ButtonText
        className={selectedUnixPeriods ? styles.labelFlexible : styles.label}
      >
        {isLoading ? translate('General_LoadingData') : buttonText}
      </ButtonText>
      <Icon
        iconType={
          isOpen && focusedInput === 'startDate' ? 'arrowUp' : 'arrowDown'
        }
        color={colors.shared.black}
        width={24}
        height={24}
        className={styles.icon}
      />
    </button>
  );
};

export default CalendarInput;
