import { Label } from 'components/UI/Brand/text';
import Calendar from 'components/UI/Calendar/Calendar';
import translate from 'translate';

interface IDepartureDateProps {
  onDateChange: (date: Date) => void;
  value: Date;
  isLoading?: boolean;
  isDatesDropdown?: boolean;
}

export const DepartureDate = ({
  onDateChange,
  value,
  isLoading,
  isDatesDropdown,
}: IDepartureDateProps) => (
  <>
    <Label htmlFor="booking_search_iconic_departure_date">
      {translate('Coastal_Search_Iconic_Departure_Label')}*
    </Label>
    <Calendar
      onDateChange={onDateChange}
      value={value}
      id="booking_search_iconic_departure_date"
      isLoading={isLoading}
      isDatesDropdown={isDatesDropdown}
    />
  </>
);
