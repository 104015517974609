import { INewsState, INewsPayload, INews } from 'interfaces/INewsState';
import { handleActions, ReduxCompatibleReducer } from 'redux-actions';
import {
  fetchNewsStart,
  fetchNewsSuccess,
  fetchNewsFailed,
} from '../actions/news';

const initialState: INewsState = {
  expeditionNews: [],
  coastalNews: [],
  coastalFilter: 0,
  expeditionFilter: 0,
  error: false,
  loading: false,
  totalExpeditionNews: null,
  totalCoastalNews: null,
};

const reducer: ReduxCompatibleReducer<INewsState, INewsPayload> = handleActions<
  INewsState,
  INewsPayload
>(
  {
    [fetchNewsStart.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchNewsSuccess.toString()]: (state, action) => {
      const { news, total, isCoastal, filter } = action.payload;

      let newsMap = new Map<string, INews>();
      if (isCoastal) {
        newsMap = new Map(state.coastalNews);
      } else {
        newsMap = new Map(state.expeditionNews);
      }
      news.forEach((newsItem) => newsMap.set(newsItem.id, newsItem));

      let result;

      if (isCoastal) {
        result = {
          coastalNews: Array.from(newsMap.entries()),
          totalCoastalNews: total,
          coastalFilter: state.coastalFilter + filter,
        };
      } else {
        result = {
          expeditionNews: Array.from(newsMap.entries()),
          totalExpeditionNews: total,
          expeditionFilter: state.expeditionFilter + filter,
        };
      }

      return {
        ...state,
        ...result,
        error: null,
        loading: false,
      };
    },
    [fetchNewsFailed.toString()]: (state) => ({
      ...state,
      error: true,
      loading: false,
    }),
  },
  initialState
);

export default reducer;
