import { SVGProps } from 'react';

const CircleWithCheckmarkIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.69995C6.31142 1.69995 1.69995 6.31142 1.69995 12C1.69995 17.6885 6.31142 22.2999 12 22.2999C17.6885 22.2999 22.2999 17.6885 22.2999 12C22.2999 6.31142 17.6885 1.69995 12 1.69995ZM0.699951 12C0.699951 5.75913 5.75913 0.699951 12 0.699951C18.2408 0.699951 23.2999 5.75913 23.2999 12C23.2999 18.2408 18.2408 23.2999 12 23.2999C5.75913 23.2999 0.699951 18.2408 0.699951 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7651 6.95212C16.9993 7.09854 17.0704 7.40703 16.9239 7.64116L11.4245 16.4348C11.3404 16.5693 11.1975 16.6558 11.0394 16.6682C10.8814 16.6805 10.7268 16.6171 10.6229 16.4973L7.12232 12.4623C6.94137 12.2537 6.96377 11.9379 7.17236 11.7569C7.38095 11.576 7.69673 11.5984 7.87769 11.807L10.9352 15.3313L16.0761 7.11093C16.2225 6.8768 16.531 6.8057 16.7651 6.95212Z"
    />
  </svg>
);

export default CircleWithCheckmarkIcon;
