import { AxiosError } from 'axios';
import { IPromotion } from './IPromotions';
import { IQuote } from './IQuote';

export enum CabinAgeCategory {
  adults = 'adults',
  children = 'children',
  infants = 'infants',
}

export enum AgeCategory {
  child = 'CHILD',
  adult = 'ADULT',
  infant = 'INFANT',
}

export interface IPersonValidationItem {
  errorMessage: string;
  valid: boolean;
}

export interface IPersonValidation {
  age: IPersonValidationItem;
  dateOfBirth: IPersonValidationItem;
  email: IPersonValidationItem;
  extras: IPersonValidationItem;
  firstName: IPersonValidationItem;
  lastName: IPersonValidationItem;
  gender: IPersonValidationItem;
  nationality: IPersonValidationItem;
  language: IPersonValidationItem;
  passportExpDate: IPersonValidationItem;
  passportIssueDate: IPersonValidationItem;
  passportNumber: IPersonValidationItem;
  phoneNumber: IPersonValidationItem;
  country: IPersonValidationItem;
  street: IPersonValidationItem;
  postalCode: IPersonValidationItem;
  city: IPersonValidationItem;
  color: IPersonValidationItem;
  registrationNumber: IPersonValidationItem;
  customerId: IPersonValidationItem;
  isCustomerIdCheck: IPersonValidationItem;
}

export interface IOptionItem {
  value: string | number;
  label: string;
}

export interface IPerson {
  title: string;
  ambassadorId?: string;
  contactPerson: boolean;
  dateOfBirth: string;
  email: string;
  extras: string[];
  firstName: string;
  lastName: string;
  gender: string;
  id: string;
  nationality: IOptionItem;
  language: string;
  passportExpDate: string;
  passportIssueDate: string;
  passportNumber: string;
  phoneNumber: string;
  country?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  validation: IPersonValidation;
  vehicleId: string;
  rentalCar?: boolean;
  registrationNumber: string;
  color: string;
  type: string;
  guestType: string;
  customerId?: string;
  isCustomerIdCheck: boolean;
}

export interface IPassengerState {
  passengerQuoteData: IQuote;
  error: AxiosError;
  loading: boolean;
  bookingId?: string;
}
export type IPassengerPrice = Pick<IPassenger, 'price' | 'passengerId'> & {
  promotions?: string[] | IPromotion;
};
export interface IPassenger {
  ageCategory: AgeCategory;
  customerId?: string;
  email?: string;
  firstName?: string;
  guestType?: string;
  isLead: boolean;
  lastName?: string;
  country?: string;
  street?: string;
  postalCode?: string;
  city?: string;
  passengerId: string;
  price: number;
  title: string;
  ambassadorId?: string;
}

export interface IPassengerInput {
  data: IPassenger;
  isChecked: boolean;
}
