import { SVGProps } from 'react';

const EyeIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={15}
    viewBox="0 0 24 15"
    fill="currentColor"
    {...props}
  >
    <path
      d="M12 0c2.645 0 5.482 1.227 8.182 3.545a23.29 23.29 0 0 1 3.354 3.491l.219.328-.219.327a23.29 23.29 0 0 1-3.354 3.49c-2.7 2.319-5.537 3.546-8.182 3.546-2.645 0-5.482-1.227-8.182-3.545A23.29 23.29 0 0 1 .464 7.69l-.219-.327.219-.328a23.29 23.29 0 0 1 3.354-3.49C6.518 1.226 9.355 0 12 0Zm0 1.09c-5.018 0-9.327 4.937-10.39 6.274C2.672 8.7 6.981 13.636 12 13.636c5.018 0 9.327-4.936 10.39-6.272C21.328 6.027 17.019 1.09 12 1.09Zm0 1.746a4.527 4.527 0 1 1 0 9.055 4.527 4.527 0 0 1 0-9.055Zm1.315 1.353A3.436 3.436 0 1 0 12 10.8a3.41 3.41 0 0 0 3.436-3.436c0-1.39-.837-2.643-2.121-3.175Z"
      fillRule="evenodd"
    />
  </svg>
);

export default EyeIcon;
