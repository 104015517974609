import { createActions, ActionFunctionAny, Action } from 'redux-actions';
import axios from '../../axios-instance';

export const {
  fetchMapDataStart,
  fetchMapDataSuccess,
  fetchMapDataFailed,
  clearMapData,
} = createActions({
  FETCH_MAP_DATA_START: undefined,
  FETCH_MAP_DATA_SUCCESS: (result) => result,
  FETCH_MAP_DATA_FAILED: undefined,
  CLEAR_MAP_DATA: undefined,
});

export const getCoastalMapData =
  (fromPortId: string, toPortId: string) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchMapDataStart());
    const portParams = new URLSearchParams({ fromPortId, toPortId });
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}coastalmap/coastalports`, {
        params: portParams,
      })
      .then((response) => {
        if (response.status === 200) {
          const { fromPlace, toPlace, intermediateQuays, pointsOnLink } =
            response.data;
          dispatch(
            fetchMapDataSuccess({
              fromPlace,
              toPlace,
              polyline: pointsOnLink.points,
              intermediateQuays,
              quays: intermediateQuays,
            })
          );
        } else dispatch(clearMapData());
      })
      .catch(() => {
        dispatch(fetchMapDataFailed());
      });
  };

export const getCoastalMapDataViaKirkenes =
  (fromPortId: string, toPortId: string, kirkenesId: string) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchMapDataStart());
    const upTourPortParams = new URLSearchParams({
      fromPortId,
      toPortId: kirkenesId,
    });
    const downTourPortParams = new URLSearchParams({
      fromPortId: kirkenesId,
      toPortId,
    });
    const upTourPromise = axios.get(
      `${process.env.REACT_APP_API_BASE_URL}coastalmap/coastalports`,
      {
        params: upTourPortParams,
      }
    );
    const downTourPromise = axios.get(
      `${process.env.REACT_APP_API_BASE_URL}coastalmap/coastalports`,
      {
        params: downTourPortParams,
      }
    );
    Promise.all([upTourPromise, downTourPromise])
      .then((response) => {
        if (response[0].status === 200 && response[1].status === 200) {
          const upTourData = response[0].data;
          const downTourData = response[1].data;
          const { fromPlace } = upTourData;
          const { toPlace } = downTourData;
          const intermediateQuays = [
            ...upTourData.intermediateQuays,
            ...downTourData.intermediateQuays,
          ].filter(
            (quay, i, quayList) =>
              quayList.findIndex((item) => item.id === quay.id) === i &&
              quay.id !== fromPlace.id &&
              quay.id !== toPlace.id
          );
          dispatch(
            fetchMapDataSuccess({
              fromPlace,
              toPlace,
              returnPlace: downTourData.fromPlace,
              polyline: upTourData.pointsOnLink.points,
              intermediateQuays,
              polylineReturn: downTourData.pointsOnLink.points,
            })
          );
        } else {
          dispatch(clearMapData());
        }
      })
      .catch(() => {
        dispatch(fetchMapDataFailed());
      });
  };
