/* eslint-disable no-param-reassign */
import axios, { AxiosRequestConfig } from 'axios';
import { getToken } from './utils/getToken';
import { getLanguage } from './utils/getLanguage';
import {
  getMetricSystem,
  getFullDateFormat,
} from './utils/multilanguage-support';

const instance = axios.create();

const setLanguage = (config: AxiosRequestConfig) => {
  const acceptedLanguage = getLanguage();
  config.headers['Accept-Language'] = acceptedLanguage;
};

instance.interceptors.request.use(
  (config) => {
    const idToken = getToken();
    setLanguage(config);
    config.headers['X-Product-Type'] = process.env.REACT_APP_COMPANY;
    if (idToken) {
      config.headers.Authorization = `Bearer ${idToken}`;
      config.headers.IsMetricSystem = getMetricSystem();
      config.headers.DateNotation = getFullDateFormat();
    }
    return config;
  },
  (error) => Promise.reject(new Error(error))
);

instance.interceptors.response.use(
  (config) => config,
  (error) => Promise.reject(new Error(error))
);

export default instance;
