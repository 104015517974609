import ReactDatePicker, {
  registerLocale,
  ReactDatePickerProps,
} from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { enGB, fr, da, nb, sv, de } from 'date-fns/locale';
import languageKeys from 'utils/languageKeys';
import { useMediaQuery } from 'hooks/useMediaQuery';
import { media } from 'style/sizes';
import { startOfToday } from 'date-fns';
import { DatePickerCalendarContainer } from './DatePickerCalendarContainer';
import { DatePickerInput } from './DatePickerInput';

registerLocale('fr', fr);
registerLocale('da', da);
registerLocale('enGB', enGB);
registerLocale('nb', nb);
registerLocale('sv', sv);
registerLocale('de', de);

export interface IReactDatePickerProps extends ReactDatePickerProps {
  language: string;
}

export const DatePicker = (props: IReactDatePickerProps) => {
  const calendarLocale = {
    'en-US': 'enGB',
    [languageKeys.EN_AMERICAS]: 'enGB',
    [languageKeys.EN_APAC]: 'enGB',
    [languageKeys.EN_UK]: 'enGB',
    [languageKeys.EN_EMEA]: 'enGB',
    [languageKeys.en]: 'enGB',
    [languageKeys.DK]: 'da',
    [languageKeys.AT]: 'de',
    [languageKeys.DE_CH]: 'de',
    [languageKeys.de]: 'de',
    [languageKeys.FR]: 'fr',
    [languageKeys.NO]: 'nb',
    [languageKeys.SE]: 'sv',
  };
  const isMobile = useMediaQuery(media.maxTablet);
  return (
    <ReactDatePicker
      customInput={<DatePickerInput />}
      calendarContainer={DatePickerCalendarContainer}
      minDate={startOfToday()}
      dateFormat={props.dateFormat}
      monthsShown={isMobile ? 1 : 2}
      id={props.id}
      locale={calendarLocale[props.language]}
      {...props}
    />
  );
};
