import { SVGProps } from 'react';

const ArrowRightLongIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="36"
    height="24"
    viewBox="0 0 36 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.5 12C0.5 11.7239 0.723858 11.5 1 11.5L33.1111 11.5C33.3873 11.5 33.6111 11.7239 33.6111 12C33.6111 12.2761 33.3873 12.5 33.1111 12.5L1 12.5C0.723858 12.5 0.5 12.2761 0.5 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.1567 4.63649C26.3574 4.44689 26.6739 4.45593 26.8635 4.65669L33.4746 11.6567C33.6566 11.8494 33.6566 12.1506 33.4746 12.3433L26.8635 19.3433C26.6739 19.5441 26.3574 19.5531 26.1567 19.3635C25.9559 19.1739 25.9469 18.8574 26.1365 18.6567L32.4234 12L26.1365 5.34331C25.9469 5.14255 25.9559 4.8261 26.1567 4.63649Z"
    />
  </svg>
);

export default ArrowRightLongIcon;
