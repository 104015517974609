import { MarginProps, spacing } from 'style/spacing';
import styled from 'styled-components';
import { getTypographyStyling } from '../fontThemes/getTypographyStyling';

type IFontWeight = 'regular' | 'medium' | 'bold' | 'black';

export interface TextProps extends MarginProps {
  fontWeight?: IFontWeight;
}

interface OverlineProps extends MarginProps {
  bold?: boolean;
}

const getFontWeightInNumber = (fontWeight: IFontWeight) => {
  switch (fontWeight) {
    case 'medium':
      return 500;
    case 'bold':
      return 700;
    case 'black':
      return 900;
    case 'regular':
    default:
      return 400;
  }
};

export const BodyText = styled.p<TextProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'body')}
  font-weight: ${({ fontWeight }) =>
    fontWeight && getFontWeightInNumber(fontWeight)}
  ${spacing}
`;

export const InfoText = styled.p<TextProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'info')}
  font-weight: ${({ fontWeight }) =>
    fontWeight && getFontWeightInNumber(fontWeight)}
  ${spacing}
`;

export const TabularText = styled.p<TextProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'tabular')}
  font-weight: ${({ fontWeight }) =>
    fontWeight && getFontWeightInNumber(fontWeight)}
  ${spacing}
`;

export const Overline = styled.p<OverlineProps>`
  ${({ theme, bold }) =>
    getTypographyStyling(
      theme.brandTypography,
      bold ? 'overlineBold' : 'overline'
    )}
  ${spacing}
`;

export const Caption = styled.p<MarginProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'caption')}
  ${spacing}
`;

export const LabelText = styled.p<MarginProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'label')}
  ${spacing}
`;

export const TagText = styled.p<MarginProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'tag')}
  ${spacing}
`;

export const ButtonText = styled.span<MarginProps>`
  ${({ theme }) => getTypographyStyling(theme.brandTypography, 'button')}
  ${spacing}
`;
