/*
 * algorithms: SHA-1, SHA-256, SHA-384, SHA-512
 * https://developer.mozilla.org/en-US/docs/Web/API/SubtleCrypto/digest
 */
export const createHash = async (text: string, algorithm: string) => {
  const msgUint8 = new TextEncoder().encode(text); // encode as (utf-8) Uint8Array
  const hashBuffer = await crypto.subtle.digest(algorithm, msgUint8); // hash the message
  const hashArray = Array.from(new Uint8Array(hashBuffer)); // convert buffer to byte array
  const hashHex = hashArray
    .map((b) => b.toString(16).padStart(2, '0'))
    .join(''); // convert bytes to hex string
  return hashHex;
};
