import { FlatButton } from 'components/UI';
import styles from './styles.module.scss';

interface IInfoListItem {
  name: string;
  setIsShowDetails: () => void;
}

const InfoListItem = ({ name, setIsShowDetails }: IInfoListItem) => (
  <FlatButton
    onClick={setIsShowDetails}
    icon="arrowRightShort"
    iconPosition="right"
    iconWidth={24}
    iconHeight={24}
    classes={styles.infoListItem}
  >
    {name}
  </FlatButton>
);

export default InfoListItem;
