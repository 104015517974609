import { SVGProps } from 'react';

const FlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={22}
    viewBox="0 0 19 22"
    fill="currentColor"
    {...props}
  >
    <path
      d="M.506 0c.28 0 .5.22.5.5v.421c1.442-.23 5.27-.546 7.7 1.999 2.94 3.07 8.81 2.54 8.87 2.53a.506.506 0 0 1 .47.77c-.17.26-4.11 6.46-8.93 6.46h-.06c-1.15-.01-1.99-.2-2.74-.36-1.495-.32-2.584-.565-5.309.93v7.47c0 .28-.22.5-.5.5s-.5-.22-.5-.5v-7.698a.493.493 0 0 1-.002-.15L.006.5c0-.28.22-.5.5-.5Zm.5 1.935.002 10.17c2.716-1.386 3.965-1.106 5.518-.775.73.16 1.49.33 2.54.34h.05c3.3 0 6.3-3.54 7.51-5.18-1.95 0-6.14-.26-8.65-2.88-2.175-2.277-5.844-1.866-6.97-1.675Z"
      fillRule="evenodd"
    />
  </svg>
);

export default FlagIcon;
