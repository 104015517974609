import { handleActions, ReduxCompatibleReducer } from 'redux-actions';
import {
  agencySearchingStart,
  agencySearchingSuccess,
  agencySearchingFailed,
  agencySearchingClear,
} from '../actions/agencySearching';

import { IAgencySearchingState } from '../../interfaces/IAgencySearch';

const initialState: IAgencySearchingState = {
  agencies: undefined,
  error: undefined,
  loading: false,
};

const reducer: ReduxCompatibleReducer<
  IAgencySearchingState,
  IAgencySearchingState
> = handleActions(
  {
    [agencySearchingStart.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [agencySearchingSuccess.toString()]: (state, action) => ({
      ...state,
      agencies: action.payload.agencies,
      loading: false,
    }),
    [agencySearchingFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
    }),
    [agencySearchingClear.toString()]: (state) => ({
      ...state,
      error: undefined,
      agencies: undefined,
      loading: false,
    }),
  },
  initialState
);

export default reducer;
