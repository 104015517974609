import { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { colors } from 'style/colors';
import { media } from 'style/sizes';
import { typography } from 'style/typography';
import styled, { css } from 'styled-components';
import { getContrastYIQ } from 'utils/getContrastYIQ';

type TButtonSize = 'normal' | 'small';

interface IRoundButtonInverted extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonColor?: string;
  size?: TButtonSize;
  classes?: string;
  selected?: boolean;
  isClickable?: boolean;
  dataTestid?: string;
  to?: string;
  textColor?: string;
}

interface IStyledProps extends IRoundButtonInverted {
  $isClickable?: boolean;
  $isContrast?: boolean;
  $textColor?: string;
  $buttonColor?: string;
}

const baseButtonStyles = css<IStyledProps>`
  color: ${({ $textColor }) => $textColor || colors.shared.textBlack};
  font-size: 16px;
  line-height: ${({ size }) =>
    size === 'normal'
      ? typography.normal.lineHeight.normal
      : typography.normal.lineHeight.small};
  font-family: ${typography.normal.fontFamily};
  font-weight: 500;
  background-color: ${({ selected, $buttonColor }) =>
    selected ? $buttonColor : colors.shared.white};
  padding: ${({ size }) => (size === 'normal' ? '12px 30px' : '0 16px')};
  border: 2px solid ${({ $buttonColor }) => $buttonColor};
  height: 40px;
  min-width: 140px;
  border-radius: 50px;
  text-align: center;
  cursor: ${({ $isClickable }) => ($isClickable ? 'auto' : 'pointer')};
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  cursor: ${({ selected }) => !selected && 'pointer'};
  &:hover {
    background-color: ${({ $buttonColor }) => $buttonColor};
    color: ${({ $isContrast }) =>
      $isContrast ? colors.shared.textBlack : colors.shared.white};
  }
  @media ${media.maxTablet} {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const Button = styled.button<IStyledProps>`
  ${baseButtonStyles}
`;

const LinkWrapper = styled(Link)<IStyledProps>`
  ${baseButtonStyles}
`;

const ButtonChildren = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const RoundButtonInverted = ({
  size = 'normal',
  children,
  classes,
  type,
  buttonColor,
  onClick,
  isClickable = true,
  dataTestid,
  textColor = colors.shared.textBlack,
  to,
  ...rest
}: IRoundButtonInverted) => {
  const buttonChildren = <ButtonChildren>{children}</ButtonChildren>;

  const isContrast = getContrastYIQ(buttonColor);

  return to ? (
    <LinkWrapper
      to={to}
      data-testid={dataTestid}
      $buttonColor={buttonColor}
      className={classes}
      size={size}
      $isClickable={isClickable}
      $isContrast={isContrast}
      $textColor={textColor}
    >
      {buttonChildren}
    </LinkWrapper>
  ) : (
    <Button
      data-testid={dataTestid}
      className={classes}
      type={type}
      size={size}
      $buttonColor={buttonColor}
      onClick={onClick}
      $isClickable={isClickable}
      $isContrast={isContrast}
      $textColor={textColor}
      {...rest}
    >
      {buttonChildren}
    </Button>
  );
};
