import { TextareaHTMLAttributes } from 'react';
import { colors } from 'style/colors';
import { typography } from 'style/typography';
import styled from 'styled-components';

export interface TextareaProps
  extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  hasError?: boolean;
}

const StyledTextarea = styled.textarea<TextareaProps>`
  border: 1.4px solid
    ${(props) =>
      props.hasError
        ? props?.theme?.brandColors?.red?.regular || colors.shared.red
        : colors.shared.warmGray6};
  color: ${(props) =>
    props?.theme?.brandColors?.black?.text || colors.shared.textBlack};
  background-color: ${(props) =>
    props?.theme?.brandColors?.white || colors.shared.white};
  width: 100%;
  margin: 0;
  padding: 15px 20px;
  box-sizing: border-box;
  font-family: ${typography.normal.fontFamily};
  font-size: ${typography.normal.fontSize.normal};
  line-height: ${typography.normal.lineHeight.normal};
  resize: none;
  border-radius: 0;
  appearance: none;
`;

const Textarea = ({ hasError = false, rows = 5, ...rest }: TextareaProps) => (
  <StyledTextarea
    {...rest}
    rows={rows}
    hasError={hasError}
    aria-invalid={hasError}
  />
);

export default Textarea;
