import { SVGProps } from 'react';

const CalendarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 6.00008C1.5 4.98794 2.32119 4.16675 3.33333 4.16675H20.6667C21.6788 4.16675 22.5 4.98794 22.5 6.00008V20.6667C22.5 21.6789 21.6788 22.5001 20.6667 22.5001H3.33333C2.32119 22.5001 1.5 21.6789 1.5 20.6667V6.00008ZM3.33333 5.16675C2.87348 5.16675 2.5 5.54022 2.5 6.00008V20.6667C2.5 21.1266 2.87348 21.5001 3.33333 21.5001H20.6667C21.1265 21.5001 21.5 21.1266 21.5 20.6667V6.00008C21.5 5.54022 21.1265 5.16675 20.6667 5.16675H3.33333Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 8.66675C1.5 8.39061 1.72386 8.16675 2 8.16675H22C22.2761 8.16675 22.5 8.39061 22.5 8.66675C22.5 8.94289 22.2761 9.16675 22 9.16675H2C1.72386 9.16675 1.5 8.94289 1.5 8.66675Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7 1.5C7.27614 1.5 7.5 1.72386 7.5 2V6C7.5 6.27614 7.27614 6.5 7 6.5C6.72386 6.5 6.5 6.27614 6.5 6V2C6.5 1.72386 6.72386 1.5 7 1.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17 1.5C17.2761 1.5 17.5 1.72386 17.5 2V6C17.5 6.27614 17.2761 6.5 17 6.5C16.7239 6.5 16.5 6.27614 16.5 6V2C16.5 1.72386 16.7239 1.5 17 1.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.66675 10.8333C8.94289 10.8333 9.16675 11.0571 9.16675 11.3333V19.3333C9.16675 19.6094 8.94289 19.8333 8.66675 19.8333C8.39061 19.8333 8.16675 19.6094 8.16675 19.3333V11.3333C8.16675 11.0571 8.39061 10.8333 8.66675 10.8333Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.3333 10.8333C15.6094 10.8333 15.8333 11.0571 15.8333 11.3333V19.3333C15.8333 19.6094 15.6094 19.8333 15.3333 19.8333C15.0571 19.8333 14.8333 19.6094 14.8333 19.3333V11.3333C14.8333 11.0571 15.0571 10.8333 15.3333 10.8333Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.16675 15.3333C4.16675 15.0571 4.39061 14.8333 4.66675 14.8333H19.3334C19.6096 14.8333 19.8334 15.0571 19.8334 15.3333C19.8334 15.6094 19.6096 15.8333 19.3334 15.8333H4.66675C4.39061 15.8333 4.16675 15.6094 4.16675 15.3333Z"
    />
  </svg>
);

export default CalendarIcon;
