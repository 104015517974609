import translate from '../../translate';

interface IAccessibleHelperText {
  type: string;
  completed?: boolean;
}

const getMessage = (type: string, completed: boolean) => {
  switch (type) {
    case 'accessibleCabin':
      return translate('SearchBar_CabinGuestSelector_Accessibility');
    case 'info':
      return translate('General_Info');
    case 'newTab':
      return translate('New_Tab');
    case 'completed':
      return completed
        ? translate('General_Completed')
        : translate('General_Current');
    default:
      return '';
  }
};
const AccessibleHelperText = ({ type, completed }: IAccessibleHelperText) => (
  <span className="visually-hidden">{getMessage(type, completed)}</span>
);

export default AccessibleHelperText;
