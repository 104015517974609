import { BOOKING_STEPS, CABIN_GRADE, CABIN_TYPE } from 'interfaces/IBooking';
import translate from 'translate';

const type = translate('General_Type').toLowerCase();
const grade = translate('General_Grade').toLowerCase();

export const shouldEditBookingStepModalBeOpened = (
  selectedBookingStep: BOOKING_STEPS,
  currentBookingStep: BOOKING_STEPS
) => {
  const hasAcceptedTermsForEditingBookingStep = Boolean(
    localStorage.getItem('hasAcceptedTermsForEditingBookingSteps')
  );

  if (hasAcceptedTermsForEditingBookingStep) return false;

  const listOfBookingStepsThatNeedsConfirmationFromUserToContinue = [
    CABIN_TYPE,
    CABIN_GRADE,
  ];

  return (
    listOfBookingStepsThatNeedsConfirmationFromUserToContinue.includes(
      selectedBookingStep
    ) && currentBookingStep !== selectedBookingStep
  );
};

export const getBookingStepTitle = (
  selectedBookingStep: BOOKING_STEPS
): string => {
  let title = '';

  switch (selectedBookingStep) {
    case CABIN_TYPE:
      title = translate('Booking_EditStep_Title', type);
      break;
    case CABIN_GRADE:
      title = translate('Booking_EditStep_Title', grade);
      break;

    default:
      break;
  }

  return title;
};

export const getBookingStepDescription = (
  selectedBookingStep: BOOKING_STEPS
): string => {
  let description = '';

  switch (selectedBookingStep) {
    case CABIN_TYPE:
      description = translate('Booking_EditStep_Description_CabinType', type);
      break;
    case CABIN_GRADE:
      description = translate('Booking_EditStep_Description_CabinGrade', grade);
      break;

    default:
      break;
  }

  return description;
};
