import { SVGProps } from 'react';

const WatchIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      fill="#000"
      d="M10 0c5.524 0 10 4.476 10 10s-4.476 10-10 10S0 15.524 0 10 4.476 0 10 0Zm0 .952C5 .952.952 5 .952 10S5 19.048 10 19.048 19.048 15 19.048 10 15 .952 10 .952ZM9.981 3.81c.267 0 .476.209.476.476V9.8l3.667 3.676a.46.46 0 0 1 0 .667.46.46 0 0 1-.667 0l-3.81-3.81A.447.447 0 0 1 9.506 10V4.286c0-.267.21-.476.476-.476Z"
      fillRule="evenodd"
    />
  </svg>
);

export default WatchIcon;
