import { Icon } from 'components';
import { TIcon } from 'components/UI/Icons/Icon.type';
import styled from 'styled-components';
import { TAlertType } from './utils/alertType';

interface AlertIconProps {
  alertType: TAlertType;
}

const AlertIconWrapper = styled.div`
  margin-top: 2px;
  flex: 0 0 auto;
`;

const AlertIcon = ({ alertType }: AlertIconProps) => {
  let icon: TIcon = 'circleWithExclamationSmall';

  if (alertType === 'error') {
    icon = 'circleWithCrossSmall';
  }

  if (alertType === 'success') {
    icon = 'circleWithCheckmarkSmall';
  }

  if (alertType === 'warning') {
    icon = 'triangleWithExclamationSmall';
  }

  return (
    <AlertIconWrapper>
      <Icon iconType={icon} />
    </AlertIconWrapper>
  );
};

export default AlertIcon;
