import { differenceInDays } from 'date-fns';
import { IBookingDetailsData } from 'interfaces/IBookingDetails';

export const getDaysLeft = (booking: IBookingDetailsData) => {
  const isDepositPayed =
    booking.depositAmount > 0 &&
    booking.payments &&
    booking.payments.totalPaymentsReceived >= booking.depositAmount;
  const isFullyPaid = booking.payments && booking.payments.balance === 0;

  if (isFullyPaid) {
    return null;
  }

  let dueDate = null;
  if (!isDepositPayed && booking.depositAmount > 0 && booking.depositDueDate) {
    dueDate = booking.depositDueDate;
  } else if (
    (isDepositPayed || booking.depositAmount === 0) &&
    booking.finalDueDate &&
    booking.payments.balance > 0
  ) {
    dueDate = booking.finalDueDate;
  }

  if (!dueDate) {
    return null;
  }

  return differenceInDays(new Date(dueDate), new Date());
};

export const getDaysLeftForNewBooking = ({
  depositAmount,
  totalPaymentsReceived,
  balance,
  depositDueDate,
  finalDueDate,
}: {
  depositAmount: number;
  totalPaymentsReceived: number;
  balance: number;
  depositDueDate: string;
  finalDueDate: string;
}): number | null => {
  if (balance === 0) {
    return null;
  }

  const isDepositPayed =
    (depositAmount > 0 && totalPaymentsReceived >= depositAmount) ||
    depositAmount === 0;

  const dueDate =
    !isDepositPayed && depositAmount > 0 ? depositDueDate : finalDueDate;

  if (!dueDate) {
    return null;
  }

  return differenceInDays(new Date(dueDate), new Date());
};
