import { IPassengerPrice } from './IPassenger';
import {
  IAddOnSelection,
  IExcursionSelection,
  IMealSelection,
  IMediaContent,
  IQuote,
  IQuoteExtras,
} from './IQuote';

import { IExcursion } from './package/IExcursion';

export interface IExtrasState {
  currentQuoteData: IQuote;
  error: boolean;
  extrasData: IQuoteExtras;
  extrasList: IExtrasList;
  loading: boolean;
  quoteExtrasSuccess: boolean;
}

export interface IExtrasPayload {
  currentQuoteData: IQuote;
  extras: {
    excursions: IExcursion[];
    meals: IMealSummary[];
    addons: IAddOnSummary[];
  };
  extrasQuoteData: IExcursionSelection | IMealSelection | IAddOnSelection;
  extraType: string;
  id: string;
  quoteId: string;
  voyageId: string;
}

export enum IExtrasType {
  Addons = 'addons',
  Excursion = 'excursions',
  Meals = 'meals',
}

export interface IAddOnSummary {
  addOnId: string;
  bookingCode?: string;
  category?: string;
  code: string;
  cruiseType?: string;
  description?: string;
  id?: string;
  media: IMediaContent[];
  passengers: IPassengerPrice[];
  price?: number;
  quantity?: number;
  title: string;
}

export interface IMealSummary {
  bookingCode?: string;
  category?: string;
  code?: string;
  cruiseType?: string;
  description: string;
  id?: string;
  mealCode: string;
  mealId: string;
  media: IMediaContent[];
  passengers: IPassengerPrice[];
  price?: number;
  quantity?: number;
  title: string;
}

export interface ISelectedExtra {
  name: string;
  price: number;
  type: string;
}

export interface IExtrasList {
  addons: IAddOnSummary[];
  excursions: IExcursion[];
  meals: IMealSummary[];
}

export interface IExtraInformationData {
  duration?: string;
  extra: IMealSummary | IAddOnSummary | IExcursion;
  extraType: string;
  images: { imageUrl: string }[];
}

export interface IExtrasSectionData {
  ariaLabelledby: string;
  caption: string;
  extras: IExtrasItemSummary[];
  title: string;
  extraType: IExtrasType;
}

export interface IExtrasItemSummary {
  addOnId?: string;
  bookingCode?: string;
  category?: string;
  code?: string;
  cruiseType?: string;
  description?: string;
  difficulty?: string;
  endDateTime?: string;
  endPort?: string;
  excursionId?: string;
  location?: string;
  mealCode?: string;
  mealId?: string;
  media: IMediaContent[];
  passengers: IPassengerPrice[];
  price?: number;
  productCode?: string;
  quantity?: number;
  startDateTime?: string;
  startPort?: string;
  title: string;
  duration?: string;
}

export interface IExtraItemsData {
  caption: string;
  duration?: string;
  extra: IExtrasItemSummary;
  extraType: string;
  images: {
    imageUrl: string;
    alternateText: string;
  }[];
}

export type ISelectedExtrasItems =
  | IExcursionSelection[]
  | IMealSelection[]
  | IAddOnSelection[];

export interface IExtraData {
  [x: string]:
    | string
    | number
    | {
        quantity: number;
        passengerId: string;
        action: string;
      }[];
  passengers: {
    quantity: number;
    passengerId: string;
    action: string;
  }[];
  quantity?: number;
}
