import { useEffect, useRef, useState } from 'react';
import { ButtonText, LabelText } from 'components/UI/Brand/text';
import translate from 'translate';
import { media } from 'style/sizes';
import { useModal } from 'hooks/useModal';
import { CloseModalIcon, Icon, SortVoyagesRadioGroup } from 'components';
import { useClickOutside } from 'hooks/useClickOutside';
import { useMediaQuery } from 'hooks/useMediaQuery';
import styles from './styles.module.scss';

interface ISortingMenu {
  sortingParam: string;
  setSortingParam: (value: string) => void;
}

const SortingMenu = ({ sortingParam, setSortingParam }: ISortingMenu) => {
  const [isSortingOpen, setIsSortingOpen] = useState(false);
  const isMobile = useMediaQuery(media.maxMobile);
  const { isModalVisible, showModal, hideModal, RenderModal } = useModal();
  const sortingMenuRef = useRef(null);
  useClickOutside(sortingMenuRef, () => setIsSortingOpen(!isSortingOpen));

  const handleClickOutside = (event: MouseEvent) => {
    if (
      sortingMenuRef.current &&
      !sortingMenuRef.current.contains(event.target)
    ) {
      if (isModalVisible) {
        setIsSortingOpen(!isSortingOpen);
      }
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  const setSortingTitle = () => {
    switch (sortingParam) {
      case 'relevant':
        return translate('SearchResult_Sorting_Lable_Relevant');
      case 'days':
        return translate('SearchResult_Sorting_Lable_Days');
      case 'price':
        return translate('SearchResult_Sorting_Lable_Price');
      default:
        return translate('SearchResult_Sorting_Lable_Relevant');
    }
  };

  const labelSecondPart = setSortingTitle();

  const onSortingChanged = (newValue: string) => {
    setSortingParam(newValue);
    setIsSortingOpen(!isSortingOpen);
    if (isMobile) {
      hideModal();
    }
  };

  return (
    <div className={styles.sortingContainer} data-testid="sorting-link">
      <div
        onClick={() =>
          isMobile ? showModal() : setIsSortingOpen(!isSortingOpen)
        }
        className={styles.sortingLabel}
      >
        <LabelText className={styles.label}>
          {translate('SearchResult_Sorting_Title', '')}
        </LabelText>
        <ButtonText className={styles.sortingTitle} id="selected-sort-option">
          {labelSecondPart}
        </ButtonText>
        <Icon
          iconType={isSortingOpen ? 'arrowUp' : 'arrowDown'}
          width={16}
          height={16}
          className={styles.arrowIcon}
        />
      </div>
      {isMobile && isModalVisible && (
        <RenderModal>
          <div className={styles.container}>
            <LabelText className={styles.label} marginBottom="four">
              {translate('SearchResult_Sorting_Title', '')}
            </LabelText>
            <CloseModalIcon onClose={hideModal} width={16} height={16} />
          </div>
          {
            <SortVoyagesRadioGroup
              changeHandler={onSortingChanged}
              sortingParam={sortingParam}
            />
          }
        </RenderModal>
      )}
      {isSortingOpen && (
        <div
          className={styles.sortingRadioContainer}
          data-testid="sorting-container"
          ref={sortingMenuRef}
        >
          {
            <SortVoyagesRadioGroup
              changeHandler={onSortingChanged}
              sortingParam={sortingParam}
            />
          }
        </div>
      )}
    </div>
  );
};

export default SortingMenu;
