import { Header1, Header2, Header3, Header4 } from 'components/UI/Brand/text';

export enum HEADER_SIZE {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  H6 = 'h6',
}

interface IGetHeaderBasedOnSize {
  title: string;
  highestHeadingSize?: HEADER_SIZE;
  highestDOMHeadingSize?: HEADER_SIZE;
  classes?: string;
}

export const getHeaderBasedOnSize = ({
  title,
  highestHeadingSize,
  highestDOMHeadingSize,
  classes,
}: IGetHeaderBasedOnSize) => {
  switch (highestHeadingSize) {
    case HEADER_SIZE.H2:
      return (
        <Header2
          as={highestDOMHeadingSize || HEADER_SIZE.H2}
          className={classes}
        >
          {title}
        </Header2>
      );

    case HEADER_SIZE.H3:
      return (
        <Header3
          as={highestDOMHeadingSize || HEADER_SIZE.H3}
          className={classes}
        >
          {title}
        </Header3>
      );

    case HEADER_SIZE.H4:
      return (
        <Header4
          as={highestDOMHeadingSize || HEADER_SIZE.H4}
          className={classes}
        >
          {title}
        </Header4>
      );
    case HEADER_SIZE.H1:
    default:
      return <Header1 className={classes}>{title}</Header1>;
  }
};
