const debounce = <T extends unknown[], U>(
  callback: (...args: T) => PromiseLike<U> | U,
  wait: number
) => {
  let timer: undefined | ReturnType<typeof setTimeout>;

  return (...args: T): Promise<U> => {
    if (timer) {
      clearTimeout(timer);
    }
    return new Promise((resolve) => {
      timer = setTimeout(() => resolve(callback(...args)), wait);
    });
  };
};

export default debounce;
