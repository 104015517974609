import { createActions, ActionFunctionAny, Action } from 'redux-actions';
import axios from 'axios-instance';

export const {
  fetchTourStart,
  fetchTourSuccess,
  fetchTourFailed,
  fetchTourTypeStart,
  fetchTourTypeSuccess,
  fetchTourTypeFailed,
  resetTour,
} = createActions({
  FETCH_TOUR_START: undefined,
  FETCH_TOUR_SUCCESS: (tourData) => ({ tourData }),
  FETCH_TOUR_FAILED: undefined,
  FETCH_TOUR_TYPE_START: undefined,
  FETCH_TOUR_TYPE_SUCCESS: (type) => ({ type }),
  FETCH_TOUR_TYPE_FAILED: undefined,
  RESET_TOUR: undefined,
});

export const fetchTourType =
  (tourId: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchTourTypeStart());
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}tours/${tourId}/type`)
      .then((response) => {
        dispatch(fetchTourTypeSuccess(response.data));
      })
      .catch(() => {
        dispatch(fetchTourTypeFailed());
      });
  };

export const fetchTour =
  (tourId: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchTourStart());
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}tours/${tourId}`)
      .then((response) => {
        dispatch(fetchTourSuccess(response.data));
        dispatch(fetchTourType(tourId));
      })
      .catch(() => {
        dispatch(fetchTourFailed());
      });
  };
