import { SVGProps } from 'react';

const LocationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={16}
    height={21}
    viewBox="0 0 16 21"
    fill="currentColor"
    {...props}
  >
    <path
      d="M7.833 0c2.078 0 4.07.83 5.539 2.29a7.85 7.85 0 0 1 2.294 5.54c0 3.18-1.925 6.46-3.768 8.89a34.722 34.722 0 0 1-3.402 3.84c-.102.1-.183.18-.239.23a.288.288 0 0 0-.064.06l-.017.01-.006.01a.51.51 0 0 1-.674 0l-.006-.01-.017-.01a.288.288 0 0 0-.064-.06c-.056-.05-.136-.13-.239-.23a34.722 34.722 0 0 1-3.402-3.84C1.925 14.29 0 11.01 0 7.83c0-2.07.825-4.07 2.294-5.54A7.868 7.868 0 0 1 7.833 0Zm0 1A6.832 6.832 0 0 0 1 7.83c0 2.83 1.741 5.88 3.565 8.28a34.04 34.04 0 0 0 2.484 2.91c.32.33.59.6.784.79.195-.19.464-.46.785-.79.678-.71 1.581-1.72 2.483-2.91 1.824-2.4 3.565-5.45 3.565-8.28A6.832 6.832 0 0 0 7.833 1Zm0 3a3.835 3.835 0 0 1 0 7.67 3.835 3.835 0 0 1 0-7.67Zm0 1a2.835 2.835 0 0 0 0 5.67 2.835 2.835 0 0 0 0-5.67Z"
      fillRule="evenodd"
    />
  </svg>
);

export default LocationIcon;
