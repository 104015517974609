import { Header4, InfoText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import DepartureInformation from 'components/Search/SearchResult/DepartureInformation/DepartureInformation';
import translate from 'translate';
import { ITableSearchResult } from 'interfaces/ITableSearchResult';
import Icon from 'components/UI/Icons/Icon';
import { Link } from 'react-router-dom';
import { getMinimumPrice } from 'utils/search';
import { SearchResultButton } from 'components/Search/SearchResult/SearchResultButton/SearchResultButton';
import { LinkText } from 'components';
import { colors } from 'style/colors';
import styles from './styles.module.scss';

const SearchResultMobileTable = ({
  arrivalDate,
  departureDate,
  index,
  isCoastal,
  isShowAccessibleCabins,
  onPriceClicked,
  prices,
  promotionList,
  shipDetail,
  isPortToPortVoyage,
  hasSearchedWithDeckSpace,
  cabins,
  isViaGeiranger,
  isViaHjorundfjorden,
  hasVehicleAvailability,
  voyageId,
  travelSuggestionCode,
}: ITableSearchResult) => {
  const getCabinTitle = (cabinCode: string) =>
    cabins.find((cabin) => cabin.code === cabinCode)?.title;

  return (
    <tr>
      <td
        className={clsx(styles.root, {
          [styles.p2pSearchResultContainer]:
            isPortToPortVoyage && getMinimumPrice(prices).price !== Infinity,
        })}
      >
        <table className={styles.table}>
          <thead>
            <tr>
              <th className={styles.tableHeader} colSpan={2}>
                <DepartureInformation
                  arrivalDate={arrivalDate}
                  departureDate={departureDate}
                  index={index}
                  isCoastal={isCoastal}
                  onPriceClicked={onPriceClicked}
                  prices={prices}
                  promotionList={promotionList}
                  shipDetail={shipDetail}
                  isPortToPortVoyage={isPortToPortVoyage}
                  hasSearchedWithDeckSpace={hasSearchedWithDeckSpace}
                  isViaGeiranger={isViaGeiranger}
                  isViaHjorundfjorden={isViaHjorundfjorden}
                  hasVehicleAvailability={hasVehicleAvailability}
                  voyageId={voyageId}
                  travelSuggestionCode={travelSuggestionCode}
                />
              </th>
            </tr>
          </thead>

          {!isPortToPortVoyage && (
            <tbody>
              {prices.map(
                ({
                  isAvailable,
                  genericCabinCategory,
                  cabinCode,
                  hasAccessibleCabin,
                  isSoldOut,
                  priceDetail,
                  hasMaxPaxNotification,
                  maxOccupancy,
                  hasDiscountInTableRow,
                }) =>
                  isAvailable &&
                  priceDetail && (
                    <tr key={`${genericCabinCategory}-${index + 1}`}>
                      <td className={styles.tableCell}>
                        <div className={styles.cabinCategory}>
                          <div className={styles.tableCellTitle}>
                            <LinkText
                              className={styles.cabinTypeLink}
                              target="_blank"
                              as={Link}
                              rel="noopener noreferrer"
                              to={`/learn/ship/${
                                shipDetail.shipCode
                              }#${getCabinTitle(cabinCode)
                                .toLowerCase()
                                .replace(/\s/g, '')}`}
                            >
                              <Header4>{getCabinTitle(cabinCode)}</Header4>
                              <Icon
                                iconType="newTab"
                                height={16}
                                width={16}
                                color={colors.shared.black}
                              />
                            </LinkText>
                          </div>
                          {isShowAccessibleCabins && hasAccessibleCabin && (
                            <div className={styles.tableCellTitle}>
                              <InfoText marginRight="two">
                                {translate(
                                  'SearchBar_CabinGuestSelector_Accessibility'
                                )}
                              </InfoText>
                              <Icon iconType="circleWithExclamationSmall" />
                            </div>
                          )}
                        </div>
                      </td>
                      <td className={styles.tableCell}>
                        <SearchResultButton
                          hasMaxPaxNotification={hasMaxPaxNotification}
                          isSoldOut={isSoldOut}
                          genericCabinCategory={genericCabinCategory}
                          cabinCode={cabinCode}
                          onPriceClicked={onPriceClicked}
                          maxOccupancy={maxOccupancy}
                          index={index}
                          hasDiscountInTableRow={hasDiscountInTableRow}
                          priceDetail={priceDetail}
                          voyageId={voyageId}
                          isMobile
                        />
                      </td>
                    </tr>
                  )
              )}
            </tbody>
          )}
        </table>
      </td>
    </tr>
  );
};

export default SearchResultMobileTable;
