import { ICampaignState } from 'containers/Learn/CampaignPage/CampaignPage.types';
import { handleActions } from 'redux-actions';
import {
  fetchCampaignFailed,
  fetchCampaignStart,
  fetchCampaignSuccess,
} from '../actions/campaign';

const initialState: ICampaignState = {
  campaign: null,
  error: null,
  loading: false,
};

const reducer = handleActions<ICampaignState, ICampaignState>(
  {
    [fetchCampaignStart.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchCampaignSuccess.toString()]: (state, action) => ({
      ...state,
      campaign: action.payload.campaign,
      loading: false,
    }),
    [fetchCampaignFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
    }),
  },
  initialState
);

export default reducer;
