import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Logo } from 'components/UI/Logo/util';
import { AlertInfoBanner } from 'components';
import translate from 'translate';
import { track } from 'utils/analytics';
import HLogo from 'components/UI/Logo/Logo';
import { getHomeLink } from 'utils/getHomeLink';
import styles from './styles.module.scss';
import NavItemsLeft from './Navbar/NavItemsLeft';
import NavItemsRight from './Navbar/NavItemsRight';
import SignupLoginButtonGroup from './SignupLoginButtonGroup/SignupLoginButtonGroup';

interface IStyledProps {
  fixed: boolean;
  isFilledHeader?: boolean;
  hasAlertBanner: boolean;
}

interface IHeaderProps extends IStyledProps {
  isAuth: boolean;
  setLearnButtonActive: (value: boolean) => void;
  setShowLearnNavigation: (value: boolean) => void;
}

const trackBook = () => track('Click book');

const showNavigationLinks = (pathName: string) =>
  !(
    pathName.includes('mybookingspayment') ||
    pathName.includes('book/progress/payment')
  );

const MainHeader = (props: IHeaderProps) => {
  const {
    isAuth,
    setLearnButtonActive,
    setShowLearnNavigation,
    isFilledHeader = false,
    hasAlertBanner,
    fixed,
  } = props;
  const { pathname: pathName } = useLocation();

  return (
    <div className={styles.mainHeader}>
      <div
        className={clsx(styles.container, {
          [styles.fixed]: fixed,
          [styles.hasAlertBanner]: hasAlertBanner,
          [styles.isFilledHeader]: isFilledHeader,
        })}
      >
        {hasAlertBanner && <AlertInfoBanner />}
        <header className={styles.header} data-testid="main-header">
          <nav className={styles.navigation}>
            <Link
              to={getHomeLink()}
              className={styles.logo}
              aria-label={translate('Navbar_Home')}
            >
              <div
                className={styles.logoContainer}
                onClick={() => isAuth && trackBook()}
              >
                <HLogo
                  logoTypes={{
                    hx: isFilledHeader
                      ? Logo.MAIN_HX_BLUE
                      : Logo.MAIN_WHITE_FONT,
                    hrn: isFilledHeader
                      ? Logo.MAIN_BLACK_FONT
                      : Logo.MAIN_WHITE_FONT,
                  }}
                />
              </div>
            </Link>
            {showNavigationLinks(pathName) &&
              (isAuth ? (
                <div className={styles.headerContainer}>
                  <NavItemsLeft
                    setShowLearnNavigation={setShowLearnNavigation}
                    setLearnButtonActive={setLearnButtonActive}
                  />
                  <NavItemsRight
                    setShowLearnNavigation={setShowLearnNavigation}
                  />
                </div>
              ) : (
                <SignupLoginButtonGroup
                  isFilledHeader={isFilledHeader}
                  isMobile={false}
                />
              ))}
          </nav>
        </header>
      </div>
    </div>
  );
};

export default MainHeader;
