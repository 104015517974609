import { useState, useEffect, useRef } from 'react';
import { BodyText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import { IVehicle } from 'interfaces/IVehicle';
import translate from 'translate';
import { useAppSelector } from 'hooks/storeHooks';
import Icon from 'components/UI/Icons/Icon';
import { useClickOutside } from 'hooks/useClickOutside';
import IncrementableInput from 'components/UI/IncrementableInput/IncrementableInput';
import { BenefitLink } from 'components/Search/CabinGuestSelection/BenefitLink';
import { colors } from 'style/colors';
import { VehiclesInpitOverlay } from './VehiclesInputOverlay';
import styles from './styles.module.scss';

export const defaultVehiclesInputValues: IVehicle = {
  cars: 0,
  motorcycles: 0,
};

interface IVehiclesInputProps {
  onChange: (vehicles: IVehicle) => void;
  maxVehicles?: number;
  isDisabled?: boolean;
  vehicles: IVehicle;
}

const VehiclesInput = ({
  onChange,
  maxVehicles,
  isDisabled = false,
  vehicles,
}: IVehiclesInputProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [placeholder, setPlaceholder] = useState(null);

  const handleIncrement = (field: string, value: string) => {
    onChange({ ...vehicles, [field]: Number(value) });
  };

  const previousSearchData = useAppSelector((state) => state.search?.params);

  const generatePlaceholder = () => {
    let carsLabel = '';
    if (vehicles.cars > 1) {
      carsLabel = `${vehicles.cars} ${translate('General_car_plural')}`;
    } else if (vehicles.cars === 1) {
      carsLabel = `${vehicles.cars} ${translate('General_car_single')}`;
    }

    let motorcyclesLabel = '';
    if (vehicles.motorcycles > 1) {
      motorcyclesLabel = `${vehicles.motorcycles} ${translate('General_motorcycle_plural')}`;
    } else if (vehicles.motorcycles === 1) {
      motorcyclesLabel = `${vehicles.motorcycles} ${translate('General_motorcycle_single')}`;
    }

    const seperatorLabel = carsLabel && motorcyclesLabel ? ', ' : '';
    const noVehicleLabel =
      carsLabel || motorcyclesLabel
        ? ''
        : translate('Search_VehiclesInput_no_vehicle');
    return `${carsLabel}${seperatorLabel}${motorcyclesLabel}${noVehicleLabel}`;
  };
  const vehiclesInputControlRef = useRef(null);
  useClickOutside(vehiclesInputControlRef, () => setIsModalOpen(false));
  const handleClickOutside = (event: MouseEvent) => {
    if (
      vehiclesInputControlRef.current &&
      !vehiclesInputControlRef.current.contains(event.target)
    ) {
      if (isOpen) {
        setIsOpen(!isOpen);
      }
    }
  };

  useEffect(() => {
    const previousVehicles = previousSearchData?.vehicles;
    if (!previousVehicles) return;
    const cars = previousVehicles.filter((vehicle) => vehicle === 'CAR').length;
    const motorcycles = previousVehicles.length - cars;

    onChange({ cars, motorcycles });
  }, []);

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  useEffect(() => {
    setPlaceholder(generatePlaceholder());
  }, [vehicles]);

  return (
    <div ref={vehiclesInputControlRef} className={styles.root}>
      <button
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className={styles.inputControl}
        disabled={isDisabled}
        id="vehicle-input"
      >
        <Icon
          iconType="car"
          height={13}
          width={22}
          color={colors.shared.warmGray5}
          className={styles.carIcon}
        />
        <BodyText className={clsx(styles.valueContainer, styles.truncate)}>
          {placeholder}
        </BodyText>
        {isOpen ? (
          <div className={styles.customDropDownIcon}>
            <Icon iconType="arrowUp" width={24} height={24} />
          </div>
        ) : (
          <div className={styles.customDropDownIcon}>
            <Icon iconType="arrowDown" width={24} height={24} />
          </div>
        )}
      </button>
      {isModalOpen && <VehiclesInpitOverlay onClose={setIsModalOpen} />}
      {isOpen && (
        <div className={styles.openContainer}>
          <BenefitLink
            onClick={setIsModalOpen}
            buttonText={translate('SearchBar_Vehicle_Details')}
          />
          <IncrementableInput
            value={vehicles.cars.toString()}
            name="cars"
            label={translate('General_car_plural')}
            min={0}
            max={maxVehicles - vehicles.motorcycles}
            handleClick={handleIncrement}
            handleChange={() => {}}
          />
          <IncrementableInput
            value={vehicles.motorcycles.toString()}
            name="motorcycles"
            label={translate('General_motorcycle_plural')}
            min={0}
            max={maxVehicles - vehicles.cars}
            handleClick={handleIncrement}
            handleChange={() => {}}
          />
        </div>
      )}
    </div>
  );
};

export default VehiclesInput;
