import {
  ChangeEvent,
  InputHTMLAttributes,
  ReactNode,
  SVGAttributes,
} from 'react';
import { colors } from 'style/colors';
import { typography } from 'style/typography';

import styled, { css } from 'styled-components';
import { TabularText } from '../Brand/text';

const CheckboxLabel = styled.label`
  position: relative;
  font-family: ${typography.normal.fontFamily};
  display: flex;
  align-items: center;
  margin: 10px 0;
  cursor: pointer;
  width: 100%;
`;

const StyledCheckbox = styled.div<{
  hasError: boolean;
  disabled?: boolean;
  hasNoMargin?: boolean;
}>`
  opacity: ${({ disabled }) => (disabled ? '0.5' : '1')};

  /* Hide the browser's default checkbox */
  input {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
  }

  .absolute-center {
    margin: ${({ hasNoMargin }) => (hasNoMargin ? 'auto auto auto 0' : 'auto')};
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  /* Create a custom checkbox */
  .checkmark-background {
    position: relative;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0);
    border-radius: 50%;
    display: inline-block;
    margin: ${({ hasNoMargin }) => (hasNoMargin ? 0 : '0 10px 0 0')};
    transition: background-color ease-in-out 0.3s;
  }

  .checkmark-box {
    position: absolute;
    flex: 0 0 auto;
    height: 20px;
    width: 20px;
    background-color: inherit;
    border: 1px solid
      ${(props) => (props.hasError ? colors.shared.red : colors.shared.black)};
    border-radius: 4px;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .check {
    display: none;
    flex: 0 0 auto;

    path {
      stroke: ${colors.shared.white};
    }
  }

  /* Style checkmark box when checked */
  input:checked ~ .checkmark-background {
    background-color: ${colors.shared.black};

    .check {
      display: block;
    }
  }

  input:checked:hover ~ .checkmark-background {
    background-color: ${colors.shared.black};
  }

  ${({ disabled }) =>
    !disabled &&
    css`
      input:not(:checked):hover ~ .checkmark-background {
        background-color: ${colors.b2b.grey1};
      }

      input:not(:checked):focus ~ .checkmark-background {
        background-color: ${colors.shared.white};
      }

      input:not(:checked):active ~ .checkmark-background {
        background-color: ${colors.shared.black};
      }
    `}
`;

const truncateStyling = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const breakWordStyling = css`
  word-break: break-word;
`;

const CheckboxText = styled(TabularText)<{ truncate: boolean }>`
  cursor: pointer;
  font-size: 14px;
  flex: 1 1 0;

  ${({ truncate }) => (truncate ? truncateStyling : breakWordStyling)}
`;

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  id?: string;
  value?: string | number | string[];
  defaultChecked?: boolean;
  truncate?: boolean;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  hasError?: boolean;
  hasNoMargin?: boolean;
  label?: string;
  children?: ReactNode;
  dataTestId?: string;
}

const CheckboxIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="13"
    height="12"
    viewBox="0 0 13 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M12 1.5L4.5 9.5L1 5" stroke="white" strokeWidth="2" />
  </svg>
);

const Checkbox = ({
  label,
  hasError = false,
  truncate = false,
  disabled,
  hasNoMargin = false,
  id,
  children,
  dataTestId,
  ...props
}: CheckboxProps) => (
  <StyledCheckbox
    hasNoMargin={hasNoMargin}
    hasError={Boolean(hasError)}
    disabled={disabled}
    aria-invalid={hasError}
    data-testid={dataTestId}
  >
    <CheckboxLabel id={id} data-testid={`${id}-input`}>
      <input type="checkbox" disabled={disabled} {...props} />
      <span className="checkmark-background">
        <span
          className="checkmark-box absolute-center"
          data-testid={`${id}-check`}
        >
          <CheckboxIcon className="check absolute-center" />
        </span>
      </span>
      {label ? (
        <CheckboxText
          truncate={truncate}
          dangerouslySetInnerHTML={{ __html: label }}
          data-testid={`${id}-label`}
        />
      ) : (
        <>{children}</>
      )}
    </CheckboxLabel>
  </StyledCheckbox>
);

export default Checkbox;
