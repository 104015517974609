import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { BodyText, Header4, InfoText } from 'components/UI/Brand/text';
import { TagType } from 'interfaces/ITag';
import { IShipDetail } from 'interfaces/IShip';
import { typography } from 'style/typography';
import { LinkText, Tag, AccessibleHelperText, Icon } from 'components';
import { colors } from 'style/colors';
import { IPromotionTag } from 'interfaces/IPromotionTag';
import { getLanguage } from 'utils/getLanguage';
import { getCalendarLocale } from 'utils/dates';
import { format } from 'date-fns';

const PackageDates = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
`;

const PackageDateAndTime = styled.div`
  padding: 0;
  margin: 0;
  white-space: nowrap;
  text-align: left;
`;

const PackageTime = styled(InfoText)`
  margin-top: 8px;
`;

const InformationSectionPromotions = styled.div`
  display: grid;
  grid-template-columns: repeat(2, auto);
  gap: 8px;
  width: fit-content;
`;

const InformationTableCellBodyLink = styled((props) => <LinkText {...props} />)`
  display: flex;
  align-items: end;
  column-gap: 4px;
  width: fit-content;
  font-size: ${typography.normal.fontSize.small};
  font-family: ${typography.normal.fontFamily};
  color: ${colors.shared.textBlack};
  background: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

const TourCode = styled(BodyText)`
  font-size: 14px;
`;

interface IShipAndDepartureInformationProps {
  departureDate: string;
  arrivalDate: string;
  promotionList: IPromotionTag[];
  index: number;
  shipDetail: IShipDetail;
  isCoastal: boolean;
  travelSuggestionCode?: string;
  isIconicVoyage?: boolean;
}

const ShipAndDepartureInformation = ({
  departureDate,
  arrivalDate,
  promotionList,
  index,
  shipDetail,
  isCoastal,
  travelSuggestionCode,
  isIconicVoyage,
}: IShipAndDepartureInformationProps) => {
  const language = getLanguage();
  const locale = getCalendarLocale(language);
  return (
    <>
      <PackageDates>
        <PackageDateAndTime>
          <Header4 as={BodyText}>
            {format(departureDate, 'd MMM yyyy', { locale })}
          </Header4>
          {isCoastal && !isIconicVoyage && (
            <PackageTime>
              {format(departureDate, 'HH:mm', { locale })}
            </PackageTime>
          )}
        </PackageDateAndTime>
        <Icon width={21} height={21} iconType="arrowRightShort" />
        <PackageDateAndTime>
          <Header4 as={BodyText}>
            {format(arrivalDate, 'd MMM yyyy', { locale })}
          </Header4>
          {isCoastal && !isIconicVoyage && (
            <PackageTime>
              {format(arrivalDate, 'HH:mm', { locale })}
            </PackageTime>
          )}
        </PackageDateAndTime>
      </PackageDates>
      {travelSuggestionCode && <TourCode>{`${travelSuggestionCode}`}</TourCode>}
      {promotionList.length > 0 && (
        <InformationSectionPromotions>
          {promotionList.map(({ title, type }, i) => (
            <Tag
              key={`${title}-${type}-${i + 1}`}
              title={title}
              type={type.toLowerCase() as TagType}
              isCoastal={isCoastal}
            />
          ))}
        </InformationSectionPromotions>
      )}
      <InformationTableCellBodyLink
        target="_blank"
        rel="noopener noreferrer"
        id={`${index}_ship_button`}
        to={`/learn/ship/${shipDetail.shipCode}${
          shipDetail.isRefurbished
            ? `?isRefurbished=${shipDetail.isRefurbished.toString()}`
            : ''
        }`}
        as={Link}
      >
        {shipDetail.shipName}
        <AccessibleHelperText type="newTab" />
        <Icon
          iconType="newTab"
          height={14}
          width={14}
          color={colors.shared.black}
        />
      </InformationTableCellBodyLink>
    </>
  );
};

export default ShipAndDepartureInformation;
