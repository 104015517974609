import { IPaymentState } from 'interfaces/IPayment';
import { handleActions } from 'redux-actions';
import {
  fetchPaymentInformationBasedOnQuoteIdFailed,
  fetchPaymentInformationBasedOnQuoteIdStart,
  fetchPaymentInformationBasedOnQuoteIdSuccess,
  fetchPaymentOptionsFailed,
  fetchPaymentOptionsStart,
  fetchPaymentOptionsSuccess,
  fetchPaymentTypesFailed,
  fetchPaymentTypesStart,
  fetchPaymentTypesSuccess,
  fetchPaymentUrlFailed,
  fetchPaymentUrlStart,
  fetchPaymentUrlSuccess,
  getPaymentStatusFailed,
  getPaymentStatusStart,
  getPaymentStatusSuccess,
  resetPaymentInformation,
} from 'store/actions/payment';

const initialState: IPaymentState = {
  paymentTypes: null,
  paymentInformation: null,
  paymentOptions: null,
  error: null,
  loading: false,
  isPaymentTypesLoading: false,
  isPaymentOptionsLoading: false,
  paymentUrl: null,
  isPaymentUrlLoading: false,
  paymentStatus: null,
  isPaymentStatusLoading: false,
};

const reducer = handleActions(
  {
    [fetchPaymentTypesStart.toString()]: (state) => ({
      ...state,
      isPaymentTypesLoading: true,
    }),
    [fetchPaymentTypesSuccess.toString()]: (state, action) => ({
      ...state,
      paymentTypes: action.payload.paymentTypes,
      error: null,
      isPaymentTypesLoading: false,
    }),
    [fetchPaymentTypesFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload.error,
      isPaymentTypesLoading: false,
    }),
    [fetchPaymentOptionsStart.toString()]: (state) => ({
      ...state,
      isPaymentOptionsLoading: true,
    }),
    [fetchPaymentOptionsSuccess.toString()]: (state, action) => ({
      ...state,
      paymentOptions: action.payload.paymentOptions,
      error: null,
      isPaymentOptionsLoading: false,
    }),
    [fetchPaymentOptionsFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload.error,
      isPaymentOptionsLoading: false,
    }),
    [fetchPaymentInformationBasedOnQuoteIdStart.toString()]: (state) => ({
      ...state,
      loading: true,
      error: false,
    }),
    [fetchPaymentInformationBasedOnQuoteIdSuccess.toString()]: (
      state,
      action
    ) => ({
      ...state,
      paymentInformation: action.payload.paymentInformation,
      loading: false,
      error: false,
    }),
    [fetchPaymentInformationBasedOnQuoteIdFailed.toString()]: (state) => ({
      ...state,
      loading: false,
      error: true,
    }),
    [fetchPaymentUrlStart.toString()]: (state) => ({
      ...state,
      paymentUrl: null,
      error: null,
      isPaymentUrlLoading: true,
    }),
    [fetchPaymentUrlSuccess.toString()]: (state, action) => ({
      ...state,
      paymentUrl: action.payload.paymentUrl,
      error: null,
      isPaymentUrlLoading: false,
    }),
    [fetchPaymentUrlFailed.toString()]: (state, action) => ({
      ...state,
      paymentUrl: null,
      error: action.payload.error,
      isPaymentUrlLoading: false,
    }),
    [resetPaymentInformation.toString()]: () => ({
      ...initialState,
    }),
    [getPaymentStatusStart.toString()]: (state) => ({
      ...state,
      error: null,
      isPaymentStatusLoading: true,
    }),
    [getPaymentStatusSuccess.toString()]: (state, action) => ({
      ...state,
      error: null,
      isPaymentStatusLoading: false,
      paymentStatus: action.payload.paymentStatus,
    }),
    [getPaymentStatusFailed.toString()]: (state, action) => ({
      ...state,
      paymentStatus: null,
      error: action.payload.error,
      isPaymentStatusLoading: false,
    }),
  },
  initialState
);

export default reducer;
