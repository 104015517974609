import { SVGProps } from 'react';

const ArrowUpLongIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="22"
    viewBox="0 0 16 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 21.5C7.72386 21.5 7.5 21.2761 7.5 21L7.5 1C7.5 0.723858 7.72386 0.5 8 0.5C8.27614 0.5 8.5 0.723858 8.5 1L8.5 21C8.5 21.2761 8.27614 21.5 8 21.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.646447 8.35355C0.451184 8.15829 0.451184 7.84171 0.646447 7.64645L7.64645 0.646446C7.84171 0.451184 8.15829 0.451184 8.35355 0.646446L15.3536 7.64645C15.5488 7.84171 15.5488 8.15829 15.3536 8.35355C15.1583 8.54881 14.8417 8.54881 14.6464 8.35355L8 1.70711L1.35355 8.35355C1.15829 8.54882 0.841709 8.54882 0.646447 8.35355Z"
      fill="currentColor"
    />
  </svg>
);

export default ArrowUpLongIcon;
