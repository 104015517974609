import { ThemeProvider } from 'styled-components';
import { ReactNode, useEffect, useState } from 'react';
import { IBrandTypography } from './interfaces/IBrandTypography';
import { getBrandTypography } from './utils/getBrandTypography';
import { IBrandColors } from './interfaces/IBrandColors';
import { getBrandColors } from './utils/getBrandColors';
import { getButtonColors } from './utils/getButtonColors';
import { TBrand } from './brand';
import { IButtonColors } from './interfaces/IButtonColors';

interface IBrandProviderProps {
  children: ReactNode;
  theme?: object;
  fixedBrand?: TBrand;
}

const BrandProvider = ({
  theme,
  fixedBrand,
  children,
}: IBrandProviderProps) => {
  const defaultBrand: TBrand = fixedBrand || 'group';

  const [hasCheckedBrand, setHasCheckedBrand] = useState(false);
  const [brand, setBrand] = useState<TBrand>(defaultBrand);
  const [brandTypography, setBrandTypography] = useState<IBrandTypography>(
    getBrandTypography(defaultBrand)
  );
  const [brandColors, setBrandColors] = useState<IBrandColors>(
    getBrandColors(defaultBrand)
  );
  const [buttonColors, setButtonColors] = useState<IButtonColors>(
    getButtonColors(defaultBrand)
  );

  useEffect(() => {
    if (!hasCheckedBrand && !fixedBrand) {
      const nextBrand = window.brand;

      if (
        nextBrand === 'group' ||
        nextBrand === 'coastal' ||
        nextBrand === 'expedition'
      ) {
        setBrand(nextBrand);
        setBrandColors(getBrandColors(nextBrand));
        setBrandTypography(getBrandTypography(nextBrand));
        setButtonColors(getButtonColors(nextBrand));
      }

      setHasCheckedBrand(true);
    }
  }, [hasCheckedBrand]);

  return (
    <ThemeProvider
      theme={{
        brand,
        brandColors,
        brandTypography,
        buttonColors,
        ...theme,
      }}
    >
      {children}
    </ThemeProvider>
  );
};

export default BrandProvider;
