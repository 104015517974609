import { IProfilePayload, IProfileState } from 'interfaces/IProfile';
import { handleActions } from 'redux-actions';
import {
  clearProfileDataStart,
  fetchProfileDataFailed,
  fetchProfileDataStart,
  fetchProfileDataSuccess,
  fetchProfileImageFailed,
  fetchProfileImageStart,
  fetchProfileImageSuccess,
  savePasswordFailed,
  savePasswordStart,
  savePasswordSuccess,
  updateProfileAgencyFailed,
  updateProfileAgencyStart,
  updateProfileAgencySuccess,
  updateProfileData,
} from '../actions/profile';

const initialState: IProfileState = {
  profileData: null,
  error: null,
  loading: false,
  profileImageUrl: null,
  isProfileImageUrlLoading: false,
  isPasswordSaveLoading: false,
  isPasswordSave: false,
};

const reducer = handleActions<IProfileState, IProfilePayload>(
  {
    [fetchProfileDataStart.toString()]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    [fetchProfileDataSuccess.toString()]: (state, action) => ({
      ...state,
      profileData: action.payload.profileData,
      error: null,
      loading: false,
    }),
    [fetchProfileDataFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
    }),
    [updateProfileData.toString()]: (state, action) => ({
      ...state,
      profileData: {
        ...state.profileData,
        ...action.payload.profileData,
      },
    }),
    [clearProfileDataStart.toString()]: () => ({
      ...initialState,
    }),
    [updateProfileAgencyStart.toString()]: (state) => ({
      ...state,
      loading: true,
      error: null,
    }),
    [updateProfileAgencySuccess.toString()]: (state, action) => ({
      ...state,
      error: null,
      profileData: {
        ...state.profileData,
        agency: {
          ...state.profileData.agency,
          ...action.payload.agencyData,
        },
      },
      loading: false,
    }),
    [updateProfileAgencyFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload.error,
      loading: false,
    }),
    [fetchProfileImageStart.toString()]: (state) => ({
      ...state,
      isProfileImageUrlLoading: true,
      error: null,
    }),
    [fetchProfileImageSuccess.toString()]: (state, action) => ({
      ...state,
      profileImageUrl: action.payload.profileImageUrl,
      error: null,
      isProfileImageUrlLoading: false,
    }),
    [fetchProfileImageFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload.error,
      isProfileImageUrlLoading: false,
    }),
    [savePasswordStart.toString()]: (state) => ({
      ...state,
      isPasswordSaveLoading: true,
      error: null,
    }),
    [savePasswordSuccess.toString()]: (state) => ({
      ...state,
      error: null,
      isPasswordSaveLoading: false,
      isPasswordSave: true,
    }),
    [savePasswordFailed.toString()]: (state, action) => ({
      ...state,
      error: action.payload.error,
      isPasswordSaveLoading: false,
    }),
  },
  initialState
);

export default reducer;
