import Icon from 'components/UI/Icons/Icon';
import { DEFAULT_SUPPORT_EMAIL, getSupportEmail } from 'utils/supportInfo';
import CustomLink from 'components/UI/CustomLink/CustomLink';
import translate from 'translate';
import { colors } from 'style/colors';
import { PrimaryButton } from 'components/UI/Buttons/PrimaryButton/PrimaryButton';
import { useEffect, useState } from 'react';
import styles from './styles.module.scss';

export const ErrorBanner = () => {
  const [supportEmail, setSupportEmail] = useState(DEFAULT_SUPPORT_EMAIL);

  useEffect(() => {
    getSupportEmail().then((_supportEmail) => setSupportEmail(_supportEmail));
  }, []);

  return (
    <div className={styles.container}>
      <div className={styles.title}>{translate('GeneralError_Title')}</div>
      <div className={styles.alertIcon}>
        <Icon
          iconType="circleWithExclamation"
          width={80}
          height={80}
          color={colors.shared.warmGray6}
        />
      </div>
      <div className={styles.description}>
        {translate(
          'GeneralError_Description',
          <CustomLink
            onClick={() => {
              window.location.href = `mailto: ${supportEmail}`;
            }}
          >
            {translate('GeneralError_Link')}
          </CustomLink>
        )}
      </div>
      <div className={styles.refreshButtonContainer}>
        <PrimaryButton
          onClick={() => {
            window.location.reload();
          }}
        >
          {translate('GeneralError_RefreshButton')}
        </PrimaryButton>
      </div>
    </div>
  );
};
