import { ChangeEvent, useEffect } from 'react';
import translate from 'translate';
import { usePrevious } from 'hooks/usePrevious';
import {
  COASTAL_OPTIONS_MAP,
  IItineraryByVoyage,
} from 'interfaces/IItineraryByVoyage';
import { COASTAL_OPTIONS } from 'utils/constants';
import { IDepartureAndArrivalPort } from 'interfaces/IPort';
import { itineraryApi } from 'store/services/itinerary';
import { Label } from 'components/UI/Brand/text';
import Dropdown from 'components/UI/Select/Dropdown';
import { IOption } from 'interfaces/IOption';
import { resetPackageStartDay, resetSearchParams } from 'store/actions';
import { useAppDispatch, useAppSelector } from 'hooks/storeHooks';

interface IItineraryDropdownProps {
  selectedVoyage: COASTAL_OPTIONS;
  classicVoyageId: string;
  setClassicVoyageId: (classicVoyageId: string) => void;
  setPorts: (ports: IDepartureAndArrivalPort) => void;
  setIsViaKirkenes: (isViaKirkenes: boolean) => void;
  setPromotionSearch: (value: boolean) => void;
  hasUrlQuery: boolean;
}

export const ItineraryDropdown = ({
  selectedVoyage,
  classicVoyageId,
  setClassicVoyageId,
  setPorts,
  setIsViaKirkenes,
  setPromotionSearch,
  hasUrlQuery,
}: IItineraryDropdownProps) => {
  const prevSelectedVoyage = usePrevious(selectedVoyage);
  const prevVoyageId = usePrevious(classicVoyageId);
  const dispatch = useAppDispatch();

  const [fetchItineraryByVoyage, itineraryResult] =
    itineraryApi.useLazyGetItineraryByVoyageQuery();

  const { data: itineraryList, isFetching, isError } = itineraryResult;
  const { params } = useAppSelector((state) => state.search);

  const itineraryActiveList = itineraryList?.filter((item) => item !== null);

  const setSelectedVoyage = (voyageId: string) => {
    dispatch(resetSearchParams());
    dispatch(resetPackageStartDay());
    setClassicVoyageId(voyageId);
    const selectedClassicVoyage: IItineraryByVoyage = itineraryActiveList.find(
      (voyage) => voyage.id === voyageId
    );
    setPorts({
      fromPort: selectedClassicVoyage?.fromPort,
      toPort: selectedClassicVoyage?.toPort,
    });
    // classic roundtrip & voyage of discovery is via kirkenes
    if (voyageId === '20940' || voyageId === '20943') {
      setIsViaKirkenes(true);
    } else {
      setIsViaKirkenes(false);
    }
    setPromotionSearch(false);
  };

  const ItineraryDropDownOptions =
    itineraryActiveList?.map(
      (itinerary) =>
        ({
          value: itinerary.id,
          description: itinerary.name,
        }) as IOption
    ) || [];

  if (isFetching) {
    ItineraryDropDownOptions.unshift({
      value: 'loading',
      description: translate('Coastal_Search_Iconic_Itinerary_Loading'),
    });
  }

  useEffect(() => {
    if (selectedVoyage === COASTAL_OPTIONS.P2P) return;

    if (
      itineraryActiveList?.length === 0 ||
      prevSelectedVoyage !== selectedVoyage
    ) {
      const voyage = COASTAL_OPTIONS_MAP[selectedVoyage];
      fetchItineraryByVoyage(voyage, true);
    }
  }, [selectedVoyage]);

  useEffect(() => {
    if (
      itineraryList?.length &&
      (prevVoyageId !== itineraryList[0].id || !prevVoyageId) &&
      !hasUrlQuery &&
      !params
    ) {
      setClassicVoyageId(itineraryList[0].id);
    }
  }, [itineraryList, hasUrlQuery, params]);

  return (
    <div>
      <Label htmlFor="itinerary-dropdown">
        {translate('InfoList_Itinerary')}
      </Label>
      <Dropdown
        id="itinerary-dropdown"
        name="itinerary-dropdown"
        options={ItineraryDropDownOptions}
        onChange={(event: ChangeEvent<HTMLSelectElement>) =>
          setSelectedVoyage(event.currentTarget.value)
        }
        disabled={isFetching || isError}
        value={
          !classicVoyageId
            ? itineraryList?.[0]?.id ?? 'default'
            : classicVoyageId
        }
      />
    </div>
  );
};
