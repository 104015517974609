import { ITour } from 'interfaces/ITour';
import { IPromotionTag } from 'interfaces/IPromotionTag';
import { getPromotionCodesFromPGPackages } from './getPromotionCodesFromPGPackages';

export const getPromotionsFromPackageTour = (
  tour: ITour,
  promotionCodes: IPromotionTag[]
) => {
  const promotionCodesFromPG = [
    ...new Set(getPromotionCodesFromPGPackages(tour)),
  ].filter((promotion) => promotion);

  promotionCodesFromPG.forEach((code) => code.toUpperCase());

  return promotionCodesFromPG
    .map((PGcode) => promotionCodes.find((code) => code.key === PGcode))
    .filter((code) => !!code);
};
