import { useEffect, useState } from 'react';
import OnboardHeader from 'containers/Onboard/OnboardHeader/OnboardHeader';
import Registration from 'containers/Onboard/Registration/Registration';
import ChooseAgentProfile from 'containers/Login/ChooseAgentProfile/ChooseAgentProfile';
import { createFailedMessage } from 'store/actions';
import translate from 'translate';
import RegionSelector from 'containers/Onboard/RegionSelector/RegionSelector';
import { getRegionName } from 'utils/regions';
import { IRegion } from 'interfaces/IOnboarding';
import { IProfileData } from 'interfaces/IProfile';
import { useAppDispatch } from 'hooks/storeHooks';
import styles from './styles.module.scss';

const ComponentState = {
  ChooseAgentData: 'ChooseAgentData',
  ChooseRegion: 'ChooseRegion',
  SetAgentData: 'SetAgentData',
};

interface ILoginFourthPageProps {
  onSubmit: (agentData: IProfileData) => void;
  contacts: IProfileData[];
}

const LoginAccountAgentInfo = ({
  onSubmit,
  contacts,
}: ILoginFourthPageProps) => {
  const [componentState, setComponentState] = useState(
    ComponentState.ChooseAgentData
  );
  const [selectedContact, setSelectedContact] = useState<IProfileData>(null);
  const dispatch = useAppDispatch();

  const updateAgencyRegion = (region: IRegion) => {
    setSelectedContact({
      ...selectedContact,
      agency: {
        ...selectedContact.agency,
        market: getRegionName(region.id),
      },
    });
  };

  useEffect(() => {
    if (!selectedContact) {
      return;
    }

    setComponentState(
      selectedContact.agency?.market
        ? ComponentState.SetAgentData
        : ComponentState.ChooseRegion
    );
  }, [selectedContact]);

  let content = null;
  window.scrollTo(0, 0);
  switch (componentState) {
    case ComponentState.ChooseAgentData:
      content = (
        <ChooseAgentProfile
          contacts={contacts}
          onSubmit={(contact) => {
            if (!contact) {
              dispatch(
                createFailedMessage(
                  translate('Login_AccountMigration_Fourth_SomethingWentWrong')
                )
              );
              return;
            }
            setSelectedContact(contact);
          }}
        />
      );
      break;
    case ComponentState.ChooseRegion:
      content = <RegionSelector onSubmit={updateAgencyRegion} />;
      break;
    case ComponentState.SetAgentData:
    default:
      content = (
        <Registration
          onSubmit={onSubmit}
          populateData={selectedContact}
          accountMigration
        />
      );
      break;
  }

  return (
    <div data-testid="loginAccountAgentInfo-container">
      <OnboardHeader
        title={translate('Login_AccountMigration_ThirdPage_Title')}
      />
      <div className={styles.container}>{content}</div>
    </div>
  );
};

export default LoginAccountAgentInfo;
