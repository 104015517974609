import { SVGProps } from 'react';

const ArticleIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.06152 3.67822C5.06152 3.40208 5.28538 3.17822 5.56152 3.17822H14.9777C16.6348 3.17822 17.9886 4.53202 17.9886 6.18919V16.2331C17.9886 16.5092 17.7648 16.7331 17.4886 16.7331C17.2125 16.7331 16.9886 16.5092 16.9886 16.2331V6.18919C16.9886 5.0843 16.0825 4.17822 14.9777 4.17822H5.56152C5.28538 4.17822 5.06152 3.95437 5.06152 3.67822Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.56194 4.17871C4.8023 4.17871 4.17871 4.8023 4.17871 5.56194V6.94516H6.81742C6.86157 6.94516 6.90439 6.95089 6.94516 6.96163V6.19202L6.93891 5.51635L6.93888 5.51172C6.93888 4.76745 6.33719 4.17871 5.56194 4.17871ZM6.94516 7.9287C6.90439 7.93944 6.86157 7.94516 6.81742 7.94516H3.67871C3.40257 7.94516 3.17871 7.72131 3.17871 7.44516V5.56194C3.17871 4.25002 4.25002 3.17871 5.56194 3.17871C6.85744 3.17871 7.93756 4.1832 7.93888 5.50927L7.94518 6.18968L7.94516 18.1544C7.94516 18.8819 8.51695 19.4703 9.25961 19.5047C9.28221 19.5016 9.30529 19.5 9.32874 19.5L9.45689 19.5001C10.1544 19.4327 10.7116 18.8233 10.7116 18.0979V16.8613C10.7116 16.5852 10.9355 16.3613 11.2116 16.3613H20C20.2761 16.3613 20.5 16.5852 20.5 16.8613V18.0979C20.5 19.4001 19.4446 20.5063 18.1168 20.5063L9.4994 20.5001C9.44295 20.5042 9.38592 20.5063 9.32839 20.5063C8.02012 20.5063 6.94516 19.4762 6.94516 18.1544V7.9287ZM11.26 19.5014L18.1168 19.5063C18.8731 19.5063 19.5 18.8673 19.5 18.0979V17.3613H11.7116V18.0979C11.7116 18.6159 11.5447 19.1028 11.26 19.5014Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45605 7.5C9.45605 7.22386 9.67991 7 9.95605 7H14.978C15.2541 7 15.478 7.22386 15.478 7.5C15.478 7.77614 15.2541 8 14.978 8H9.95605C9.67991 8 9.45605 7.77614 9.45605 7.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45605 10.5C9.45605 10.2239 9.67991 10 9.95605 10H14.978C15.2541 10 15.478 10.2239 15.478 10.5C15.478 10.7761 15.2541 11 14.978 11H9.95605C9.67991 11 9.45605 10.7761 9.45605 10.5Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.45605 13.5C9.45605 13.2239 9.67991 13 9.95605 13H14.978C15.2541 13 15.478 13.2239 15.478 13.5C15.478 13.7761 15.2541 14 14.978 14H9.95605C9.67991 14 9.45605 13.7761 9.45605 13.5Z"
      fill="currentColor"
    />
  </svg>
);

export default ArticleIcon;
