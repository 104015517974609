import { Header1, Subtitle, BodyText } from 'components/UI/Brand/text';
import { IHomeDestination } from 'interfaces/IDestination';
import translate from 'translate';
import { Icon, LinkText } from 'components';
import { Link } from 'react-router-dom';
import { colors } from 'style/colors';
import styles from './styles.module.scss';

interface IDestinationHeaderProps {
  destination: IHomeDestination;
}

const DestinationHeader = ({ destination }: IDestinationHeaderProps) => {
  const { name, tagline, description, usps, id } = destination;
  return (
    <div className={styles.root} data-testid="destination-info">
      <div className={styles.searchHeader}>
        <Header1 marginBottom="three">{name}</Header1>
        {tagline && (
          <>
            <Subtitle marginBottom="three">{tagline}</Subtitle>

            {description && (
              <BodyText className={styles.desc}>{description}</BodyText>
            )}

            <div className={styles.iconsContainer}>
              {usps.map((usp) => (
                <div className={styles.uspListElement} key={usp.text}>
                  <div className={styles.iconCntr}>
                    <Icon
                      iconType={usp.icon}
                      color={colors.shared.black}
                      width={24}
                      height={24}
                    />
                  </div>
                  <BodyText className={styles.desc}>{usp.text}</BodyText>
                </div>
              ))}
            </div>
            <div className={styles.readMore}>
              <LinkText
                className={styles.linkText}
                target="_blank"
                rel="noopener noreferrer"
                to={`/learn/destination/${id}`}
                as={Link}
                id="destination-read-more-button"
              >
                {translate('General_ReadMore')}
                <Icon
                  iconType="arrowRightShort"
                  height={24}
                  width={24}
                  style={{ marginLeft: 5 }}
                />
              </LinkText>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default DestinationHeader;
