import { useState, useEffect } from 'react';
import RadioOptionGroup from 'components/UI/RadioOptionGroup/RadioOptionGroup';
import {
  Header2,
  InfoText,
  LabelText,
  BodyText,
} from 'components/UI/Brand/text';
import { createFailedMessage } from 'store/actions';
import translate from 'translate';
import { DEFAULT_SUPPORT_EMAIL, setSupportInfo } from 'utils/supportInfo';
import { IProfileData } from 'interfaces/IProfile';
import { useAppDispatch } from 'hooks/storeHooks';
import { PrimaryButton } from 'components/UI/Buttons/PrimaryButton/PrimaryButton';
import { SecondaryButton } from 'components/UI/Buttons/SecondaryButton/SecondaryButton';
import { getRegionById, getRegionByName } from 'utils/regions';
import styles from './styles.module.scss';

const firstNameLabel = translate(
  'Login_AccountMigration_ThirdPage_AgencyChooser_FirstName'
);
const lastNameLabel = translate(
  'Login_AccountMigration_ThirdPage_AgencyChooser_LastName'
);
const agencyNameLabel = translate(
  'Login_AccountMigration_ThirdPage_AgencyChooser_Agency'
);
const agencyIdLabel = translate(
  'Login_AccountMigration_ThirdPage_AgencyChooser_AgencyID'
);
const phoneNumberLabel = translate(
  'Login_AccountMigration_ThirdPage_AgencyChooser_DirectPhoneNumber'
);
const agencyRegionLabel = translate('General_Region');
const selectAgencyContactLabel = translate(
  'Login_AccountMigration_ThirdPage_AgencyChooser_AgencyContactSelect'
);

interface IChooseAgentProfileProps {
  contacts: IProfileData[];
  onSubmit: (contactId: IProfileData) => void;
}

const ChooseAgentProfile = ({
  contacts,
  onSubmit,
}: IChooseAgentProfileProps) => {
  const [selectedContact, setSelectedContact] = useState(contacts[0].id);
  const [supportEmail, setSupportEmail] = useState(DEFAULT_SUPPORT_EMAIL);
  const dispatch = useAppDispatch();

  const numberOfFoundContacts =
    contacts.length === 1
      ? translate(
          'Login_AccountMigration_ThirdPage_AgencyChooser_WeFoundOneAccount'
        )
      : translate(
          'Login_AccountMigration_ThirdPage_AgencyChooser_MultipleAgentContactsWereFound'
        );

  const message =
    contacts.length === 1
      ? translate(
          'Login_AccountMigration_ThirdPage_AgencyChooser_PleaseCheckContact'
        )
      : translate(
          'Login_AccountMigration_ThirdPage_AgencyChooser_PleaseSelectTheRightContact'
        );

  useEffect(() => {
    setSupportInfo(
      getRegionById(
        getRegionByName(
          contacts[0].agency.market === 'CE' ? 'DE' : contacts[0].agency.market
        )
      )
    ).then(setSupportEmail);
  }, []);

  const onContactSelected = () => {
    const contact = contacts.find(({ id }) => id === selectedContact);
    if (!contact) {
      dispatch(
        createFailedMessage(
          translate(
            'Login_AccountMigration_ThirdPage_AgencyChooser_SomethingWentWrong'
          )
        )
      );
      return;
    }
    onSubmit(contact);
  };

  return (
    <div className={styles.root}>
      <div>
        <Header2>
          {translate(
            'Login_AccountMigration_ThirdPage_AgencyChooser_AgentContactsTitle'
          )}
        </Header2>
        <BodyText>
          {`${numberOfFoundContacts} ${translate(
            'Login_AccountMigration_ThirdPage_AgencyChooser_BasedOnYourEmail'
          )} `}
          <span className={styles.contactEmail}>{contacts[0].email}</span>
          {` ${message}`}
        </BodyText>
      </div>
      <table>
        <thead>
          <tr>
            <th className={styles.headerRadioButton}>&nbsp;</th>
            <th>
              <LabelText>{firstNameLabel}</LabelText>
            </th>
            <th>
              <LabelText>{lastNameLabel}</LabelText>
            </th>
            <th>
              <LabelText>{agencyNameLabel}</LabelText>
            </th>
            <th>
              <LabelText>{agencyIdLabel}</LabelText>
            </th>
            {contacts.some((contact) => contact.phoneNumber) && (
              <th>
                <LabelText>{phoneNumberLabel}</LabelText>
              </th>
            )}
            {contacts.some((contact) => contact.agency.market) && (
              <th>
                <LabelText>{agencyRegionLabel}</LabelText>
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {contacts.map((contact) => (
            <tr key={contact.id}>
              <td data-label={selectAgencyContactLabel}>
                <RadioOptionGroup
                  name="radio-button-group"
                  className={styles.radioButtonGroup}
                  onChange={() => {
                    setSelectedContact(contact.id);
                  }}
                  options={[
                    {
                      label: (
                        <p
                          aria-label={`${contact.id} ${contact.firstName} ${contact.lastName}`}
                        />
                      ),
                      value: contact.id,
                    },
                  ]}
                  defaultValue={contacts[0].id}
                />
              </td>
              <td data-label={firstNameLabel}>{contact.firstName}</td>
              <td data-label={lastNameLabel}>{contact.lastName}</td>
              <td data-label={agencyNameLabel}>{contact.agency?.name}</td>
              <td data-label={agencyIdLabel}>
                {contact.agency.iataNumber ||
                  contact.agency.pgId ||
                  translate('General_NotFound')}
              </td>
              {contacts.some(({ phoneNumber }) => phoneNumber) && (
                <td data-label={phoneNumberLabel}>
                  {contact.phoneNumber || ''}
                </td>
              )}
              {contacts.some(({ agency: { market } }) => market) && (
                <td data-label={agencyRegionLabel}>
                  {contact.agency.market || ''}
                </td>
              )}
            </tr>
          ))}
        </tbody>
      </table>

      <div className={styles.bottomContainer}>
        <BodyText>
          {translate(
            'Login_AccountMigration_ThirdPage_AgencyChooser_IsThisRightContactForTheRightAgency'
          )}
        </BodyText>
        <InfoText>
          {translate(
            'Login_AccountMigration_ThirdPage_AgencyChooser_YoucanEasilyChangeYourPhoneNumber'
          )}
        </InfoText>
        <div className={styles.buttonsContainer}>
          <div className={styles.buttonContainer}>
            <PrimaryButton onClick={onContactSelected} size="normal">
              {translate(
                'Login_AccountMigration_ThirdPage_AgencyChooser_YesContinue'
              )}
            </PrimaryButton>
          </div>
          <div className={styles.buttonContainer}>
            <SecondaryButton
              onClick={() => {
                window.location.href = `mailto:${supportEmail}`;
              }}
              size="normal"
            >
              {translate(
                'Login_AccountMigration_ThirdPage_AgencyChooser_ContactSupport'
              )}
            </SecondaryButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChooseAgentProfile;
