import { SVGProps } from 'react';

const RailIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect opacity="0.01" width="24" height="24" fill="#DEDEDE" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.55 2C8.683 2 8.81 2.05 8.905 2.15L10.234 3.47L11.559 4.79C11.754 4.98 11.755 5.3 11.562 5.49L9.05991 8.03H13.859H16.652C16.774 8.03 16.893 8.08 16.984 8.16L22.523 13.1C22.629 13.19 22.69 13.33 22.69 13.47V17.18C22.69 17.3 22.645 17.42 22.565 17.51L20.763 19.55C20.668 19.66 20.532 19.72 20.389 19.72H18.0973C18.0029 20.2221 17.7676 20.6526 17.4381 21H22.19C22.466 21 22.69 21.22 22.69 21.5C22.69 21.78 22.466 22 22.19 22H14.943H5.102H4.959C4.9311 22 4.90373 21.9978 4.87707 21.9934C3.31219 21.9015 2 20.8523 2 19.22C2 18.94 2.224 18.72 2.5 18.72C2.50771 18.72 2.51538 18.7202 2.523 18.7205C2.53062 18.7202 2.53829 18.72 2.546 18.72H7.802H12.345H17.643H20.163L21.69 16.99V13.7L19.8895 12.092C19.8383 12.1101 19.7833 12.12 19.726 12.12H13.859C13.583 12.12 13.359 11.89 13.359 11.62V9.03H2.5C2.224 9.03 2 8.81 2 8.53C2 8.26 2.224 8.03 2.5 8.03H8.07305L5.556 5.53C5.36 5.34 5.359 5.02 5.552 4.83L6.872 3.49L8.197 2.15C8.29 2.05 8.417 2 8.55 2ZM7.59744 21H12.511C12.1969 20.6514 11.9763 20.2201 11.888 19.72H8.25629C8.16199 20.2221 7.92677 20.6526 7.59744 21ZM5.102 21C6.1671 21 6.97315 20.4683 7.22183 19.72H3.07214C3.30069 20.4786 4.05591 21 5.102 21ZM14.943 21C16.0073 21 16.8139 20.4683 17.0628 19.72H12.9168C13.1444 20.4786 13.8969 21 14.943 21ZM8.555 3.21L6.614 5.17L8.563 7.11L10.501 5.14L8.555 3.21ZM14.359 9.03V11.12H18.8012L16.461 9.03H14.359ZM7.802 11.12C7.526 11.12 7.302 11.34 7.302 11.62V16.18C7.302 16.45 7.526 16.68 7.802 16.68H10.831C11.107 16.68 11.331 16.45 11.331 16.18V11.62C11.331 11.34 11.107 11.12 10.831 11.12H7.802ZM8.302 15.68V12.12H10.331V15.68H8.302ZM2 11.62C2 11.34 2.224 11.12 2.5 11.12H5.529C5.805 11.12 6.029 11.34 6.029 11.62V16.18C6.029 16.45 5.805 16.68 5.529 16.68H2.5C2.224 16.68 2 16.45 2 16.18V11.62ZM3 12.12V15.68H5.029V12.12H3Z"
      fill={props.color}
    />
  </svg>
);

export default RailIcon;
