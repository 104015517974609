import localStorageKeys from './localStorageKeys';
import languageKeys from './languageKeys';

export const setLanguage = (language: string) => {
  global?.window?.localStorage.setItem(
    localStorageKeys.currentLanguage,
    language
  );
};

export const getLanguage = () => {
  let acceptedLanguage = global?.window?.localStorage.getItem(
    localStorageKeys.currentLanguage
  );
  if (!acceptedLanguage || acceptedLanguage.length === 0) {
    setLanguage(languageKeys.en);
    acceptedLanguage = languageKeys.en;
  }
  if (acceptedLanguage === 'de') {
    return 'de-DE';
  }
  return acceptedLanguage;
};
