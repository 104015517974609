import { ButtonHTMLAttributes } from 'react';
import { Link } from 'react-router-dom';
import { colors } from 'style/colors';
import { media } from 'style/sizes';
import { typography } from 'style/typography';
import styled, { css } from 'styled-components';

type TButtonSize = 'normal' | 'small';

interface ISecondaryButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  buttonColor?: string;
  size?: TButtonSize;
  classes?: string;
  selected?: boolean;
  isClickable?: boolean;
  dataTestid?: string;
  to?: string;
}

interface IStyledProps extends ISecondaryButton {
  $isClickable?: boolean;
}

const baseButtonStyles = css<IStyledProps>`
  color: ${process.env.REACT_APP_COMPANY === 'HX'
    ? colors.hrx.blue
    : colors.shared.hurtigrutenRed1};
  font-size: 16px;
  line-height: ${({ size }) =>
    size === 'normal'
      ? typography.normal.lineHeight.normal
      : typography.normal.lineHeight.small};
  font-family: ${typography.normal.fontFamily};
  font-weight: 500;
  background-color: ${colors.shared.white};
  padding: 0px 4px;
  border: 2px solid
    ${process.env.REACT_APP_COMPANY === 'HX'
      ? colors.hrx.blue
      : colors.shared.hurtigrutenRed1};
  height: 44px;
  min-width: 172px;
  border-radius: 50px;
  box-sizing: border-box;
  text-align: center;
  cursor: ${({ $isClickable }) => ($isClickable ? 'auto' : 'pointer')};
  text-decoration: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  transition: 0.2s ease-in-out;
  cursor: ${({ selected }) => !selected && 'pointer'};
  &:hover {
    background-color: ${process.env.REACT_APP_COMPANY === 'HX'
      ? colors.hrx.blue
      : colors.shared.hurtigrutenRed1};
    color: ${colors.shared.white};
  }
  @media ${media.maxTablet} {
    width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
`;

const Button = styled.button<IStyledProps>`
  ${baseButtonStyles}
`;

const LinkWrapper = styled(Link)<IStyledProps>`
  ${baseButtonStyles}
`;

const ButtonChildren = styled.span`
  display: flex;
  gap: 4px;
  align-items: center;
`;

export const SecondaryButton = ({
  size = 'normal',
  children,
  classes,
  type,
  onClick,
  isClickable = true,
  dataTestid,
  to,
  ...rest
}: ISecondaryButton) => {
  const buttonChildren = <ButtonChildren>{children}</ButtonChildren>;

  return to ? (
    <LinkWrapper
      to={to}
      data-testid={dataTestid}
      className={classes}
      size={size}
      $isClickable={isClickable}
    >
      {buttonChildren}
    </LinkWrapper>
  ) : (
    <Button
      data-testid={dataTestid}
      className={classes}
      type={type}
      size={size}
      onClick={onClick}
      $isClickable={isClickable}
      {...rest}
    >
      {buttonChildren}
    </Button>
  );
};
