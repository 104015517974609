import { SVGProps } from 'react';

const ArrowDownIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.64645 6.64645C1.84171 6.45118 2.15829 6.45118 2.35355 6.64645L12 16.2929L21.6464 6.64645C21.8417 6.45118 22.1583 6.45118 22.3536 6.64645C22.5488 6.84171 22.5488 7.15829 22.3536 7.35355L12.3536 17.3536C12.1583 17.5488 11.8417 17.5488 11.6464 17.3536L1.64645 7.35355C1.45118 7.15829 1.45118 6.84171 1.64645 6.64645Z"
    />
  </svg>
);

export default ArrowDownIcon;
