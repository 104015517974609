import { css } from 'styled-components';
import { IBrandTypography, TFontType } from '../interfaces/IBrandTypography';

const MAX_WIDTH_TABLET = 1024;
const MAX_WIDTH_MOBILE = 768;

const floorWithPrecision = (value: number, precision: number) => {
  const multiplier = 10 ** precision;
  return Math.floor(value * multiplier) / multiplier;
};

const getDynamicFontSize = (fontSize: number, lowerLimit: number) => {
  const scaleFactor = lowerLimit / 100;

  return `${floorWithPrecision(fontSize / scaleFactor, 1)}vw`;
};

export const getTypographyStyling = (
  brandTypography: IBrandTypography,
  fontType: TFontType
) => {
  if (!brandTypography) {
    return null;
  }

  const typographyDefinition = brandTypography[fontType];

  if (!typographyDefinition) {
    return null;
  }

  return css`
    font-family: ${typographyDefinition.family};
    font-weight: ${typographyDefinition.weight};
    line-height: ${typographyDefinition.lineHeight};
    text-transform: ${typographyDefinition.textTransform || 'none'};

    font-size: ${typographyDefinition.size.desktop}px;
    font-size: clamp(
      ${typographyDefinition.size.tablet}px,
      ${getDynamicFontSize(typographyDefinition.size.tablet, MAX_WIDTH_TABLET)},
      ${typographyDefinition.size.desktop}px
    );

    @media (max-width: ${MAX_WIDTH_MOBILE}px) {
      font-size: ${typographyDefinition.size.mobile}px;
    }

    @media (max-width: ${MAX_WIDTH_TABLET}px) {
      font-size: ${typographyDefinition.size.tablet}px;
      font-size: clamp(
        ${typographyDefinition.size.mobile}px,
        ${getDynamicFontSize(
          typographyDefinition.size.mobile,
          MAX_WIDTH_MOBILE
        )},
        ${typographyDefinition.size.tablet}px
      );
    }
  `;
};
