import translate from 'translate';
import OnboardHeader from 'containers/Onboard/OnboardHeader/OnboardHeader';
import SetPassword from 'containers/Onboard/SetPassword/SetPassword';
import styles from './styles.module.scss';

interface ILoginAccountMigrationPasswordProps {
  validatedEmail: string;
  onSubmit: (password: string) => void;
}

const LoginAccountMigrationPassword = ({
  validatedEmail,
  onSubmit,
}: ILoginAccountMigrationPasswordProps) => (
  <div data-testid="loginAccountMigrationPassword-container">
    <OnboardHeader
      title={translate('Login_AccountMigration_SecondPage_HeaderTitle')}
    />
    <div className={styles.container}>
      <SetPassword
        validatedEmail={validatedEmail}
        emailDisabled
        title={translate('Login_AccountMigration_SecondPage_SetPasswordTitle')}
        desc={translate('Login_AccountMigration_SecondPage_SetPasswordDesc')}
        onSubmit={onSubmit}
      />
    </div>
  </div>
);

export default LoginAccountMigrationPassword;
