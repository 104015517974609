import { LabelHTMLAttributes } from 'react';
import { colors } from 'style/colors';
import { typography } from 'style/typography';
import styled from 'styled-components';

interface LabelProps extends LabelHTMLAttributes<HTMLLabelElement> {
  disabled?: boolean;
  hasError?: boolean;
}

export const Label = styled.label<LabelProps>`
  display: block;
  padding: 5px 0;
  margin-bottom: 2px;
  color: ${(props) =>
    props.hasError ? colors.shared.red : colors.shared.textBlack};
  font-weight: 400;
  font-family: ${typography.normal.fontFamily};
  font-size: ${typography.normal.fontSize.small};
  line-height: ${typography.normal.lineHeight.small};
  opacity: ${(props) => (props.disabled ? '0.4' : '1')};
  border: none;
`;
