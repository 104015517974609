import { Header4 } from 'components/UI/Brand/text';
import IncrementableInput from 'components/UI/IncrementableInput/IncrementableInput';
import { ICabinBaseSelector } from 'interfaces/ICabin';
import translate from 'translate';
import {
  CHILD_P2P,
  CHILD_REGULAR,
  INFANT_P2P,
  INFANT_REGULAR,
} from 'utils/constants';
import styles from './styles.module.scss';

export interface IPassengersSelector {
  deckPassengers: ICabinBaseSelector;
  onDeckPassengersIncrement: (field: string, value: string) => void;
  maxPassengers: ICabinBaseSelector;
  isEU: boolean;
  isNo: boolean;
}

export const PassengersSelector = ({
  deckPassengers,
  onDeckPassengersIncrement,
  maxPassengers,
  isEU,
  isNo,
}: IPassengersSelector) => (
  <div data-testid="deck-passengers-selector">
    <Header4 as={'p'} className={styles.cabinTitle}>
      {translate('BookingSummary_Passengers')}
    </Header4>
    <div>
      <IncrementableInput
        value={deckPassengers.adults.toString()}
        name="adults"
        label={translate('General_Adults')}
        max={maxPassengers.adults}
        handleClick={(field, value) => onDeckPassengersIncrement(field, value)}
        handleChange={() => {}}
      />
      <IncrementableInput
        value={deckPassengers.children.toString()}
        name="children"
        max={maxPassengers.children}
        label={`${translate('General_Children')} (${
          isEU ? CHILD_P2P : CHILD_REGULAR
        } ${translate('General_Years')})`}
        handleClick={(field, value) => onDeckPassengersIncrement(field, value)}
        handleChange={() => {}}
      />
      <IncrementableInput
        value={deckPassengers.infants.toString()}
        name="infants"
        label={`${translate('General_Infant')} (${
          isEU ? INFANT_P2P : INFANT_REGULAR
        } ${translate('General_Years')})`}
        handleClick={(field, value) => onDeckPassengersIncrement(field, value)}
        handleChange={() => {}}
        max={maxPassengers.infants}
      />
      {isEU && (
        <IncrementableInput
          value={deckPassengers.benefitFares.toString()}
          name="benefitFares"
          label={translate('General_BenefitFares')}
          max={maxPassengers.benefitFares}
          handleClick={(field, value) =>
            onDeckPassengersIncrement(field, value)
          }
          handleChange={() => {}}
        />
      )}
      {isNo && (
        <>
          <IncrementableInput
            value={deckPassengers.companions.toString()}
            name="companions"
            label={translate('General_Companions')}
            max={maxPassengers.companions}
            handleClick={(field, value) =>
              onDeckPassengersIncrement(field, value)
            }
            handleChange={() => {}}
            isDisabled={deckPassengers.benefitFares === 0}
          />
          <IncrementableInput
            value={deckPassengers.militaries.toString()}
            name="militaries"
            label={translate('General_Militaries')}
            max={maxPassengers.militaries}
            handleClick={(field, value) =>
              onDeckPassengersIncrement(field, value)
            }
            handleChange={() => {}}
          />
          <IncrementableInput
            value={deckPassengers.students.toString()}
            name="students"
            label={translate('General_Students')}
            max={maxPassengers.students}
            handleClick={(field, value) =>
              onDeckPassengersIncrement(field, value)
            }
            handleChange={() => {}}
          />
        </>
      )}
    </div>
  </div>
);

export default PassengersSelector;
