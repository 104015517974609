import { InfoText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import Icon from 'components/UI/Icons/Icon';
import { TIcon } from 'components/UI/Icons/Icon.type';
import { ICabinFeatureUI } from 'interfaces/ICabin';
import styles from './styles.module.scss';

interface ICabinFeatures {
  cabinFeatures: ICabinFeatureUI[];
  selected?: boolean;
}

const CabinFeatures = ({ cabinFeatures, selected }: ICabinFeatures) => (
  <ul className={styles.list}>
    {cabinFeatures?.map((cabinFeature) => (
      <li key={cabinFeature.feature} className={styles.listItem}>
        <Icon
          className={clsx(styles.text, {
            [styles.isSelectedText]: selected,
          })}
          iconType={cabinFeature.icon as TIcon}
          width={24}
          height={24}
        />
        <InfoText
          className={clsx(styles.text, {
            [styles.isSelectedText]: selected,
          })}
        >
          {cabinFeature.feature}
        </InfoText>
      </li>
    ))}
  </ul>
);

export default CabinFeatures;
