import Icon from 'components/UI/Icons/Icon';
import styled from 'styled-components';
import { LinkText } from 'components';

const ArrowButton = styled.button`
  border: none;
  background: none;
  cursor: pointer;
`;

const ButtonLink = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  text-align: center;
`;

export const BenefitLink = ({
  onClick,
  buttonText,
}: {
  onClick: (isDiscountOpen: boolean) => void;
  buttonText: string;
}) => (
  <ButtonLink data-testid="benefit-button">
    <ArrowButton>
      <Icon
        iconType="arrowLeft"
        height={20}
        width={20}
        onClick={() => onClick(true)}
      />
    </ArrowButton>
    <LinkText
      onClick={() => onClick(true)}
      style={{
        fontFamily: 'Atlas Grotesk Regular',
        fontSize: 12,
      }}
    >
      {buttonText}
    </LinkText>
  </ButtonLink>
);
