import { SVGProps } from 'react';

const PlusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    preserveAspectRatio="xMidYMid meet"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.5C12.2761 1.5 12.5 1.72386 12.5 2V22C12.5 22.2761 12.2761 22.5 12 22.5C11.7239 22.5 11.5 22.2761 11.5 22V2C11.5 1.72386 11.7239 1.5 12 1.5Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.5 12C1.5 11.7239 1.72386 11.5 2 11.5H22C22.2761 11.5 22.5 11.7239 22.5 12C22.5 12.2761 22.2761 12.5 22 12.5H2C1.72386 12.5 1.5 12.2761 1.5 12Z"
    />
  </svg>
);

export default PlusIcon;
