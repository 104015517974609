import { forwardRef, InputHTMLAttributes, Ref, useState } from 'react';
import { Icon } from 'components';
import styles from './styles.module.scss';

type Props = InputHTMLAttributes<HTMLInputElement>;

const Input = (props: Props, ref: Ref<HTMLInputElement>) => {
  const { onClick } = props;
  const [isFocused, setIsFocused] = useState<boolean>(false);

  return (
    <div className={styles.inputWrapper} onClick={onClick}>
      <input
        {...props}
        type="text"
        className={styles.input}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        ref={ref}
      />

      {isFocused ? (
        <Icon iconType="arrowUp" className={styles.inputPostIcon} />
      ) : (
        <Icon iconType="arrowDown" className={styles.inputPostIcon} />
      )}
    </div>
  );
};

export const DatePickerInput = forwardRef(Input);
