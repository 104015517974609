import { SVGProps } from 'react';

const DoubleBedIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={23}
    viewBox="0 0 22 23"
    {...props}
  >
    <g fillRule="evenodd" fill="currentColor">
      <path d="M3.708 1.79c-.163.18-.267.44-.267.8v4c0 .28-.224.5-.5.5s-.5-.22-.5-.5v-4c0-.55.163-1.08.534-1.48.376-.4.918-.62 1.566-.62h12.798c.651 0 1.192.22 1.567.63.369.4.533.93.533 1.47v4c0 .28-.224.5-.5.5-.277 0-.5-.22-.5-.5v-4c0-.35-.104-.62-.268-.79-.158-.18-.417-.31-.832-.31H4.541c-.419 0-.677.13-.833.3zm-.767 16.3c.276 0 .5.22.5.5v3.2c0 .28-.224.5-.5.5s-.5-.22-.5-.5v-3.2c0-.28.224-.5.5-.5zm15.997 0c.277 0 .5.22.5.5v3.2c0 .28-.223.5-.5.5-.276 0-.5-.22-.5-.5v-3.2c0-.28.224-.5.5-.5z" />
      <path d="M3.099 6.12c.262.08.404.37.316.63l-2.182 6.54h19.414l-2.183-6.54a.495.495 0 0 1 .316-.63c.262-.09.545.05.633.31l2.402 7.2a.503.503 0 0 1-.474.66H.539a.504.504 0 0 1-.474-.66l2.402-7.2c.087-.26.37-.4.632-.31z" />
      <path d="M.039 13.79c0-.28.224-.5.5-.5h20.802c.276 0 .5.22.5.5v4.75c0 .27-.224.5-.5.5H.539c-.276 0-.5-.23-.5-.5v-4.75zm1 .5v3.75h19.802v-3.75H1.039zM2.72 6.14a.499.499 0 0 0-.227.67.5.5 0 0 0 .669.23" />
      <path d="M10.94 6.09c.276 0 .5.22.5.5v7.2c0 .27-.224.5-.5.5s-.5-.23-.5-.5v-7.2c0-.28.224-.5.5-.5z" />
      <path d="M19 7H3V6h16z" />
    </g>
  </svg>
);

export default DoubleBedIcon;
