import { SVGProps } from 'react';

const NewTabIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    height={24}
    viewBox="0 0 512 512"
    width={24}
    xmlns="http://www.w3.org/2000/svg"
    fill="currentColor"
    {...props}
  >
    <path d="M464 24H336a24 24 0 0 0 0 48h70.059L176 302.059A24 24 0 0 0 209.941 336L440 105.941V176a24 24 0 0 0 48 0V48a24 24 0 0 0-24-24z" />
    <path d="M464 248a24 24 0 0 0-24 24v168H72V72h168a24 24 0 0 0 24-24 24 24 0 0 0-24-24H48a24 24 0 0 0-24 24v416a24 24 0 0 0 24 24h416a24 24 0 0 0 24-24V272a24 24 0 0 0-24-24z" />
  </svg>
);

export default NewTabIcon;
