import { SVGProps } from 'react';

const FlightIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={25}
    height={20}
    viewBox="0 0 25 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fill={props.color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.49922 2.7738C7.56125 2.64353 7.69267 2.56055 7.83695 2.56055H11.4036C11.4837 2.56055 11.5617 2.58627 11.6261 2.63393L15.1285 5.40932C15.2945 5.53221 15.3295 5.76645 15.2066 5.93251C15.0837 6.09857 14.8495 6.13356 14.6834 6.01066L11.2803 3.30865H8.62257L11.5413 6.90276C11.6715 7.06313 11.6471 7.2987 11.4867 7.42893C11.3264 7.55916 11.0908 7.53473 10.9606 7.37437L7.54658 3.1704C7.45563 3.0584 7.4372 2.90407 7.49922 2.7738Z"
    />
    <path
      fill={props.color}
      fillRule="evenodd"
      clipRule="evenodd"
      d="M22.5239 2.50488C23.1998 2.54425 23.8417 2.83394 24.1711 3.52755C24.4913 4.20291 24.3388 4.86717 23.9502 5.41123C23.5705 5.94284 22.9571 6.38078 22.2797 6.68773L22.2785 6.68829C21.6089 6.98884 20.3196 7.56494 19.1981 8.06576L17.3171 8.9054L16.4633 16.383C16.4487 16.5102 16.3702 16.6211 16.255 16.677L12.7376 18.3853C12.6251 18.4399 12.4927 18.4347 12.3848 18.3714C12.277 18.3081 12.2079 18.1951 12.2007 18.0702L11.82 11.4264L6.60153 13.8484C6.53337 13.8801 6.45725 13.8904 6.38311 13.8782L2.93526 13.3088C2.80808 13.2878 2.70068 13.2028 2.65102 13.0838L0.862852 8.80013C0.822625 8.70377 0.824513 8.59497 0.868059 8.50006C0.911605 8.40515 0.992844 8.33276 1.09213 8.3004L3.72085 7.44366C3.83991 7.40486 3.97048 7.42821 4.06872 7.50586L6.42723 9.37022L20.4792 2.9675C21.1257 2.66082 21.8603 2.46622 22.5239 2.50488ZM16.8173 8.3092L18.8931 7.38266C20.0144 6.88192 21.3026 6.30633 21.9716 6.00605C22.5735 5.73316 23.0627 5.36673 23.3414 4.97644C23.6113 4.59853 23.6729 4.22307 23.4953 3.84849C23.3161 3.47118 22.9702 3.28025 22.4804 3.25172C21.9791 3.22252 21.371 3.37209 20.7975 3.64453L20.7921 3.64709L6.52955 10.1457C6.40194 10.2039 6.25251 10.1857 6.1425 10.0988L3.76206 8.21707L1.70939 8.88606L3.26158 12.6044L6.39121 13.1213L12.0044 10.5161C12.1168 10.4639 12.2478 10.4708 12.3541 10.5344C12.4604 10.5981 12.5283 10.7102 12.5354 10.8339L12.9155 17.4672L15.7433 16.0939L16.598 8.60838C16.613 8.4769 16.6964 8.36312 16.8173 8.3092Z"
    />
  </svg>
);

export default FlightIcon;
