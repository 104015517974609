import { SVGProps } from 'react';

const CircleInformationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="#656664"
      d="M12 23C5.925 23 1 18.074 1 12S5.925 1 12 1s11 4.926 11 11-4.925 11-11 11zm0-.973c5.538 0 10.027-4.488 10.027-10.027 0-5.539-4.489-10.027-10.027-10.027C6.463 1.973 1.973 6.461 1.973 12c0 5.539 4.49 10.027 10.027 10.027zm.864-14.262c-.45.458-1.266.458-1.728 0a1.304 1.304 0 0 1-.353-.866c0-.321.135-.642.353-.876.231-.214.548-.35.864-.35.316 0 .633.136.864.35.22.234.353.555.353.876a1.3 1.3 0 0 1-.353.866zM12 17.841c-.535 0-.973-.497-.973-1.11v-5.568c0-.613.438-1.11.973-1.11.535 0 .973.497.973 1.11v5.568c0 .613-.438 1.11-.973 1.11z"
    />
  </svg>
);

export default CircleInformationIcon;
