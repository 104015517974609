import { SVGProps } from 'react';

const ShareIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={21}
    height={23}
    viewBox="0 0 21 23"
    fill="currentColor"
    {...props}
  >
    <path
      d="M17.5 0C19.433 0 21 1.57 21 3.5S19.433 7 17.5 7a3.494 3.494 0 0 1-2.754-1.342L6.75 10.201c.16.402.249.84.249 1.299 0 .459-.089.897-.25 1.299l7.996 4.543A3.494 3.494 0 0 1 17.5 16c1.933 0 3.5 1.57 3.5 3.5S19.433 23 17.5 23a3.502 3.502 0 0 1-3.25-4.799l-7.996-4.543A3.494 3.494 0 0 1 3.5 15C1.567 15 0 13.43 0 11.5S1.567 8 3.5 8c1.118 0 2.114.525 2.754 1.342l7.995-4.543A3.502 3.502 0 0 1 17.5 0Zm0 17a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm-14-8a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Zm14-8a2.5 2.5 0 1 0 0 5 2.5 2.5 0 0 0 0-5Z"
      fillRule="evenodd"
    />
  </svg>
);

export default ShareIcon;
