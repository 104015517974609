import { Fragment } from 'react';
import { InfoText } from 'components/UI/Brand/text';
import { Link } from 'react-router-dom';
import AccessibleHelperText from 'components/UI/AccessibleHelperText';
import Icon from 'components/UI/Icons/Icon';
import SearchResultMobileTable from 'components/Search/SearchResult/Table/SearchResultMobileTable';
import SearchResultDesktopTable from 'components/Search/SearchResult/Table/SearchResultDesktopTable/SearchResultDesktopTable';
import translate from 'translate';
import { media } from 'style/sizes';
import { ITableHeader } from 'interfaces/ITableSearchResult';
import { IPackageTableSearchResult } from 'interfaces/IPackage';
import { LinkText } from 'components';
import { colors } from 'style/colors';
import { useMediaQuery } from 'hooks/useMediaQuery';
import styles from './styles.module.scss';

interface ISearchResultRowTable {
  isCoastal: boolean;
  isShowAccessibleCabins: boolean;
  tableData: IPackageTableSearchResult[];
  tableHeader: ITableHeader[];
  onPriceClicked: (voyageId: string, cabinCode?: string) => void;
  isPortToPortVoyage: boolean;
  hasSearchedWithDeckSpace: boolean;
}

const SearchResultRowTable = ({
  isCoastal,
  isShowAccessibleCabins,
  onPriceClicked,
  tableData,
  tableHeader,
  isPortToPortVoyage,
  hasSearchedWithDeckSpace,
}: ISearchResultRowTable) => {
  const isDesktop = useMediaQuery(media.minLaptop);

  return (
    <table className={styles.table} data-testid="table-results">
      {isDesktop && !isPortToPortVoyage && (
        <thead className={styles.tableHeader}>
          <tr>
            <th className={styles.tableHeadline}>
              <InfoText>{translate('General_Dates')}</InfoText>
            </th>
            {tableHeader.map(({ title, shipCode }, idx) => (
              <th
                key={`${shipCode}-${title.replace(/\s/g, '-')}-${idx + 1}`}
                align="right"
              >
                <div className={styles.accessibilityLabel}>
                  <LinkText
                    target="_blank"
                    as={Link}
                    rel="noopener noreferrer"
                    to={`/learn/ship/${shipCode}#${title
                      .toLowerCase()
                      .replace(/\s/g, '')}`}
                    style={{ fontSize: 12 }}
                  >
                    {title}
                    <AccessibleHelperText type="newTab" />
                    <Icon
                      iconType="newTab"
                      height={13}
                      width={13}
                      color={colors.shared.black}
                      style={{ marginLeft: 2 }}
                    />
                  </LinkText>
                </div>
              </th>
            ))}
          </tr>
        </thead>
      )}
      <tbody className={styles.tableBody}>
        {tableData.map(
          (
            {
              departureDate,
              arrivalDate,
              prices,
              promotions: promotionList,
              ship,
              cabins,
              isViaGeiranger,
              isViaHjorundfjorden,
              hasVehicleAvailability,
              voyageId,
              travelSuggestionCode,
            },
            index
          ) =>
            prices?.length > 0 && (
              <Fragment key={`${ship.shipCode}-${departureDate}-${index + 1}`}>
                {isDesktop ? (
                  <SearchResultDesktopTable
                    departureDate={departureDate}
                    arrivalDate={arrivalDate}
                    prices={prices}
                    promotionList={promotionList}
                    shipDetail={ship}
                    onPriceClicked={onPriceClicked}
                    isShowAccessibleCabins={isShowAccessibleCabins}
                    tableHeader={tableHeader}
                    isCoastal={isCoastal}
                    voyageId={voyageId}
                    isPortToPortVoyage={isPortToPortVoyage}
                    index={index}
                    hasSearchedWithDeckSpace={hasSearchedWithDeckSpace}
                    isViaGeiranger={isViaGeiranger}
                    isViaHjorundfjorden={isViaHjorundfjorden}
                    hasVehicleAvailability={hasVehicleAvailability}
                    travelSuggestionCode={travelSuggestionCode}
                  />
                ) : (
                  <SearchResultMobileTable
                    departureDate={departureDate}
                    arrivalDate={arrivalDate}
                    promotionList={promotionList}
                    index={index}
                    shipDetail={ship}
                    isCoastal={isCoastal}
                    prices={prices}
                    isShowAccessibleCabins={isShowAccessibleCabins}
                    onPriceClicked={onPriceClicked}
                    isPortToPortVoyage={isPortToPortVoyage}
                    voyageId={voyageId}
                    hasSearchedWithDeckSpace={hasSearchedWithDeckSpace}
                    cabins={cabins}
                    isViaGeiranger={isViaGeiranger}
                    isViaHjorundfjorden={isViaHjorundfjorden}
                    hasVehicleAvailability={hasVehicleAvailability}
                    travelSuggestionCode={travelSuggestionCode}
                  />
                )}
              </Fragment>
            )
        )}
      </tbody>
    </table>
  );
};

export default SearchResultRowTable;
