import { BodyText } from 'components/UI/Brand/text';
import { colors } from 'style/colors';
import { Icon } from 'components';

import styled from 'styled-components';

interface IText {
  isValidationPassed: boolean;
}

const Row = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  gap: 8px;
`;

const Text = styled(BodyText)<IText>`
  font-weight: ${({ isValidationPassed }) => (isValidationPassed ? 400 : 500)};
  color: ${colors.shared.warmGray5};
`;

export const ValidationRow = ({
  isValidationPassed = false,
  text,
}: {
  isValidationPassed: boolean;
  text: string;
}) => (
  <Row>
    <Icon
      iconType={isValidationPassed ? 'circleWithCheckmark' : 'circleWithCross'}
      color={
        isValidationPassed ? colors.b2b.snowGreen : colors.shared.warmGray5
      }
      width={24}
      height={24}
    />
    <Text
      isValidationPassed={isValidationPassed}
      aria-hidden={isValidationPassed}
    >
      {text}
    </Text>
  </Row>
);
