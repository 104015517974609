import { Action, ActionFunctionAny, createActions } from 'redux-actions';
import axios from 'axios-instance';
import translate from 'translate';
import { createSuccessMessage, createFailedMessage } from './headerMessage';

export const {
  fetchSummaryStart,
  fetchSummarySuccess,
  fetchSummaryFailed,
  shareSummaryStart,
  shareSummarySuccess,
  shareSummaryFailed,
  resetSummary,
} = createActions({
  FETCH_SUMMARY_START: undefined,
  FETCH_SUMMARY_SUCCESS: (summaryData) => ({ summaryData }),
  FETCH_SUMMARY_FAILED: (error) => ({ error }),
  SHARE_SUMMARY_START: undefined,
  SHARE_SUMMARY_SUCCESS: (shareSummaryData) => ({ shareSummaryData }),
  SHARE_SUMMARY_FAILED: (error) => ({ error }),
  RESET_SUMMARY: undefined,
});

export const fetchSummary =
  (quoteId: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchSummaryStart());
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}quote/${quoteId}`)
      .then((res) => {
        dispatch(fetchSummarySuccess(res.data));
      })
      .catch((err) => {
        dispatch(fetchSummaryFailed(err));
      });
  };

export const shareSummary =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (summary: any) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(shareSummaryStart());
    axios
      .post(`${process.env.REACT_APP_API_BASE_URL}user/share`, summary)
      .then((res) => {
        dispatch(createSuccessMessage(translate('Share_QuoteSent')));
        dispatch(shareSummarySuccess(res.data));
      })
      .catch((err) => {
        dispatch(createFailedMessage(translate('Share_QuoteError')));
        dispatch(shareSummaryFailed(err));
      });
  };
