import { colors } from 'style/colors';
import { IBrandColors } from '../interfaces/IBrandColors';

export const expeditionColors: IBrandColors = {
  black: {
    regular: colors.shared.black,
    header: colors.shared.black,
    text: colors.shared.textBlack,
  },
  white: colors.shared.white,
  default: {
    regular: colors.hrx.blue,
    dark: colors.hrx.blueDark,
  },
  grey: { regular: colors.hrx.greyRegular, dark: colors.shared.greyDark },
  offer: colors.shared.expeditionJungleGreen1,
  menu: { regular: colors.shared.textBlack, dark: colors.shared.black },
  link: colors.hrn.clearBlue1,
};
