import { SVGProps } from 'react';

const BusIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={20}
    height={20}
    viewBox="0 0 20 20"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2_549)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.8737 16.459C2.10382 16.459 2.29036 16.6455 2.29036 16.8757V18.959H5.20703V16.8757C5.20703 16.6455 5.39358 16.459 5.6237 16.459C5.85382 16.459 6.04036 16.6455 6.04036 16.8757V19.3757C6.04036 19.6058 5.85382 19.7923 5.6237 19.7923H1.8737C1.64358 19.7923 1.45703 19.6058 1.45703 19.3757V16.8757C1.45703 16.6455 1.64358 16.459 1.8737 16.459Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.3737 16.459C14.6038 16.459 14.7904 16.6455 14.7904 16.8757V18.959H17.707V16.8757C17.707 16.6455 17.8936 16.459 18.1237 16.459C18.3538 16.459 18.5404 16.6455 18.5404 16.8757V19.3757C18.5404 19.6058 18.3538 19.7923 18.1237 19.7923H14.3737C14.1436 19.7923 13.957 19.6058 13.957 19.3757V16.8757C13.957 16.6455 14.1436 16.459 14.3737 16.459Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.1237 1.04232C2.66382 1.04232 2.29036 1.41577 2.29036 1.87565V16.459H17.707V1.87565C17.707 1.41577 17.3336 1.04232 16.8737 1.04232H3.1237ZM1.45703 1.87565C1.45703 0.955532 2.20358 0.208984 3.1237 0.208984H16.8737C17.7938 0.208984 18.5404 0.955532 18.5404 1.87565V16.8756C18.5404 17.1058 18.3538 17.2923 18.1237 17.2923H1.8737C1.64358 17.2923 1.45703 17.1058 1.45703 16.8756V1.87565Z"
        fill={props.color}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.45703 10.6257C1.45703 10.3955 1.64358 10.209 1.8737 10.209H18.1237C18.3538 10.209 18.5404 10.3955 18.5404 10.6257C18.5404 10.8558 18.3538 11.0423 18.1237 11.0423H1.8737C1.64358 11.0423 1.45703 10.8558 1.45703 10.6257Z"
        fill={props.color}
      />
      <path
        d="M5 15C5.69036 15 6.25 14.4404 6.25 13.75C6.25 13.0596 5.69036 12.5 5 12.5C4.30964 12.5 3.75 13.0596 3.75 13.75C3.75 14.4404 4.30964 15 5 15Z"
        fill={props.color}
      />
      <path
        d="M15 15C15.6904 15 16.25 14.4404 16.25 13.75C16.25 13.0596 15.6904 12.5 15 12.5C14.3096 12.5 13.75 13.0596 13.75 13.75C13.75 14.4404 14.3096 15 15 15Z"
        fill={props.color}
      />
      <path
        d="M1.25 11.25H1.66667V6.25H1.25C0.56 6.25 0 6.81 0 7.5V10C0 10.69 0.56 11.25 1.25 11.25Z"
        fill={props.color}
      />
      <path
        d="M18.7487 11.25H18.332V6.25H18.7487C19.4387 6.25 19.9987 6.81 19.9987 7.5V10C19.9987 10.69 19.4387 11.25 18.7487 11.25Z"
        fill={props.color}
      />
    </g>
    <defs>
      <clipPath id="clip0_2_549">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default BusIcon;
