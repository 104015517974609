import { Header2, BodyText } from 'components/UI/Brand/text';
import { useSelector } from 'react-redux';
import translate from 'translate';
import { IProfileData } from 'interfaces/IProfile';
import { getRegionById } from 'utils/regions';
import { IRootState } from 'store/types/root';
import RegisterUserData from './RegisterUserData';
import styles from './styles.module.scss';

interface IRegistrationProps {
  onSubmit: (agentData: IProfileData) => void;
  populateData?: IProfileData;
  accountMigration?: boolean;
}

const Registration = ({
  onSubmit,
  populateData,
  accountMigration = false,
}: IRegistrationProps) => {
  const { region } = useSelector((state: IRootState) => state.onboarding);

  return (
    <div className={styles.registrationContainer}>
      <div className={styles.descContainer}>
        <Header2>
          {translate('Login_AccountMigration_ThirdPage_EnterAgentInfo')}
        </Header2>
        <BodyText>
          {translate(
            'Login_AccountMigration_ThirdPage_PleaseEnterTheBelowInfo'
          )}
        </BodyText>
      </div>
      <RegisterUserData
        region={getRegionById(region?.id)}
        onSubmit={onSubmit}
        populateData={populateData}
        accountMigration={accountMigration}
      />
    </div>
  );
};

export default Registration;
