import { SVGProps } from 'react';

const ArrowLeftIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M17.4,1.6c0.2,0.2,0.2,0.5,0,0.7L7.7,12l9.6,9.6c0.2,0.2,0.2,0.5,0,0.7c-0.2,0.2-0.5,0.2-0.7,0l-10-10
      c-0.2-0.2-0.2-0.5,0-0.7l10-10C16.8,1.5,17.2,1.5,17.4,1.6z"
    />
  </svg>
);

export default ArrowLeftIcon;
