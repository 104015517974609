import languageKeys from './languageKeys';
import { IRegion } from '../interfaces/IOnboarding';
import { Region } from './regions';

const getLocaleByRegionId = (regionId: number) => {
  const {
    EN_AMERICAS,
    EN_APAC,
    EN_UK,
    EN_EMEA,
    de,
    DE_CH,
    FR,
    NO,
    SE,
    DK,
    AT,
  } = languageKeys;
  switch (regionId) {
    case Region.Americas:
      return EN_AMERICAS;
    case Region.APACs:
      return EN_APAC;
    case Region.Germany:
      return de;
    case Region.UK:
      return EN_UK;
    case Region.EMEA:
      return EN_EMEA;
    case Region.Switzerland:
      return DE_CH;
    case Region.France:
      return FR;
    case Region.Norway:
      return NO;
    case Region.Sweden:
      return SE;
    case Region.Denmark:
      return DK;
    case Region.Austria:
      return AT;
    default:
      return EN_AMERICAS;
  }
};

export default (selectedRegion: IRegion) =>
  getLocaleByRegionId(selectedRegion.id);

export const getLocaleByRegion = (selectedRegion: Region) =>
  getLocaleByRegionId(selectedRegion);
