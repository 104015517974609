import { IProduct } from 'interfaces/IAnalytics';
import { createActions, Action, ActionFunctionAny } from 'redux-actions';

export const {
  setAnalyticsData,
  updateCabinNumber,
  addProduct,
  removeProduct,
  increaseStep,
} = createActions({
  SET_ANALYTICS_DATA: (datalayerData) => ({ datalayerData }),
  UPDATE_CABIN_NUMBER: (cabinNumber) => ({ cabinNumber }),
  ADD_PRODUCT: (product) => ({ product }),
  REMOVE_PRODUCT: (productId) => ({ productId }),
  INCREASE_STEP: null,
});

// what type for datalayerData ? never use it in our code
export const setDatalayerData =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (datalayerData: any) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(setAnalyticsData(datalayerData));
  };

export const updateCabinNumberAnalytics =
  (cabinNumber: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(updateCabinNumber(cabinNumber));
  };

export const addAnalyticsExtras =
  (product: IProduct) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(addProduct(product));
  };

export const removeAnalyticsExtra =
  (productId: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(removeProduct(productId));
  };

export const increaseEcommerceStep =
  () => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(increaseStep());
  };
