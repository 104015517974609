import React from 'react';
import clsx from 'clsx';
import { ButtonText } from 'components/UI/Brand/text';
import styles from './styles.module.scss';

interface IStyledProps {
  disabled?: boolean;
}

interface IOutlinedButtonProps extends IStyledProps {
  onClick?: () => void;
  disabled?: boolean;
  id?: string;
  children: React.ReactNode;
  size?: 'normal' | 'small';
  isCoastal?: boolean;
}

const OutlinedButton = ({
  onClick,
  disabled = false,
  children,
  size = 'normal',
  id,
  isCoastal,
}: IOutlinedButtonProps) => (
  <button
    id={id}
    onClick={onClick}
    type="button"
    className={clsx(styles.root, {
      [styles.small]: size === 'small',
      [styles.isCoastal]: isCoastal,
    })}
    disabled={disabled}
  >
    <ButtonText>{children}</ButtonText>
  </button>
);

export default OutlinedButton;
