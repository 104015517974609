import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IHeaderState } from 'interfaces/IHeader';

const initialState: IHeaderState = {
  isContentHeader: false,
  hasInfoBanner: false,
};

const headerSlice = createSlice({
  name: 'header',
  initialState,
  reducers: {
    onContentHeader: (state, action: PayloadAction<boolean>) => {
      state.isContentHeader = action.payload;
    },
    onInfoBanner: (state, action: PayloadAction<boolean>) => {
      state.hasInfoBanner = action.payload;
    },
  },
});

export const { onContentHeader, onInfoBanner } = headerSlice.actions;

export default headerSlice.reducer;
