import { IPasswordValidation } from 'interfaces/IAuth';

const hasMin8Chars = (value: string) => value.length > 7;

const hasLowerCase = (value: string) => /[a-z]/.test(value);

const hasUpperCase = (value: string) => /[A-Z]/.test(value);

const hasNumber = (value: string) => /\d/.test(value);

const passwordValidator = (value: string, validation: IPasswordValidation) => {
  const min8Chars = value && hasMin8Chars(value);
  const atLeastOneLowerCase = value && hasLowerCase(value);
  const atLeastOneCapital = value && hasUpperCase(value);
  const atLeastOneNumber = value && hasNumber(value);

  return {
    ...validation,
    valid:
      min8Chars && atLeastOneLowerCase && atLeastOneCapital && atLeastOneNumber,
    min8Chars,
    atLeastOneLowerCase,
    atLeastOneCapital,
    atLeastOneNumber,
  };
};

export default passwordValidator;
