import { AxiosError } from 'axios';
import { IBookingDetailsState } from 'interfaces/IBookingDetails';
import { handleActions } from 'redux-actions';
import {
  fetchBookingDetailsFailed,
  fetchBookingDetailsStart,
  fetchBookingDetailsSuccess,
  resetBookingDetails,
} from '../actions/bookingdetails';

const initialState: IBookingDetailsState = {
  data: null,
  error: null,
  loading: false,
  fetched: false,
};

const reducer = handleActions(
  {
    [fetchBookingDetailsStart.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchBookingDetailsSuccess.toString()]: (state, action) => ({
      ...state,
      data: action.payload.data,
      fetched: true,
      error: null,
      loading: false,
    }),
    [fetchBookingDetailsFailed.toString()]: (state, action) => ({
      ...state,
      data: initialState.data,
      fetched: true,
      error: (action.payload as unknown as AxiosError).response.data.Message,
      loading: false,
    }),
    [resetBookingDetails.toString()]: () => ({
      ...initialState,
    }),
  },
  initialState
);

export default reducer;
