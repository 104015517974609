import { NavLink } from 'react-router-dom';
import { getRegionByLocale, Region } from 'utils/regions';
import { ButtonText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import { getJwtPayload, track } from 'utils/analytics';
import translate from 'translate';
import { getLanguage } from 'utils/getLanguage';
import styles from './styles.module.scss';

const { REACT_APP_COMPANY } = process.env;

const trackBook = () => track('Click book');
const trackLearn = () => track('Click learn');
// const trackGetCertified = () => track('Click get certified');
const trackResources = () => track('Click resources');
const trackAgencyArea = () => track('Click Agenturbereic German');

interface INavItemsLeftProps {
  setLearnButtonActive: (value: boolean) => void;
  setShowLearnNavigation: (value: boolean) => void;
}

const NavItemsLeft = ({
  setShowLearnNavigation,
  setLearnButtonActive,
}: INavItemsLeftProps) => {
  const marketId = getJwtPayload()['http://user/market'];

  function canRegionShowTraining() {
    const region = getRegionByLocale(getLanguage());

    if (REACT_APP_COMPANY === 'HX') {
      return (
        region === Region.UK ||
        region === Region.Americas ||
        region === Region.EMEA ||
        region === Region.APACs ||
        region === Region.Switzerland
      );
    }

    if (REACT_APP_COMPANY === 'HRN') {
      return region === Region.UK || region === Region.EMEA;
    }

    return false;
  }

  return (
    <ul
      className={styles.list}
      onMouseLeave={() => setLearnButtonActive(false)}
      onMouseEnter={() => {
        setShowLearnNavigation(true);
        setLearnButtonActive(true);
      }}
    >
      {(REACT_APP_COMPANY === 'HX' || !REACT_APP_COMPANY) && (
        <li>
          <NavLink
            to="/book/expedition"
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
                [styles.hx]: REACT_APP_COMPANY === 'HX' && isActive,
              })
            }
            onClick={() => {
              trackBook();
              setShowLearnNavigation(false);
            }}
            onMouseEnter={() => setShowLearnNavigation(false)}
          >
            <ButtonText>{translate('SearchBar_Tabs_Expedition')}</ButtonText>
          </NavLink>
        </li>
      )}
      {(REACT_APP_COMPANY === 'HRN' || !REACT_APP_COMPANY) && (
        <li>
          <NavLink
            to="/book/coastal"
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
              })
            }
            onClick={() => {
              trackBook();
              setShowLearnNavigation(false);
            }}
            onMouseEnter={() => setShowLearnNavigation(false)}
          >
            <ButtonText>
              {translate('SearchBar_Tabs_NorwegianCoastal')}
            </ButtonText>
          </NavLink>
        </li>
      )}
      {
        <li>
          <NavLink
            to="/learn"
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
                [styles.hx]: REACT_APP_COMPANY === 'HX' && isActive,
              })
            }
            onClick={() => {
              trackLearn();
              setShowLearnNavigation(false);
            }}
          >
            <ButtonText>{translate('Navbar_Learn')}</ButtonText>
          </NavLink>
        </li>
      }
      {/* {canRegionShowTraining() && (
      {canRegionShowTraining() && (
        <li>
          <NavLink
            to="/training"
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
                [styles.hx]: REACT_APP_COMPANY === 'HX' && isActive,
              })
            }
            onClick={() => {
              trackGetCertified();
              setShowLearnNavigation(false);
            }}
            onMouseEnter={() => setShowLearnNavigation(false)}
          >
            <ButtonText>{translate('Navbar_Training')}</ButtonText>
          </NavLink>
        </li>
      )} */}
      {canRegionShowTraining() && (
        <li>
          <NavLink
            to={
              REACT_APP_COMPANY === 'HX'
                ? 'learn/article/training-hx'
                : 'learn/article/external-training'
            }
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
                [styles.hx]: REACT_APP_COMPANY === 'HX' && isActive,
              })
            }
            onClick={() => {
              trackAgencyArea();
              setShowLearnNavigation(false);
            }}
          >
            <ButtonText>{translate('Navbar_Training')}</ButtonText>
          </NavLink>
        </li>
      )}
      {
        <li>
          <NavLink
            to="/marketing"
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
                [styles.hx]: REACT_APP_COMPANY === 'HX' && isActive,
              })
            }
            onClick={() => {
              trackResources();
              setShowLearnNavigation(false);
            }}
            onMouseEnter={() => setShowLearnNavigation(false)}
          >
            <ButtonText>{translate('Navbar_Marketing')}</ButtonText>
          </NavLink>
        </li>
      }
      {marketId === 'DE' && (
        <li>
          <NavLink
            to={
              REACT_APP_COMPANY === 'HX'
                ? '/learn/articleContainer/48WIPkoHRvQi0Fo6vhzdGa'
                : '/learn/articleContainer/1tvz3TNuNGxSatNCW7OfQe'
            }
            end
            className={({ isActive }) =>
              clsx(styles.link, {
                [styles.linkActive]: isActive,
                [styles.hx]: REACT_APP_COMPANY === 'HX' && isActive,
              })
            }
            onClick={() => {
              trackAgencyArea();
              setShowLearnNavigation(false);
            }}
          >
            <ButtonText>{translate('Navbar_AgencyArea')}</ButtonText>
          </NavLink>
        </li>
      )}
    </ul>
  );
};

export default NavItemsLeft;
