import { useModal } from 'hooks/useModal';
import { useInterval } from 'hooks/useInterval';
import TenMinutesModal from './BookingTimerModals/TenMinutesModal';
import TimesupModal from './BookingTimerModals/TimesupModal';

const BookingTimer = () => {
  const {
    isModalVisible: isTenMinutesModalVisible,
    showModal: showTenMinutesModal,
    hideModal: hideTenMinutesModal,
    RenderModal: RenderTenMinutesModal,
  } = useModal();
  const {
    isModalVisible: isTimesupModalVisible,
    showModal: showTimesupModal,
    hideModal: hideTimesupModal,
    RenderModal: RenderTimesupModal,
  } = useModal();

  const twentyMinutes = 1200; // ten minutes left
  const thirtyMinutes = 1800;
  const { secondsElapsed } = useInterval({
    callback: () => {
      if (secondsElapsed === twentyMinutes) {
        showTenMinutesModal();
      }
      if (secondsElapsed >= thirtyMinutes) {
        hideTenMinutesModal();
        showTimesupModal();
      }
    },
    options: { maxSeconds: thirtyMinutes },
  });

  return (
    <>
      {isTenMinutesModalVisible && (
        <RenderTenMinutesModal>
          <TenMinutesModal
            seconds={secondsElapsed}
            closeModal={hideTenMinutesModal}
          />
        </RenderTenMinutesModal>
      )}
      {isTimesupModalVisible && (
        <RenderTimesupModal>
          <TimesupModal closeModal={hideTimesupModal} />
        </RenderTimesupModal>
      )}
    </>
  );
};

export default BookingTimer;
