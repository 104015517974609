import { SVGProps } from 'react';

const WindowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={17}
    height={19}
    viewBox="0 0 17 19"
    fill="#000"
    {...props}
  >
    <path
      d="M13.8 0c1.58 0 2.88 1.29 2.88 2.88v12.66c0 1.58-1.3 2.88-2.88 2.88H2.88c-1.59 0-2.88-1.3-2.88-2.88V2.88A2.88 2.88 0 0 1 2.88 0H13.8Zm0 1H2.88C1.85 1 1 1.84 1 2.88v12.66c0 1.03.85 1.88 1.88 1.88H13.8c1.03 0 1.88-.85 1.88-1.88V2.88c0-1.04-.85-1.88-1.88-1.88Zm-2.3 2.17c1.13 0 2.09.98 2.09 2.08v7.92a2.1 2.1 0 0 1-2.09 2.08H5.17c-1.13 0-2.08-.95-2.08-2.08V5.25c0-1.08.94-2.08 2.08-2.08h6.33Zm0 1H5.17c-.57 0-1.08.53-1.08 1.08v7.92c0 .58.5 1.08 1.08 1.08h6.33c.59 0 1.09-.51 1.09-1.08V5.25c0-.56-.52-1.08-1.09-1.08Z"
      fillRule="evenodd"
    />
  </svg>
);

export default WindowIcon;
