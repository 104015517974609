import styles from './styles.module.scss';
import ShimmerLoading from './ShimmerLoading/ShimmerLoading';

const OnBoardingPopupShimmerLoading = () => (
  <div className={styles.loader}>
    <ShimmerLoading type="title" />
    <ShimmerLoading type="image" width="100%" height="288px" />
    <ShimmerLoading type="text" />
    <ShimmerLoading type="button" />
  </div>
);

export default OnBoardingPopupShimmerLoading;
