import { SVGAttributes } from 'react';

const ExclamationInTriangleSmallIcon = (props: SVGAttributes<SVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.33341 1.8335C8.51905 1.8335 8.68941 1.93635 8.77587 2.10062L15.4425 14.7673C15.5241 14.9223 15.5186 15.1086 15.4281 15.2586C15.3376 15.4085 15.1752 15.5002 15.0001 15.5002H1.66675C1.49161 15.5002 1.32923 15.4085 1.23871 15.2586C1.14819 15.1086 1.14272 14.9223 1.22429 14.7673L7.89095 2.10062C7.97742 1.93635 8.14777 1.8335 8.33341 1.8335ZM2.49493 14.5002H14.1719L8.33341 3.40704L2.49493 14.5002Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.7334 7.70497C7.7334 7.39069 8.0034 7.13354 8.3334 7.13354C8.6634 7.13354 8.9334 7.39069 8.9334 7.70497V10.5621C8.9334 10.8764 8.6634 11.1335 8.3334 11.1335C8.0034 11.1335 7.7334 10.8764 7.7334 10.5621V7.70497Z"
    />
    <path d="M7.86008 12.1864C7.74008 12.3139 7.66675 12.4883 7.66675 12.6627C7.66675 12.8371 7.74008 13.0116 7.86008 13.139C7.98675 13.2598 8.16008 13.3336 8.33341 13.3336C8.50675 13.3336 8.68008 13.2598 8.80675 13.139C8.92675 13.0116 9.00008 12.8371 9.00008 12.6627C9.00008 12.4883 8.92675 12.3139 8.80675 12.1864C8.55341 11.9382 8.10675 11.9382 7.86008 12.1864Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.64373 12.7861C8.65611 12.7559 8.66675 12.7147 8.66675 12.6627C8.66675 12.6107 8.65611 12.5695 8.64373 12.5393C8.63139 12.5092 8.61347 12.4804 8.58818 12.4536L8.36602 12.6627L8.58818 12.8719C8.61347 12.845 8.63139 12.8163 8.64373 12.7861ZM8.33341 12.6934L8.11002 12.9037C8.19255 12.9829 8.27848 13.0002 8.33091 13.0002C8.33174 13.0002 8.33257 13.0002 8.33341 13.0002C8.38406 12.9997 8.47153 12.9826 8.55668 12.9036L8.33341 12.6934ZM8.33341 12.632L8.43103 12.5401L8.5501 12.4152C8.52329 12.3897 8.494 12.371 8.46252 12.358C8.43098 12.3449 8.38781 12.3336 8.33341 12.3336C8.27902 12.3336 8.23585 12.3449 8.20431 12.358C8.17283 12.371 8.14354 12.3897 8.11673 12.4152L8.2358 12.5401L8.33341 12.632ZM7.86008 12.1864C7.74008 12.3139 7.66675 12.4883 7.66675 12.6627C7.66675 12.8371 7.74008 13.0116 7.86008 13.139C7.98675 13.2598 8.16008 13.3336 8.33341 13.3336C8.50675 13.3336 8.68008 13.2598 8.80675 13.139C8.92675 13.0116 9.00008 12.8371 9.00008 12.6627C9.00008 12.4883 8.92675 12.3139 8.80675 12.1864C8.55341 11.9382 8.10675 11.9382 7.86008 12.1864ZM8.30081 12.6627L8.07865 12.4536C8.05336 12.4804 8.03544 12.5092 8.0231 12.5393C8.01072 12.5695 8.00008 12.6107 8.00008 12.6627C8.00008 12.7147 8.01072 12.7559 8.0231 12.7861C8.03544 12.8163 8.05336 12.845 8.07865 12.8719L8.30081 12.6627Z"
    />
  </svg>
);

export default ExclamationInTriangleSmallIcon;
