import { Link, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import { Icon, LinkText } from 'components';
import { colors } from 'style/colors';
import AccessibleHelperText from '../AccessibleHelperText';
import styles from './styles.module.scss';

interface IAnchorLink {
  anchorId: string;
  to: string;
  title: string;
  shouldOpenInNewTab?: boolean;
  className?: string;
  iconColor?: string;
  stickyElementsHeight: number;
}

const AnchorLink = ({
  anchorId,
  to,
  title,
  shouldOpenInNewTab,
  className,
  iconColor,
  stickyElementsHeight,
}: IAnchorLink) => {
  const { hash } = useLocation();

  const scrollToAnchor = () => {
    if (shouldOpenInNewTab && !hash) {
      return;
    }
    if (anchorId) {
      const anchor = document.getElementById(anchorId);
      setTimeout(() => {
        window.scrollTo({
          left: 0,
          top: anchor.offsetTop - stickyElementsHeight,
          behavior: 'smooth',
        });
      }, 250);
    }
  };

  return (
    <LinkText
      to={to}
      as={Link}
      target={shouldOpenInNewTab ? '_blank' : null}
      rel={shouldOpenInNewTab ? 'noopener noreferrer' : null}
      onClick={() => {
        scrollToAnchor();
      }}
      className={clsx(styles.link, className)}
    >
      {title}
      {shouldOpenInNewTab && (
        <>
          <AccessibleHelperText type="newTab" />
          <Icon
            iconType="newTab"
            height={13}
            width={13}
            color={colors.shared.white || iconColor}
          />
        </>
      )}
    </LinkText>
  );
};

export default AnchorLink;
