import { TBrand } from '../brand';
import coastalTheme from '../fontThemes/coastalTheme';
import expeditionTheme from '../fontThemes/expeditionTheme';
import groupTheme from '../fontThemes/groupTheme';

export const getBrandTypography = (brand: TBrand) => {
  if (brand === 'coastal') return coastalTheme;
  if (brand === 'group') return groupTheme;
  if (brand === 'expedition') return expeditionTheme;

  return coastalTheme;
};
