import { createSlice } from '@reduxjs/toolkit';
import { IBookingsState } from 'interfaces/IBookings';
import {
  getAgencyBookings,
  getAgentBookings,
  getBookingDetails,
  getNewAgencyBookings,
  getNewAgentBookings,
  searchBooking,
} from 'store/services/bookings';
import {
  removeBookingsDuplicates,
  removeNewBookingsDuplicates,
} from './utils/removeBookingsDuplicates';

const initialState: IBookingsState = {
  agencyBookings: null,
  agencyNewBookings: null,
  totalNumberOfBookingsForAgency: 0,
  agentBookings: null,
  agentNewBookings: null,
  totalNumberOfBookingsForAgent: 0,
  bookingDetails: null,
};

const bookingsSlice = createSlice({
  name: 'bookings',
  initialState,
  reducers: {
    resetAgentBookings: (state) => {
      state.agentBookings = null;
      state.totalNumberOfBookingsForAgent = 0;
    },
    resetAgencyBookings: (state) => {
      state.agencyBookings = null;
      state.totalNumberOfBookingsForAgency = 0;
    },
    resetBookingDetails: (state) => {
      state.bookingDetails = null;
    },
  },
  extraReducers: (builder) => {
    builder.addMatcher(getAgencyBookings.matchFulfilled, (state, action) => {
      const allBookings =
        state.agencyBookings !== null
          ? [...state.agencyBookings, ...action.payload.bookings]
          : [...action.payload.bookings];
      state.agencyBookings = removeBookingsDuplicates(allBookings);
      state.totalNumberOfBookingsForAgency =
        action.payload.totalNumberOfBookingsForAgent;
    });
    builder.addMatcher(getNewAgencyBookings.matchFulfilled, (state, action) => {
      const allBookings =
        state.agencyNewBookings !== null
          ? [...state.agencyNewBookings, ...action.payload.bookings]
          : [...action.payload.bookings];
      state.agencyNewBookings = removeNewBookingsDuplicates(allBookings);
      state.totalNumberOfBookingsForAgency =
        action.payload.totalNumberOfBookings;
    });
    builder.addMatcher(getAgentBookings.matchFulfilled, (state, action) => {
      const allBookings =
        state.agentBookings !== null
          ? [...state.agentBookings, ...action.payload.bookings]
          : [...action.payload.bookings];
      state.agentBookings = removeBookingsDuplicates(allBookings);
      state.totalNumberOfBookingsForAgent =
        action.payload.totalNumberOfBookingsForAgent;
    });
    builder.addMatcher(getNewAgentBookings.matchFulfilled, (state, action) => {
      const allBookings =
        state.agentNewBookings !== null
          ? [...state.agentNewBookings, ...action.payload.bookings]
          : [...action.payload.bookings];
      state.agentNewBookings = removeNewBookingsDuplicates(allBookings);
      state.totalNumberOfBookingsForAgent =
        action.payload.totalNumberOfBookings;
    });
    builder.addMatcher(getBookingDetails.matchFulfilled, (state, action) => {
      state.bookingDetails = action.payload;
    });
    builder.addMatcher(searchBooking.matchFulfilled, (state, action) => {
      if (action.payload.isAgencyBookingSearch) {
        state.agencyNewBookings =
          state.agencyNewBookings !== null
            ? [...state.agencyNewBookings, action.payload.booking]
            : [action.payload.booking];
      } else {
        state.agentNewBookings =
          state.agentNewBookings !== null
            ? [...state.agentNewBookings, action.payload.booking]
            : [action.payload.booking];
      }
    });
  },
});

export const { resetAgentBookings, resetAgencyBookings, resetBookingDetails } =
  bookingsSlice.actions;
export default bookingsSlice.reducer;
