import {
  BACKWARD,
  BOOKING_STEPS,
  BOOKING_STEP_DIRECTIONS,
  CABIN_STEPS,
  DECKSPACE_STEPS,
  FORWARD,
  NOT_CABIN_STEPS,
  PACKAGES,
} from 'interfaces/IBooking';

export interface IBookingStepAndCabinIndex {
  bookingStep: BOOKING_STEPS;
  cabinIndex?: number | undefined;
}
export const getInitialBookingSteps = ({
  isDeckSearch,
  exclude,
}: {
  isDeckSearch: boolean;
  exclude?: BOOKING_STEPS[];
}): BOOKING_STEPS[] => {
  const initialBookingSteps = !isDeckSearch ? BOOKING_STEPS : DECKSPACE_STEPS;
  const bookingSteps = Object.values(initialBookingSteps);
  if (isDeckSearch) {
    return bookingSteps.filter((step) => step !== PACKAGES);
  }
  if (exclude) {
    return bookingSteps.filter((step) => !exclude.includes(step));
  }
  return bookingSteps;
};

export const createBookingStepId = (
  step: BOOKING_STEPS,
  cabinIndex?: number
) =>
  cabinIndex !== undefined && CABIN_STEPS.includes(step)
    ? `${step}_${cabinIndex}`
    : step;

export const getBookingStepAndCabinIndexFromStepId = (
  step: string
): IBookingStepAndCabinIndex => {
  const index = step.replace(/[^0-9]/g, '');
  const cabinIndex = index ? parseInt(index, 10) : undefined;
  const bookingStep = step.replace(/[_0-9]/g, '') as BOOKING_STEPS;

  return {
    bookingStep,
    cabinIndex,
  };
};
/** creates booking steps order array... i.e: ["cabin-type_0", "cabin-grade_0", "packages"] */
export const createBookingStepsOrder = (
  numberOfCabins: number,
  bookingSteps: BOOKING_STEPS[]
): string[] => {
  const steps: string[] = [];
  Array.from(Array(numberOfCabins).keys()).forEach((_, index) =>
    CABIN_STEPS.forEach((step) => steps.push(createBookingStepId(step, index)))
  );
  const notCabinSteps = bookingSteps.filter((step) =>
    NOT_CABIN_STEPS.includes(step)
  );
  steps.push(...notCabinSteps);
  return steps;
};

export const getNextBookingStep = (
  currentSelectedStep: BOOKING_STEPS,
  currentCabinIndex: number,
  bookingSteps: string[],
  direction: BOOKING_STEP_DIRECTIONS
): IBookingStepAndCabinIndex => {
  const currentStepIndex = bookingSteps.indexOf(
    createBookingStepId(currentSelectedStep, currentCabinIndex)
  );
  let nextStep = '';

  if (direction === FORWARD) {
    nextStep =
      bookingSteps[
        currentStepIndex < bookingSteps.length
          ? currentStepIndex + 1
          : currentStepIndex
      ];
  }
  if (direction === BACKWARD) {
    nextStep =
      bookingSteps[
        currentStepIndex > 0 ? currentStepIndex - 1 : currentStepIndex
      ];
  }
  return getBookingStepAndCabinIndexFromStepId(nextStep);
};
