import { getPassengersAmountPerSearchCabin } from 'containers/Book/Search/utils/getPassengersAmountPerSearchCabin';
import { ICabin, ICabinCategory } from 'interfaces/ICabin';

interface IGetMaxPaxData {
  cabins: ICabin[];
  cabinCategories: ICabinCategory[];
  hasSearchedWithDeckSpace: boolean;
  isCoastal: boolean;
}

export const getMaxPaxData = ({
  cabins,
  cabinCategories,
  hasSearchedWithDeckSpace,
  isCoastal,
}: IGetMaxPaxData) => {
  const passengersAmountPerSearchCabin = getPassengersAmountPerSearchCabin(
    cabins,
    !isCoastal
  );

  let hasMaximumOccupancyBeenReached = false;
  let maxOccupancy = 1;

  cabinCategories.forEach((category) => {
    maxOccupancy = Math.max(
      ...(category?.cabinGradesExtended?.flatMap(
        (_cabin) => _cabin?.maxOccupancy || 0
      ) || []),
      maxOccupancy
    );
  });

  hasMaximumOccupancyBeenReached = passengersAmountPerSearchCabin
    .map((passengerAmount) => passengerAmount > maxOccupancy)
    ?.some((amount) => !!amount);

  const hasMaxPaxNotification =
    hasMaximumOccupancyBeenReached && !hasSearchedWithDeckSpace;

  return { hasMaxPaxNotification, maxOccupancy };
};
