import { HurtigrutenLogo } from '..';
import HxLogo from './HxLogo';
import { IconProps, LogoType } from './util';

interface ILogoProps extends Omit<IconProps, 'logoType'> {
  logoTypes: {
    hx: LogoType;
    hrn: LogoType;
  };
}

const Logo = ({ logoTypes, ...rest }: ILogoProps) => {
  const isHX = process.env.REACT_APP_COMPANY === 'HX';

  return isHX ? (
    <HxLogo logoType={logoTypes.hx} {...rest} />
  ) : (
    <HurtigrutenLogo logoType={logoTypes.hrn} {...rest} />
  );
};

export default Logo;
