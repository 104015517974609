import { BodyText, Header3 } from 'components/UI/Brand/text';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import { HEADER_SIZE } from 'containers/Learn/Articles/utils/getHeader.util';
import { IProductDescriptionPartial } from 'interfaces/IProduct';
import translate from 'translate';
import {
  BrandProvider,
  FocalPointImage,
  ContentfulRenderer,
  LinkText,
} from 'components';
import styles from './styles.module.scss';

interface IProductDescriptionComponentProps extends IProductDescriptionPartial {
  isCoastal: boolean;
}

const ProductDescriptionComponent = ({
  isCoastal,
  image,
  title,
  link,
  richDescription,
  longDescription,
}: IProductDescriptionComponentProps) => (
  <section
    className={styles.productDescriptionSection}
    id={title?.replace(' ', '')}
  >
    {image?.title && image?.focalPoint && (
      <div className={styles.imageContainer}>
        <FocalPointImage
          title={image.title}
          image={image.image}
          styles={styles.focalStyles}
          focalPoint={image.focalPoint}
        />
      </div>
    )}
    <div
      className={clsx(
        styles.productDescription,
        image && styles.productDescriptionWithImage
      )}
    >
      {isCoastal ? (
        <BrandProvider fixedBrand="coastal">
          <Header3>{title}</Header3>
        </BrandProvider>
      ) : (
        <Header3>{title}</Header3>
      )}
      {richDescription ? (
        <ContentfulRenderer
          fixedBrand="coastal"
          highestHeadingSize={HEADER_SIZE.H3}
        >
          {richDescription}
        </ContentfulRenderer>
      ) : (
        longDescription && (
          <BodyText className={styles.productDescriptionText}>
            {longDescription}
          </BodyText>
        )
      )}
      {link?.hyperlink && link?.title && (
        <LinkText to={link.hyperlink} as={Link} className={styles.linkStyle}>
          {translate('General_ReadMore')}
        </LinkText>
      )}
    </div>
  </section>
);

export default ProductDescriptionComponent;
