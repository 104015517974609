export const countParagraphs = (text: string): number => {
  if (!text) {
    return 1;
  }

  // Remove HTML tags except for <p> and </p>
  const cleanedString = text.replace(/<(?!p\s*\/?)[^>]+>/g, '');

  // Split by <p> tags and preserve line breaks
  const paragraphs = cleanedString.split(/<\/?p\s*\/?>/);

  // Remove empty strings and trim whitespace from paragraphs
  const nonEmptyParagraphs = paragraphs.filter(
    (paragraph) => paragraph.trim() !== ''
  );

  return nonEmptyParagraphs.length;
};
