import { SVGProps } from 'react';

const ClockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2.5C6.75329 2.5 2.5 6.75329 2.5 12C2.5 17.2467 6.75329 21.5 12 21.5C17.2467 21.5 21.5 17.2467 21.5 12C21.5 6.75329 17.2467 2.5 12 2.5ZM1.5 12C1.5 6.20101 6.20101 1.5 12 1.5C17.799 1.5 22.5 6.20101 22.5 12C22.5 17.799 17.799 22.5 12 22.5C6.20101 22.5 1.5 17.799 1.5 12Z"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9802 5.50024C12.2564 5.50024 12.4802 5.7241 12.4802 6.00024V11.7931L16.3338 15.6467C16.529 15.842 16.529 16.1585 16.3338 16.3538C16.1385 16.5491 15.8219 16.5491 15.6267 16.3538L11.6267 12.3538C11.5329 12.26 11.4802 12.1329 11.4802 12.0002V6.00024C11.4802 5.7241 11.7041 5.50024 11.9802 5.50024Z"
    />
  </svg>
);

export default ClockIcon;
