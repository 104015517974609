export const cabinsConverterToObject = (cabinParameters?: string) => {
  const cabin = {
    adults: 2,
    children: 0,
    infants: 0,
    accessibility: false,
  };
  if (cabinParameters && cabinParameters !== '') {
    const formatedCabinParameters = cabinParameters.replace(/[[\]]/g, '');
    const newCabin = JSON.parse(formatedCabinParameters);
    return [newCabin];
  }
  return [cabin];
};
