import { SVGProps } from 'react';

const CircleWithExclamationIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 1.69995C6.31142 1.69995 1.69995 6.31142 1.69995 12C1.69995 17.6885 6.31142 22.2999 12 22.2999C17.6885 22.2999 22.2999 17.6885 22.2999 12C22.2999 6.31142 17.6885 1.69995 12 1.69995ZM0.699951 12C0.699951 5.75913 5.75913 0.699951 12 0.699951C18.2408 0.699951 23.2999 5.75913 23.2999 12C23.2999 18.2408 18.2408 23.2999 12 23.2999C5.75913 23.2999 0.699951 18.2408 0.699951 12Z"
    />
    <path d="M12 6C11.45 6 11 6.51429 11 7.14286V12.8571C11 13.4857 11.45 14 12 14C12.55 14 13 13.4857 13 12.8571V7.14286C13 6.51429 12.55 6 12 6Z" />
    <path d="M11.1125 16.3491C10.8875 16.5881 10.75 16.9151 10.75 17.2421C10.75 17.5692 10.8875 17.8962 11.1125 18.1352C11.35 18.3616 11.675 18.5 12 18.5C12.325 18.5 12.65 18.3616 12.8875 18.1352C13.1125 17.8962 13.25 17.5692 13.25 17.2421C13.25 16.9151 13.1125 16.5881 12.8875 16.3491C12.4125 15.8836 11.575 15.8836 11.1125 16.3491Z" />
  </svg>
);

export default CircleWithExclamationIcon;
