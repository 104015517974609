import { handleActions, ReduxCompatibleReducer } from 'redux-actions';
import {
  fetchShipsStart,
  fetchShipsSuccess,
  fetchShipsFailed,
} from '../actions/ships';
import { IShipsState } from '../../interfaces/IShip';

const initialState: IShipsState = {
  shipsList: [],
  loading: false,
};

const reducer: ReduxCompatibleReducer<IShipsState, IShipsState> = handleActions(
  {
    [fetchShipsStart.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchShipsSuccess.toString()]: (state, action) => ({
      ...state,
      shipsList: action.payload.shipsList,
      loading: false,
    }),
    [fetchShipsFailed.toString()]: (state) => ({
      ...state,
      error: true,
      loading: false,
    }),
  },
  initialState
);

export default reducer;
