import { createActions, ActionFunctionAny, Action } from 'redux-actions';
import axios from 'axios-instance';

export const {
  fetchTourNamesStart,
  fetchTourNamesSuccess,
  fetchTourNamesFailed,
} = createActions({
  FETCH_TOUR_NAMES_START: undefined,
  FETCH_TOUR_NAMES_SUCCESS: (tourNames) => ({ tourNames }),
  FETCH_TOUR_NAMES_FAILED: undefined,
});

export const fetchTourNames =
  (brand: string) => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchTourNamesStart());
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}Tours/names?brand=${brand}`)
      .then((response) => {
        dispatch(fetchTourNamesSuccess(response.data));
      })
      .catch(() => {
        dispatch(fetchTourNamesFailed());
      });
  };
