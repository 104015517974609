import { SVGProps } from 'react';

const NoWindowIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={19}
    height={19}
    viewBox="0 0 19 19"
    {...props}
  >
    <g fillRule="evenodd">
      <path d="M3.75 1c-1.03 0-1.88.84-1.88 1.88v12.66c0 1.03.85 1.88 1.88 1.88h10.92c1.03 0 1.88-.85 1.88-1.88V2.88c0-1.04-.85-1.88-1.88-1.88H3.75zM.87 2.88A2.88 2.88 0 0 1 3.75 0h10.92c1.58 0 2.88 1.29 2.88 2.88v12.66c0 1.58-1.3 2.88-2.88 2.88H3.75c-1.59 0-2.88-1.3-2.88-2.88V2.88z" />
      <path d="M6.04 4.17c-.57 0-1.08.53-1.08 1.08v7.92c0 .58.5 1.08 1.08 1.08h6.33c.59 0 1.09-.51 1.09-1.08V5.25c0-.56-.52-1.08-1.09-1.08H6.04zM3.96 5.25c0-1.08.94-2.08 2.08-2.08h6.33c1.13 0 2.09.98 2.09 2.08v7.92a2.1 2.1 0 0 1-2.09 2.08H6.04c-1.13 0-2.08-.95-2.08-2.08V5.25z" />
      <path d="M18.27.15c.2.19.2.51 0 .7L.85 18.27c-.19.2-.51.2-.7 0-.2-.2-.2-.51 0-.71L17.56.15c.2-.2.51-.2.71 0z" />
    </g>
  </svg>
);

export default NoWindowIcon;
