import { SVGProps } from 'react';

const SandGlassIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={18}
    height={21}
    viewBox="0 0 18 21"
    fill="currentColor"
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M.643 21A.65.65 0 0 1 0 20.344a.65.65 0 0 1 .643-.657H2.57v-.656c0-3.429.994-6.538 2.67-8.337l.189-.194C3.64 8.736 2.571 5.525 2.571 1.969v-.657H.643A.647.647 0 0 1 .007.753L0 .656A.65.65 0 0 1 .643 0h16.714A.65.65 0 0 1 18 .656a.65.65 0 0 1-.643.656h-1.929v.657c0 3.429-.993 6.538-2.669 8.337l-.189.194.22.228c1.658 1.804 2.639 4.896 2.639 8.303l-.001.656h1.93c.322 0 .59.243.635.56l.007.097a.65.65 0 0 1-.643.656zM14.142 1.312H3.857v.657c0 3.452 1.096 6.493 2.777 7.866l.17.132a.664.664 0 0 1 0 1.066c-1.777 1.303-2.947 4.43-2.947 7.998v.656h10.285v-.656c0-3.452-1.095-6.493-2.776-7.866l-.17-.132a.664.664 0 0 1 0-1.066c1.777-1.303 2.947-4.43 2.947-7.998l-.001-.657z"
    />
  </svg>
);

export default SandGlassIcon;
