import { ITour } from 'interfaces/ITour';
import { getPromotionsFromPackageTour } from 'utils/promotions/getPromotionsFromPackageTour';
import getManualPromotions from 'utils/promotions/getManualPromotions';
import { IPromotionTag } from 'interfaces/IPromotionTag';

export const getPromotions = (
  tour: ITour,
  promotionTags: IPromotionTag[],
  isCoastal: boolean,
  coastalManualPromotions: IPromotionTag[],
  expeditionManualPromotions: IPromotionTag[]
) => {
  let promotionFromPackageTour: IPromotionTag[] = [];
  if (promotionTags) {
    promotionFromPackageTour = getPromotionsFromPackageTour(
      tour,
      promotionTags
    );
  }

  const manualPromotions: IPromotionTag[] = [];
  tour.packages.forEach((tourPackage) => {
    const promos = getManualPromotions(
      isCoastal,
      coastalManualPromotions,
      expeditionManualPromotions,
      tourPackage
    );

    manualPromotions.push(...promos);
  });
  const promotionsResult = promotionFromPackageTour.concat(manualPromotions);

  // make sure all promotions are unique based on title
  return promotionsResult.filter(
    (promotion: IPromotionTag, index: number, array: IPromotionTag[]) =>
      array.findIndex((tag) => tag.title === promotion.title) === index
  );
};
