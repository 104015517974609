import { SVGProps } from 'react';

const CreditcardIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={23}
    height={18}
    viewBox="0 0 23 18"
    {...props}
  >
    <path
      fill="#000"
      d="M18.83 0c.99 0 1.82.3 2.4.88.58.59.85 1.4.85 2.3v10.74c0 .9-.27 1.71-.85 2.3-.58.58-1.41.88-2.4.88H3.25c-.99 0-1.82-.3-2.4-.88-.58-.59-.85-1.4-.85-2.3V3.18c0-.9.27-1.71.85-2.3C1.43.3 2.26 0 3.25 0h15.58Zm2.249 12H.999L1 13.92c0 .7.21 1.24.56 1.59.35.36.9.59 1.69.59h15.58c.79 0 1.34-.23 1.69-.59.35-.35.56-.89.56-1.59L21.079 12ZM18.83 1H3.25c-.79 0-1.34.23-1.69.59-.35.35-.56.89-.56 1.59L.999 9h20.08l.001-5.82c0-.7-.21-1.24-.56-1.59-.35-.36-.9-.59-1.69-.59Z"
      fillRule="evenodd"
    />
  </svg>
);

export default CreditcardIcon;
