import clsx from 'clsx';
import translate from 'translate';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch } from 'hooks/storeHooks';
import { resetAuthError, setLoginStatus } from 'store/actions';
import { LOGIN_STATUSES } from 'containers/Login';
import { LinkText } from 'components';
import styles from './styles.module.scss';

interface ISignupLoginButtonGroup {
  isFilledHeader: boolean;
  isMobile?: boolean;
}

const SignupLoginButtonGroup = ({
  isFilledHeader,
  isMobile,
}: ISignupLoginButtonGroup) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const headerType = isMobile ? 'mobileheader' : 'desktopheader';

  const clickLoginHandler = () => {
    dispatch(setLoginStatus(LOGIN_STATUSES.WAIT_USER_EMAIL));
    dispatch(resetAuthError());
    navigate('/login');
  };

  const clickSignupHandler = () => {
    navigate('/onboard');
  };

  return (
    <div className={styles.signupLoginButtonGroup}>
      <div
        className={clsx(
          isMobile ? styles.mobileButtonContainer : styles.buttonContainer
        )}
      >
        <div style={{ marginRight: isMobile ? 8 : 20 }}>
          <LinkText
            className={clsx(styles.linkText, {
              [styles.isHeader]: isFilledHeader,
            })}
            id={`${headerType}_login_btn`}
            onClick={clickLoginHandler}
          >
            {translate('Navbar_Login')}
          </LinkText>
        </div>
        <LinkText
          className={clsx(styles.linkText, {
            [styles.isHeader]: isFilledHeader,
          })}
          id={`${headerType}_signup_btn`}
          onClick={clickSignupHandler}
        >
          {translate('General_SignUp')}
        </LinkText>
      </div>
    </div>
  );
};

export default SignupLoginButtonGroup;
