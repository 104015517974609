import styled from 'styled-components';
import { typography } from 'style/typography';
import { colors } from 'style/colors';

export interface LinkProps {
  to?: string;
  type?: string;
}

const LinkText = styled.a<LinkProps>`
  font-size: ${typography.normal.fontSize.small};
  font-family: ${typography.normal.fontFamily};
  color: ${({ theme }) => theme?.brandColors?.link || colors.shared.textBlack};
  background: transparent;
  border: none;
  padding: 0;
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
`;

export default LinkText;
