import { handleActions, ReduxCompatibleReducer } from 'redux-actions';
import {
  fetchPortsStart,
  fetchPortsSuccess,
  fetchPortsFailed,
  fetchDeckAvailabilityStart,
  fetchDeckAvailabilitySuccess,
  fetchDeckAvailabilityFailed,
} from '../actions/ports';
import { IPortsState } from '../../interfaces/IPort';

const initialState: IPortsState = {
  portsList: [],
  loading: false,
  isDeckSpaceAvalabilityLoading: false,
  isOnlyDeckSpaceAvailable: false,
  isDeckSpaceAndCabinAvailable: true,
};

const reducer: ReduxCompatibleReducer<IPortsState, IPortsState> = handleActions(
  {
    [fetchPortsStart.toString()]: (state) => ({
      ...state,
      loading: true,
    }),
    [fetchPortsSuccess.toString()]: (state, action) => ({
      ...state,
      portsList: action.payload.portsList,
      loading: false,
    }),
    [fetchPortsFailed.toString()]: (state) => ({
      ...state,
      error: true,
      loading: false,
    }),
    [fetchDeckAvailabilityStart.toString()]: (state) => ({
      ...state,
      isDeckSpaceAvalabilityLoading: true,
    }),
    [fetchDeckAvailabilitySuccess.toString()]: (state, { payload }) => ({
      ...state,
      isOnlyDeckSpaceAvailable: payload.isOnlyDeckSpaceAvailable,
      isDeckSpaceAndCabinAvailable: payload.isDeckSpaceAndCabinAvailable,
      isDeckSpaceAvalabilityLoading: false,
    }),
    [fetchDeckAvailabilityFailed.toString()]: (state) => ({
      ...state,
      error: true,
      isDeckSpaceAvalabilityLoading: false,
    }),
  },

  initialState
);

export default reducer;
