import { IPromotionTag } from 'interfaces/IPromotionTag';
import { IDetailedVoyage } from 'interfaces/IVoyage';

export const checkDateRange = (
  startDate: string,
  endDate: string,
  departureDate: string
): boolean =>
  new Date(startDate) <= new Date(`${departureDate}+00:00`) &&
  new Date(endDate) >= new Date(`${departureDate}+00:00`);

export default (
  isCoastal: boolean,
  coastalManualPromotions: IPromotionTag[],
  expeditionManualPromotions: IPromotionTag[],
  voyage: IDetailedVoyage
): IPromotionTag[] => {
  const results: IPromotionTag[] = [];

  if (
    isCoastal &&
    coastalManualPromotions &&
    voyage.fromPort?.code &&
    voyage.toPort?.code
  ) {
    coastalManualPromotions.forEach((coastalPromotion) => {
      if (
        coastalPromotion.fromPort === voyage.fromPort.code &&
        coastalPromotion.toPort === voyage.toPort.code &&
        checkDateRange(
          coastalPromotion.startDate,
          coastalPromotion.endDate,
          voyage.departureDate
        )
      ) {
        results.push(coastalPromotion);
      }
    });
  }

  if (expeditionManualPromotions && voyage.travelSuggestionCode) {
    expeditionManualPromotions.forEach((expeditionPromotion) => {
      if (
        expeditionPromotion.travelSuggestionCodes.includes(
          voyage.travelSuggestionCode
        ) &&
        checkDateRange(
          expeditionPromotion.startDate,
          expeditionPromotion.endDate,
          voyage.departureDate
        )
      ) {
        results.push(expeditionPromotion);
      }
    });
  }

  return results;
};
