import { ReactNode } from 'react';
import LoadingGif from './UI/Loading/LoadingGif';
import SomethingWentWrongBanner from './UI/SomethingWentWrongBanner';
import styles from './styles.module.scss';

interface ILoadingWrapper {
  loading: boolean;
  error?: boolean;
  children: ReactNode;
  hasScroll?: boolean;
  className?: string;
  timer?: number;
  alertText?: string;
  redirectPath?: string;
  isRedirect?: boolean;
}

const LoadingWrapperInner = ({
  loading,
  error = false,
  children,
  hasScroll = true,
  timer = 5000,
  alertText = '',
  redirectPath = '',
  isRedirect = true,
}: Omit<ILoadingWrapper, 'className'>) => {
  if (loading) {
    return (
      <div className={styles.loader}>
        <LoadingGif scroll={hasScroll} />
      </div>
    );
  }

  if (error) {
    return (
      <SomethingWentWrongBanner
        timer={timer}
        alertText={alertText}
        redirectPath={redirectPath}
        isRedirect={isRedirect}
      />
    );
  }

  return <>{children}</>;
};

const LoadingWrapper = ({ className, ...rest }: ILoadingWrapper) => (
  <div aria-live="polite" className={className}>
    <LoadingWrapperInner {...rest} />
  </div>
);

export default LoadingWrapper;
