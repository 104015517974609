import { ImageWithFocalPoint } from 'interfaces/IImage';
import clsx from 'clsx';
import stylesGeneral from './styles.module.scss';

interface IFocalPointImage extends ImageWithFocalPoint {
  styles?: unknown;
  alt?: string;
  isPresentation?: boolean;
  height?: number;
  width?: number;
  dataTestid?: string;
}

const FocalPointImage = ({
  image,
  focalPoint,
  styles,
  alt,
  isPresentation,
  height,
  width,
  dataTestid,
}: IFocalPointImage) => (
  <div
    className={clsx(stylesGeneral.focalPointImage, styles)}
    style={{ width: width || 'auto', height: height || '100%' }}
    data-testid={dataTestid}
  >
    <img
      key={image.url}
      className={stylesGeneral.coverImage}
      style={{
        objectPosition: `${(focalPoint.xAxis / image.width) * 100}% ${
          (focalPoint.yAxis / image.height) * 100
        }%`,
      }}
      src={image.url.includes('https') ? image.url : `https:${image.url}`}
      alt={isPresentation && alt}
      role={isPresentation && 'presentation'}
    />
  </div>
);

export default FocalPointImage;
