import { Header2, InfoText, Subtitle } from 'components/UI/Brand/text';
import translate from 'translate';
import Icon from 'components/UI/Icons/Icon';
import OutlinedButton from 'components/UI/Buttons/OutlinedButton/OutlinedButton';
import { COASTAL_OPTIONS, isSearchTypeCoastal } from 'utils/constants';
import { useAppSelector } from 'hooks/storeHooks';
import { useMemo } from 'react';
import { colors } from 'style/colors';
import styles from './styles.module.scss';

interface EmptyResultProps {
  onFetchMoreResults?: ({
    daysOffset,
    monthsOffset,
    newDestinationId,
  }: {
    daysOffset?: number;
    monthsOffset?: number;
    newDestinationId?: string;
  }) => void;
}

const EmptyResult = ({ onFetchMoreResults }: EmptyResultProps) => {
  const destinationList = useAppSelector(
    (state) => state.destinations.homeDestinations
  );
  const {
    searchType,
    coastalVoyage,
    params: searchParams,
  } = useAppSelector((state) => state.search);
  const isCoastal = isSearchTypeCoastal(searchType);
  const isIconicVoyage = [
    COASTAL_OPTIONS.NORTH_CAPE_EXPRESS,
    COASTAL_OPTIONS.SVALBARD_EXPRESS,
    COASTAL_OPTIONS.HURTIGRUTEN_HAMBURG,
    COASTAL_OPTIONS.SPITSBERGEN_ADVENTURER,
  ].includes(coastalVoyage);

  const renderDestination = useMemo(() => {
    const otherDestinations = destinationList
      .filter((destination) => destination.id !== searchParams.destinationId)
      .filter((destination) => destination.id !== '20927')
      .filter((destination) => destination.name.length <= 25);

    const suggestionList =
      otherDestinations.length >= 3
        ? otherDestinations.slice(0, 3)
        : otherDestinations;

    const suggestionButtons = suggestionList.map((destination) => (
      <div className={styles.button} key={destination.name}>
        <OutlinedButton
          onClick={() =>
            onFetchMoreResults({ newDestinationId: destination.id })
          }
        >
          {destination.name}
        </OutlinedButton>
      </div>
    ));

    return (
      <>
        <Subtitle className={styles.subTitle}>
          {translate('SearchResult_SeeOtherDestinations')}
        </Subtitle>
        <div className={styles.buttonContainer}>{suggestionButtons}</div>
      </>
    );
  }, []);

  return (
    <div className={styles.container}>
      <Header2 className={styles.title}>
        {isCoastal
          ? translate('SearchResult_NoCoastalVoyages')
          : translate('SearchResult_NoVoyages')}
      </Header2>
      <Icon
        height={80}
        width={80}
        color={colors.shared.warmGray3}
        iconType="searchAlert"
      />
      <div className={styles.descContainer}>
        <InfoText className={styles.desc}>
          {translate('SearchResult_TryDifferentSearchParams')}
        </InfoText>
      </div>

      {isCoastal && (
        <div className={styles.buttonContainerCenter}>
          <div className={styles.buttonCenter}>
            <OutlinedButton
              onClick={() =>
                onFetchMoreResults({ daysOffset: isIconicVoyage ? 30 : 7 })
              }
            >
              {translate(
                'SearchResult_SearchNextDays',
                isIconicVoyage ? 30 : 7
              )}
            </OutlinedButton>
          </div>
        </div>
      )}

      {!isCoastal && (
        <>
          <Subtitle className={styles.subTitle}>
            {translate(
              'SearchResult_SearchSuggestions',
              destinationList.find(
                (destination) => destination.id === searchParams.destinationId
              )?.name
            )}
          </Subtitle>
          <div className={styles.buttonContainer}>
            {new Array(3).fill(0).map((_, index) => (
              <div
                className={styles.button}
                key={`OutlinedButton-${index + 1}`}
              >
                <OutlinedButton
                  onClick={() =>
                    onFetchMoreResults({ monthsOffset: 3 * (index + 1) })
                  }
                >
                  {translate('SearchResult_SearchNextMonths', 3 * (index + 1))}
                </OutlinedButton>
              </div>
            ))}
          </div>
        </>
      )}

      {!isCoastal && destinationList.length > 1 && renderDestination}
    </div>
  );
};

export default EmptyResult;
