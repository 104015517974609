import styled from 'styled-components';
import { Icon } from 'components';
import { colors } from 'style/colors';
import translate from 'translate';

const CloseButton = styled.button`
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: block;
`;

export const CloseModalIcon = ({
  onClose,
  width = 24,
  height = 24,
  className,
}: {
  onClose: () => void;
  width?: number;
  height?: number;
  className?: string;
}) => (
  <CloseButton
    onClick={onClose}
    aria-label={translate('Search_MobileSearchBar_Close')}
    className={className}
  >
    <Icon
      iconType="cross"
      color={colors.shared.black}
      width={width}
      height={height}
    />
  </CloseButton>
);
