import { LinkText } from 'components';
import AnimatedArrow from 'components/UI/AnimatedArrow/AnimatedArrow';
import { Link } from 'react-router-dom';
import { searchQueryBuilder } from 'utils/search';
import { ILink } from 'interfaces/ILink';

type Props = {
  link: ILink;
};

const ArrowSearchLink = ({ link }: Props) => (
  <AnimatedArrow>
    <LinkText as={Link} to={`/book/${searchQueryBuilder(link)}`}>
      {link.title}
    </LinkText>
  </AnimatedArrow>
);

export default ArrowSearchLink;
