import { SVGProps } from 'react';

const CirclePlayIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={22}
    height={22}
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      fill="#000"
      d="M11 0c6.075 0 11 4.925 11 11s-4.925 11-11 11S0 17.075 0 11 4.925 0 11 0Zm0 1.5a9.5 9.5 0 1 0 0 19 9.5 9.5 0 0 0 0-19ZM8 7l7 4-7 4V7Z"
      fillRule="evenodd"
    />
  </svg>
);

export default CirclePlayIcon;
