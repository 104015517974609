import { Header2, BodyText } from 'components/UI/Brand/text';
import { FormEvent, useState } from 'react';
import { RoundButtonLoadable } from 'components/UI/Buttons/RoundButtonLoadable/RoundButtonLoadable';
import TextInput, {
  TextInputChangeEvent,
} from 'components/UI/TextInput/TextInput';
import inputValidator from 'utils/inputValidator';
import passwordValidator from 'utils/passwordValidator';
import debounce from 'utils/debounce';
import PasswordValidation from 'components/UI/PasswordValidation/PasswordValidation';
import translate from 'translate';
import { DEBOUNCE_TIME } from 'utils/constants';
import { IEmailValidation } from 'interfaces/IEmailValidation';
import { IPasswordValidation } from 'interfaces/IAuth';
import styles from './styles.module.scss';

interface ISetPasswordProps {
  title: string;
  desc: string;
  validatedEmail: string;
  emailDisabled: boolean;
  onSubmit: (value: string) => void;
  isLoading?: boolean;
}

const validation = debounce(
  (
    setPasswordValidation: (value: IPasswordValidation) => void,
    setEmailValidation: (value: IEmailValidation) => void,
    emailValidation: IEmailValidation,
    passwordValidation: IPasswordValidation,
    name: string,
    value: string
  ) => {
    if (name === 'email') {
      setEmailValidation({
        ...emailValidation,
        valid: inputValidator(name, value),
      });
    }
    if (name === 'password') {
      const newPasswordValidation = passwordValidator(
        value,
        passwordValidation
      );
      setPasswordValidation(newPasswordValidation);
    }
  },
  DEBOUNCE_TIME
);

const SetPassword = ({
  title,
  desc,
  validatedEmail,
  emailDisabled,
  onSubmit,
  isLoading = false,
}: ISetPasswordProps) => {
  const [email, setEmail] = useState(validatedEmail);
  const [password, setPassword] = useState('');
  const [validationVisible, setValidationVisible] = useState(false);
  const [emailValidation, setEmailValidation] = useState({
    valid: true,
    errorMessage: translate('SetPasswordForm_EmailValidation'),
  });
  const [passwordValidation, setPasswordValidation] =
    useState<IPasswordValidation>({
      valid: true,
      min8Chars: false,
      atLeastOneLowerCase: false,
      atLeastOneCapital: false,
      atLeastOneNumber: false,
      errorMessage: translate('SetPasswordForm_PasswordValidation'),
    });

  const setValues = (name: string, value: string) => {
    if (name === 'email') {
      setEmail(value);
    }
    if (name === 'password') {
      setPassword(value);
    }
  };

  const checkPasswordIsNull = (value: string) => {
    if (!value) {
      setPasswordValidation({
        ...passwordValidation,
        valid: false,
      });
      return true;
    }
    return false;
  };

  const onInputChanged = (event: TextInputChangeEvent) => {
    const { value, name } = event.target;
    if (!validationVisible && name !== 'email') {
      setValidationVisible(true);
    }
    setValues(name, value);
    validation(
      setPasswordValidation,
      setEmailValidation,
      emailValidation,
      passwordValidation,
      name,
      value
    );
  };

  const onContinueClick = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (
      !checkPasswordIsNull(password) &&
      emailValidation.valid &&
      passwordValidation.valid
    ) {
      onSubmit(password);
    }
  };

  return (
    <div className={styles.root}>
      <div>
        <Header2 marginBottom="five">{title}</Header2>
        <BodyText>{desc}</BodyText>
      </div>
      <div className={styles.mainContainer}>
        <form
          className={styles.leftCntr}
          onSubmit={(event) => onContinueClick(event)}
        >
          <div className={styles.inputContainer}>
            <TextInput
              className={styles.inputField}
              name="email"
              label={translate('SetPasswordForm_EmailLabel')}
              disabled={emailDisabled}
              placeholder={translate('SetPasswordForm_EmailPlaceholder')}
              value={email}
              onChange={onInputChanged}
              helperText={
                !emailValidation.valid ? emailValidation.errorMessage : null
              }
              error={!emailValidation.valid}
            />
          </div>
          <div className={styles.inputContainer}>
            <TextInput
              className={styles.inputField}
              id="onboard_password_input"
              isPassword
              name="password"
              label={translate('SetPasswordForm_PasswordLabel')}
              placeholder={translate('SetPasswordForm_Validation_MinimumChars')}
              value={password}
              onChange={onInputChanged}
              isAutoFocus
            />
            {validationVisible && (
              <PasswordValidation passwordValidation={passwordValidation} />
            )}
            <div className={styles.mandatoryField}>
              {translate('SetPasswordForm_MandatoryField')}
            </div>
            <div className={styles.buttonContainerRow}>
              <div className={styles.buttonContainer}>
                <RoundButtonLoadable
                  dataTestid="onboard_password_submit_btn"
                  disabled={
                    !emailValidation.valid ||
                    !passwordValidation.valid ||
                    !email ||
                    !password
                  }
                  loading={isLoading}
                  type="submit"
                >
                  {translate('General_Continue')}
                </RoundButtonLoadable>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SetPassword;
