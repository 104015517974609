import { ITour } from 'interfaces/ITour';
import { getThirtyUnixMinutesFromNow } from 'utils/constants';

export const updateVoyageExpireDate = (
  currentQuoteId: string,
  tours: ITour[]
): ITour[] =>
  tours.map((tour) => {
    const voyagePackages = tour.packages.map((voyage) => {
      if (voyage.quoteId === currentQuoteId) {
        return {
          ...voyage,
          expireDate: getThirtyUnixMinutesFromNow(),
        };
      }
      return voyage;
    });
    return { ...tour, packages: voyagePackages };
  });
