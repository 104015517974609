import { SVGProps } from 'react';

const DimensionsIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={20}
    height={20}
    fill="#000"
    viewBox="0 0 20 20"
    {...props}
  >
    <path
      d="m16.69 13.48 2.5 2.5c.03.03.056.063.077.098l.01.02a.31.31 0 0 1 .026.05l.01.031c.008.02.014.041.018.062l.002.017c.004.02.006.041.007.062v.04a.488.488 0 0 1-.005.042l.005-.062a.477.477 0 0 1-.013.11l-.007.024a.505.505 0 0 1-.164.25L16.69 19.19c-.19.2-.51.2-.71 0a.513.513 0 0 1 0-.71l1.647-1.641-3.787.001c-.28 0-.5-.23-.5-.5 0-.28.22-.5.5-.5l3.789-.001-1.649-1.649a.513.513 0 0 1 0-.71c.2-.19.52-.19.71 0ZM3 10.84c.28 0 .5.22.5.5v4.5H8c.28 0 .5.22.5.5 0 .27-.22.5-.5.5H3c-.27 0-.5-.23-.5-.5v-5c0-.28.23-.5.5-.5ZM18.84 0c.27 0 .5.23.5.5v10c0 .28-.23.5-.5.5h-10c-.28 0-.5-.22-.5-.5V.5c0-.27.22-.5.5-.5h10Zm-.5 1h-9v9h9V1ZM3 0c.03 0 .06.003.089.008L3 0c.047 0 .092.006.135.019l.024.008a.46.46 0 0 1 .13.065l.026.02A.52.52 0 0 1 3.36.15l2.5 2.501c.19.2.19.51 0 .71-.2.19-.52.19-.71 0L3.5 1.709V5.5c0 .249-.174.45-.41.492L3 6c-.27 0-.5-.22-.5-.5V1.711L.86 3.36c-.2.19-.52.19-.71 0-.2-.2-.2-.51 0-.71L2.68.12A.503.503 0 0 1 3 0Z"
      fillRule="evenodd"
    />
  </svg>
);

export default DimensionsIcon;
