import { Header3 } from 'components/UI/Brand/text';
import translate from 'translate';
import { CloseModalIcon } from 'components';
import { PrimaryButton } from '../../Buttons/PrimaryButton/PrimaryButton';
import styles from './styles.module.scss';

interface ITermsModal {
  onClose: () => void;
  content: string;
}

const TermsModal = ({ onClose, content }: ITermsModal) => (
  <div className={styles.modalCntr}>
    <div className={styles.container}>
      <Header3 className={styles.modalTitle}>
        {translate('Payment_TermsAndConditions')}
      </Header3>
      <CloseModalIcon onClose={onClose} width={16} height={16} />
    </div>
    <div
      id="contentDiv"
      className={styles.scrollableArea}
      dangerouslySetInnerHTML={{ __html: content }}
    />
    <div className={styles.btnRow}>
      <PrimaryButton onClick={onClose}>{translate('General_OK')}</PrimaryButton>
    </div>
  </div>
);

export default TermsModal;
