import { createActions, ActionFunctionAny, Action } from 'redux-actions';
import axios from '../../axios-instance';

export const {
  fetchClassicVoyagesStart,
  fetchClassicVoyagesSuccess,
  fetchClassicVoyagesFailed,
} = createActions({
  FETCH_CLASSIC_VOYAGES_START: undefined,
  FETCH_CLASSIC_VOYAGES_SUCCESS: (classicVoyagesList) => ({
    classicVoyagesList,
  }),
  FETCH_CLASSIC_VOYAGES_FAILED: undefined,
});

export const fetchClassicVoyages =
  () => (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchClassicVoyagesStart());
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}Tours/Classic`)
      .then((response) => {
        dispatch(fetchClassicVoyagesSuccess(response.data));
      })
      .catch(() => {
        dispatch(fetchClassicVoyagesFailed());
      });
  };
