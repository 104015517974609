import { ReactNode } from 'react';
import { isString, isStringOrNumber } from './typeCheckHelpers';

export function replaceString(
  string: string,
  substringToReplace: string,
  value: ReactNode
): ReactNode[] {
  if (isStringOrNumber(value)) {
    return [string.replaceAll(substringToReplace, String(value))];
  }

  const result: ReactNode[] = [];
  const splitArray = string.split(substringToReplace);
  splitArray.forEach((item, idx, arr) => {
    if (item) {
      // Remove empty strings caused by string split if it is splited at the start or at the end of the string
      result.push(item);
    }
    if (idx < arr.length - 1) {
      // Push React node in between every element in split array
      result.push(value);
    }
  });

  return result;
}

export function reactStringReplace(
  text: ReactNode | ReactNode[],
  match: string,
  value: ReactNode
): ReactNode[] | string {
  const nodeArray = Array.isArray(text) ? text : [text];

  const parsedArray = nodeArray
    .map((node) =>
      isString(node) ? replaceString(node as string, match, value) : node
    )
    .flat();

  if (parsedArray.length === 1 && isString(parsedArray[0])) {
    return parsedArray[0] as string;
  }
  return parsedArray;
}
