import { getLogoColors, IconProps } from './util';

const HrgLogo = ({ logoType, ...rest }: IconProps) => {
  const logoColors = getLogoColors(logoType);
  const { textColor, circleFillColor, innerCircleFillColor } = logoColors;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 407.93 84.32"
      height={24}
      {...rest}
    >
      <title>Hurtigruten Logo</title>
      <g>
        <path
          fill={textColor}
          d="M279.63,20.3h-6.75V12h6.55c2.9,0,4.35,1.35,4.35,3.7v.9C283.78,19,282.38,20.3,279.63,20.3Zm-100.8,0h-6.75V12h6.55c2.9,0,4.35,1.35,4.35,3.7v.9C183,19,181.58,20.3,178.83,20.3ZM401.23,5.75v8.1A164.82,164.82,0,0,0,402,31.4h-.5c-1.5-4.4-3-8.45-5.6-14.9L391.58,5.75H382v33.3h6.7v-8.8c0-6.4-.05-10.95-.4-17.15h.5c1.3,3.55,2.35,6.5,5,13.2l5.05,12.75h9.1V5.75Zm-40.05,19.4h13.05v-6.3H361.18v-6.8H376V5.75H354.38v33.3h22.1v-6.3h-15.3v-7.6ZM324.68,12h9.05v27.1h6.8V12h9.05V5.75h-24.9V12Zm-11,16.55c0,3.15-1.3,4.75-5.2,4.75h-1.15c-3.85,0-5.15-1.6-5.15-4.75V5.75h-6.8v23.8c0,6.8,4.8,10,11.85,10h1.35c7.05,0,11.9-3.2,11.9-10V5.75h-6.8V28.5Zm-23.1-12.1v-.7c0-6.2-4.25-9.95-10.9-9.95h-13.6v33.3h6.8V26.3h6.25l4.5,12.75h7.7l-6.05-14.2A9.12,9.12,0,0,0,290.58,16.4ZM222,39.05h6.8V5.75H222ZM192.38,12h9.05v27.1h6.8V12h9.05V5.75h-24.9V12Zm-2.6,4.45v-.7c0-6.2-4.25-9.95-10.9-9.95h-13.6v33.3h6.8V26.3h6.25l4.5,12.75h7.7l-6.05-14.2A9.12,9.12,0,0,0,189.78,16.4Zm-37.5,12.1c0,3.15-1.3,4.75-5.2,4.75h-1.15c-3.85,0-5.15-1.6-5.15-4.75V5.75H134v23.8c0,6.8,4.8,10,11.85,10h1.35c7,0,11.9-3.2,11.9-10V5.75h-6.8V28.5Zm-31.35-9.85h-12.1V5.75H102v33.3h6.8V25.25h12.1v13.8h6.8V5.75h-6.8Zm127.3-13.5h-1.55c-7,0-12,3.65-12,10.8v13.2c0,6.85,4.2,10.4,9.55,10.4h1.25a7.82,7.82,0,0,0,7.75-5.1h.5l1,4.6h4.7l.05-18.75h-13v5.6h6.65v2.55c0,3.1-1.75,5-5,5h-1.7c-3.3,0-5.05-1.8-5.05-5.2v-12c0-3.1,1.5-4.9,5.2-4.9H248c3.35,0,4.7,1.7,4.7,4.45v.45h6.75v-.6C259.48,9.2,255.48,5.15,248.23,5.15Z"
        />
        <path
          fill={textColor}
          d="M236,56.06c0,3.5-2,5.45-6,5.45h-7.15V49.66H230c4,0,6,2,6,5.5Zm-99.45-6.5h7c3.9,0,5.9,1.9,5.9,5.3v.9c0,3.4-2,5.3-5.7,5.3h-7.2Zm40.8,18.8c0,3.9-1.75,6.3-6.2,6.3h-.9c-4.45,0-6.2-2.4-6.2-6.3V55.46c0-3.9,1.75-6.3,6.2-6.3h.9c4.45,0,6.2,2.4,6.2,6.3Zm52.4-23.1h-11.6v33.3h4.7V65.81h7c5.9,0,10.85-3.2,10.85-9.6V55C240.68,49,236.48,45.26,229.73,45.26ZM206.63,69c0,3.55-1.75,5.7-6.3,5.7h-.9c-4.55,0-6.3-2.15-6.3-5.7V45.26h-4.7V69.31c0,6.55,4.5,9.85,11,9.85h.9c6.45,0,11-3.3,11-9.85V45.26h-4.7Zm-52.5-13.4v-.7c0-6.45-4.6-9.6-10.6-9.6h-11.7v33.3h4.7V65.36h7.65l5.35,13.2h5.2l-6.35-14.35A9,9,0,0,0,154.13,55.56Zm17-10.9h-.9c-6.45,0-10.9,3.8-10.9,10.65v13.2c0,6.85,4.45,10.65,10.9,10.65h.9c6.4,0,10.9-3.8,10.9-10.65V55.31C182,48.46,177.53,44.66,171.13,44.66Zm-56.9,0h-.8c-6.7,0-11.4,3.85-11.4,11v12.5c0,7.35,4.2,11,9.9,11h.85a7.46,7.46,0,0,0,7.15-4.6h.5l1.1,4.1h3.1V60.86H112.68v4h7.75V68c0,4.35-2.15,6.9-6.6,6.9h-.75c-4.4,0-6.35-2.45-6.35-7V55.71c0-4.4,2.1-6.75,6.65-6.75h.8c4.2,0,5.85,2.15,5.85,5.9v.55h4.6v-.75C124.63,48.26,120.78,44.66,114.23,44.66Z"
        />
        <circle cx="42.16" cy="42.16" r="42.16" fill={circleFillColor} />
        <rect
          x="50.94"
          y="19.52"
          width="11.35"
          height="45.42"
          fill={innerCircleFillColor}
        />
        <polygon
          points="22.04 65.52 50.55 37.06 33.39 37.06 33.39 19.52 22.04 19.52 22.04 65.52"
          fill={innerCircleFillColor}
        />
      </g>
    </svg>
  );
};

export default HrgLogo;
