import { IPrice } from 'interfaces/IPrice';

export const getMinimumPrice = (prices: IPrice[]) => {
  let minimumPrice = Infinity;
  let minimumStrikeThroughPrice = Infinity;

  prices.forEach(({ price, isAvailable, isSoldOut, priceDetail }) => {
    if (isAvailable && !isSoldOut && price < minimumPrice) {
      minimumPrice = price;
      minimumStrikeThroughPrice = priceDetail?.strikeThroughPrice;
    }
  });

  return {
    price: minimumPrice,
    strikeThroughPrice: minimumStrikeThroughPrice,
  };
};
