import { ChangeEvent, FocusEvent } from 'react';
import { colors } from 'style/colors';
import { IOption } from 'interfaces/IOption';
import { HelperText, Label } from '../Brand/text';
import DropdownComponent from './DropdownComponent';

interface IDropdownComponent {
  id: string;
  placeholder?: string;
  label?: string;
  value?: string;
  helperText?: string;
  name?: string;
  onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
  onFocus?: (event: FocusEvent<HTMLSelectElement>) => void;
  onBlur?: (event: FocusEvent<HTMLSelectElement>) => void;
  disabled?: boolean;
  error?: boolean;
  isRequired?: boolean;
  options: IOption[];
  width?: number;
  isHelperTextWhite?: boolean;
  defaultValue?: string | number;
  hasBorderRadius?: boolean;
}

const Dropdown = ({
  id,
  disabled,
  placeholder,
  label,
  options,
  value,
  error,
  helperText,
  name,
  onChange,
  onBlur,
  onFocus,
  width,
  isHelperTextWhite,
  isRequired = false,
  defaultValue,
  hasBorderRadius = true,
}: IDropdownComponent) => (
  <div style={{ width: width || '100%' }}>
    {label && (
      <Label htmlFor={id} hasError={error}>
        {label}
      </Label>
    )}
    <DropdownComponent
      id={id}
      disabled={disabled}
      placeholder={placeholder}
      name={name}
      options={options}
      value={value || undefined}
      hasError={error}
      onChange={onChange}
      style={{ boxSizing: 'border-box' }}
      aria-required={isRequired}
      onBlur={onBlur}
      onFocus={onFocus}
      defaultValue={defaultValue}
      hasBorderRadius={hasBorderRadius}
    />
    {helperText && (
      <HelperText
        hasError={error}
        style={{
          color: isHelperTextWhite && `${colors.shared.white} !important`,
        }}
      >
        {helperText}
      </HelperText>
    )}
  </div>
);

export default Dropdown;
