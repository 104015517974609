import { IProductPayload, IProductState } from 'interfaces/IProduct';
import { handleActions, ReduxCompatibleReducer } from 'redux-actions';
import {
  fetchProductsFailed,
  fetchProductsStart,
  fetchProductsSuccess,
} from 'store/actions';

const initialState: IProductState = {
  error: false,
  loading: false,
  expeditionProducts: [],
  coastalProducts: [],
};

const reducer: ReduxCompatibleReducer<IProductState, IProductPayload> =
  handleActions<IProductState, IProductPayload>(
    {
      [fetchProductsStart.toString()]: (state) => ({
        ...state,
        loading: true,
      }),
      [fetchProductsSuccess.toString()]: (state, action) => {
        const products = action.payload.isCoastal
          ? { coastalProducts: action.payload.products }
          : { expeditionProducts: action.payload.products };

        return {
          ...state,
          ...products,
          loading: false,
        };
      },
      [fetchProductsFailed.toString()]: (state) => ({
        ...state,
        error: true,
        loading: false,
      }),
    },
    initialState
  );

export default reducer;
