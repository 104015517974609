import { TBrand } from '../brand';
import {
  coastalButtonColors,
  expeditionButtonColors,
  groupButtonColors,
} from '../colorThemes/buttonColors';

export const getButtonColors = (brand: TBrand) => {
  if (brand === 'coastal') return coastalButtonColors;
  if (brand === 'expedition') return expeditionButtonColors;
  if (brand === 'group') return groupButtonColors;
  return groupButtonColors;
};
