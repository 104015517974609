import { Header3, BodyText } from 'components/UI/Brand/text';
import { SecondaryButton } from 'components/UI/Buttons/SecondaryButton/SecondaryButton';
import styles from './styles.module.scss';

interface LoginHelpBlockProps {
  title: string;
  desc: string;
  buttonText: string;
  onClick: () => void;
}

const LoginHelpBlock = ({
  title,
  desc,
  buttonText,
  onClick,
}: LoginHelpBlockProps) => (
  <div className={styles.signup}>
    <Header3 className={styles.rightTitle}>{title}</Header3>
    <BodyText className={styles.rightDesc}>{desc}</BodyText>
    <div className={styles.rightButtonCntr}>
      <SecondaryButton className={styles.buttonWhite} onClick={onClick}>
        {buttonText}
      </SecondaryButton>
    </div>
  </div>
);

export default LoginHelpBlock;
