import { IBrandTypography } from '../interfaces/IBrandTypography';

const expeditionTheme: IBrandTypography = {
  h0: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 48,
      tablet: 36,
      mobile: 30,
    },
    weight: 700,
    lineHeight: 1.25,
  },
  h1: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 48,
      tablet: 36,
      mobile: 30,
    },
    weight: 700,
    lineHeight: 1.25,
  },
  h2: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 34,
      tablet: 28,
      mobile: 26,
    },
    weight: 700,
    lineHeight: 1.25,
  },
  h3: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 24,
      tablet: 20,
      mobile: 20,
    },
    weight: 700,
    lineHeight: 1.25,
  },
  h4: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 18,
      tablet: 16,
      mobile: 16,
    },
    weight: 700,
    lineHeight: 1.25,
  },
  body: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 16,
      tablet: 16,
      mobile: 16,
    },
    weight: 400,
    lineHeight: 1.5,
  },
  info: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 14,
      tablet: 14,
      mobile: 14,
    },
    weight: 400,
    lineHeight: 1.5,
  },
  subtitle: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 20,
      tablet: 16,
      mobile: 16,
    },
    weight: 500,
    lineHeight: 1.5,
  },
  label: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 16,
      tablet: 14,
      mobile: 14,
    },
    weight: 500,
    lineHeight: 1.5,
  },
  button: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 16,
      tablet: 16,
      mobile: 16,
    },
    weight: 500,
    lineHeight: 1.4,
  },
  caption: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 12,
      tablet: 12,
      mobile: 12,
    },
    weight: 400,
    lineHeight: 1.5,
  },
  overline: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 14,
      tablet: 12,
      mobile: 12,
    },
    weight: 400,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  overlineBold: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 14,
      tablet: 12,
      mobile: 12,
    },
    weight: 500,
    lineHeight: 1.5,
    textTransform: 'uppercase',
  },
  tag: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 14,
      tablet: 12,
      mobile: 12,
    },
    weight: 400,
    lineHeight: 1.5,
  },
  tabular: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 16,
      tablet: 16,
      mobile: 14,
    },
    weight: 400,
    lineHeight: 1.5,
  },
  tabularHeader: {
    family: 'Atlas Grotesk',
    size: {
      desktop: 16,
      tablet: 16,
      mobile: 14,
    },
    weight: 700,
    lineHeight: 1.5,
  },
};

export default expeditionTheme;
