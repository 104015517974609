import {
  IQuoteCommitPayload,
  IQuotePassengersObject,
} from 'containers/Book/BookingProgress/BookingFunnel/PassengerDetails/PassengerDetails.types';
import {
  ICabinNumbersRequest,
  IAvailableCabinsResponse,
  IQuoteCabinsDataRequest,
} from 'interfaces/ICabin';
import { IQuoteCommitResult } from 'interfaces/ICommitQuote';
import {
  IQuote,
  IQuoteADPackage,
  IQuoteBookingNotes,
  IQuoteExtras,
} from 'interfaces/IQuote';
import { api } from './api';

const { REACT_APP_COMPANY } = process.env;

export const quoteApi = api.injectEndpoints({
  endpoints: (build) => ({
    getAvailableCabinNumbers: build.query<
      IAvailableCabinsResponse,
      ICabinNumbersRequest
    >({
      query: (deckData) => ({
        url: `Quote/fetch-available-cabins`,
        method: 'POST',
        body: { ...deckData, deckspaces: null },
      }),
    }),
    quoteCabin: build.mutation<IQuote, IQuoteCabinsDataRequest>({
      query: (cabinData) => ({
        url: `Quote/cabin`,
        method: 'POST',
        body: cabinData,
      }),
    }),
    quotePassengers: build.mutation<IQuote, IQuotePassengersObject>({
      query: (passengerData) => ({
        url: `Quote/passenger`,
        method: 'POST',
        body: passengerData,
      }),
    }),
    getQuote: build.query<IQuote, string>({
      query: (quoteId) => ({
        url: `Quote/${quoteId}`,
      }),
    }),
    quotePackages: build.mutation<IQuote, IQuoteADPackage>({
      query: (packagesData) => ({
        url: `Quote/packages`,
        method: 'POST',
        body: packagesData,
      }),
    }),
    deletePackage: build.mutation<IQuote, IQuoteADPackage>({
      query: (packagesData) => ({
        url: `Quote/packages`,
        method: 'DELETE',
        body: packagesData,
      }),
    }),
    quoteExtras: build.mutation<IQuote, IQuoteExtras>({
      query: (extraData) => ({
        url: `Quote/extras`,
        method: 'POST',
        body: extraData,
      }),
    }),
    quoteDeckSpace: build.mutation<
      IQuote,
      {
        quoteId: string;
        voyages: string[];
      }
    >({
      query: (deckSpaceData) => ({
        url: `Quote/deckspace`,
        method: 'POST',
        body: deckSpaceData,
      }),
    }),
    quoteBookingNotes: build.mutation<IQuote, IQuoteBookingNotes>({
      query: ({ quoteId, bookingNotes }) => ({
        url: `Quote/BookingNotes/${quoteId}/${bookingNotes}`,
        method: 'POST',
        body: { quoteId, bookingNotes },
      }),
    }),
    commitQuote: build.mutation<IQuoteCommitResult, IQuoteCommitPayload>({
      query: ({ commitQuoteData }) => ({
        url: `Quote/commit/${commitQuoteData.quoteId}`,
        method: 'POST',
        params: { productType: REACT_APP_COMPANY },
      }),
    }),
    // the method to test confirmation email
    testEmail: build.query<
      void,
      {
        bookingId: string;
      }
    >({
      query: ({ bookingId }) => ({
        url: `Quote/testEmail/${bookingId}`,
        method: 'GET',
      }),
    }),
  }),
});

export const {
  useGetAvailableCabinNumbersQuery,
  useQuoteCabinMutation,
  useGetQuoteQuery,
  useQuoteDeckSpaceMutation,
  useQuoteExtrasMutation,
  useQuotePassengersMutation,
  useCommitQuoteMutation,
  useQuoteBookingNotesMutation,
  useQuotePackagesMutation,
  useDeletePackageMutation,
  useTestEmailQuery,
} = quoteApi;

export const {
  endpoints: {
    getAvailableCabinNumbers,
    quoteCabin,
    getQuote,
    quotePackages,
    deletePackage,
    quoteExtras,
    quoteDeckSpace,
    quoteBookingNotes,
    quotePassengers,
    commitQuote,
    testEmail,
  },
} = quoteApi;
