import { INewsPayload } from 'interfaces/INewsState';
import { ISearchParams } from 'interfaces/ISearchParams';
import { Action, ActionFunctionAny, createActions } from 'redux-actions';
import axios from '../../axios-instance';

export const { fetchNewsStart, fetchNewsSuccess, fetchNewsFailed } =
  createActions({
    FETCH_NEWS_START: undefined,
    FETCH_NEWS_SUCCESS: (news: INewsPayload) => news,
    FETCH_NEWS_FAILED: (error) => ({ error }),
  });

export interface INewsSearchParams extends ISearchParams {
  isCoastal: boolean;
}

export const fetchNews =
  (searchParams: INewsSearchParams) =>
  (dispatch: ActionFunctionAny<Action<void>>) => {
    dispatch(fetchNewsStart());

    const searchQuery = new URLSearchParams(Object.entries(searchParams));

    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}news?${searchQuery}`)
      .then((res) => {
        dispatch(
          fetchNewsSuccess({
            ...res.data,
            news: res.data.items,
            isCoastal: searchParams.isCoastal,
          })
        );
      })
      .catch((err) => {
        dispatch(fetchNewsFailed(err));
      });
  };
