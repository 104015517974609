import { AlertBox, LinkText } from 'components';
import translate from 'translate';
import styles from './styles.module.scss';

export const TemporaryLoginAlert = () => (
  <AlertBox type="error">
    <div className={styles.title}>{translate('Login_ErrorMessage')}</div>
    <div id="form_helper_text">
      <span
        className={styles.italic}
      >{`${translate('General_PleaseNote')} `}</span>
      {translate(
        'Login_ErrorMessage_Temporary',
        <LinkText
          className={styles.bold}
          href="https://agentportal.travelhx.com/forgetpassword/"
        >
          Forgot Password
        </LinkText>
      )}
    </div>
  </AlertBox>
);
