import { SVGProps } from 'react';

const MessageIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 22.08 17.1"
    height={15}
    width="auto"
    {...props}
  >
    <g data-name="Layer 2" stroke="red">
      <path
        d="M18.83 0a3.3 3.3 0 0 1 2.4.88 3.2 3.2 0 0 1 .85 2.3v10.74a3.2 3.2 0 0 1-.85 2.3 3.3 3.3 0 0 1-2.4.88H3.25a3.3 3.3 0 0 1-2.4-.88 3.2 3.2 0 0 1-.85-2.3V3.18A3.2 3.2 0 0 1 .85.88 3.3 3.3 0 0 1 3.25 0ZM8 9.09l-6.22 6.52-.05.05a2.41 2.41 0 0 0 1.52.44h15.58a2.41 2.41 0 0 0 1.52-.44l-.05-.05-6.22-6.51-2.7 2.5a.5.5 0 0 1-.68 0Zm13-6.45-6.19 5.77 6.13 6.41a2.74 2.74 0 0 0 .14-.9V3.18a3.1 3.1 0 0 0-.08-.54ZM1 3.18v10.74a2.74 2.74 0 0 0 .14.9l6.13-6.41-6.22-5.78a3.12 3.12 0 0 0-.05.55ZM18.83 1H3.25a2.26 2.26 0 0 0-1.69.59l-.08.08h.05L11 10.55l9.53-8.84-.08-.09A2.26 2.26 0 0 0 18.83 1Z"
        style={{
          fillRule: 'evenodd',
        }}
        data-name="Layer 1"
      />
    </g>
  </svg>
);

export default MessageIcon;
