import { useCallback } from 'react';
import { InfoText } from 'components/UI/Brand/text';
import clsx from 'clsx';
import Icon from 'components/UI/Icons/Icon';
import styles from './styles.module.scss';

export enum StepStatus {
  COMPLETE = 1,
  CURRENT = 2,
  PENDING = 3,
}

export interface Step {
  label: string;
  status: StepStatus;
}

interface IProgressStepperProps {
  steps: Step[];
}

const ProgressStepper = ({ steps }: IProgressStepperProps) => {
  const getStepIcon = useCallback((stepStatus: StepStatus) => {
    const iconComplete = (
      <span className={styles.iconCheckmarkContainer}>
        <Icon className={styles.iconCheckmark} iconType="checkmark" />
      </span>
    );
    const iconCurrent = <span className={styles.iconCurrent} />;
    const iconPending = <span className={styles.iconPending} />;

    if (stepStatus === StepStatus.COMPLETE) {
      return iconComplete;
    }
    if (stepStatus === StepStatus.CURRENT) {
      return iconCurrent;
    }
    return iconPending;
  }, []);

  return (
    <div className={styles.wrapper} aria-hidden="true">
      <ul className={styles.container}>
        {steps.map((step) => (
          <li
            key={step.label}
            className={clsx(styles.step, {
              [styles.complete]: step.status === StepStatus.COMPLETE,
              [styles.current]: step.status === StepStatus.CURRENT,
            })}
          >
            {getStepIcon(step.status)}
            <InfoText className={styles.label}>{step.label}</InfoText>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProgressStepper;
