import { createActions } from 'redux-actions';

export const {
  openExtraModal,
  closeExtraModal,
  openAddExtraModal,
  closeAddExtraModal,
} = createActions({
  OPEN_ADD_EXTRA_MODAL: (addExtraModalData) => ({ addExtraModalData }),
  CLOSE_ADD_EXTRA_MODAL: undefined,
});
