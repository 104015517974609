import { useState } from 'react';
import { ITab } from 'interfaces/ITab';
import TabTertiaryHeader from 'components/UI/Tab/TabTertiary/TabTertiaryHeader';
import styles from './styles.module.scss';

const TabTertiary = ({ tabItems, activeTab }: ITab) => {
  const [activeTabIndex, setActiveTabIndex] = useState(activeTab || 0);

  return (
    <div className={styles.tabTernaryContainer}>
      <TabTertiaryHeader
        headerItems={tabItems.map(({ name }, id) => ({
          name,
          id,
        }))}
        setActiveTabIndex={(index) => {
          setActiveTabIndex(index);
        }}
        activeTabIndex={activeTabIndex}
      />
      {tabItems[activeTabIndex] ? tabItems[activeTabIndex].content : null}
    </div>
  );
};

export default TabTertiary;
