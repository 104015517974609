import ContentfulRenderer from 'components/ContentfulRenderer';
import clsx from 'clsx';
import { Document } from '@contentful/rich-text-types';
import { BodyText } from 'components/UI/Brand/text';
import {
  getHeaderBasedOnSize,
  HEADER_SIZE,
} from './Articles/utils/getHeader.util';
import styles from './styles.module.scss';

interface TitleAndDescriptionStyle {
  description?: string;
  isArticle?: boolean;
  isArticleContainer?: boolean;
  descriptionAsNode?: Document;
  titleClassName?: string;
  className?: string;
}

interface ITitleAndDescription extends TitleAndDescriptionStyle {
  highestHeadingSize?: HEADER_SIZE;
  hasMargin?: boolean;
  title?: string;
}

const TitleAndDescription = ({
  description,
  highestHeadingSize = HEADER_SIZE.H2,
  isArticle = false,
  isArticleContainer = false,
  descriptionAsNode,
  hasMargin,
  title,
  titleClassName,
  className,
}: ITitleAndDescription) => (
  <div
    className={clsx(styles.titleAndDescription, className, {
      [styles.hasMargin]: hasMargin,
      [styles.isArticle]: isArticle,
      [styles.isArticleContainer]: isArticleContainer,
    })}
  >
    {title &&
      getHeaderBasedOnSize({
        title,
        highestHeadingSize,
        highestDOMHeadingSize: HEADER_SIZE.H1,
        classes: `${styles.title} ${titleClassName || ''}`,
      })}
    {descriptionAsNode ? (
      <div
        className={clsx(styles.descriptionAsNode, {
          [styles.isArticle]: isArticle,
        })}
      >
        <ContentfulRenderer>{descriptionAsNode}</ContentfulRenderer>
      </div>
    ) : (
      !!description && (
        <BodyText
          className={clsx(styles.descriptionAsNode, {
            [styles.isArticle]: isArticle,
          })}
        >
          {description}
        </BodyText>
      )
    )}
  </div>
);

export default TitleAndDescription;
