import React, { ReactNode } from 'react';
import Icon from '../Icons/Icon';
import styles from './styles.module.scss';

interface IBackDropProps {
  onClick: () => void;
  children: ReactNode;
}

export const Backdrop = ({ onClick, children }: IBackDropProps) => (
  <div className={styles.backdrop} onClick={onClick}>
    <Icon
      className={styles.backdropCloseButton}
      iconType="cross"
      color="white"
    />
    <div
      className={styles.backdropContent}
      onClick={(e: React.MouseEvent) => e.stopPropagation()}
    >
      {children}
    </div>
  </div>
);
